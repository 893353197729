.fl-mobile-nav-sticky {
    position: fixed;
    height: 60px;
    z-index: 99;
}

.fl-mobile-top-nav-main.fl-mobile-top-nav-main-light {
    background-color: #fff !important;
    border-bottom: 1px solid #00000020 !important;
}

.fl-mobile-top-nav-main.fl-mobile-top-nav-main-dark {
    background-color: #000 !important;
    border-bottom: 1px solid #ffffff20 !important;
}

.fl-mobile-top-nav-main {
    width: 100%;
    height: 60px;
    left: 0;
    top: 0;
    z-index: 10;
    position: fixed;
    background: white;
}

.fl-mobile-top-navbar-inner {
    /* padding: 20px; */
    padding: 0 20px 0 20px;
    display: flex;
    justify-content: space-between;
    align-items: center;
    height: 60px;
}

.fl-mobile-navbar-logo {
    width: 60px;
    float: left;
    display: flex;
    align-items: center;
}

.fl-mobile-navbar-logo img {
    width: 100%;
}

.fl-mobile-navbar-logo-force-min {
    height: 60px;
    display: flex;
}

.fl-mobile-navbar-locations {
    font-weight: 600;
    display: flex;
    align-items: center;
    margin-left: 30px;
}

.fl-mobile-navbar-locations span {
     margin-top: 5px;
 }

.fl-mobile-top-nav-main-dark .fl-mobile-navbar-locations {
    color: white;
}