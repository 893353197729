.fl-expanding-content {
    transition: 0.4s max-height;
    overflow: hidden;
    width: 100%;
}

.fl-expanding-content-header {
    display: flex;
    width: 100%;
}

.fl-expanding-content-header svg {
    transition: transform 0.3s;
    transform: rotate(0deg) scale(1.3);
}

.fl-expanding-content-header-active svg {
    transform: rotate(180deg) scale(1.3);
}

.fl-expanding-content:hover {
    cursor: pointer;
}

.fl-expanding-content-header > span {
    margin-right: 5px;
}

.fl-expanding-content > span {
    margin-left: 5px;
    display: block;
    margin-top: 20px;
}

.fl-expanding-content-expand {
    max-height: calc(5rem + 30px);
}

.fl-expanding-content-shrink {
    max-height: 1rem;
}