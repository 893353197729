.fl-preview-main {
    width: 100%;
    flex: 1;
    height: 100%;
}

.fl-preview-main.fl-preview-main-light {
    background-color: #f4f4f4;
}

.fl-preview-main.fl-preview-main-dark {
    background-color: #1b1b1b;
}