.fl-settings-email p {
    color: #888888;
}

.fl-settings-email button {
    /*float: left;*/
    margin-top: 0px;
}

.fl-verify-button-float {
    float: left;
}

.fl-settings-email-verification {
    text-decoration: underline;
}

.fl-settings-email a {
    color: #888888;
    text-decoration: none;
}

.fl-settings-email a:hover {
    text-decoration: underline;
}

.fl-settings-email-p {
    float: left;
    width: 100%;
}