.fl-options-wrapper {
  display: flex;
  flex-direction: column;
}

.fl-options-container {
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
}

.fl-options-container div span {
  cursor: pointer !important;
}

.fl-options-wrapper-dark {
  color: white;
}

.fl-options-span {
  margin-left: 10px;
}

.fl-options-wrapper > p {
  margin-top: 0px;
  font-weight: 600;
}

.fl-options-container > div:hover {
  cursor: pointer;
}

.fl-sun-icon-option {
  transform: scale(1.2);
}

#fl-options-fixed-dark,
#fl-posting-fixed-dark {
  position: fixed;
  max-width: 367px;
  background-color: #131313;
  z-index: 1;
  transition: all 0.5s;
  top: 100px;
  z-index: 99;
}

#fl-posting-fixed-dark {
  top: 210px;
}
#fl-posting-fixed-dark span {
  color: black;
}
.fl-options-hidden,
.fl-posting-hidden {
  display: none;
}

@media (max-width: 1199.98px) {
  #fl-options-fixed-dark {
    max-width: 300px;
  }
}

#fl-options-fixed-light,
#fl-posting-fixed-light {
  max-width: 367px;
  background-color: #cccccc;
  z-index: 1;
  top: 100px;
  position: fixed;
  transition: 0.5s;
  z-index: 99;
}

#fl-posting-fixed-light {
  top: 100px;
}

#fl-posting-fixed-light span {
  color: black;
}

@media (max-width: 1199.98px) {
  #fl-options-fixed-light,
  #fl-posting-fixed-light {
    max-width: 300px;
  }
}
