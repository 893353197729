.fl-cards-main {
    width: 100%;
    flex: 1;
}

.fl-cards-inner {
    display: flex;
    padding: 20px;
    flex-wrap: wrap;
}

.fl-cards-single {
    width: calc(100% / 3);
    padding: 20px;
    display: flex;
}

.fl-image-section-main {
    width: 100%;
    overflow: hidden;
}

.fl-image-section-main.fl-image-section-main-light {
    background-color: #f4f4f4;
}

.fl-image-section-main.fl-image-section-main-dark {
    background-color: #1B1B1B;
}

.fl-image-section-inner {
    padding: 50px 20px;
}

.fl-featured-profiles {
    width: 100%;
}

.fl-featured-profiles-inner {
    padding: 50px 20px;
}

.fl-featured-profiles-inner {

}

.fl-featured-profiles-title {
    text-align: center;
    font-size: 36px;
    line-height: 48px;
    font-weight: 600;
}

.fl-featured-profiles-title.fl-featured-profiles-title-light {
    color: #000;
}

.fl-featured-profiles-title.fl-featured-profiles-title-dark {
    color: #fff;
}

.fl-featured-profiles-button {
    display: flex;
    justify-content: center;
}

.fl-featured-profiles-list {
    padding: 50px 0px;
    display: flex;
    flex-wrap: wrap;
}

.fl-home-page-featured .fl-profile-card-main {
    height: 240px;
}

.fl-featured-profiles-list .fl-profile-holder {
    border-radius: unset;
}

.fl-profile-card-holder {
    width: 50%;
    padding: 20px;
}

.fl-profile-card-holder .fl-profile-card-box {
    width: 170px;
    height: 70px;
    position: absolute;
    top: 0px;
    left: -50px;
    transform: rotate(-45deg);
}

.fl-profile-card-box .fl-profile-card-box-angle {
    position: absolute;
    padding: 0px;
    bottom: 0;
    font-size: 15px;
    color: white;
    background: #a020f0;
    width: 190px;
    height: 50px;
    text-align: center;
    display: table;
}

.fl-profile-card-box .fl-profile-card-box-angle  span {
    display: table-cell;
    vertical-align: middle;
    padding-left: 25px;
    padding-right: 43px;
    font-weight: 600;
}

.fl-profile-card-box .fl-profile-card-box-angle > span > b {
    font-size: 25px;
}

/* Large devices (desktops, less than 1200px) */
@media (max-width: 1199.98px) { 
    .fl-featured-profiles-title {
        font-size: 36px;
        line-height: 42px;
    }
 }

/* Medium devices (tablets, less than 992px) */
@media (max-width: 991.98px) { 
    .fl-cards-single {
        padding: 15px;
    }
    .fl-featured-profiles-title {
        font-size: 28px;
        line-height: 32px;
    }
    .fl-home-page-featured .fl-profile-card-image {
        height: 300px;
    }
    .fl-home-page-featured .fl-profile-card-main {
        height: auto;
    }
}

/* Small devices (landscape phones, less than 768px) */
@media (max-width: 767.98px) { 

 }

/* Extra small devices (portrait phones, less than 576px) */
@media (max-width: 575.98px) { 
    .fl-cards-single {
        width: 100%;
    }
    .fl-cards-inner {
        display: block;
    }
    .fl-profile-card-holder {
        width: 100%;
    }
}