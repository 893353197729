.fl-purchased-sub-section {
    width: 100%;
    margin-bottom: 30px;
    margin-top: 30px;
}

.fl-purchased-sub-payment-transactions-light {
    color: #000;
}

.fl-purchased-sub-payment-transactions {
    width: 100%;
}

.fl-purchased-sub-payment-transactions-single-head {
    background-color: #424242 !important;
    color: #fff;
    font-weight: 500;
    text-transform: uppercase;
}

.fl-purchased-sub-payment-transactions-single {
    width: 100%;
    display: flex;
    min-height: 40px;
    align-items: center;
}

.fl-purchased-sub-payment-transactions-single-currency,
.fl-purchased-sub-payment-transactions-single-seller,
.fl-purchased-sub-payment-transactions-single-amount,
.fl-purchased-sub-payment-transactions-single-date {
    width: 23%;
    padding: 0px 10px;
}

.fl-purchased-sub-payment-transactions-single-img {
    width: 8%;
    padding: 0px 10px;
    outline: none;
}

.fl-purchased-sub-payment-transactions-single-img div {
    outline: none;
}

.fl-filter-head-actions-dropdown {
    outline: none;
}

.fl-purchased-sub-payment-transactions-single-light {
    background-color: #00000005;
}

.fl-purchased-sub-payment-transactions-single-light:nth-of-type(odd) {
    background-color: #00000010;
}

.fl-purchased-sub-payment-transactions-single-dark {
    background-color: #ffffff05;
}

.fl-purchased-sub-payment-transactions-single-dark:nth-of-type(odd) {
    background-color: #ffffff10;
}

.fl-purchased-sub-payment-transactions-single-date-short {
    display: block;
}

.fl-purchased-sub-payment-transactions-single-date-long {
    display: none;
}

.fl-purchased-sub-payment-transactions-dark {
    color: #fff;
}

.fl-purchased-sub-payment-transactions-single-date:hover .fl-purchased-sub-payment-transactions-single-date-short {
    display: none;
}

.fl-purchased-sub-payment-transactions-single-date:hover .fl-purchased-sub-payment-transactions-single-date-long {
    display: block;
}

.fl-purchased-sub-payment-transactions-single-date-long span {
    color: #888;
}

.fl-purchased-sub-payment-transactions-more {
    color: #ff0d72;
    padding: 10px 0px;
    margin-top: 10px;
    cursor: pointer;
}

.fl-purchased-sub-payment-transactions-single-seller span {
    color: #15c;
    cursor: pointer;
}

.fl-purchased-sub-payment-transactions-single-seller:hover span {
    text-decoration: underline;
}

.fl-purchased-sub-payment-transactions-single-date,
.fl-purchased-sub-payment-transactions-single-img{
    cursor: default;
}

.fl-purchased-file .fl-filter-head-actions-dropdown {
    width: 200px;
    right: 1px;
    top: 44px;
}

.fl-purchased-file .fl-dropdown-filter-option {
    cursor: pointer;
}

.fl-purchased-sub-payment-transactions-single-img .smooth-image-wrapper {
    cursor: pointer;
}

.fl-purchased-sub-payment-transactions-single-img .smooth-image-wrapper img {
    width: 50px;
    height: 50px;
    object-fit: cover;
    padding: 5px;
    border-radius: 15%;
    cursor: pointer;
    object-fit: contain;
}

/* Medium devices (tablets, less than 992px) */
@media (max-width: 991.98px) {

}

/* Small devices (landscape phones, less than 768px) */
@media (max-width: 767.98px) {
    .fl-purchased-sub-payment-transactions-single-head {
        display: none !important;
    }
    .fl-purchased-sub-payment-transactions-single {
        display: block;
        height: auto;
        padding: 20px 10px;
    }
    .fl-purchased-sub-payment-transactions-single-date,
    .fl-purchased-sub-payment-transactions-single-amount,
    .fl-purchased-sub-payment-transactions-single-currency {
        width: 100%;
    }
    .fl-purchased-sub-payment-transactions-single-img {
        width: 100%;
        display: flex;
        justify-content: center;
    }
    .fl-purchased-sub-payment-transactions-single-date,
    .fl-purchased-sub-payment-transactions-single-date-short,
    .fl-purchased-sub-payment-transactions-single-seller,
    .fl-purchased-sub-payment-transactions-single-amount,
    .fl-purchased-sub-payment-transactions-single-currency {
        text-align: center;
    }
    .fl-purchased-sub-payment-transactions-single-seller {
        width: 100%;
    }
    .fl-purchased-sub-payment-transactions-single-img .smooth-image-wrapper {
        width: 200px;
        height: 200px;
    }
    .fl-purchased-sub-payment-transactions-single-img .smooth-image-wrapper img {
        height: 100%;
        width: 100%;
    }
    .fl-purchased-sub-payment-transactions-single {
        line-height: 22px;
    }
}

/* Extra small devices (portrait phones, less than 576px) */
@media (max-width: 575.98px) {
}
