.fl-support-icon {
  font-size: 30px;
}

.fl-support-icon-light {
  color: #000;
}

.fl-support-icon-dark {
  color: white;
}

.fl-support-box {
  position: relative;
  text-align: center;
}

.fl-support-box svg {
  cursor: pointer;
}

.fl-support-text {
  font-size: 21px;
  line-height: 21px;
  font-weight: 600;
  margin-top: 20px;
  display: block;
  text-decoration: none;
}

.fl-support-text:hover {
  color: #525252 !important;
}

.fl-support-text.fl-support-text-light {
  color: #000;
}
.fl-support-text.fl-support-text-dark {
  color: #fff;
}

.fl-support-link {
  font-size: 14px;
  text-decoration: none;
  color: #888888;
  text-align: right;
}

@keyframes shakeBug {
  0% {
    transform: rotate(0deg);
  }
  16% {
    transform: rotate(15deg);
  }
  32% {
    transform: rotate(-15deg);
  }
  48% {
    transform: rotate(15deg);
  }
  64% {
    transform: rotate(-15deg);
  }
  80% {
    transform: rotate(15deg);
  }
  100% {
    transform: rotate(0deg);
  }
}

.fl-support-bug {
  /* position: absolute;
  top: 125px; */
  position: fixed;
  top: 95vh;
  left: 96vw !important;
  display: inline;
  right: 5px;
  font-size: 24px;
  color: #888888;
  transition: 300ms color ease-in-out;
  z-index: 1;
}

.fl-support-bug-left {
  left: 5px;
}

.fl-support-bug-right {
  right: 5px;
}

.fl-support-bug:hover {
  color: #a408ff;
}

.fl-support-bug:hover .fl-support-bug-icon {
  animation: shakeBug 1000ms forwards ease-in-out;
}

/* Large devices (desktops, less than 1200px) */
@media (max-width: 1199.98px) {
  .fl-support-icon {
    font-size: 24px;
  }
  .fl-support-text {
    font-size: 18px;
  }
  .fl-support-link {
    font-size: 16px;
  }
}

/* Medium devices (tablets, less than 992px) */
@media (max-width: 991.98px) {
}

/* Small devices (landscape phones, less than 768px) */
@media (max-width: 767.98px) {
}

/* Extra small devices (portrait phones, less than 576px) */
@media (max-width: 575.98px) {
}
