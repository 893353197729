.fl-support-main {
    width: 100%;
    flex: 1;
    height: 100%;
    padding-top: 15px;
}

.fl-support-main.fl-support-main-light {
    background-color: #f4f4f4;
}

.fl-support-main.fl-support-main-dark {
    color: white;
    background-color: #1b1b1b;
}

.fl-support-col {
    padding: 0px 15px;
}

.fl-support-col-holder {
    padding: 0px 15px;
}

.fl-support-subroutes {
    padding: 25px 0px;
}

.fl-support-subroutes-link {
    display: block;
    text-decoration: none;
    padding: 20px;
    font-size: 20px;
    border-top: 1px solid;
    font-weight: 500;
}
.fl-support-subroutes-link:nth-last-of-type(1) {
    border-bottom: 1px solid;
}

.fl-problem-reply {
    border-top: 1px solid #0000001c;
}

.fl-support-subroutes-link.fl-support-subroutes-link-dark {
    color: #fff;
    border-color: #ffffff20;
}
.fl-support-subroutes-link.fl-support-subroutes-link-light {
    color: #000;
    border-color: #00000020;
}

.fl-support-subroutes-link-active {
    color: #fff !important;
    background: linear-gradient(to right, #FF037C, #FF4443);
}

.fl-support-bug-section .fl-minput-input-outer,
.fl-support-feature-section .fl-minput-input-outer  {
    margin: 20px 0px;
}

.fl-support-bug-section textarea,
.fl-support-feature-section textarea{
    overflow: hidden;
}

.fl-problem-text {
    padding: 0px 30px;
    font-size: 16px;
    line-height: 33px;
    padding-bottom: 10px;
}

.fl-problem-subject-light,
.fl-problem-text-light {
    color: #888888;
}

.fl-problem-subject-dark,
.fl-problem-text-dark {
    color: #ffffff;
}

.fl-problem-subject {
    padding: 0px 30px;
    font-size: 16px;
    font-weight: normal;
}

.fl-problem-comments .fl-simple-comment-problem-main:not(.fl-problem-comments .fl-simple-comment-problem-main:last-child) {
    padding-bottom: 20px;
}

.fl-problem-comments .fl-simple-comment-problem-main:last-child {
    padding-bottom: 0px;
}

.fl-simple-comment-problem-main .fl-user-profile-verified{
    position: unset;
    bottom: 0;
    right: 0px;
    margin-left: 2px;
}

.fl-support-closed {
    color: #ff0d72;
}

.fl-support-closed-div span {
    display: block;
}

.fl-support-closed-div div {
    display: flex;
}

.fl-support-closed-div div span:first-child {
    width: 160px;
}

.fl-support-head-info .fl-post-head-info-time{
    width: 25%;
}

.fl-ticket-attachments {
    display: grid;
    grid-template-columns: minmax(0,1fr) minmax(0,1fr) minmax(0,1fr);
    grid-column-gap: 10px;
    -webkit-column-gap: 10px;
    column-gap: 10px;
    width: 100%;
    align-items: center;
    margin-top: 15px;
}

.fl-ticket-attachments .fl-file-ticket-image video,
.fl-ticket-attachments .fl-file-ticket-image image {
    width: 100%;
}

.fl-file-ticket-comment-image {
    width: 80px;
    height: 80px;
    margin-right: 10px;
}

.fl-file-ticket-comment-main {
    padding: 5px 0px 0px 0px;
    width: 100%;
}

.fl-file-ticket-main {
    padding: 0px 20px 20px 20px;
    width: 100%;
}

.fl-file-ticket-image .smooth-image-wrapper,
.fl-file-ticket-comment-image .smooth-image-wrapper {
    cursor: pointer;
}

.fl-file-ticket-image .smooth-image-wrapper img,
.fl-file-ticket-comment-image .smooth-image-wrapper img
{
    width: 100%;
    height: 100%;
    object-fit: contain;
    object-position: center;
}

/*.fl-single-ticket .fl-support-head-info {*/
/*    width: calc(100% - 100px);*/
/*}*/
.fl-support-filter {
    color: #888888;
    text-align: center;
    font-weight: 600;
    font-size: 16px;
    padding: 15px;
}

.fl-support-filter span {
    cursor: pointer;
}

.fl-support-filter .active {
    color: #ff0d72;
}

.fl-support-filter b {
    font-weight: normal;
}

.fl-problem-time {
    cursor: default;
}

.fl-problem-comment {
    cursor: default;
    display: flex;
    align-items: center;
}

.fl-problem-comment .fl-user-profile-verified-crt {
    color: #4690cd;
}

.fl-support-head-info .fl-post-head-info-username:hover {
    color: #888888;
    cursor: default;
}

.fl-ticket-closed-light {
    background-color: #e6e5e5 !important;
}

.fl-ticket .fl-settings-sub-title {
    padding: 15px;
}

.fl-simple-comment-name-no-hover.fl-simple-comment-name-light:hover {
    color: #000;
}

.fl-tickets-empty {
    text-align: center;
    padding: 40px 20px;
}

.fl-support-closed-div {
    line-height: 23px;
}

/* Large devices (desktops, less than 1200px) */
@media (max-width: 1199.98px) {
    .fl-ticket .fl-post-head-info .fl-align-center {
        display: block;
    }
    .fl-ticket .fl-post-head-info-dot {
        display: none;
    }
}

/* Medium devices (tablets, less than 992px) */
@media (max-width: 991.98px) { 

}

/* Small devices (landscape phones, less than 768px) */
@media (max-width: 767.98px) { 
    .fl-col-4-sm {
        width: calc(calc(100% / 12) * 4);
      }
 }

/* Extra small devices (portrait phones, less than 576px) */
@media (max-width: 575.98px) {
    .xsm-block-view {
        display: block;
    }
    .fl-col-4-sm {
        width: auto;
    }
    .fl-support-closed-div div {
       display: block;
    }
    .fl-ticket-attachments {
        display: block;
    }
}