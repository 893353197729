.fl-unapproved-box {
    text-align: center;
}

.fl-unapproved-box span {
    color: white;
    font-weight: 500;
}

.fl-unapproved-box a {
    color: white;
}

.fl-unapproved-box a:hover {
    text-decoration: none;
    background: white;
    color: #FF0D72;
}

.fl-trial-box .fl-referral-text {
    color: white;
    line-height: 1.4;
}

.fl-unapproved-box {
    background: linear-gradient(to right, #FF037C, #FF4443) !important;
}

.fl-trial-box {
    background: linear-gradient(to bottom, #ffb659, #ff840f);
}

/* Large devices (desktops, less than 1200px) */
@media (max-width: 1199.98px) {

}

/* Medium devices (tablets, less than 992px) */
@media (max-width: 991.98px) {
}

/* Small devices (landscape phones, less than 768px) */
@media (max-width: 767.98px) {
}

/* Extra small devices (portrait phones, less than 576px) */
@media (max-width: 575.98px) {
}
