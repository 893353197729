@import "~react-image-gallery/styles/css/image-gallery.css";

html,body, #root, .fl-app {
  height: 100%;
}

.fl-app {
  display: flex;
  flex-direction: column;
}

.fl-app-mobile-post {
  padding-top: 0px !important;
}

.fl-app-mobile-post .fl-main-container {
  margin-top: 0px;
}

.fl-app-mobile-post .fl-simple-card-main {
  box-shadow: none;
  border-radius: 0px;
}

.fl-route-not-visible,
.fl-main-nav-not-visible {
  display: none;
}

.fl-container {
  max-width: 100%;
  width: 1200px;
  margin: 0px auto;
}

.fl-col-1 {
  width: calc(calc(100% / 12) * 1);
}
.fl-col-2 {
  width: calc(calc(100% / 12) * 2);
}
.fl-col-3 {
  width: calc(calc(100% / 12) * 3);
}
.fl-col-4 {
  width: calc(calc(100% / 12) * 4);
}
.fl-col-5 {
  width: calc(calc(100% / 12) * 5);
}
.fl-col-6 {
  width: calc(calc(100% / 12) * 6);
}
.fl-col-7 {
  width: calc(calc(100% / 12) * 7);
}
.fl-col-8 {
  width: calc(calc(100% / 12) * 8);
}
.fl-col-9 {
  width: calc(calc(100% / 12) * 9);
}
.fl-col-10 {
  width: calc(calc(100% / 12) * 10);
}
.fl-col-11 {
  width: calc(calc(100% / 12) * 11);
}
.fl-col-12 {
  width: calc(calc(100% / 12) * 12);
}

.fl-mb-20 {
  margin-bottom: 20px;
}
.fl-mt-20 {
  margin-top: 20px;
}
.fl-ml-20 {
  margin-left: 20px;
}
.fl-ml-5 {
  margin-left: 5px;
}
.fl-ml-10 {
  margin-left: 10px;
}

.fl-mr-20 {
  margin-right: 20px;
}

.fl-mt-5 {
  margin-top: 5px;
}

.fl-d-flex {
  display: flex;
}

.fl-align-center {
  align-items: center;
}

.fl-text-align-center {
  text-align: center !important;
}

.fl-justify-space-between {
  justify-content: space-between;
}

.fl-justify-flex-end {
  justify-content: flex-end;
}

.fl-justify-flex-center {
  justify-content: center;
}

.fl-fullscreen-with-footer {
  width: 100%;
  min-height: calc(100vh - 270px);
  overflow: hidden;
  flex: 1;
  display: flex;
  align-items: center;
  justify-content: center;
}

.fl-contact-form-guest {
  margin-top: 80px;
}

.fl-fullscreen-with-footer-bg {
  position: absolute;
  width: 100%;
  height: 100%;
  left: 0;
  top: 0;
}

.fl-fullscreen-with-footer-circle-orange {
  display: block;
  width: 57%;
  padding-top: 70%;
  background: linear-gradient(to bottom, #ffb659, #ffb659);
  border-radius: 50% 50% 50% 50% / 60% 60% 40% 20%;
  transform: translate(-50%, -50%) rotate(0deg);
  position: absolute;
  left: 96%;
  top: 75%;
}

.fl-fullscreen-with-footer-circle-pink {
  display: block;
  width: 55%;
  padding-top: 60%;
  background: linear-gradient(to bottom, #ff027d, #ff4443);
  border-radius: 50% 50% 50% 50% / 60% 60% 40% 40%;
  transform: translate(-30%, -35%) rotate(-25deg);
  position: absolute;
  left: 75%;
  top: 75%;
}

.fl-fullscreen-with-footer-swap .fl-fullscreen-with-footer-bg {
  transform: rotateY(180deg);
}

.fl-fullscreen-with-footer-content {
  width: 100%;
  display: flex;
  align-items: center;
  position: relative;
  height: 100%;
}

.fl-fullscreen-with-footer-inner {
  position: relative;
}

.fl-fullscreen-with-footer-content-heading {
  width: 100%;
  font-size: 48px;
  line-height: 60px;
  font-weight: 600;
  margin-bottom: 20px;
}

.fl-fullscreen-with-footer-content-heading.fl-fullscreen-with-footer-content-heading-light {
  color: #000;
}
.fl-fullscreen-with-footer-content-heading.fl-fullscreen-with-footer-content-heading-dark {
  color: #fff;
}

.fl-fullscreen-with-footer-content-part {
  padding: 20px;
  position: relative;
}
.fl-fulscreen-with-footer-content-part-image {
  pointer-events: none;
}

.fl-fullscreen-with-footer-content-link {
  font-size: 14px;
  font-weight: 500;
  text-decoration: none;
  color: #ff0d72;
  cursor: pointer;
}

.fl-select-main {
  width: 100%;
  max-width: 300px;
  border-radius: 5px;
  border: none;
  padding: 8px 10px;
  height: auto;
  display: block;
  margin: 5px 0px;
  font-size: 18px;
  height: 35px;
  cursor: pointer;
}
.fl-select-main:focus {
  outline: none;
}

.fl-select-main-light {
  background-color: #00000010;
  color: #000;
}

.fl-select-main-dark {
  background-color: #ffffff10;
  color: #fff;
}

.fl-select-main-dark option {
  background-color: black;
}

.fl-fullscreen-with-footer-content-link-text {
  font-size: 14px;
  line-height: 1.5;
  font-weight: 500;
  text-decoration: none;
  color: #646464;
}

.fl-fullscreen-with-footer-content-image {
  max-height: 500px;
  max-width: 80%;
  margin: 0px auto;
  border-radius: 20px;
  display: block;
}

.fl-fullscreen-with-footer-swap .fl-fullscreen-with-footer-content-image {
  margin-left: auto;
  margin-right: 20%;
}

.fl-fullscreen-with-footer-content-description {
  font-size: 18px;
  line-height: 32px;
  color: #888888;
}

.fl-modal-wrapper {
  position: fixed;
  width: 100%;
  height: 100%;
  background-color: #00000090;
  left: 0;
  top: 0;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  padding: 20px;
  z-index: 999;
}

.fl-toasts-column {
  z-index: 1000;
  position: fixed;
  left: 0px;
  bottom: 0px;
  padding: 20px;
  pointer-events: none;
}

.fl-modal-disable-true {
  pointer-events: none;
}

.fl-modal-window {
  pointer-events: auto !important;
}

.fl-modal-window {
  width: 100%;
  max-width: 600px;
  border-radius: 20px;
  position: relative;
  max-height: 100%;
}

.fl-theme-dark .fl-modal-window {
  background-color: #000;
  box-shadow: 0px 0px 4px 2px #ffffff20;
}

.fl-theme-light .fl-modal-window {
  background-color: #fff;
  box-shadow: 0px 0px 4px 2px #00000020;
}

/*.fl-theme-light .fl-app {*/
/*  background: #f4f4f4;*/
/*}*/

/*.fl-theme-dark .fl-app {*/
/*  background: #000;*/
/*}*/

.fl-modal-window-close {
  position: absolute;
  right: 30px;
  top: 24px;
  width: 26px;
  height: 26px;
  font-size: 26px;
  color: #fff;
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;
}

.fl-modal-window-close:hover {
  color: #afafaf;
}

.fl-modal-title {
  font-size: 26px;
  color: #fff;
  width: 100%;
  white-space: nowrap;
  text-overflow: ellipsis;
  overflow: hidden;
  padding-right: 30px;
  font-weight: 600;
  padding: 24px 60px 24px 30px;
  background: linear-gradient(to right, #ff037c, #ff4443);
  vertical-align: text-bottom;
  display: flex;
  align-items: center;
  position: relative;
  border-radius: 20px 20px 0px 0px;
}

.fl-modal-display-block {
  display: block;
}

.fl-modal-footer-buttons {
  margin: 0 1rem 1rem;
}

.fl-fullscreen-with-footer-content-form {
  width: 100%;
  margin-bottom: 30px;
}

.fl-fullscreen-with-footer-content-button {
  margin: 10px 0px;
  width: 100%;
}

.fl-fullscreen-with-footer-content-button-icon {
  margin-left: 5px;
  transform: translateX(0px);
  transition: transform 300ms ease-in-out;
}

.fl-fullscreen-with-footer-content-button:hover
  .fl-fullscreen-with-footer-content-button-icon {
  transform: translateX(8px);
}

.fl-modal-close-button {
  right: 20px;
}

.fl-modal-back-button {
  left: 20px;
}

.fl-modal-close-button,
.fl-modal-back-button {
  width: 40px;
  height: 40px;
  font-size: 25px;
  background-color: #00000054;
  color: #fff;
  position: absolute;
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;
  top: 20px;
  border-radius: 50%;
  z-index: 1;
}

.fl-modal-close-button {
  top: 5px;
  right: 5px;
}

.fl-modal-close-button:hover,
.fl-modal-back-button:hover {
  background-color: #00000078;
}

.fl-modal-title-text {
  width: calc(100% - 38px);
  white-space: break-spaces;
}

.fl-modal-description {
  width: 100%;
  padding: 40px 30px;
  font-size: 16px;
  color: #666666;
  overflow-y: auto;
}
.fl-modal-overflow-initial {
  overflow: initial;
}

.fl-modal-description:not(.fl-modal-segpay) {
  max-height: calc(100vh - 209px);
}

.fl-modal-buttons {
  padding: 20px 0px;
  width: calc(100% - 60px);
  margin: 0px auto;
}

.fl-theme-dark .fl-modal-buttons {
  border-top: 1px solid #ffffff20;
}
.fl-theme-light .fl-modal-buttons {
  border-top: 1px solid #00000020;
}

.fl-modal-description-row {
  margin-bottom: 20px;
}

.fl-modal-warnings-row {
  margin-bottom: 20px;
  color:#ff0000 !important;
}

.fl-modal-copy-button {
  background: linear-gradient(to bottom, #0066ff, #1010b3);
  border: none;
}

.fl-modal-copy-button:hover {
  background: linear-gradient(to bottom, #0056d8, #0f0f94) !important;
}


.fl-modal-description-row-error {
  color: #ff0000 !important;
  font-size: 0.75rem;
}

.fl-modal-description-row:nth-last-of-type(1) {
  margin-bottom: 0px;
}

.fl-modal-description-copy-button {
  margin-top: 5px;
  display: block;
  color: #15c;
  cursor: pointer;
}

.fl-modal-description-copy-button-disable {
  opacity: 0.8;
  pointer-events: none;
}

.fl-body-lock {
  overflow: hidden !important;
  padding-right: 0px;
}

.fl-body-lock-scroll {
  overflow: hidden !important;
  /*padding-right: 15px !important;*/
}

.fl-modal-body {
  padding-right: 15px;
}

.fl-body-filter-lock {
  overflow: hidden !important;
}

.fl-body-fixed {
  overflow: hidden !important;
  padding-right: 12px;
}
.fl-html-fixed {
  /* overflow: hidden !important; */
  height: 100vh;
}

/* Modal popup css Start*/

html.is-locked,
html.is-locked body {
	/* want to fix the height to the window height */
	height: calc(var(--window-inner-height) - 1px);

	/* want to block all overflowing content */
	overflow: hidden;

	/* want to exclude padding from the height */
	box-sizing: border-box;
}

/* End */

.fl-modal-title-icon {
  border: 3px solid #fff;
  border-radius: 50%;
  width: 30px;
  height: 30px;
  margin-right: 8px;
  position: relative;
}

.fl-modal-title-icon.fl-modal-title-icon-error::before {
  position: absolute;
  left: 50%;
  top: 50%;
  width: 3px;
  height: 80%;
  background-color: #fff;
  transform: translate(-50%, -50%) rotate(-45deg);
  content: "";
}

.fl-modal-title-icon.fl-modal-title-icon-error::after {
  position: absolute;
  left: 50%;
  top: 50%;
  width: 3px;
  height: 80%;
  background-color: #fff;
  transform: translate(-50%, -50%) rotate(45deg);
  content: "";
}

.fl-modal-title-icon.fl-modal-title-icon-success::before {
  position: absolute;
  left: 50%;
  top: 50%;
  width: 40%;
  height: 60%;
  border-bottom: 3px solid #fff;
  border-right: 3px solid #fff;
  transform: translate(-50%, -70%) rotate(45deg);
  content: "";
}

.fl-modal-report-reason {
  width: 100%;
  padding: 20px;
  border-top: 1px solid;
  border-left: 1px solid;
  cursor: pointer;
  border-right: 1px solid;
}

.fl-modal-report-reason:nth-last-of-type(1) {
  border-bottom: 1px solid;
  border-radius: 0px 0px 15px 15px;
}

.fl-modal-report-reason:nth-of-type(1) {
  border-radius: 15px 15px 0px 0px;
}

.fl-modal-report-reason.fl-modal-report-reason-dark {
  border-color: #ffffff20;
  color: #fff;
}

.fl-modal-report-reason.fl-modal-report-reason-dark {
  border-color: #ffffff20;
  color: #fff;
}

.fl-modal-report-reason.fl-modal-report-reason-light {
  border-color: #00000020;
  color: #000;
}

.fl-modal-report-reason.fl-modal-report-reason-dark:hover {
  background-color: #ffffff10;
}

.fl-modal-report-reason.fl-modal-report-reason-light:hover {
  background-color: #00000010;
}

.fl-modal-report-reason-active {
  background-color: #ff0d72 !important;
  color: #fff !important;
  font-weight: 500;
}

.fl-modal-description-recorder {
  width: 100%;
  position: relative;
}

.fl-modal-description-recorder-actions {
  display: flex;
  align-items: center;
  justify-content: center;
  padding-top: 10px;
}

.fl-modal-description-recorder-button {
  width: 60px;
  height: 60px;
  display: flex;
  align-items: center;
  border-radius: 50%;
  font-size: 24px;
  position: relative;
}

.fl-modal-description-recorder-button-recording {
  background: #888 !important;
}

.fl-modal-description-recorder-button-recording::before {
  width: 100%;
  height: 100%;
  content: "";
  position: absolute;
  left: 0px;
  top: 0px;
  background-clip: padding-box;
  border-radius: 50%;
  border: 5px solid #ff0d72;
  border-bottom: 5px solid #afafaf;
  box-sizing: border-box;
  animation: ringRotate 1s infinite ease-in-out;
  transform: rotate(0deg);
  pointer-events: none;
}

.fl-toggled-image-outer {
  width: 100%;
  height: 100%;
  position: fixed;
  left: 0px;
  top: 0px;
  z-index: 10000;
  background-color: #00000090;
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 20px;
}

.fl-toggled-image-inner {
  max-width: 600px;
  max-height: 400px;
  width: 100%;
  height: 100%;
}

.fl-toggled-image-inner img {
  width: 100%;
  height: 100%;
  object-fit: contain;
  object-position: center;
}

.fl-align-self-flex-start {
  align-self: flex-start;
}

.fl-sidebuttons-main,
.fl-sidebuttons-main-top {
  position: fixed;
  right: 30px;
  bottom: 50px;
  z-index: 10;
  pointer-events: none;
}

.fl-sidebuttons-main {
  bottom: 105px;
}

img {
  -webkit-user-select: none;
  -khtml-user-select: none;
  -moz-user-select: none;
  -o-user-select: none;
  user-select: none;
  pointer-events: none;
}

.fl-theme-dark .emoji-picker-react {
  background-color: #000;
  border-color: #ffffff20;
  box-shadow: 0 0px 4px 2px #ffffff20;
  color: #fff;
}

aside.emoji-picker-react .content-wrapper:before {
  right:20px
}

.fl-theme-dark .emoji-picker-react .emoji-group:before {
  background: #000 !important;
  font-size: 10px !important;
}

.fl-theme-dark .emoji-scroll-wrapper {
  color-scheme: dark;
}

.fl-theme-dark .emoji-picker-react .emoji-categories button {
  filter: invert(1);
}
.fl-theme-dark .emoji-search {
  background-color: #ffffff10;
  border-color: #ffffff20 !important;
  color: #fff;
}
.fl-theme-dark .emoji-search::placeholder {
  color: #888;
}

.fl-spinningBubbles {
  margin-left: 5px !important;
}

.fl-image-modal-window {
  width: auto;
  /*animation: pop 300ms forwards 200ms ease-in-out;*/
  /*opacity: 0;*/
  position: relative;
  outline: none;
  max-height: 100%;
}

.fl-image-modal-window .fl-modal-image {
  width: 100%;
  height: 100%;
  outline: none;
  display: flex;
  justify-content: center;
}

.fl-modal-drive-window-open {
  background-color: unset !important;
  box-shadow: none !important;
}

.fl-image-modal-window .fl-modal-image img {
  border-radius: 15px;
  max-width: 600px;
  max-height: 100%;
}

.fl-image-modal-window .fl-modal-image .fl-smooth-video {
  width: 600px;
  height: 500px;
}

.fl-image-modal-window .fl-image-modal-window-prev {
  left: -50px;
}

.fl-image-modal-window .fl-image-modal-window-next {
  right: -50px;
}

.fl-full-screen-preview .fl-image-modal-window-prev {
  left: 50px;
}

.fl-full-screen-preview .fl-image-modal-window-next {
  right: 50px;
}

.fl-image-modal-window .fl-image-modal-window-next,
.fl-image-modal-window .fl-image-modal-window-prev,
.fl-full-screen-preview .fl-image-modal-window-next,
.fl-full-screen-preview .fl-image-modal-window-prev {
  z-index: 999;
  position: absolute;
  top: 45%;
  color: white;
  width: 40px;
  height: 40px;
  background: #4a4a4a;
  border-radius: 50%;
  display: flex;
  align-items: center;
  cursor: pointer;
}

.fl-full-screen-preview .fl-image-modal-window-next,
.fl-full-screen-preview .fl-image-modal-window-prev {
  background-color: #00000054 !important;
}

.fl-image-modal-window .fl-image-modal-window-next svg,
.fl-image-modal-window .fl-image-modal-window-prev svg,
.fl-full-screen-preview .fl-image-modal-window-next svg,
.fl-full-screen-preview .fl-image-modal-window-prev svg {
  width: 100%;
}

.fl-image-modal-window .fl-image-modal-window-next:hover,
.fl-image-modal-window .fl-image-modal-window-prev:hover {
  background: #888;
}

.fl-full-screen-preview .fl-image-modal-window-next:hover,
.fl-full-screen-preview .fl-image-modal-window-prev:hover {
  opacity: 0.8;
}

.fl-modal-window-drive {
  max-width: calc(100% - 200px);
  max-height: calc(100vh - 200px);
}

.fl-modal-window-drive .fl-modal-description {
  height: calc(100vh - 370px);
}

.fl-modal-window-drive .fl-modal-window-close {
  color: black;
}

.fa-drive-option-modal {
  position: absolute;
  background: linear-gradient(to right, #ff037c, #ff4443);
  right: 15px;
  top: 10px;
  color: white;
  width: 35px;
  height: 35px;
  display: flex;
  align-items: center;
  text-align: center;
  border-radius: 50%;
  cursor: pointer;
  z-index: 99;
}

.fa-drive-option-modal svg {
  width: 100% !important;
}

.fl-loader-animation {
  display: flex;
  justify-content: center;
}

.fl-drive-loader-animation-padding {
  padding: 20px;
}

.fl-loader-animation .fl-spinningBubbles {
  opacity: 0.5;
  fill: #888888 !important;
}

.fl-modal-description-subscription {
  /*padding: 30px 30px 0px 30px;*/
  padding: 30px;
}

.fl-border-bottom {
  border-bottom: 1px solid #00000020;
}

.fl-border-bottom-dark {
  border-bottom: 1px solid #ffffff20;
}

.fl-red {
  color: red;
}

.fl-bold-600 {
  font-weight: 600;
}

.fl-bold-500 {
  font-weight: 500;
}

.fl-font-14 {
  font-size: 14px;
}

.fl-font-13 {
  font-size: 14px;
}

@keyframes ringRotate {
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(360deg);
  }
}

.iconButton {
  border-radius: 50%;
}

.fl-no-result {
  text-align: center;
}

@keyframes pop {
  0% {
    transform: translate(0%, 200px) scale(0.2);
    opacity: 0;
  }
  100% {
    transform: translate(0%, 0px) scale(1);
    opacity: 1;
  }
}

@keyframes like {
  0% {
    transform: translate(-50%, -50%) scale(0);
  }
  100% {
    transform: translate(-50%, -50%) scale(1);
  }
}

@keyframes likeActive {
  0% {
    transform: translate(-50%, -50%) scale(0);
  }
  100% {
    transform: translate(-50%, -50%) scale(1);
  }
}

@keyframes show {
  0% {
    opacity: 0;
  }
  100% {
    opacity: 1;
  }
}

@keyframes hide {
  0% {
    opacity: 1;
  }
  100% {
    opacity: 0;
  }
}

@keyframes popCard {
  0% {
    opacity: 0;
    transform: rotateX(50deg);
  }
  100% {
    opacity: 1;
    transform: rotateX(0deg);
  }
}

@keyframes scaleUp {
  0% {
    transform: scale(0);
  }
  100% {
    transform: scale(1);
  }
}

.loader-payment,
.loader-payment:after {
  border-radius: 50%;
  width: 10em;
  height: 10em;
}
.loader-payment {
  margin: 30px auto;
  font-size: 10px;
  position: relative;
  border-top: 1.1em solid #00000010;
  border-right: 1.1em solid  #00000010;
  border-bottom: 1.1em solid #00000010;
  border-left: 1.1em solid #ff0d72;
  -webkit-transform: translateZ(0);
  -ms-transform: translateZ(0);
  transform: translateZ(0);
  -webkit-animation: load8 1.1s infinite linear;
  animation: load8 1.1s infinite linear;
}
.loader-text {
  text-align: center;
  font-weight: 500;
  color: #888888;
}
@-webkit-keyframes load8 {
  0% {
    -webkit-transform: rotate(0deg);
    transform: rotate(0deg);
  }
  100% {
    -webkit-transform: rotate(360deg);
    transform: rotate(360deg);
  }
}
@keyframes load8 {
  0% {
    -webkit-transform: rotate(0deg);
    transform: rotate(0deg);
  }
  100% {
    -webkit-transform: rotate(360deg);
    transform: rotate(360deg);
  }
}

.fl-post-spinner {
  display: flex;
  align-items: center;
  justify-content: center;
}

@keyframes postSpinnerAnimation {
  0% { background-color: #afafaf; }
  50% { background-color: #888888; }
  100% { background-color: #afafaf; }
}

.fl-post-spinner span {
  display: block;
  width: 15px;
  height: 15px;
  margin-right: 15px;
  background-color: #afafaf;
  border-radius: 50%;
}

.fl-post-spinner span:nth-of-type(1) {
  animation: postSpinnerAnimation 1000ms ease-in-out 0ms infinite;
}
.fl-post-spinner span:nth-of-type(2) {
  animation: postSpinnerAnimation 1000ms ease-in-out 100ms infinite;
}
.fl-post-spinner span:nth-of-type(3) {
  animation: postSpinnerAnimation 1000ms ease-in-out 200ms infinite;
}

.fl-main-container-row {
  height: calc(100% - 130px);
}

.fl-coming-soon-main {
  height: 100%;
}

.fl-post-spinner span:last-child {
  margin-right: 0px;
}

.fl-main-container {
  margin-top: 80px;
  height: calc(100% - 80px);
}

.fl-main-inbox {
  margin-top: 0px;
  height: 100%;
}

/* Large devices (desktops, less than 1200px) */
@media (max-width: 1199.98px) {
  .fl-container {
    width: 1000px;
  }
  .fl-fullscreen-with-footer-content-heading {
    font-size: 36px;
    line-height: 42px;
  }
  .fl-fullscreen-with-footer-content-image {
    max-height: 450px;
  }
  .fl-modal-close-button,
  .fl-modal-back-button {
    top: 1px;
  }
}

/* Medium devices (tablets, less than 992px) */
@media (max-width: 991.98px) {
  .fl-body-lock,
  .fl-modal-body {
    padding-right: 0;
  }
  .fl-modal-title {
    padding: 10px 40px 10px 20px;
  }
  .fl-modal-window-close {
    top: 10px;
    right: 20px;
  }
  .fl-modal-window {
    max-width: 400px;
  }
  .fl-modal-description {
    padding: 20px;
  }
  .fl-modal-buttons {
    padding: 10px 0px;
  }
  .fl-fullscreen-with-footer-swap .fl-fullscreen-with-footer-content-image,
  .fl-fullscreen-with-footer-content-image {
    margin: 0px auto;
    max-width: 100%;
  }
  .fl-container {
    width: 750px;
  }
}

/* Small devices (landscape phones, less than 768px) */
@media (max-width: 767.98px) {
  .fl-col-1,
  .fl-col-2,
  .fl-col-3,
  .fl-col-4,
  .fl-col-5,
  .fl-col-6,
  .fl-col-7,
  .fl-col-8,
  .fl-col-9,
  .fl-col-10,
  .fl-col-11,
  .fl-col-12 {
    width: 100%;
  }

  .fl-modal-description:not(.fl-modal-segpay) {
    max-height: calc(100vh - 141px);
  }
  .fl-modal-wrapper {
    padding: 10px;
  }
  .fl-modal-title-text {
    font-size: 22px;
  }
  .fl-sidebuttons-main-top {
    right: 20px;
    bottom: 80px;
  }
  .fl-body-fixed {
    padding-right: 0px;
  }
}

/* Extra small devices (portrait phones, less than 576px) */
@media (max-width: 575.98px) {
  .__react_component_tooltip {
    display: none !important;
  }
  .fl-image-modal-window .fl-modal-image img {
    max-width: 250px;
  }
  .fl-fullscreen-with-footer-bg {
    display: none;
  }
  .fl-fullscreen-with-footer-content {
    display: block;
  }
  .fl-fullscreen-with-footer-content-heading {
    font-size: 32px;
  }
  .fl-fullscreen-with-footer {
    height: auto;
  }
  .fl-fullscreen-with-footer-content-image {
    width: 100%;
    margin-right: auto;
    height: intrinsic;
    object-fit: contain;
  }
  .fl-fullscreen-with-footer-swap .fl-fullscreen-with-footer-content-image,
  .fl-fullscreen-with-footer-content-image {
    max-width: unset;
  }
  .fl-fullscreen-with-height-fix {
    height: calc(100vh - 200px);
  }
  .fl-fulscreen-with-footer-content-part-image {
    display: none;
  }
  .fl-fullscreen-with-footer-content {
    padding: 0px 0px;
  }
}

.underline:hover {
  text-decoration: underline;
}
.mr-l-3 {
  margin-left: 3px;
}

.light-up:hover span {
  opacity: 0.7;
}
.regular-text {
  text-transform: none !important;
}

.fl-restricted-box,
.fl-restricted-input textarea,
.fl-restricted-input input,
.fl-restricted-input .fl-minput-input-inner:focus,
.fl-wrong-type-file textarea {
  border: 1px solid red !important;
}

.fl-dark-text-white {
  color: white;
}