.fullscreen {
  display: none;
}
.fullscreen-enabled {
  display: block;
  text-align: center;
}
.fullscreen-enabled img {
  object-fit: contain !important;
  pointer-events: all !important;
}

.fl-user-profile-show-more {
  color: #15c;
  cursor: pointer;
  margin-top: 10px;
}

.fl-user-profile-show-more span:hover {
  text-decoration: underline;
}

.fl-user-profile-fullscreen {
  position: fixed;
  z-index: 100;
  top: 0px;
  left: 0px;
  width: 100%;
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: rgba(128, 128, 128, 0.25);
}

.fl-user-profile-fullscreen img {
  max-width: 90%;
  max-height: 100%;
  user-select: none;
}

.fl-user-profile-is-subscribed {
  padding: 12px 20px 10px;
  border: 2px solid #ff0d72;
  color: #ff0d72;
  background-color: #88888820;
  border-radius: 50px;
  display: flex;
  justify-content: space-between;
  width: calc(100% - 40px);
  margin: 0px auto 20px;
  cursor: default;
}

.fl-user-profile-is-subscribed-active:hover {
  background: linear-gradient(90deg, #ff037c, #ff4443);
  color: #fff;
  cursor: pointer;
}

.fl-user-profile-is-subscribed-text svg {
  color: green;
}

.fl-user-profile-is-subscribed-text {
  color: #888;
  padding: 0px 20px 20px;
  font-size: 14px;
}

.fl-user-profile-is-subscribed-text.fl-user-profile-is-subscribed-text-dark
span {
  color: #fff;
}
.fl-user-profile-is-subscribed-text.fl-user-profile-is-subscribed-text-light
span {
  color: #000;
}

.fl-user-profile-options {
  position: absolute;
  width: 30px;
  height: 30px;
  font-size: 20px;
  color: #fff;
  display: flex;
  justify-content: center;
  align-items: center;
  right: 10px;
  top: 10px;
  cursor: pointer;
  outline: none;
}
.fl-user-profile-options:hover {
  color: #afafaf;
}

.fl-user-profile-fullscreen-close {
  position: absolute;
  top: 16px;
  right: 16px;
  font-size: 2rem;
  cursor: pointer;
  color: #333333;
}

.fl-user-profile-main {
  padding: 0px;
  overflow: hidden;
}

.fl-user-profile-images {
  width: 100%;
  height: 200px;
  position: relative;
}

.fl-user-profile-images-change {
  position: absolute;
  right: 30px;
  top: 30px;
  width: 20px;
  height: 20px;
  text-align: center;
  outline: none;
}

.fl-user-profile-images-change-icon {
  cursor: pointer;
  font-size: 20px;
  /* color: #ffffff88; */
  color: #888;
}
.fl-user-profile-images-change-icon-active {
  color: #ff037c;
}

.fl-user-profile-images-cover {
  width: 100%;
  height: 100%;
  overflow: hidden;
}
.fl-user-profile-images-cover.fl-user-profile-images-cover-dark {
  background-color: #ffffff20;
}
.fl-user-profile-images-cover.fl-user-profile-images-cover-light {
  background-color: #00000020;
}

.fl-user-profile-images-cover img {
  width: 100%;
  height: 100%;
  object-fit: cover;
  /* object-position: center; */
  position: relative;
}
.fl-user-profile-images-cover img::before {
  content: "";
  width: 100%;
  height: 100%;
  position: absolute;
  left: 0px;
  top: 0px;
}

.fl-user-profile-images-main {
  position: absolute;
  left: 50px;
  bottom: -75px;
  height: 150px;
  width: 150px;
  /* overflow: hidden; */
  background-size: cover;
  z-index: 1;
}

.fl-user-profile-images-main-inner {
  width: 100%;
  height: 100%;
  border-radius: 50%;
  overflow: hidden;
  /*position: relative;*/
}

.fl-user-profile-images-main-inner img {
  width: 100%;
  height: 100%;
  object-fit: cover;
  border-radius: 50%;
}

.fl-user-profile-data {
  width: 100%;
  padding: 20px 40px 0px;
  display: flex;
  flex-wrap: wrap;
  position: relative;
}

.fl-user-profile-data-left {
  width: 170px;
  padding-top: 80px;
}

.fl-user-profile-data-right {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  width: calc(100% - 170px);
  padding-left: 40px;
}

.fl-user-profile-data-icon {
  width: 30px;
  height: 30px;
  margin-right: 8px;
}

.fl-user-profile-data-icon img {
  width: 100%;
  height: 100%;
  object-fit: contain;
  object-position: center;
  opacity: 0.5;
}

.fl-user-profile-data-icon.fl-user-profile-data-icon-light img {
  filter: invert(1);
}

.fl-user-profile-data-row span {
  margin-top: 5px;
}

.fl-user-profile-data-row {
  display: flex;
  align-items: center;
  font-size: 20px;
  font-weight: 500;
  color: #888;
  margin-bottom: 15px;
  position: relative;
  z-index: 0;
  justify-content: center;
}

.fl-user-profile-data-row-add-index {
  display: flex;
  align-items: center;
  font-size: 20px;
  font-weight: 500;
  color: #888;
  margin-bottom: 15px;
  position: relative;
  z-index: -1;
}

.fl-user-profile-data-verified {
  margin-bottom: 5px;
}

.fl-user-profile-data-info {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  width: 100%;
  padding-bottom: 20px;
}

.fl-user-profile-data-info-logout {
  justify-content: left;
}

.fl-user-info {
  margin: 0 10px 0 5px;
  text-align: center;
}

.fl-user-profile-data-name {
  width: 50%;
}

.fl-user-profile-data-name-favorite {
  width: 100%;
}

.fl-user-profile-data-name-full {
  font-size: 21px;
  font-weight: 600;
  margin-bottom: 5px;
  text-decoration: none;
  display: flex;
  align-items: center;
  justify-content: center;
}

.fl-mobile-navigation-profile,
.fl-mobile-navigation-profile-count {
  display: block;
  margin-bottom: 0px;
}

.fl-mobile-navigation-profile {
  position: absolute;
  z-index: 99;
  top: 13px;
  left: 115px;
}

.fl-mobile-navigation-profile-count {
  color: #888888;
  font-weight: 500;
  margin-top: 5px;
}

.fl-user-profile-full-info {
  padding-bottom: 20px;
}

.fl-user-profile-data-name-full.fl-user-profile-data-name-full-dark {
  color: #fff;
}

.fl-user-profile-data-name-full.fl-user-profile-data-name-full-light {
  color: #000;
}

.fl-user-profile-data-name-username {
  text-decoration: none;
  display: flex;
  align-items: center;
  color: #888;
  font-size: 18px;
  text-decoration: none;
  text-overflow: ellipsis;
  white-space: nowrap;
  overflow: hidden;
  justify-content: center;
}

.fl-user-profile-data-right-bio {
  width: 100%;
  color: #888;
  font-size: 18px;
  line-height: 30px;
  text-align: center;
  border-top: 1px solid #88888847;
  padding-top: 30px;
}

.fl-user-profile-data-bottom {
  padding: 0px 40px 40px;
}

.fl-simple-card-main-light .fl-user-profile-data-right-bio {
  border-top: 1px solid #88888847;
}

.fl-simple-card-main-dark .fl-user-profile-data-right-bio {
  border-top: 1px solid #888888;
}

.fl-user-profile-data-right-bio-default {
  width: 100%;
  line-height: 18px;
  text-align: left;
}

.fl-follower-statistics {
  cursor: pointer;
}

.fl-follower-statistics:hover {
  cursor: pointer;
  color:#b3b3b3 !important;
}

.fl-follower-statistics:hover * {
  cursor: pointer;
  color:#b3b3b3 !important;
}

.fl-follower-statistics svg {
  color: #888888;
  margin-left: 10px;
  padding-top: 2px;
}

.fl-follower-statistics svg:hover {
  color:#b3b3b3;
}

.fl-follower-statistics-full {
  display: flex;
  padding: 10px 0px 10px 0px;
}

.fl-follower-statistics-menu-closed {
  max-height: 0px;
  transform: scaleY(0);
  overflow: visible;
  transform-origin: top;
  transition: transform 0.3s, max-height 0.1s;
}
.fl-follower-statistics-menu-open {
  transition: transform 0.5s, max-height 0.1s;
  transform-origin: top;
  max-height: 100%;
  transform: scaleY(1);
}

.fl-follower-statistics-full:not(.fl-follower-statistics-full:last-child) {
  border-bottom: 1px solid #8888;
}

.fl-follower-statistics-full span {
  width: 50%;
}

.fl-user-statistics-right {
  padding-top: 20px;
}

.fl-follower-statistics-full span:last-child {
  text-align: right;
}

.fl-user-profile-data-right-bio-expand {
  display: block;
}

.fl-user-profile-data-right-bio-expand-button,
.fl-support-show-more,
.fl-link-favourite {
  color: #ff0d72;
  cursor: pointer;
  margin-top: 20px;
}
.fl-profile-favourite .fl-profile-holder a,
.fl-profile-favourite {
  cursor: pointer;
}
.fl-link-favourite {
  text-decoration: none;
  color: #15c;
}
.fl-link-favourite:hover {
  text-decoration: underline;
}

.fl-user-profile-data-right-bio-info {
  padding-bottom: 10px;
}
.fl-support-show-more:hover {
  text-decoration: underline;
}

.fl-user-profile-data-right-bio-social {
  display: flex;
  margin-top: 10px;
  justify-content: center;
}

.fl-user-profile-data-right-bio-social-item {
  width: 30px;
  height: 30px;
  display: flex;
  justify-content: center;
  align-items: center;
  border-radius: 50%;
  margin-right: 5px;
  font-size: 14px;
  overflow: hidden;
  position: relative;
}

.fl-user-profile-data-right-bio-social-item svg {
  position: relative;
}

.fl-user-profile-data-right-bio-social-item::before {
  width: 100%;
  height: 100%;
  content: "";
  position: absolute;
  left: 0px;
  top: 0px;
  opacity: 1;
  transition: 300ms opacity ease-in-out;
}

.fl-user-profile-data-right-bio-social-item:hover::before {
  opacity: 0;
}

.fl-user-profile-data-right-bio-social-item:last-child {
  margin-right: 0px;
}

.fl-user-profile-data-right-bio-social-item-instagram {
  background: linear-gradient(
          45deg,
          #f09433 0%,
          #e6683c 25%,
          #dc2743 50%,
          #cc2366 75%,
          #bc1888 100%
  );
  color: white;
}

.fl-user-profile-data-right-bio-social-item-twitter {
  background: linear-gradient(#47baff, #74c7ff);
  color: white;
}

.fl-user-profile-data-right-bio-social-item-reddit {
  background: linear-gradient(rgb(255, 113, 0), rgb(212, 71, 71));
  color: white;
}

.fl-user-profile-data-right-bio-social-item-snap {
  background: linear-gradient(#ffeb3b, #c7b82e);
  color: white;
}

.fl-user-profile-data-right-bio-social-item-youtube {
  background: linear-gradient(rgb(255 0 0), rgb(199 18 18));
  color: white;
}

.fl-user-profile-data-right-bio-social-item-amazon {
  background: linear-gradient(#ff7100, #d44747);
  color: white;
}

.fl-user-profile-data-right-bio-social-item-tiktok {
  background: linear-gradient(rgb(7 15 56), rgb(0 10 47));
  color: white;
}

.fl-user-profile-data-right-bio-social a:hover {
  background: white;
  color: #ff0d72;
}

.fl-user-profile-data-right-bio-social a:hover svg {
  transform: scale(1.3);
  transition: transform 500ms ease-in-out;
}

.fl-user-profile-data-right-bio-website svg {
  color: #888;
}

.fl-user-profile-data-right-bio-website span {
  padding-left: 10px;
  color: #ff0d72;
}

.fl-user-profile-data-right-bio-website a {
  text-decoration: none;
}

.fl-user-profile-data-right-bio-website a:hover {
  text-decoration: underline;
  text-decoration-color: #ff0d72;
}

.fl-user-profile-data-right-bio-website {
  margin-top: 10px;
}

.fl-user-profile-data-name-full-location {
  width: 30px;
  height: 30px;
  border-radius: 50%;
  overflow: hidden;
  border: 2px solid #888;
}

.fl-user-profile-favourite-location {
  margin-left: 10px;
  width: 20px;
  height: 20px;
  margin-top: -3px;
}

.fl-user-profile-data-name-full-location img {
  width: 100%;
  height: 100%;
  object-fit: cover;
  object-position: center;
}

.fl-user-profile-data-values {
  display: flex;
  align-items: center;
  flex-grow: 1;
  justify-content: flex-end;
}

.fl-user-profile-data-key {
  font-size: 18px;
  padding: 5px 0px;
  color: #888888;
  font-weight: 500;
}
.fl-user-profile-data-value {
  font-size: 18px;
  padding: 5px 0px;
  font-weight: 700;
}

.fl-user-profile-data-value.fl-user-profile-data-value-light {
  color: #000;
}

.fl-user-profile-data-value.fl-user-profile-data-value-dark {
  color: #fff;
}

.fl-user-profile-images-change-dropdown {
  width: 280px;
  position: absolute;
  top: 30px;
  right: 0px;
  font-size: 16px;
  color: #888;
  text-align: left;
}

.fl-user-profile-image-change-icon .fl-post-head-actions-dropdown {
  left: 0;
  margin-top: 15px;
  outline: none;
}

.fl-user-background-image-change-icon .fl-post-head-actions-dropdown {
  margin-top: 15px;
  outline: none;
}

.fl-user-profile-image-change-icon,
.fl-user-background-image-change-icon {
  outline: none;
}

.fl-user-profile-image-change-icon {
  width: 1.75rem;
  height: 1.75rem;
  padding: 0.25rem;
  position: absolute;
  background-color: #888888;
  border-radius: 50%;
  transition: 0.25s background-color;
  cursor: pointer;
  z-index: 2;
  left: 50px;
  bottom: -70px;
}
.fl-user-profile-image-change-icon:hover {
  background-color: rgba(128, 128, 128);
}
.fl-user-profile-image-change-icon svg {
  /* color: #fff; */
  font-size: 1.25rem;
}

.fl-user-background-image-change-icon {
  width: 30px;
  height: 30px;
  position: absolute;
  background-color: #888888;
  transition: 0.25s;
  cursor: pointer;
  z-index: 1;
  right: 10px;
  bottom: 10px;
  color: #fff;
  width: 1.75rem;
  height: 1.75rem;
  padding: 0.25rem;
  border-radius: 50%;
  transition: 0.25s background-color;
}
.fl-user-background-image-change-icon p {
  margin: 0;
}
.fl-user-background-image-change-icon:hover {
  background-color: rgba(128, 128, 128);
}
.fl-user-background-image-change-icon svg {
  /* color: #fff; */
  font-size: 1.25rem;
}

.fl-user-profile-image-change-dropdown {
  top: 95%;
  left: 20%;
  z-index: 10;
}

.fl-user-profile-images-change-dropdown-inner {
  padding: 10px 0px;
  border: 1px solid #00000020;
  overflow: hidden;
}

.fl-user-profile-images-change-dropdown-option {
  width: 100%;
  display: block;
  padding: 10px 15px;
  border-bottom: 1px solid #00000020;
  cursor: pointer;
}

.fl-user-profile-images-change-dropdown-option:hover {
  background-color: #ff0d72;
  color: #fff;
}

.fl-user-profile-images-change-dropdown-option:nth-last-of-type(1) {
  border-bottom: none;
}

.fl-user-profile-modal-img-holder {
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
}

.fl-user-profile-modal-bg-img-holder {
  width: 100%;
  margin: 0px auto 20px;
}

.fl-user-profile-modal-bg-img-display {
  width: 100%;
  position: relative;
  border: 3px solid #00000020;
  overflow: hidden;
  height: 200px;
  background-color: #00000020;
  display: flex;
  align-items: center;
  justify-content: center;
  color: #888888;
  cursor: pointer;
}

.fl-user-profile-modal-bg-img-display h1 {
  font-weight: 600;
  cursor: pointer;
}

.fl-user-profile-modal-bg-img-display img {
  width: 100%;
  object-fit: cover;
  height: 100%;
}

.fl-user-profile-modal-img-display {
  width: 200px;
  height: 200px;
  position: relative;
  border-radius: 50%;
  border: 3px solid #00000020;
  overflow: hidden;
}

.fl-user-profile-modal-img-display img {
  width: 100%;
}

.fl-user-profile-click {
  cursor: pointer;
}

.fl-user-profile-modal-img-actions {
  padding: 0px 15px;
  display: flex;
  justify-content: center;
  align-items: center;
}

.fl-user-profile-modal-img-actions-single {
  padding: 20px;
  color: #888;
  font-size: 18px;
  cursor: pointer;
  display: block;
}

.fl-user-profile-modal-img-actions-single-icon {
  margin-right: 8px;
}

.fl-user-profile-modal-img-loader-main {
  width: 100%;
  padding: 20px 0px 0px;
}

.fl-user-profile-modal-img-loader-holder {
  width: 100%;
  height: 26px;
  display: flex;
  align-items: center;
  justify-content: center;
  position: relative;
  border-radius: 5px;
  background-color: #88888880;
  overflow: hidden;
}

.fl-user-profile-modal-img-loader-progress {
  position: absolute;
  left: 0%;
  top: 0%;
  width: 100%;
  height: 100%;
  transform-origin: 0% 50%;
  transform: scaleX(0);
  background: linear-gradient(to right, #ff037c, #ff4443);
  transition: 0.2s all;
}

.fl-user-profile-modal-img-loader-percent {
  color: #fff;
  text-transform: uppercase;
  font-size: 16px;
  position: relative;
  font-weight: bold;
}

.fl-user-profile-modal-recommended {
  width: 100%;
  text-align: center;
  color: #888;
}

.fl-user-profile-data-edit {
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 20px 20px 20px;
}

.fl-user-profile-data-edit-button {
  max-width: 200px;
  width: 120px;
  font-size: 12px;
  padding: 5px 10px;
  height: auto;
  border-radius: 5px;
  margin-right: 5px;
}

.fl-user-profile-data-record-button {
  width: 155px;
  font-size: 12px;
  padding: 5px 10px;
  height: auto;
  border-radius: 5px;
}

.fl-user-profile-data-button-icons {
  margin-right: 2px;
}

.fl-user-profile-addons {
  position: absolute;
  left: 10px;
  top: 10px;
  display: flex;
}

/* FIRST LEVEL CHILD */
.fl-user-profile-addons > * {
  margin-right: 8px;
}

.fl-user-profile-verified {
  /* background-color: #FF0D72; */
  /* padding: 4px 5px 2px; */
  /* border-radius: 5px; */
  text-transform: uppercase;
  font-size: 26px;
  font-weight: 600;
  /* color: #fff; */
  width: 30px;
  height: 30px;
  /* border: 3px solid #FF0D72; */
  border-radius: 50%;
  /* box-shadow: 0px 0px 1px 1px #00000020; */
  position: relative;
  margin: auto;
  display: flex;
  align-items: center;
  justify-content: center;
}
.fl-user-profile-data-row-verified {
  margin-bottom: 5px;
}

.fl-user-profile-verified-crt {
  color: #ff0d72;
  font-size: 20px;
}

.fl-user-profile-voice {
  /* background-color: #FF0D72; */
  padding: 4px 5px 2px;
  border-radius: 5px;
  text-transform: uppercase;
  font-size: 26px;
  font-weight: 600;
  /* color: #fff; */
  width: 30px;
  height: 30px;
  border: 3px solid #277eff;
  border-radius: 50%;
  box-shadow: 0px 0px 1px 1px #00000020;
  position: relative;
  cursor: pointer;
}

.fl-user-profile-voice-crt {
  color: #277eff;
  font-size: 20px;
  position: absolute;
  left: 50%;
  top: 50%;
  transform: translate(-50%, -50%);
}

.fl-user-profile-verified-chc {
  color: #fff;
  font-size: 12px;
  position: absolute;
}

.fl-user-profile-voice-play {
  color: #fff;
  font-size: 10px;
  position: absolute;
  left: 54%;
  top: 50%;
  transform: translate(-50%, -50%);
}

.fl-user-profile-blocked {
  width: 100%;
  background-color: #888;
  color: #fff;
  text-align: center;
  padding: 13px 10px 10px;
  text-transform: uppercase;
  font-weight: 500;
  font-size: 14px;
}

.fl-user-profile-modal-bg-img-big {
  text-align: center;
}

.fl-user-profile-voice-presentation {
  color: #ff0d72 !important;
  font-size: 14px;
  display: flex;
  align-items: center;
  justify-content: center;
  position: relative;
  width: 20px;
  height: 20px;
  margin-left: 8px;
  padding-bottom: 6px;
}

.fl-user-profile-data-social {
  width: calc(100% + 80px);
  display: flex;
  margin: 30px -40px -40px;
}

.fl-user-profile-data-edit .fa-info-circle {
  color: #ff0d72;
  cursor: pointer;
}
.fl-user-profile-data-edit .fa-info-circle:hover {
  opacity: 0.6;
}

.fl-user-profile-full-name-span {
  position: relative;
}

.fl-user-profile-verified {
  position: absolute;
  bottom: -4px;
  right: -30px;
}

/* Large devices (desktops, less than 1200px) */
@media (max-width: 1199.98px) {
  .fl-user-profile-data-name-full-location {
    width: 24px;
    height: 24px;
  }
}

/* Medium devices (tablets, less than 992px) */
@media (max-width: 991.98px) {
}

/* Small devices (landscape phones, less than 768px) */
@media (max-width: 767.98px) {
  .fl-user-profile-modal-img-holder {
    max-width: 100%;
    text-align: center;
  }
  .fl-user-profile-data-verified {
    margin-left: 1rem;
  }
}

/*.fl-user-profile-active-online {*/
/*  color: #00aa00;*/
/*  font-size: 15px;*/
/*  padding-left: 5px;*/
/*  height: 10px;*/
/*}*/
/*.fl-user-profile-active-offline {*/
/*  color: #888;*/
/*  font-size: 15px;*/
/*  padding-left: 5px;*/
/*  height: 10px;*/
/*}*/

.fl-user-profile-images-main-inner .fl-direct-messages-title-indicator {
  position: absolute;
  bottom: 20px;
  right: 13px;
}

.fl-user-profile-images-main-inner .fl-direct-messages-title-indicator-profile {
  position: absolute;
  bottom: 15px;
  left: 7px;
}

.fl-post-head-actions-dropdown-status {
  position: absolute;
  z-index: 1;
  left: 165px;
  bottom: -60px;
}

.fl-post-head-actions-dropdown-profile-status {
  width: 130px;
}

.fl-post-head-actions-dropdown-profile-status
.fl-post-head-actions-dropdown-inner {
  padding: 0px 0px;
}

/* Large devices (desktops, less than 1200px) */
@media (max-width: 1199.98px) {
}

/* Medium devices (tablets, less than 992px) */
@media (max-width: 991.98px) {
}

/* Small devices (landscape phones, less than 768px) */
@media (max-width: 767.98px) {
  .fl-user-profile-data-values,
  .fl-user-profile-data-right-bio-social {
    justify-content: center;
  }
  .fl-user-profile-verified-crt {
    margin-left: 0;
  }
  .fl-user-profile-data {
    flex-wrap: wrap;
    padding: 15px;
  }
  .fl-user-profile-data-left {
    width: 100%;
    order: 2;
    padding-top: 5px;
    display: flex;
    justify-content: center;
  }
  .fl-user-profile-data-values {
    padding-top: 5px;
  }
  .fl-user-profile-data-right {
    order: 1;
    width: 100%;
    padding-top: 80px;
    padding-left: 0px;
  }
  .fl-user-profile-data-row {
    padding: 0px 15px;
  }
  .fl-user-profile-images-main {
    left: 50%;
    transform: translate(-50%, 0%);
  }
  .fl-user-profile-image-change-icon {
    left: calc(50% - 70px);
  }
  .fl-user-profile-modal-img-actions-single {
    text-align: center;
  }
  .fl-post-head-actions-dropdown-status {
    left: calc(50% + 40px);
  }
  .fl-user-profile-data-edit .fa-info-circle {
    display: none;
  }
  .fl-post-head-actions-dropdown-profile-status {
    width: 95px;
  }
}

/* Extra small devices (portrait phones, less than 576px) */
@media (max-width: 575.98px) {
  .fl-user-profile-data-edit {
    display: block;
  }
  .fl-user-profile-data-edit-button,
  .fl-user-profile-data-record-button {
    width: 100%;
    max-width: 100%;
    margin: 0px;
  }
  .fl-user-profile-data-record-button {
   margin-top: 10px;
  }
}

.fl-user-profile-voice svg:last-child {
  margin-left: -1px;
}
.fl-direct-messages-title-indicator-true {
  cursor: pointer;
}

