.fl-simple-comment-main {
  width: 100%;
  padding: 0px 0px 35px 0px;
  display: flex;
  outline: none;
  position: relative;
}

.fl-simple-comment-main-simplified {
  /* padding: 4px 0; */
}

.fl-simple-comment-image {
  width: 40px;
  height: 40px;
  position: relative;
  border-radius: 50%;
}

.fl-simple-comment-image .fl-profile-holder-status {
  right: -5px;
}

.fl-simple-comment-text {
  padding-left: 20px;
  width: calc(100% - 40px);
}

.fl-simple-comment-name {
  font-size: 16px;
  font-weight: 500;
  text-decoration: none;
  display: block;
}

.fl-simple-comment-name.fl-simple-comment-name-dark {
  color: #fff;
}
.fl-simple-comment-name.fl-simple-comment-name-light {
  color: #000;
}

.fl-simple-comment-name span {
  display: block;
}

.fl-simple-comment-username {
  padding-left: 5px;
  margin-right: 20px;
  text-overflow: ellipsis;
  white-space: nowrap;
  overflow: hidden;
}

.fl-simple-comment-description {
  color: #888888;
  font-size: 15px;
  line-height: 22px;
  padding: 5px 0px;
  word-break: break-word;
  position: relative;
}

.fl-simple-comment-description-dropdown {
  position: absolute;
  top: 5px;
  right: 0;
}

.fl-simple-comment-description-dropdown .fl-post-head-actions-dropdown {
  width: 190px;
}

.fl-simple-comment-make-all-light textarea {
  border: 1px solid #00000020;
}

.fl-simple-comment-make-all-dark textarea {
  border: 1px solid #ffffff20;
}

.fl-simple-comment-description .fl-post-comment-make-button-cancel {
  top: -6px;
  right: -12px;
}

.fl-simple-comment-actions {
  display: flex;
  align-items: center;
}

.fl-simple-comment-actions
  .fl-simple-comment-actions-single:not(.fl-simple-comment-actions
    .fl-simple-comment-actions-single:first-child) {
  cursor: pointer;
}

.fl-simple-comment-actions
  .fl-simple-comment-actions-single:hover:not(.fl-simple-comment-actions
    .fl-simple-comment-actions-single:first-child:hover) {
  color: #ff0d72;
}

.fl-simple-comment-actions .fl-simple-comment-actions-single:first-child {
  cursor: default;
  margin-right: 4px;
}

.fl-simple-comment-actions-single {
  display: flex;
  align-items: center;
  color: #888888;
}

.fl-simple-comment-actions-single span {
  padding-top: 4px;
}

.fl-simple-comment-actions-single:first-child:hover {
  color: #ff0d72;
  cursor: pointer;
}

.fl-simple-comment-like:hover {
  cursor: pointer;
  color: #ff0d72;
}

.fl-simple-comment-reply:hover {
  cursor: pointer;
}

.fl-simple-comment-actions-single:nth-last-of-type(1) {
  margin-right: 0px;
}
/* 
.fl-simple-comment-name span {
    color: #FF0D72;
    font-size: 14px;
    margin-left: 5px;
} */

.fl-simple-comment-values {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 5px 0px;
}

.fl-simple-comment-values-likes {
  font-size: 15px;
}

.fl-simple-comment-values-likes.fl-simple-comment-values-likes-dark {
  color: #fff;
}
.fl-simple-comment-values-likes.fl-simple-comment-values-likes-light {
  color: #000;
}

.fl-simple-comment-values-likes span {
  color: #ff0d72;
}

.fl-simple-comment-values-comments {
  color: #888888;
  cursor: pointer;
}

.fl-simple-comment-child {
  padding: 0px 0px 0px 55px;
  margin-bottom: 0px;
  position: relative;
}

.fl-simple-comment-child-simplified {
  padding: 10px 0px 10px 15px;
  margin-left: 40px;
  margin-bottom: 10px;
  position: relative;
  border-left: 1px solid #88888870;
}

.fl-simple-comment-username {
  text-decoration: none;
  color: #ff0d72;
  display: block;
  font-size: 14px;
  width: fit-content;
}

.fl-simple-comment-make-all {
  width: 100%;
  position: relative;
  display: flex;
  align-items: center;
  padding-bottom: 5px;
}

.fl-simple-comment-reply-cancel {
  position: absolute;
  right: 0;
  top: 0;
  width: 30px;
  height: 30px;
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;
}
.fl-simple-comment-reply-cancel.fl-simple-comment-reply-cancel-light {
  color: #666;
}
.fl-simple-comment-reply-cancel.fl-simple-comment-reply-cancel-light:hover {
  color: #000;
}
.fl-simple-comment-reply-cancel.fl-simple-comment-reply-cancel-dark {
  color: #aaa;
}
.fl-simple-comment-reply-cancel.fl-simple-comment-reply-cancel-dark:hover {
  color: #fff;
}

.fl-simple-comment-name:hover,
.fl-simple-comment-description-dropdown svg:hover,
.fl-gotopost-post-comment-single-data-comments:hover {
  color: #525252;
}

.fl-simple-comment-username:hover {
  opacity: 0.7;
  cursor: pointer;
}

.fl-simple-comment-username-name {
  display: flex;
  align-items: center;
}

/* Large devices (desktops, less than 1200px) */
@media (max-width: 1199.98px) {
}

/* Medium devices (tablets, less than 992px) */
@media (max-width: 991.98px) {
}

@media (max-width: 767.98px) {
}
@media (max-width: 575.98px) {
  .fl-simple-comment-username {
    font-size: 12px;
  }
  .fl-simple-comment-description {
    font-size: 14px;
  }
  .fl-simple-comment-actions-single span:not(.fl-simple-comment-time) {
    display: none;
  }
}

.fl-simple-comment-time:hover {
  cursor: default;
  color: #888;
}

.fl-simple-comment-time {
  color: #888888;
}
