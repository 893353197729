.fl-messageaudio-main {
    height: 40px;
    padding: 5px 10px;
    display: flex;
    align-items: center;
    position: relative;
    background-color: #ff0d72;
    color: #fff;
    border-radius: 15px;
}

.fl-message-bubble-main-left .fl-messageaudio-main {
    float: left;
}

. fl-message-bubble-main-right .fl-messageaudio-main {
    float: right;
}

.fl-direct-message-audio-option {
    float: right;
}

.fl-message-bubble-main-left .fl-messageaudio-main {
    background-color: #efefef;
}

.fl-message-bubble-main-right .fl-messageaudio-main {
    background-color: #ff0d72;
}

.fl-message-bubble-main-right .fl-messageaudio-action,
.fl-message-bubble-main-right  fl-messageaudio-timer{
    color: white;
}

.fl-message-bubble-main-left .fl-messageaudio-action,
.fl-message-bubble-main-left .fl-messageaudio-timer {
    color: #ff0d72;
}

.fl-messageaudio-action {
    width: 30px;
    height: 30px;
    display: flex;
    justify-content: center;
    align-items: center;
}

.fl-messageaudio-graph {
    width: 100%;
    min-width: 90px;
    height: 30px;
    overflow: hidden !important;
}
.fl-messageaudio-graph wave {
    height: 100% !important;
    overflow: hidden !important;
}

.fl-messageaudio-timer {
    width: 50px;
    height: 100%;
    display: flex;
    align-items: center;
    justify-content: flex-end;
    font-size: 10px;
    font-weight: 600;
    padding-top: 2px;
}

.fl-message-audio-send {
    background: #f2f1f1ff;
    border-radius: 20px;
}

.fl-message-audio-report-true {
    pointer-events: none;
    opacity: 0.5;
}

.fl-messageaudio-main-recording wave {
    width: 100%;
    overflow-x: hidden !important;
    float: left;
}

.fl-messageaudio-timer span {
    padding-right: 5px;
}

/* Large devices (desktops, less than 1200px) */
@media (max-width: 1199.98px) {

}

/* Medium devices (tablets, less than 992px) */
@media (max-width: 991.98px) { 

}

/* Small devices (landscape phones, less than 768px) */
@media (max-width: 767.98px) { 
    
 }

/* Extra small devices (portrait phones, less than 576px) */
@media (max-width: 575.98px) {

}
