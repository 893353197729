.comments-textarea__control {
  margin-top: 10px;
  margin-bottom: 20px;
}
.comments-textarea__input {
  background-color: #fff;
  border-radius: 3px;
  transition: 0.3s ease-in-out;
  padding: 9px;
  font-size: 14px;
  color: #333;
  border: 1px solid #dedede;
}
.comments-textarea__input:focus,
.comments-textarea__input:active {
  outline: none;
  border: 1px solid #3fb9de;
}
.comments-textarea__input::placeholder {
  color: #a4b0be;
  font-size: 14px;
}
.comments-textarea__highlighter {
  padding: 10px;
}
.comments-textarea__suggestions__list {
  background-color: #fff;
  font-size: 14px;
  max-height: 200px;
  overflow: auto;
}
div[class^="css-4-0-1"] {
  max-height: 20rem;
  border-radius: 5px;
  border: 1px solid rgba(0, 0, 0, 0.15);
  overflow-y: auto;
}
.comments-textarea__suggestions__item {
  padding: 5px 20px;
  border-bottom: 1px solid rgba(0, 0, 0, 0.15);
}
.comments-textarea__suggestions__item-focused {
  background-color: #daf4fa;
}

.send-text {
  padding: 1rem 0 0;
  color: #8f908f;
  margin-bottom: 0;
  background-color: white;
  border: 0;
}
.comment-image {
  cursor: pointer;
}

.comment-input-img {
  background-size: 100%;
  border-radius: 100%;
  width: 32px;
  height: 32px;
  margin: 0.8rem 0.5rem;
}

/*test*/
.mentions {
  margin: 1em 0;
}

.mentions-light__input {
  border: none;
  outline: none;
}

.mentions-dark__input {
  color: #fff;
  border: none;
  outline: none;
}

.mentions--singleLine .mentions__control {
  display: inline-block;
  width: 130px;
}
.mentions--singleLine .mentions__highlighter {
  padding: 1px;
  border: 2px inset transparent;
}
.mentions--singleLine .mentions__input {
  padding: 1px;
  border: 2px inset;
}

.mentions--multiLine .mentions__control {
  font-family: "Objectivity", -apple-system, BlinkMacSystemFont, "Segoe UI",
    "Roboto", "Oxygen", "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans",
    "Helvetica Neue", sans-serif;
  font-size: 14pt;
}
.mentions--multiLine .mentions__highlighter {
  padding: 0 0.5rem;
  border: 0;
  font-size: 12pt;
  font-family: "Objectivity", -apple-system, BlinkMacSystemFont, "Segoe UI",
    "Roboto", "Oxygen", "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans",
    "Helvetica Neue", sans-serif;
}
.mentions--multiLine .mentions__input {
  padding: 0 0.5rem;
  outline: 0;
  border: 0;
  font-size: 12pt;
  font-family: "Objectivity", -apple-system, BlinkMacSystemFont, "Segoe UI",
    "Roboto", "Oxygen", "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans",
    "Helvetica Neue", sans-serif;
  margin: 0px 0px 0px 0px;
}

.mentions__suggestions__list {
  background-color: white;
  font-size: 10pt;
}

.mentions__suggestions__item {
  padding: 5px 15px;
  border-bottom: 1px solid rgba(0, 0, 0, 0.15);
}

.mentions__suggestions__item--focused {
  background-color: #cee4e5;
}

.mentions__mention {
  position: relative;
  z-index: 1;
  color: #007bff;
  pointer-events: none;
  top: 1.4px;
  left: 0.9px;
}

/* Dark-mode */
.mentions-dark .mentions__input {
    color: white;
}

.mentions-dark .mentions__suggestions__list {
    background-color: #1b1b1b;
}

.mentions-dark .mentions__suggestions__item {
    color: white;
}

.mentions-dark .mentions__suggestions__item--focused {
    background-color: black;
}
/*  */

.fl-tag-box {
  border: none;
  width: 100%;
  padding: 35px 0px;
  margin: 0;
}

.mentions-light__suggestions li:hover,
.mentions-dark__suggestions li:hover {
  background: linear-gradient(to right, #ff037c, #ff4443);
  color: white;
}

.mentions-light__suggestions li:hover span,
.mentions-dark__suggestions li:hover span {
  color: white;
}

.mentions-dark__suggestions ul {
  border: 1px solid #ffffff20;
  z-index: 99;
}

.mentions-light__suggestions ul {
  border: 1px solid #0000001c;
  z-index: 99;
}


.mentions-dark__suggestions li {
  border-bottom: 1px solid #ffffff20;
  padding: 10px;
}

.mentions-light__suggestions li {
  border-bottom: 1px solid #0000001c;
  padding: 10px;
}

.fl-tag-list {
  display: flex;
  align-items: center;
}

.fl-tag-list-image .smooth-image-wrapper,
.fl-tag-list-image .smooth-image-wrapper img {
  border-radius: 50%;
  width: 100%;
}

.fl-tag-list-image {
  width: 35px;
  height: 35px;
}

.fl-tag-list-info span {
  display: block;
}

.fl-tag-list-info {
  margin-left: 10px;
}

.fl-tag-body {
  position: relative;
}

.fl-tag-body-img {
  width: 50px;
  height: 50px;
  border-radius: 50%;
  position: relative;
  display: block;
}

.fl-display-block {
  display: block;
}

.fl-tag-body-profile {
    position: relative;
    width: 320px;
    border-radius: 15px;
    padding: 15px 15px 15px 15px;
    position: absolute;
    z-index: 99;
    top: 18px;
    box-shadow: 0px 0px 8px 2px #00000008;
    left: 5px;
    border: 0.5px solid #88888821;
}

.fl-tag-body-profile-dark {
    background: #1b1b1b;
}

.fl-tag-body-profile-light {
    background: #f4f4f4;
}

.fl-tag-body-info a {
  padding: 10px;
  color: white;
  background: #277eff;
  position: absolute;
  right: 15px;
  top: 20px;
  padding: 7px 15px;
  height: 40px;
  border-radius: 15px;
}

.fl-tag-body-link:hover {
  text-decoration: underline;
}

.fl-my-profile-info a:hover {
  text-decoration: none;
}

.fl-tag-body-profile {
  display: none;
}

.comment-paragraph-text:hover .fl-tag-body-profile {
  display: block;
}