.fl-button {
  padding: 10px 25px;
  border-radius: 15px;
  font-size: 16px;
  line-height: 24px;
  border: none;
  outline: none;
  background: none;
  font-weight: 600;
  cursor: pointer;
  height: 50px;
  text-decoration: none;
  display: flex;
  justify-content: center;
  align-items: center;
}

.fl-button-normal {
  color: #fff;
  background: linear-gradient(to right, #ff037c, #ff4443);
  border: 3px solid #ffffff00;
}

.fl-button-normal:not(:disabled):hover {
  background: linear-gradient(to right, #ea0070, #e43c3b);
  box-shadow: 0px 5px 8px 2px #00000020;
}

.fl-button-normal-white {
  color: #ff4443;
  background: #fff;
  border: 3px solid #ffffff00;
}

.fl-button-normal-white:not(:disabled):hover {
  color: #ff4443;
  background: #efefef;
  border: 3px solid #ffffff00;
  box-shadow: 0px 5px 8px 2px #00000020;
}

.fl-button-outlined {
  color: #ff037c;
  border: 3px solid #ff037c;
}

.fl-button-outlined:not(:disabled):hover {
  background: linear-gradient(to right, #ff037c, #ff4443);
  color: #fff;
  border: 3px solid #ff037c;
}
.fl-button-outlined:not(:disabled):hover > span {
  color: #fff;
}

.fl-button-outlined-white {
  color: #fff;
  border: 3px solid #fff;
}

.fl-button-outlined-white:not(:disabled):hover {
  background-color: #fff;
  color: #ff4443;
  border: 3px solid #fff;
}

.fl-button-empty.fl-button-dark {
  color: #fff;
  background: none;
  border: 3px solid #ffffff00;
}

.fl-button-empty.fl-button-light {
  color: #000;
  background: none;
  border: 3px solid #ffffff00;
}

.fl-button-empty-white {
  color: #fff;
  background: none;
  border: 3px solid #ffffff00;
}

.fl-button-empty-white:not(:disabled):hover {
  opacity: 0.6;
}

.fl-button-empty:not(:disabled):hover {
  opacity: 0.6;
}

.fl-button-location,
.fl-button-active-location {
  box-shadow: 0px 0px 8px 2px #00000008;
}

.fl-button-location.fl-button-light {
  color: #888888;
  background-color: #fff;
  border: 3px solid #fff;
}

.fl-button-location.fl-button-dark {
  color: #888888;
  background-color: #000;
  border: 3px solid #000;
}

.fl-button-active-location {
  color: #fff;
  background: linear-gradient(to right, #ff037c, #ff4443);
  border: 3px solid #ff037c;
}

.fl-button-light.fl-button-location img {
  opacity: 0.5;
}
.fl-button-dark.fl-button-location img {
  filter: invert(1);
  opacity: 0.5;
}

.fl-button-active-location img {
  filter: invert(1);
}

.fl-button-cancel {
  color: #fff;
  border: 3px solid #888;
  background-color: #888;
}

.fl-button-cancel.fl-button-dark:not(:disabled):hover {
  background-color: #999;
}

.fl-button-cancel.fl-button-light:not(:disabled):hover {
  background-color: #777;
}

.fl-button-disabled {
  pointer-events: none;
  opacity: 0.5;
}

.fl-button-default:not(:disabled):hover {
  opacity: 0.6;
}

.fl-button-default.fl-button-dark {
  color: #fff;
}

/* Large devices (desktops, less than 1200px) */
@media (max-width: 1199.98px) {
  .fl-button {
    font-size: 14px;
    height: 40px;
    line-height: 14px;
  }
}

/* Medium devices (tablets, less than 992px) */
@media (max-width: 991.98px) {
}

/* Small devices (landscape phones, less than 768px) */
@media (max-width: 767.98px) {
}

/* Extra small devices (portrait phones, less than 576px) */
@media (max-width: 575.98px) {
  .fl-button {
    font-size: 12px;
  }
  .fl-button-force-normal-on-mobile {
    border: none !important;
    border-radius: 0px !important;
    width: 100%;
  }
  .fl-button-force-normal-on-mobile-light.fl-button-force-normal-on-mobile {
    color: #000 !important;
    background: #00000020 !important;
  }
  .fl-button-force-normal-on-mobile-light.fl-button-force-normal-on-mobile:nth-of-type(odd) {
    background: #00000010 !important;
  }
  .fl-button-force-normal-on-mobile-dark.fl-button-force-normal-on-mobile {
    color: #fff !important;
    background: #ffffff20 !important;
  }
  .fl-button-force-normal-on-mobile-dark.fl-button-force-normal-on-mobile:nth-of-type(odd) {
    background: #ffffff10 !important;
  }
}

.fl-button:disabled {
  opacity: 0.5;
  cursor: unset;
}
