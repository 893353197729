.fl-text-underline:hover {
    text-decoration: underline #ff0d72 1px;
}

.fl-flex-centered-column {
    display: flex;
    flex-direction: column;
    align-items: center;
}

.fl-fullscreen-with-footer-bg {
    z-index: -1;
}