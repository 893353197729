.fl-discover__nav {
  display: grid;
  grid-template-columns: repeat(5, 1fr);
  align-items: center;
}
.fl-discover__nav--link {
  text-decoration: none;
  flex: 1;
  padding: 0 0.8rem;
  color: #808081;
  font-size: 1rem;
  transition: 0.2s all;
  display: flex;
  align-items: center;
  justify-content: center;
  display: block;
  text-align: center;
  line-height: 1.5;
}

.fl-discover__nav--link span {
  display: block;
}

.fl-discover__nav--link b {
  margin-top: -5px;
  padding: 0px 6px 0px 2px;
}

.fl-menu-button {
  cursor: pointer;
}
.fl-inline-button {
  display: inline-block;
}
.fl-inline-button-open {
  margin-bottom: 20px;
}
.fl-flex-row-container {
  display: flex;
}
.fl-menu-closed {
  max-height: 0px;
  transform: scaleY(0);
  overflow: visible;
  transform-origin: top;
  transition: transform 0.3s, max-height 0.1s;
}
.fl-menu-open {
  transition: transform 0.5s, max-height 0.1s;
  transform-origin: top;
  max-height: 100%;
  transform: scaleY(1);
}
.fl-inline-button > svg {
  transform: scale(1.7);
}
.fl-discover__nav--link--active {
  color: #ff3546;
  font-weight: 500;
}

.fl-discover__card {
  display: flex;
  justify-content: center;
}

.fl-filter-span-dark {
  color: white;
}

.fl-discover__filter__sticky {
  position: sticky;
  /*margin-top: 95px;*/
  /*height: 760px;*/
  overflow-y: auto;
  height: 100%;
  border-radius: 24px;
}

.fl-discover__filter__sticky .fl-filter__accordion .fl-minput-main-expand {
  padding-top: 10px;
}

/*.fl-discover__filter__sticky::-webkit-scrollbar {*/
/*  width: 10px;*/
/*}*/

/*.fl-discover__filter__sticky::-webkit-scrollbar-track {*/
/*  background: transparent;*/
/*}*/

.fl-discover__filter__sticky::-webkit-scrollbar-thumb {
  background: #6e6e6e;
  border-radius: 20px;
}

.fl-discover__filter__sticky::-webkit-scrollbar-thumb:hover {
  background: #6e6e6e;
}

/* -----------------------------------
    #SEARCH
----------------------------------- */
.fl-discover__search {
  width: 100%;
  border: 1px solid #cecece;
  padding: 0.5rem;
  color: #808080;
  border-radius: 5px;
}
.fl-discover__search:focus {
  border: 1px solid #808080;
  outline: none;
}
.fl-discover__search-dark {
  background-color: #ffffff10;
  border: 1px solid #ffffff10;
  color: #fff;
}

/* ------------------------------------
    #CHECKBOXES
---------------------------------------*/
.fl-discover__checklist {
}
.fl-discover__checklist__checkbox {
  display: flex;
  align-items: center;
  user-select: none;
}

.fl-discover__checklist__checkbox:hover {
  color: #ff2b57;
}

.fl-discover__checklist__checkbox--selected {
  color: #ff2128;
}
.fl-discover__checklist__checkbox span {
  width: 18px;
  color: #ff2128 !important;
  transform: scale(0);
  transition: 0.2s transform;
}
.fl-discover__checklist__checkbox--checked {
  transform: scale(1) !important;
}
.fl-discover__checklist__checkbox label {
  margin-left: 0.25rem;
  cursor: pointer;
}

.fl-discover__inputbox {
  display: flex;
  margin-left: 5%;
}
.fl-discover__inputbox__field {
  width: 40%;
}
.fl-discover__inputbox__field input {
  padding: 0.25rem;
  width: 80%;
}

.fl-discover__nav--link-online {
  color: #00aa00;
}

.fl-discover__nav--link-online-indicator {
  margin-left: 5px;
  margin-top: -4px;
  background: #4dd965;
  width: 12px;
  height: 12px;
  border-radius: 50%;
  display: inline-block;
}

.fl-profile-card-link {
  font-size: 12px;
  font-weight: 600;
  color: #fff;
  text-decoration: none;
}

.fl-profile-card-name:not(.fl-featured-profiles-inner .fl-profile-card-name) {
  font-size: 12px;
  font-weight: 400;
  color: #efefef;
  text-decoration: none;
}

.fl-usercard__image {
  cursor: pointer;
}

/* --------------------------------
    #SELECT
  -----------------------------------*/
.fl-discover__select {
  width: 100%;
}
.fl-discover__select__selected {
  display: flex;
  justify-content: space-between;
  margin: 0.25rem;
  align-items: center;
}

.fl-discover__select__selected svg {
  margin-left: 5px;
}

.fl-discover__select__selected span {
  cursor: pointer;
}
.fl-discover__select__selected--svg {
  cursor: pointer;
}

.fl-discover__select__wrapper-scroll {
  overflow-y: auto;
  max-height: 200px;
  padding: 3px;
}

.fl-discover__select__wrapper-scroll > div {
  box-shadow: 0px 0px 8px 2px #00000008;
}

.fl-discover__select__item:hover {
  background-color: #ff0d72;
  color: #fff;
}

.fl-discover__select__item {
  padding: 0.25rem;
  transition: 0.2s all;
  cursor: pointer;
  display: flex;
}

.fl-discover__select__item-hover {
  background-color: #ff0d72;
  color: #fff;
}

.fl-discover__select__item-selected {
  background-color: #ff3546af;
  color: white;
  position: relative;
}

.fl-discover__select__item-selected:hover > .fl-selected-item-close-button {
  display: flex;
  top: 12px;
  right: 12px;
}

.fl-discover__select__item img {
  width: 20px;
  height: 20px;
  border-radius: 50%;
  margin-right: 0.25rem;
}

.fl-discover__select__section {
  background-color: #808080;
  color: #fff;
  width: 110%;
  margin-left: -5%;
  padding: 0 1rem;
  display: block;
}

.fl-discover__filter__tags {
  display: flex;
  flex-wrap: wrap;
  margin-bottom: 1rem;
  min-height: 33px;
}

.fl-discover__filter__tag {
  padding: 0.25rem 0.5rem;
  margin: 0.25rem;
  border-radius: 4px;
  background: #ff3b4b;
  color: #fff;
  cursor: pointer;
}

.fl-usercard__wrapper {
  display: flex;
  flex-wrap: wrap;
}

.fl-discover__filter--tablet--hamburger {
  display: none;
}

.fl-discover-navigation-active {
  cursor: default;
}

.fl-explore-simple-card {
  margin-bottom: 15px;
}

.fl-explore-change-view-select {
  background: #00000010;
}

.fl-explore-change-view-select svg {
  color: black;
}

.fl-explore-change-view {
  display: flex;
  align-items: center;
  justify-content: center;
  margin-bottom: 15px;
  color: #888;
  padding: 0px 5px;
}

.fl-explore-change-view-section {
  display: flex;
  justify-content: center;
}

.fl-explore-change-view:hover svg {
  color: black;
}

.fl-explore-change-view span {
  padding-top: 5px;
  padding-left: 5px;
  cursor: pointer;
}

.fl-explore-change-view div {
  cursor: pointer;
  width: 35px;
  height: 35px;
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 50%;
  font-size: 20px;
}

.flag-icon-label {
  margin: 5px;
}

.fl-explore-buttons a {
  padding: 10px 0px;
}

.fl-explore-verified {
  position: relative;
  margin-left: 5px;
  margin-top: 1px;
}

.fl-explore-verified svg:first-child {
  color: white;
}

.fl-explore-verified .fl-explore-verified-tick {
  color: #ff0d72;
  font-size: 8px;
  top: 3px;
  left: 2px;
  position: absolute;
}

@media (max-width: 1199.98px) {
  .fl-explore-verified .fl-explore-verified-tick {
    top: 7px;
  }
}

@media (max-width: 767.98px) {
  .fl-discover__filter__sticky {
    height: 80%;
  }
  .fl-discover__filter--tablet {
    background-color: rgba(0, 0, 0, 0.2);
    transform: translateX(-100%);
    transition: 0.4s all;
    z-index: 1;
    position: fixed;
    top: 0;
    left: 0;
    bottom: 0;
    right: 0;
  }
  .fl-discover__filter--tablet--active {
    transform: translateX(0);
    z-index: 999;
  }
  .fl-discover__filter--tablet--hamburger {
    display: flex;
    z-index: 3;
    position: absolute;
    top: 150px;
    right: -30px;
    padding: 7px 7px;
    background: #ececec;
    border-radius: 25%;
    cursor: pointer;
  }
  .fl-discover__filter--tablet--active .fl-discover__filter--tablet--hamburger {
    right: 0px;
    padding: 7px 9.5px;
  }

  .fl-discover__feed--tablet {
    /* position: absolute; */
    width: 90% !important;
  }
}
.fl-explore-trial-profile-link {
  line-height: normal !important;
}
.fl-explore-copy-profile,
.fl-explore-trial-profile {
  display: inline-block;
  text-align: center;
  color: white;
  cursor: pointer;
  width: 20px;
  height: 20px;
  border-radius: 50%;
  border: 1px solid #ffff;
  font-size: 11px;
}

.fl-explore-copy-profile svg,
.fl-explore-trial-profile svg {
  margin-top: 3px;
}

.fl-explore-copy-profile:hover,
.fl-explore-trial-profile:hover {
  opacity: 0.7;
}

.fl-explore-trial-profile {
  margin-left: 3px;
}

@media (max-width: 575.98px) {
  .fl-discover__nav--link {
    padding: 0 0.4rem;
  }
  .fl-discover__nav-padding {
    padding: 10px;
  }
}
@media (max-width: 520px) {
  .fl-discover__nav--link {
    font-size: 0.9rem;
  }
}
@media (max-width: 470px) {
  .fl-discover__nav--link {
    font-size: 0.8rem;
  }
}

@media (max-width: 389px) {
  .fl-discover__nav--link {
    font-size: 0.7rem;
  }
}
