.fl-message-bubble-main {
  width: calc(100% - 100px);
  margin: 0px auto 10px;
  position: relative;
  flex-direction: column;
  display: flex;
}

.fl-message-bubble-main:nth-of-type(1) {
  margin-bottom: 0px;
}

.fl-message-bubble-message-attachment {
  width: 250px;
  border-radius: 5px;
  height: 250px;
  margin-top: 10px;
  display: flex;
  flex-wrap: wrap;
  overflow: hidden;
  border: 4px solid;
  justify-content: space-between;
  align-content: space-between;
  box-shadow: 0px 0px 2px 1px #00000020;
  position: relative;
}

.fl-message-padlock {
  width: 46px;
  margin-bottom: 10px;
}

.fl-message-bubble-message-attachment-unlock {
  position: absolute;
  left: 0px;
  top: 0px;
  width: 100%;
  height: 100%;
  backdrop-filter: blur(5px);
  /* background-color: #00000090; */
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
}

.fl-message-bubble-message-attachment-unlock-creator {
  backdrop-filter: blur(0px);
}

.fl-message-bubble-message-typing{
  background-color: #E6E7ED;
  width: auto;
  border-radius: 50px;
  padding: 9px;
  display: table;
  margin: 0 auto;
  position: relative;
  -webkit-animation: 2s bulge infinite ease-out;
  animation: 2s bulge infinite ease-out;
}
.fl-message-bubble-message-typing:before, .fl-message-bubble-message-typing:after {
  content: '';
  position: absolute;
  bottom: -3px;
  left: 2px;
  height: 10px;
  width: 10px;
  border-radius: 50%;
  background-color: #E6E7ED;
}
.fl-message-bubble-message-typing:after {
  height: 7px;
  width: 7px;
  left: -2px;
  bottom: -10px;
}
.fl-message-bubble-message-typing span {
  height: 7px;
  width: 7px;
  float: left;
  margin: 0 1px;
  background-color: #9E9EA1;
  display: block;
  border-radius: 50%;
  opacity: 0.4;
}
.fl-message-bubble-message-typing span:nth-of-type(1) {
  -webkit-animation: 1s blink infinite 0.3333s;
  animation: 1s blink infinite 0.3333s;
}
.fl-message-bubble-message-typing span:nth-of-type(2) {
  -webkit-animation: 1s blink infinite 0.6666s;
  animation: 1s blink infinite 0.6666s;
}
.fl-message-bubble-message-typing span:nth-of-type(3) {
  -webkit-animation: 1s blink infinite 0.9999s;
  animation: 1s blink infinite 0.9999s;
}

@-webkit-keyframes blink {
  50% {
    opacity: 1;
  }
}

@keyframes blink {
  50% {
    opacity: 1;
  }
}
@-webkit-keyframes bulge {
  50% {
    -webkit-transform: scale(1.05);
    transform: scale(1.05);
  }
}
@keyframes bulge {
  50% {
    -webkit-transform: scale(1.05);
    transform: scale(1.05);
  }
}

.fl-message-bubble-message-attachment-unlock-attachments {
  color: #fff;
  text-align: center;
  margin-bottom: 10px;
}

.fl-message-bubble-message-attachment-unlock-button {
  padding: 5px 15px;
  height: auto;
  border-radius: 5px;
}

.fl-message-bubble-message-attachment.fl-message-bubble-message-attachment-left {
  background-color: #efefef;
  border-color: #efefef;
}

.fl-message-bubble-message-attachment.fl-message-bubble-message-attachment-right {
  background-color: #545454;
  border-color: #ff0d72;
  float: right;
}

.fl-message-bubble-message-info {
  padding: 5px;
  font-size: 10px;
  font-weight: 500;
  color: #888;
}

.fl-message-bubble-message-attachment-img {
  position: relative;
  background-color: #414141;
  color: #fff;
  font-size: 26px;
  display: flex;
  align-items: center;
  justify-content: center;
}

.fl-message-bubble-message-attachment-img img {
  width: 100%;
  height: 100%;
  object-fit: cover;
  object-position: center;
}
.fl-message-bubble-message-attachment-img video {
  width: 100%;
  height: 100% !important;
  object-fit: cover;
  object-position: center;
}
.fl-message-bubble-message-attachment-img .fl-smooth-video,
.fl-message-bubble-message-attachment-img .video {
  height: 100%;
  width: 100%;
}
  /* 1 Image */
.fl-message-bubble-message-attachment-1
.fl-message-bubble-message-attachment-img:nth-of-type(1) {
  width: 100%;
  height: 100%;
}

/* 2 Images */
.fl-message-bubble-message-attachment-2
.fl-message-bubble-message-attachment-img:nth-of-type(1),
.fl-message-bubble-message-attachment-2
.fl-message-bubble-message-attachment-img:nth-of-type(2) {
  width: calc(50% - 2px);
  height: 100%;
}

/* 3 Images */
.fl-message-bubble-message-attachment-3
.fl-message-bubble-message-attachment-img:nth-of-type(1),
.fl-message-bubble-message-attachment-3
.fl-message-bubble-message-attachment-img:nth-of-type(2) {
  width: calc(50% - 2px);
  height: calc(50% - 2px);
}
.fl-message-bubble-message-attachment-3
.fl-message-bubble-message-attachment-img:nth-of-type(3) {
  width: 100%;
  height: calc(50% - 2px);
}

/* 4 Images */
.fl-message-bubble-message-attachment-4
.fl-message-bubble-message-attachment-img:nth-of-type(1),
.fl-message-bubble-message-attachment-4
.fl-message-bubble-message-attachment-img:nth-of-type(2),
.fl-message-bubble-message-attachment-4
.fl-message-bubble-message-attachment-img:nth-of-type(3),
.fl-message-bubble-message-attachment-4
.fl-message-bubble-message-attachment-img:nth-of-type(4) {
  width: calc(50% - 2px);
  height: calc(50% - 2px);
}

/* More Images */
.fl-message-bubble-message-attachment-more
.fl-message-bubble-message-attachment-img:nth-of-type(1),
.fl-message-bubble-message-attachment-more
.fl-message-bubble-message-attachment-img:nth-of-type(2),
.fl-message-bubble-message-attachment-more
.fl-message-bubble-message-attachment-img:nth-of-type(3),
.fl-message-bubble-message-attachment-more
.fl-message-bubble-message-attachment-img:nth-of-type(4) {
  width: calc(50% - 2px);
  height: calc(50% - 2px);
}

.fl-message-bubble-message-attachment-img-blur img {
  filter: blur(5px);
}

.fl-message-bubble-message-attachment-img-blur-count {
  position: absolute;
  color: #FF0D72;
  font-size: 40px;
}

.fl-message-bubble-main-left .fl-message-bubble-message-info-timestamp {
  order: 2;
}
.fl-single-message-restricted {
  color: red;
}
.fl-message-bubble-main-left .fl-message-bubble-message-info-status {
  order: 1;
  margin-right: 3px;
}
.fl-message-bubble-main-right .fl-message-bubble-message-info-timestamp {
  order: 1;
}
.fl-message-bubble-main-right .fl-message-bubble-message-info-status {
  order: 2;
  margin-left: 3px;
}

.fl-message-bubble-message-info-status-gray {
  color: #888;
}

.fl-message-bubble-message-info-status-pink {
  color: #ff0d72;
}

.fl-message-bubble-main.fl-message-bubble-main-left {
  align-items: flex-start;
}

.fl-message-bubble-main.fl-message-bubble-main-left-recording {
  height: 50px;
}

.fl-message-bubble-main.fl-message-bubble-main-right {
  align-items: flex-end;
}

.fl-message-bubble-main-left .fl-message-bubble-message-dark {
  background-color: #1b1b1b;
  color: #fff;
}

.fl-message-bubble-main-right .fl-message-bubble-message-dark {
  color: #fff;
}

/*.fl-message-bubble-main-right .fl-message-bubble-message-light {*/
/*  background-color: #ff0d72;*/
/*  color: #fff;*/
/*}*/

.fl-message-bubble-main-left .fl-message-bubble-message-light .fl-message-bubble-message-text {
  background-color: #efefef;
  border-radius: 15px;
  position: relative;
}

.fl-message-bubble-message-text  {
  cursor: default;
}

.fl-message-bubble-main-right .fl-message-bubble-message-light .fl-message-bubble-message-text {
  background-color: #ff0d72;
  color: #fff;
  border-radius: 15px;
  position: relative;
}

.fl-message-bubble-message {
  border-radius: 10px;
  width: fit-content;
  align-items: center;
  position: relative;
}

/*.fl-message-bubble-main-right .fl-message-body-files {*/
/*  display: contents;*/
/*}*/

.fl-direct-option-both {
  bottom: 0;
}
.fl-direct-option-both .fl-simple-card-main {
  margin-bottom: 0px;
}

.fl-message-bubble-message-text {
  padding: 10px;
  max-width: 530px;
}

.fl-message-bubble-main-right .fl-message-bubble-message-text {
  float: right;
}

.fl-message-bubble-main-left .fl-message-bubble-message-text {
  float: left;
}

.fl-message-bubble-message-date {
  text-transform: uppercase;
  font-size: 10px;
  padding: 10px;
  border-top: 1px solid #00000020;
  font-weight: 500;
  color: #888;
}

.fl-message-bubble-avatar {
  width: 40px;
  height: 40px;
  border-radius: 50%;
  position: relative;
  display: block;
  position: absolute;
  text-decoration: none;
  top: 0px;
}

.fl-message-bubble-avatar .fl-profile-holder-status-icon{
  right: -3px;
  bottom: 3px;
}

.fl-message-bubble-avatar.fl-message-bubble-avatar-left {
  left: -50px;
}

.fl-message-bubble-avatar.fl-message-bubble-avatar-right {
  right: -50px;
}

.fl-message-bubble-message-info {
  display: flex;
  align-items: center;
}

.fl-message-bubble-main-left .fl-message-bubble-message-info {
  justify-content: flex-start;
}

.fl-message-bubble-main-right .fl-message-bubble-message-info {
  justify-content: flex-end;
}

.unlocked-true {
  color: green;
}

.fl-bubble-progress .CircularProgressbar {
  height: 150px;
}

.fl-bubble-progress .circural-progress-bar-span text {
  -webkit-text-stroke-width: 0.3px;
  -webkit-text-stroke-color: #ff0d72;
}

.fl-bubble-progress .circural-progress-bar-span {
  color: white;
  font-size: 20px;
  stroke: #ff0d72;
  stroke-width: 1;
}
.fl-bubble-progress .CircularProgressbar-text {
  font-size: 25px;
}

.fl-bubble-progress .CircularProgressbar .CircularProgressbar-path {
  stroke: #ff0d72 !important;
}

.fl-message-bubble-typing-text {
  padding-top: 15px;
  color:#888;
  font-size: 14px;
}

.fl-message-bubble-message-attachment-cancel {
  padding-top: 5px;
  padding-bottom: 5px;
}

.fl-message-bubble-main-none {
  display: none !important;
}

.fl-retry-message-svg {
  margin-left: 5px;
}

.fl-message-bubble-message-attachment-unlock-svg {
  width: 100%;
  position: absolute;
  bottom: 0;
  padding-left: 5px;
  padding-right: 5px;
  padding-bottom: 2px;
}

.fl-message-bubble-message-attachment-unlock-svg svg{
  color: white;
  float: left;
}

.fl-message-bubble-message-attachment-unlock-svg span {
  float: right;
  color: white;
  font-weight: 600;
}

.fl-message-bubble-message-attachment-unlock-logo {
  width: 100%;
  position: absolute;
  top: 0;
  padding-left: 5px;
  padding-right: 5px;
  padding-top: 5px;
}
.fl-message-bubble-message-attachment-unlock-logo img {
  width: 40px;
  float: right;
  opacity: 0.5;
}

.fl-message-bubble-message-attachment-unlock-delete-message {
  width: 100%;
  height: 20px;
  position: absolute;
  top: 0;
  padding-left: 5px;
  padding-right: 5px;
  padding-top: 5px;
}
.fl-message-bubble-message-attachment-unlock-delete-message .fl-direct-messages-title-options {
  right: 0px;
  color: #ff0d72;
}

.fl-direct-messages-title-options-left {
  left: 0px;
  color: #ff0d72;
  position: absolute;
  right: 10px;
  cursor: pointer;
  top: 65%;
  transform: translateY(-50%);
  width: 30px;
  height: 30px;
  display: flex;
  justify-content: center;
  align-items: center;
  font-size: 20px;
  z-index: 10;
}

.fl-message-bubble-message-attachment-unlock-delete-message .fl-post-head-actions-dropdown {
  position: absolute;
  right: 5px;
  top: 28px;
  width: 85px;
  z-index: 10;
  font-size: 14px;
  height: 37px;
}

.fl-message-bubble-message-attachment-unlock-delete-message  .fl-post-head-actions-dropdown-inner {
  padding: 0px;
}

.fl-message-bubble-message-attachment-unlock .CircularProgressbar-text,
.circural-progress-bar-span svg{
  fill: white;
  stroke: #ff0d72;
  stroke-width: 0.5;
}
.circural-progress-bar-span text
 {
  color: #ffffff;
}

.fl-message-bubble-message-attachment-creator-send {
  opacity: 1 !important;
  position: absolute;
  /*left: 5px;*/
  /*top: 5px;*/
  /*font-size: 13px;*/
  /*padding: 5px;*/
}

.fl-message-bubble-message-body-report {
  cursor: pointer;
  margin-left: 5px;
}

.fl-message-bubble-message-attachment-img-true,
.fl-message-bubble-message-text-true {
  opacity: 0.2;
}

.fl-message-bubble-new-day > div {
  border-bottom: solid #e4e3e3 0.5px;
}
.fl-message-bubble-new-day {
  font-size: 13px;
  color: #adadad;
}

.fl-direct-message-option-dropdown-list {
  width: 160px;
  position: absolute;
  left: 10px;
  top: 0px;
}

.fl-direct-message-option-dropdown-list .fl-simple-card-main {
  padding: 0;
}

.fl-direct-option-text-false {
  display: none;
  outline: none;
}

.fl-direct-option-text-true {
  outline: none;
  display: block;
  margin-top: 5px;
  float: right;
  float: left;
}

/*.fl-direct-option-text {*/
/*  display: none;*/
/*  outline: none;*/
/*}*/

.fl-direct-option-text-sender {
  outline: none;
}

.fl-direct-option-text,
.fl-direct-message-option-img {
  outline: none;
}

.fl-direct-option-text .fl-simple-card-outter {
  min-width: 150px;
}

.fl-direct-message-audio-option {
  display: none;
  outline: none;
}

.fl-message-bubble-main-left:hover .fl-direct-message-audio-option {
  display: block;
  float: right;
  cursor: auto;
  outline: none;
}

.fl-direct-message-audio-option-true {
  display: block;
  float: right;
  cursor: auto;
  outline: none;
}

.fl-message-option {
  font-size: 14px;
  padding: 0px;
}

.fl-message-option-position-top {
  position: absolute;
  top: -10px;
  z-index: 99;
  min-width: 150px;
  margin-left: 5px;
}

.fl-message-option-position-top-audio {
  position: absolute;
  top: 0px;
  left: 140px;
  z-index: 99;
  min-width: 160px;
}

.fl-message-option-position-false {
  top: 0px !important;
  margin-left: 5px;
}

.fl-message-bubble-main-left:hover .fl-direct-option-text {
  display: block;
  margin-top: 5px;
  float: right;
  float: left;
}

.fl-message-bubble-main-right .fl-message-option-position-top {
  margin-left: -20px;
}

.fl-direct-message-option-img {
  display: none;
}

.fl-direct-message-option-img-sender {
  display: none;
  outline: none;
}

.fl-message-bubble-main:hover .fl-direct-message-option-img-sender {
  display: block;
  position: absolute;
  right: 260px;
  top: 45%;
  padding-left: 10px;
}

.fl-direct-message-option-img-sender-true {
  display: block;
  position: absolute;
  right: 260px;
  top: 45%;
  padding-left: 10px;
}

.fl-direct-message-option-img-sender .fl-direct-message-option-dropdown-list {
  width: 90px;
  left: -25px;
}

.fl-direct-message-option-img-sender:hover {
  display: block;
  position: absolute;
  right: 260px;
  top: 45%;
  padding-left: 10px;
}

.fl-message-bubble-main:hover .fl-direct-message-option-img {
  display: block;
  position: absolute;
  right: 0;
  left: 245px;
  top: 45%;
  padding-left: 10px;
}

.fl-direct-message-option-img-true {
  display: block;
  position: absolute;
  right: 0;
  left: 245px;
  top: 45%;
  padding-left: 10px;
}

.fl-direct-message-option-img:hover {
  display: block;
  position: absolute;
  right: 0;
  left: 245px;
  top: 45%;
  padding-left: 10px;
}

.fl-direct-message-option-dropdown,
.fl-direct-message-option-like {
  float: left;
  width: 30px;
  height: 30px;
  display: flex;
  justify-content: center;
  align-items: center;
  border-radius: 50%;
  margin-left: 8px;
  font-size: 20px;
  overflow: hidden;
  position: relative;
  color: #888888;
}

.fl-direct-message-option-dropdown {
  margin-left: 20px;
}

.fl-direct-message-option-dropdown svg,
.fl-direct-message-option-like svg {
  cursor:pointer;
}

.fl-direct-message-option-dropdown:hover,
.fl-direct-message-option-like:hover {
  background: rgba(136, 136, 136, 0.65);
  border-radius: 50%;
  color: white;
}

.fl-dm-sent-box {
  width: 170px;
  height: 70px;
  position: absolute;
  top: 0px;
  left: -50px;
  transform: rotate(-45deg);
}
.fl-dm-sent-box-angle {
  position: absolute;
  padding: 0px;
  bottom: 0;
  font-size: 16px;
  color: #fff;
  background: #ff0d72;
  width: 170px;
  height: 50px;
  text-align: center;
  display: table;
}
.fl-dm-sent-box-angle span {
  display: table-cell;
  vertical-align: middle;
  padding-left: 43px;
  padding-right: 43px;
  font-weight: 600;
}

.fl-message-bubble-message-delete {
  background: white;
  color: #888;
  border: 1px solid #888;
  border-radius: 10px;
}

.fl-direct-message-unsend-message {
  z-index: 99;
  float: left;
  width: 20px;
  height: 20px;
  display: flex;
  justify-content: center;
  align-items: center;
  border-radius: 50%;
  right: -8px;
  font-size: 12px;
  bottom: -5px;
  overflow: hidden;
  color: white;
  background: linear-gradient(to bottom, #5886dd, #0067cd);
  position: absolute;
  cursor: pointer;
}

.fl-direct-message-unsend-message:hover {
  background: #4383fa;
}

.fl-message-text-mt {
  margin-top: 5px;
}

.fl-direct-message-like-message {
  z-index: 10;
  float: left;
  width: 20px;
  height: 20px;
  display: flex;
  justify-content: center;
  align-items: center;
  border-radius: 50%;
  font-size: 12px;
  overflow: hidden;
  color: white;
  background: linear-gradient(to bottom, #5886dd, #0067cd);
  position: absolute;
}

.fl-direct-message-like-message div,
.fl-direct-message-like-message span {
  display: flex;
  justify-content: center;
  align-items: center;
}

/*.fl-direct-message-like-message-body {*/
/*  top: -10px;*/
/*}*/

.fl-direct-message-unlike-true {
  display: none;
}

.fl-message-option-position-bottom {
  bottom: -80px;
  position: absolute;
  z-index: 99;
}

.fl-direct-option-right {
  float: right;
  outline: none;
}

.fl-expand-image-left {
  cursor: pointer;
  position: absolute;
  top: 50%;
  color: black;
  color: white;
  left: 30px;
  width: 30px;
  height: 30px;
  border-radius: 50%;
  padding: 2px;
}

.fl-expand-image-right {
  position: absolute;
  top: 50%;
  color: black;
  color: white;
  right: 30px;
  cursor: pointer;
  width: 30px;
  height: 30px;
  border-radius: 50%;
  padding: 2px;
}

.fl-expand-image-right:hover,
.fl-expand-image-left:hover {
  color: #888;
  background: white;
}

.fl-message-liked {
  color: #ff0d72;
}

.fl-message-bubble-main-right .fl-fullscreen-icon {
  margin-right: .5rem;
  right: 0;
}


.fl-message-bubble-message-attachment-img .fl-fullscreen-icon {
  color: white
}

.fl-message-bubble-message-attachment-img .fl-fullscreen-icon svg,
.fl-message-bubble-gallery-content-single .fl-fullscreen-icon svg {
  stroke: #ff0d72;
  stroke-width: 25px;
}

.fl-message-bubble-animation {
  display: none;
  font-size: 50px;
  color:#ff0d72;
  z-index: 999;
}

.fl-fullscreen-icon {
  outline: none;
}

.fl-message-bubble-animation-false {
  display: none;
}

.fl-message-bubble-animation-true {
  display: flex;
  animation: createBox 0.3s;
  animation-iteration-count: 1;
}
.fl-direct-message-like-message {
  animation: createBox 0.3s;
  animation-iteration-count: 1;
}

.fl-direct-message-option-sender {
  display: none;
}

.fl-message-bubble-main-right:hover .fl-direct-message-option-sender {
  display: block;
  margin-top: 5px;
  float: left;
}

.fl-direct-message-option-sender-true {
  display: block;
  margin-top: 5px;
  float: left;
}

.fl-message-option-position-sender-true {
  position: absolute;
  top: 0px;
  left: -75px;
  z-index: 99;
  min-width: 140px;
  outline: none;
}

.fl-direct-message-option-sender .fl-direct-message-option-dropdown {
  margin-right: 20px;
}

.fl-message-option-position-sender {
  position: absolute;
  left: -75px;
  z-index: 99;
  min-width: 140px;
  outline: none;
}

.fl-message-option-position-sender-single {
  top: 0px;
}

.fl-message-reported {
  background: linear-gradient(to right, #15c, #347af7);
  border: 3px solid #15c;
}

.fl-message-reported:hover {
  background: linear-gradient(to right, #0f4fbf, #1e6cf7) !important;
  box-shadow: 0px 5px 8px 2px #00000020;
}


@keyframes createBox {
  from {
    transform: scale(0);
  }
  to {
    transform: scale(1);
  }
}

.fl-message-bubble-main-right .fl-direct-message-like-message {
  left: -10px;
  bottom: -3px;
}

.fl-message-bubble-main-left .fl-direct-message-like-message {
  right: -10px;
  bottom: -3px;
}

.fl-message-reported-full-screen {
  cursor: pointer;
}

/* Large devices (desktops, less than 1200px) */
@media (max-width: 1199.98px) {
}

/* Medium devices (tablets, less than 992px) */
@media (max-width: 991.98px) {
}

/* Small devices (landscape phones, less than 768px) */
@media (max-width: 767.98px) {
}

/* Extra small devices (portrait phones, less than 576px) */
@media (max-width: 575.98px) {
}


