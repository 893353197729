.fl-favorites .fl-profile-count-icons svg {
    width: 100%;
}

.fl-favorites .fl-profile-count-icons img {
    width: 25px;
    height: 25px;
}

.fl-favorites .fl-profile-sort-single-icon-count {
    display:flex;
    align-items: center;
    margin-left: 5px;
}

.fl-favorites .fl-profile-count-icons {
    padding-bottom: 4px;
}
.fl-favorites-display-grid .fl-user-profile-images-main {
    left: 50%;
    transform: translate(-50%, 0%);
}

.fl-favorites-display-grid .fl-user-profile-data-left {
    width: 100%;
    order: 2;
    padding-top: 0px;
}

.fl-favorites-display-grid .fl-user-profile-data-right {
    order: 1;
    width: 100%;
    padding-top: 80px;
    padding-left: 0px;
}

.fl-favorites-display-grid  .fl-user-profile-data {
    padding: 10px 0px;
}

.fl-favorites-display-grid .fl-user-profile-data-right-bio {
   padding-top: 0px;
}
.fl-favorites-display-grid .fl-user-profile-data-right-bio-expand-button {
    margin-top: 10px;
}

.fl-favorites .fl-profile-count-icons svg {
    width: 25px;
    height: 25px;
}

/* Large devices (desktops, less than 1200px) */
@media (max-width: 1199.98px) {

}

/* Medium devices (tablets, less than 992px) */
@media (max-width: 991.98px) { 

}

/* Small devices (landscape phones, less than 768px) */
@media (max-width: 767.98px) { 
    .fl-favorites-tablet {
      padding-bottom: 100px;
    }
 }

/* Extra small devices (portrait phones, less than 576px) */
@media (max-width: 575.98px) {
    
}