.fl-subroutes-main {
    width: 100%;
    height: 130px;
}

.fl-subroutes-main .fl-container  {

}

.fl-subroutes-main.fl-subroutes-main-light {
    background-color: #f4f4f4;
}

.fl-subroutes-main.fl-subroutes-main-dark {
    background-color: #1b1b1b;
}

.fl-subroutes-navbar-items {
    padding: 40px 0px;
}

.fl-subroutes-navbar-item {
    padding: 0px 8px;
    width: 20%;
}

.fl-subroutes-navbar-icon {
    margin-right: 10px;
    position: relative;
    height: 24px;
    font-size: 24px;
    min-width: 23px;
}

.fl-subroutes-navbar-icon .fl-profile-holder-status-icon {
    right: -4px;
    bottom: 0px;
    width: 9px;
    height: 9px;
}

.fl-subroutes-navbar-item span {
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
    margin-top: 3px;
}

.smooth-image-wrapper .loader {
    margin-top: 0px !important;
    margin-left: 0.5px;
}

.fl-subroutes-link {
    width: 100%;
    justify-content: center;
    padding: 10px 10px;
    /* transition: 300ms color ease-in-out, 300ms background-color ease-in-out; */
}

.fl-button-active-location .fl-subroute-counter {
    color: red;
    background-color: white;
}

.fl-subroute-counter {
    min-width: 14px;
    height: 14px;
    font-size: 10px;
    line-height: 10px;
    color: #fff;
    border-radius: 5px;
    background-color: red;
    position: absolute;
    right: 0px;
    top: 0px;
    transform: translate(50%,-50%);
    display: flex;
    align-items: center;
    justify-content: center;
    font-weight: bold;
    padding: 2px 3px 0px;
}

.fl-subroutes-new-post-light,
.fl-subroutes-new-post-dark {
    margin-left: 5px;
}

.fl-button-location .fl-subroutes-new-post-light {
    color: #ff0d72;
}

.fl-button-active-location .fl-subroutes-new-post-light {
    color: white;
}

/* .fl-button-light.fl-subroutes-link:hover {
    background-color: #efefef;
    border-color: #afafaf60;
}

.fl-button-dark.fl-subroutes-link:hover {
    background-color: #232323;
    border-color: #42424260;
} */

.fl-button-light.fl-subroutes-link:hover {
    background: #f6f6f6;
    box-shadow: 0px 5px 8px 2px #00000020;
}

.fl-button-dark.fl-subroutes-link:hover {
    background: #1b1b1b;
    box-shadow: 0px 5px 8px 2px #00000020;
}

.fl-subroutes-link.fl-button-active-location:hover {
    background: linear-gradient(to right, #ea0070, #e43c3b);
    box-shadow: 0px 5px 8px 2px #00000020;
}

.fl-subroutes-navbar-items .fl-subroutes-navbar-item:last-child .fl-subroutes-navbar-icon img {
    width: 24px;
    height: 24px;
    /*background-color: #afafaf;*/
    border-radius: 50%;
    opacity: 1;
    /*border: 1px solid rgb(232, 232, 232);*/
    filter: unset;
}

.fl-subroute-size {
    font-size: 14px;
}

/* Large devices (desktops, less than 1200px) */
@media (max-width: 1199.98px) {
    .fl-subroutes-link {
        font-size: 13px;
    }
}

/* Medium devices (tablets, less than 992px) */
@media (max-width: 991.98px) { 
    .fl-subroutes-link {
        display: flex;
        align-items: center;
        justify-content: center;
    }
    .fl-subroutes-link span {
        display: none;
    }
    .fl-subroutes-navbar-icon {
        margin-right: 0px;
    }
}

/* Small devices (landscape phones, less than 768px) */
@media (max-width: 767.98px) { 
    .fl-subroutes-main {
        padding-top: 30px;
    }
    .fl-subroutes-main .fl-container{
        display: none;
    }
 }

/* Extra small devices (portrait phones, less than 576px) */
@media (max-width: 575.98px) {

}