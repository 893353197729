.fl-not-found {
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    padding: 0 25px;
}

.fl-not-found-image {
    max-width: 200px;
}

.fl-not-found-text {
    text-align: center;
    font-size: 36px;
    padding: 10px 0px;
    font-weight: 600;
}

.fl-not-found-text.fl-not-found-text-dark {
    color: #fff;
}
.fl-not-found-text.fl-not-found-text-light {
    color: #000;
}

.fl-not-found-redirection {
    font-size: 18px;
    font-weight: 400;
    padding: 20px;
}

.fl-not-found-redirection.fl-not-found-redirection-light {
    color: #000;
}
.fl-not-found-redirection.fl-not-found-redirection-dark {
    color: #fff;
}

.fl-not-found-redirection-link  {
    font-weight: 500;
    color: #FF037C;
    cursor: pointer;
}

.fl-not-found-icon {
    font-size: 100px;
    margin-bottom: 10px;
    color: #888888;
}

.fl-not-found-redirection-link {
    text-decoration: none;
}

.fl-not-found-redirection-link:hover {
    text-decoration: underline;
}



/* Large devices (desktops, less than 1200px) */
@media (max-width: 1199.98px) { }

/* Medium devices (tablets, less than 992px) */
@media (max-width: 991.98px) { 
    .fl-not-found-text {
        font-size: 32px;
    }
}

/* Small devices (landscape phones, less than 768px) */
@media (max-width: 767.98px) {  }

/* Extra small devices (portrait phones, less than 576px) */
@media (max-width: 575.98px) {
    .fl-not-found {
        padding: 30px 10px;
    }
}