.fl-messages-main {
  width: 100%;
  flex: 1;
  height: 100%;
}

.fl-messages-main .fl-container,
.fl-messages-main .fl-container .fl-feed-row {
  height: 100%;
}

.fl-messages-col {
  height: 100%;
}

.fl-fluffa-desktop .fl-messages-col {
  height: calc(100% - 80px)
}

.fl-fluffa-desktop .fl-messages-col .fl-simple-card-outter {
  height: 100%;
}

.fl-messages-main.fl-messages-main-light {
  background-color: #f4f4f4;
}

.fl-messages-main.fl-messages-main-dark {
  background-color: #1b1b1b;
}

.fl-messages-single {
  width: 100%;
  padding: 10px;
  display: flex;
  align-items: center;
  text-decoration: none;
}

.fl-messages-single.fl-messages-single-dark:nth-last-of-type(1),
.fl-messages-single.fl-messages-single-light:nth-last-of-type(1) {
  border-bottom: 1px solid #ffffff20;
}

.fl-messages-single.fl-messages-single-light:nth-last-of-type(1) {
  border-bottom: 1px solid #00000020;
}

.fl-messages-single.fl-messages-single-light:hover {
  background-color: #00000010;
}

.fl-messages-single.fl-messages-single-dark:hover {
  background-color: #ffffff10;
}

.fl-messages-title {
  width: 100%;
  font-size: 21px;
  font-weight: 500;
  padding: 20px;
  line-height: 21px;
  display: flex;
  justify-content: space-between;
  align-items: center;
  position: relative;
  outline: none;
}
.fl-messages-title.fl-messages-title-light {
  color: #888;
  border-bottom: 1px solid #00000020;
}
.fl-messages-title.fl-messages-title-dark {
  color: #fff;
  border-bottom: 1px solid #ffffff20;
}

.fl-messages-people {
  padding: 0px;
  min-height: inherit;
  display: flex;
  flex-direction: column;
  overflow: hidden;
  height: 100%;
}

.fl-messages-all {
  position: relative;
  flex: 1;
}

.fl-profile-holder .loader,
.fl-profile-holder .smooth-preloader {
  border-radius: 50%;
}

.fl-messages-all-inner {
  position: absolute;
  width: 100%;
  height: 100%;
  overflow-x: hidden;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
}

.fl-messages-show-more {
  width: 100%;
  height: 70px;
  display: flex;
  justify-content: center;
  align-content: center;
}

.fl-messages-show-more-link {
  font-size: 18px;
  text-decoration: none;
  color: #ff0d72;
  cursor: pointer;
  text-align: center;
}

.fl-messages-more-link-show {
  padding: 13px 20px 0px;
}

.fl-messages-more-link-hide {
  padding: 0px;
}

.fl-messages-single-photo {
  min-width: 50px;
  min-height: 50px;
  position: relative;
  border-radius: 50%;
  /*overflow: hidden;*/
}

.fl-messages-single-photo .fl-profile-holder-status-icon {
  right: -1px;
}

.fl-messages-single-text {
  width: calc(100% - 50px);
  height: 50px;
  padding-left: 15px;
  display: flex;
  justify-content: center;
  flex-direction: column;
}

.fl-messages-single-text-info {
  width: 90%;
  height: 18px;
  font-size: 16px;
  font-weight: 600;
  line-height: 18px;
  text-overflow: ellipsis;
  overflow: hidden;
  white-space: nowrap;
}

.fl-messages-single-text-msg {
  font-size: 16px;
  height: 24px;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
  width: 60%;
  align-items: center;
}

.fl-messages-single-text-username-dark {
  color: white;
}

.fl-messages-single-text-handle-light:not(.fl-messages-single-active .fl-messages-single-text-handle-light) {
  color: #888;
}

.fl-messages-single-text-handle-dark {
  color: white;
}

.fl-messages-single-text-msg:not(.fl-messages-single-active .fl-messages-single-text-msg) {
  color: #888;
}

.fl-messages-single-active .fl-messages-single-text-msg {
  color: white;
}

.fl-messages-single-seen-false .fl-messages-single-text-msg {
  font-weight: 600;
}

.fl-messages-single-text-msg svg {
  margin-left: 5px;
}

.fl-messages-single-date-msg {
  display: flex;
  flex-direction: column;
  align-items: flex-end;
  min-width: 30%;
  color: #888;
}

.fl-messages-single-active .fl-messages-single-date-msg {
  color: white;
}

.fl-messages-single-info-msg {
  display: flex;
  color: #888;
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  align-items: center;
}

.fl-messages-single-seen-false  .fl-messages-single-date-msg-date-default {
  color:  #ff0d72;
}

.fl-messages-single-seen-false .fl-messages-single-date-msg-date-muted {
  color: #888;
}

.fl-messages-single-seen-false  .fl-msg-date-default {
  color: #ff0d72;
}

.fl-messages-single-seen-false   .fl-msg-dot-default {
  background: #ff0d72;
}

.fl-messages-single-date-msg .fl-messages-single-date-msg-dot {
  height: 10px;
  width: 10px;
  border-radius: 50%;
  display: inline-block;
  margin-left: 5px;
}

.fl-msg-dot-muted {
  background: #888;
}

fl-msg-dot-default {
  background-color: #ff0d72;
}

.fl-message-list-delete,
.fl-message-list-ban-bell {
  width: 17px;
  text-align: center;
}

.fl-message-list-delete svg:hover,
.fl-direct-messages-title-actions span:hover,
.fl-direct-messages-title-options:hover {
  color: #525252;
}

.fl-direct-messages-title-icon:hover {
  color: #ea0070;
}

.fl-message-list-delete {
  padding-bottom: 8px;
}

.fl-messages-single-active {
  background-color: #ff0d72 !important;
  border-color: transparent !important;
}

.fl-active-ban-icon {
  color:white;
}

.fl-messages-all-convs {
  height: 100%;
}

.fl-messages-all-convs-new {
  display: flex;
  align-items: center;
  height: 72px;
  padding: 20px;
  color: #fff;
  background: linear-gradient(
          180deg
          ,#679dff,#007eff);
  cursor: pointer;
  text-decoration: none;
  font-weight: 500;
  font-size: 18px;
}

.fl-messages-all-convs-new:hover {
  background: linear-gradient(180deg,#6e67ff,#007eff);
}

.fl-messages-all-convs-new-icon {
  margin-right: 10px;
}

.fl-messages-single-photo .fl-direct-messages-title-indicator {
  position: absolute;
  bottom: 5px;
  right: 0px;
  z-index: 999;
}

.fl-direct-messages-title-dm {
  cursor: default;
}

.fl-messages-single-seen-false {
  background:rgba(0, 0, 0, 0.23);
}

/*.fl-messages-all-inner::-webkit-scrollbar {*/
/*  width: 6px;*/
/*  height: 6px;*/
/*  border-radius: 20px;*/
/*}*/

/*.fl-messages-all-inner::-webkit-scrollbar-track {*/
/*  background: transparent;*/
/*}*/

/*.fl-messages-all-inner::-webkit-scrollbar-thumb {*/
/*  background: #ff4444;*/
/*}*/

/*.fl-messages-all-inner::-webkit-scrollbar-thumb:hover*/
/*{*/
/*  background: black;*/
/*}*/

.fl-messages-filter span {
  cursor: pointer;
  background: #00000010;
  width: 35px;
  height: 35px;
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 50%;
  float: right;
  margin-left: 8px;
  outline: none;
}

.fl-messages-filter-main {
  position: absolute;
  right: 20px;
  top: 15px;
  z-index: 999;
}

.fl-messages-count-selected {
  float: left;
  height: 35px;
  display: flex;
  align-items: center;
  justify-content: center;
  font-size: 20px;
}

.fl-messages-count-selected b {
  margin-top: 5px;
  font-weight: normal;
}

.fl-messages-count-selected-all {
  margin-top: 5px;
  font-weight: normal;
}

.fl-messages-count-selected span {
  min-width: 35px;
  height: 35px;
  display: flex;
  align-items: center;
  justify-content: center;
  margin-left: 8px;
  font-weight: normal;
  font-size: 16px;
}

.fl-messages-filter-option {
  width: 100%;
  text-decoration: none;
}

.fl-messages-filter-option .fl-messages-single-option {
  cursor: pointer;
  color: #888;
  padding-right: 25%;
  padding-left: 25%;
  padding-top: 15px;
  padding-bottom: 15px;
  border-bottom: 1px solid #00000020;
}

.fl-messages-filter-option .fl-messages-single-option:hover {
  background-color: #00000010;
}

.fl-messages-single-option-active {
  font-weight: 500;
  color: #ff0d72 !important;
}

.fl-messages-single-option svg {
  position: absolute;
  margin-left: -25px;
}

.fl-messages-list-check {
  position: absolute;
  top: 20px;
  right: 20px;
  width: 35px;
  height: 35px;
  border-radius: 50%;
  border: 1px solid rgba(0,0,0,.12549019607843137);
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: transparent;
  transition: background-color .3s ease-in-out;
}

.fl-messages-single-active .fl-messages-list-check {
  border-color: white;
}

.fl-messages-list-check-all {
  float: right;
  border-radius: 50%;
  border: 1px solid rgba(0,0,0,.12549019607843137);
  cursor: pointer;
  width: 35px;
  height: 35px;
  display: flex;
  align-items: center;
  justify-content: center;
}

.fl-messages-list-check-all:hover {
  background: #00000026;
}

.fl-single-message-added {
  background-color: rgba(255,13,114,.12549019607843137);
}

.fl-messages-list-checked,
.fl-messages-list-checked-all {
  background-color: #ff0d72 !important;
  color: white;
  border-color: white;
}

.fl-messages-title .fl-post-head-actions-dropdown {
  right: 2px;
  top: 60px;
}

.fl-filter-head-actions-dropdown {
  position: absolute;
  right: 5px;
  top: 50px;
  width: 250px;
  z-index: 10;
  font-size: 16px;
}

.fl-filter-head-actions-dropdown-inner {
  overflow: hidden;
  padding: 0px;
}

.fl-messages-filter-check {
  font-size: 12px;
  width: 25px;
  height: 25px;
  border-radius: 50%;
  border: 1px solid gray;
  background-color: transparent;
  display: flex;
  align-items: center;
  justify-content: center;
  transition: background-color .3s ease-in-out;
}

.fl-dropdown-filter-option {
  display: flex;
  align-items: center;
  padding: 15px 10px 10px 15px;
}

.fl-dropdown-filter-option:not(.fl-dropdown-filter-option:first-child) {
  cursor: pointer;
}

.fl-dropdown-filter-option:first-child {
  cursor: default;
}

.fl-dropdown-filter-option span {
  padding-left: 10px;
}

.fl-filter-head-actions-dropdown-light .fl-filter-head-actions-dropdown-inner {
  border: 1px solid #00000020;
}

.fl-messages-filter-active {
  background-color: #ff0d72 !important;
  color: white;
  border-color: white;
}

.fl-dropdown-filter-option-read {
  border-top: 1px solid rgba(0,0,0,.12549019607843137);
  border-bottom: 1px solid rgba(0,0,0,.12549019607843137);
  padding: 15px 15px 15px 15px;
}

.fl-filter-head-actions-dropdown-option-icon {
  width: 25px;
  height: 25px;
  margin-right: 5px;
  display: flex;
  justify-content: center;
  align-items: center;
  font-size: 16px;
  color: #ff0d7290;
}

.fl-dropdown-filter-option:hover:not(.fl-dropdown-filter-option:first-child),
.fl-dropdown-filter-option-read:hover,
.fl-dropdown-filter-option-delete:hover,
.fl-dropdown-filter-option-read:hover .fl-filter-head-actions-dropdown-option-icon,
.fl-dropdown-filter-option-delete:hover .fl-filter-head-actions-dropdown-option-icon {
  background: #ff0d72;
  color: white;
}

.fl-dropdown-filter-option:hover .fl-messages-filter-check {
  border-color: white;
}

.fl-messages-filter-show-more:hover {
  background: #e3e3e3;
}

.fl-dropdown-filter-option-title {
  border-bottom: 1px solid rgba(0,0,0,.12549019607843137);
}

.fl-messages-single-text-handle {
  margin-left: 5px;
  text-decoration: none;
}

.fl-messages-single-active {
  color: white;
}

/* Large devices (desktops, less than 1200px) */
@media (max-width: 1199.98px) {
  .fl-messages-show-more-link {
    font-size: 16px;
  }
}

/* Medium devices (tablets, less than 992px) */
@media (max-width: 991.98px) {
  .fl-messages-col {
    display: none;
  }
  .fl-messages-mobile-priority {
    width: 100% !important;
    display: flex;
  }
}

/* Small devices (landscape phones, less than 768px) */
@media (max-width: 767.98px) {

}

/* Extra small devices (portrait phones, less than 576px) */
@media (max-width: 575.98px) {
}
