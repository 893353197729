@import "./assets/fonts/fonts-loader.css";

:root {
  --fl-body-color: #fff;
}

html,
body {
  position: relative;
  height: 100%;
}

body {
  margin: 0;
  font-family: "Objectivity", -apple-system, BlinkMacSystemFont, "Segoe UI",
    "Roboto", "Oxygen", "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans",
    "Helvetica Neue", sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  width: 100%;
  box-sizing: border-box;
  background-color: var(--fl-body-color);
  word-wrap: break-word;
  overflow-y: scroll;
}

textarea,
input {
  font-family: "Objectivity", -apple-system, BlinkMacSystemFont, "Segoe UI",
    "Roboto", "Oxygen", "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans",
    "Helvetica Neue", sans-serif;
}

body * {
  box-sizing: border-box;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
    monospace;
}

.__react_component_tooltip.place-top.type-dark {
  max-width: 70vw;
}

@media screen and (max-width: 767px) {
  input,
  select,
  textarea {
    font-size: 16px !important;
  }
  .__react_component_tooltip.show {
    visibility: hidden;
  }
}
