.fl-accordion {
  overflow: hidden;
  border-radius: 1rem;
  margin-bottom: 1rem;
  padding: 1rem;
  border: 1px solid rgba(191, 191, 191, 0.5);
  font-size: 16px;
  line-height: 24px;
  font-weight: 600;
  transition: 0.4s height;
  cursor: pointer;
}

.fl-accordion-toggle {
  cursor: pointer;
  border-radius: 5px;
  padding: 0.5rem;
  transition: 0.2s background-color;
  position: relative;
}

.fl-accordion-toggle:hover {
  color: #15c;
}

.fl-accordion-arrow {
  position: absolute;
  right: 20px;
  top: 10px;
}

.fl-accordion-body {
  margin-top: 1rem;
  font-weight: 400;
  color: #888888;
  padding: 0 0.5rem;
}

.fl-accordion-close {
  transition: max-height 0.1s;
  -moz-transition: max-height 0.1s;
  /* Firefox 4 */
  -webkit-transition: max-height 0.1s;
  /* Safari and Chrome */
  -o-transition: max-height 0.1s;
  /* Opera */
}

.fl-accordion-open {
  transition: max-height 1s;
  -moz-transition: max-height 1s;
  /* Firefox 4 */
  -webkit-transition: max-height 1s;
  /* Safari and Chrome */
  -o-transition: max-height 1s;
  /* Opera */
}

.fl-faq-link {
  color: #15c;
  text-decoration: none;
}

.fl-faq-link:hover {
  text-decoration: underline;
}