.fl-settings-sub-plans-card {
  min-height: 200px;
  width: calc(calc(100% - 40px) / 3);
  margin-right: 20px;
  margin-bottom: 20px;
  border-radius: 20px;
  padding: 20px;
  border: 2px solid #00000010;
}
.fl-settings-sub-plans-card-dark {
  border-color: #fff;
}

.fl-settings-sub-plans-card:nth-of-type(3n) {
  margin-right: 0px;
}

.fl-settings-sub-plans-all {
  display: flex;
}

.fl-settings-sub-plans-all-light {
  display: flex;
  flex-wrap: wrap;
}
.fl-settings-sub-plans-all-dark {
  display: flex;
  flex-wrap: wrap;
  color: white;
}

.fl-settings-sub-plans-message {
  padding-bottom: 30px;
}
.fl-settings-sub-plans-message-p-dark {
  color: white;
}

.fl-settings-sub-plans-card-property {
  width: 100%;
  color: #888;
  text-transform: uppercase;
  font-weight: 500;
  font-size: 10px;
  display: flex;
  justify-content: space-between;
  margin-bottom: 10px;
}

.fl-settings-sub-plans-card-property span {
  display: block;
  text-transform: uppercase;
  color: red;
}

.fl-settings-sub-plans-card-img {
  width: 100%;
  height: 80px;
  display: flex;
  justify-content: center;
  align-items: center;
  margin-bottom: 20px;
}
.fl-settings-sub-plans-card-img img {
  width: 100%;
  height: 100%;
  object-fit: contain;
  object-position: center;
}

.fl-settings-sub-plans-card-billing-period {
  padding-bottom: 20px;
  font-weight: 500;
  margin-bottom: 20px;
  display: flex;
  justify-content: center;
  align-items: center;
  text-transform: uppercase;
  font-size: 20px;
  border-bottom: 1px solid #00000020;
}
.fl-settings-sub-plans-card-billing-period-light {
  color: #000;
}
.fl-settings-sub-plans-card-billing-period-dark {
  color: #fff;
  border-bottom: 1px solid #ffffff20;
}

.fl-settings-sub-plans-card-trial {
  color: green !important;
}

.fl-plans-datepicker {
  margin-top: 20px;
}

.fl-settings-plan-gift {
  color: #ff0d72;
}

.fl-settings-paid-plans {
 display: flex;
  align-items: center;
}

.fl-settings-paid-plans img {
  max-width: 23px;
  margin-left: 5px;
}

.fl-settings-plans-free-profile .fl-toggle-main{
  margin-top: -5px;
}

.fl-settings-plans-free-profile {
  display: flex;
  align-items: center;
  border-bottom: 1px solid #00000020;
}

.fl-settings-plans-free-profile-dark {
  color: #fff;
  border-bottom: 1px solid #ffffff20;
}

.fl-settings-plans-free-profile-open {
  border: none;
}

.fl-settings-plans-free-profile-close {
  padding: 5px 0px 15px;
}

.fl-settings-plans-free-profile p {
  padding: 0px 8px 0px 8px;
}

.fl-color-black {
  color: #000000 !important;
}

.fl-color-grey {
  color: #888888 !important;
}

.fl-upgrade-plans:hover {
  background: #f6f6f6;
}

.fl-upgrade-plans {
  padding: 0.4rem 1em;
  margin: 0.25rem;
  border-radius: 4px;
  border: 1px solid #ff3b4b;
  color: #ff3b4b !important;
  cursor: pointer;
  max-height: 32px;
  display: flex;
  align-items: center;
}

.fl-upgrade-plan-selected {
  color: white !important;
  background: #ff3b4b;
}

.fl-upgrade-plans-all {
  display: flex;
}

.fl-settings-sub-plans-card-new-button {
  min-height: 260px;
  width: calc(calc(100% - 40px) / 3);
  align-items: center;
  justify-content: center;
  display: flex;
}

/* Large devices (desktops, less than 1200px) */
@media (max-width: 1199.98px) {
}

/* Medium devices (tablets, less than 992px) */
@media (max-width: 991.98px) {
    .fl-settings-sub-plans-card {
      /*width: calc(calc(100% - 40px) / 2)*/
      width: 100%;
    }
  .fl-settings-sub-plans-all {
    display: block;
  }
  .fl-settings-sub-plans-card-new-button {
    min-height: auto;
    width: 100%;
  }
}

/* Small devices (landscape phones, less than 768px) */
@media (max-width: 767.98px) {
}

/* Extra small devices (portrait phones, less than 576px) */
@media (max-width: 575.98px) {
  .fl-settings-sub-plans-card {
    width: 100%;
  }
}

