.fl-fluffa-mobile {
    padding: 60px 0px 60px 0px;
}

.fl-open-inbox {
    padding: 0px !important;
}

.fl-fluffa-mobile .fl-main-container {
    margin-top: 0px;
}

.fl-fluffa-mobile ~ .fl-sidebar-wrapper .fl-sidebar-main {
    max-width: 80%;
}

.fl-fluffa-mobile .fl-feed-column,
.fl-fluffa-mobile .fl-feed-row,
.fl-fluffa-mobile .fl-gotopost-card {
    padding: 0;
}

.fl-simple-card-main-mobile:not(.fl-post-head-actions-dropdown .fl-simple-card-main-mobile,.fl-inbox-filter-dropdown .fl-simple-card-main-mobile) {
    border-radius: 0px;
    margin-bottom: 0px !important;
}

.fl-simple-card-main-mobile.fl-post-main,
.fl-fluffa-mobile .fl-discover__nav-padding {
    border-bottom: 1px solid rgba(138,150,163,.2);
}

.fl-simple-card-main-mobile {
    box-shadow: none !important;
    position: relative;
    padding: 0px;
}

.fl-fluffa-mobile .fl-settings-link .fl-settings-link-box {
    padding: 20px;
}

.fl-simple-card-main-mobile .fl-messages-filter-main {
    position: fixed;
}

.fl-fluffa-mobile .fl-post-actions {
    padding: 15px 20px;
}

.fl-fluffa-mobile .fl-notifications__main--light,
.fl-fluffa-mobile .fl-profile-main.fl-profile-main-light,
.fl-fluffa-mobile .fl-messages-main-light,
.fl-fluffa-mobile .fl-settings-main-light,
.fl-fluffa-mobile .fl-subscriptions-main-light,
.fl-fluffa-mobile .fl-support-main-light,
.fl-fluffa-mobile .fl-support-col,
.fl-fluffa-mobile .fl-preview-main-light,
.fl-fluffa-mobile-unauthenticated .fl-profile-main.fl-profile-main-light {
    background-color: #ffffff !important;
}

.fl-fluffa-mobile .fl-notifications__main--dark,
.fl-fluffa-mobile .fl-profile-main.fl-profile-main-dark,
.fl-fluffa-mobile .fl-messages-main-dark,
.fl-fluffa-mobile .fl-settings-main-dark,
.fl-fluffa-mobile .fl-subscriptions-main-dark,
.fl-fluffa-mobile .fl-support-main-dark,
.fl-fluffa-mobile .fl-support-col,
.fl-fluffa-mobile .fl-feed-main.fl-feed-main-dark,
.fl-fluffa-mobile .fl-preview-main-dark {
    background-color: #000000 !important;
}

.fl-fluffa-mobile .fl-notifications__title h3 {
    margin-block-start: 0;
    margin-block-end: 0;
    padding: 20px;
}

.fl-fluffa-mobile .fl-notifications__all {
    margin:0;
}

.fl-fluffa-mobile .fl-user-profile-data-bottom {
    padding: 0px 40px 0px;
}

.fl-fluffa-mobile .fl-profile-main-light .fl-profile-sort {
    padding-bottom: 0px;
    background: #f4f4f4;
    margin-bottom: 0px;
}

.fl-fluffa-mobile .fl-profile-main-dark .fl-profile-sort {
    padding-bottom: 0px;
    background: #000000;
    border-bottom: 1px solid #0000001c;
}


.fl-fluffa-mobile .fl-settings-col,
.fl-fluffa-mobile .fl-subscriptions-inner,
.fl-fluffa-mobile .fl-subscriptions-col,
.fl-fluffa-mobile .fl-subscriptions-main,
.fl-fluffa-mobile .fl-settings-sub-section,
.fl-fluffa-mobile .fl-purchased-page,
.fl-fluffa-mobile .fl-support-main,
.fl-fluffa-mobile .fl-support-main .fl-support-col-holder,
.fl-fluffa-mobile .fl-notifications-main,
.fl-fluffa-mobile .fl-notifications__title,
.fl-fluffa-mobile .fl-support-col {
    padding: 0;
}

.fl-post-modal-mobile-main .fl-gotopost-row-files {
    padding-bottom: 0px;
}

.fl-post-modal-mobile-preview {
   height: 100%;
    overflow: scroll;
}

.fl-post-modal-mobile-main {
    padding: 0px !important;
}

.fl-fluffa-mobile .fl-settings-link,
.fl-fluffa-mobile .fl-subscriptions-filter,
.fl-fluffa-mobile .fl-following-page,
.fl-fluffa-mobile .fl-earnings-page {
    border-bottom: 1px solid #0000001c;
}

.fl-fluffa-mobile .fl-purchased-title {
    padding: 20px;
}

.fl-fluffa-mobile .fl-explore-filter {
    display: flex;
    justify-content: center;
}

.fl-fluffa-mobile .fl-notifications__show-more__link  {
    padding: 20px;
}

.fl-fluffa-mobile .fl-notifications__title {
    justify-content: end;
    padding: 15px;
}
.fl-fluffa-mobile .fl-container-gotopost {
    width: 100%;
}

.fl-fluffa-mobile .fl-preview-main .fl-feed-row {
    display: block;
}

.fl-fluffa-mobile .fl-messages-main {
    display: flex;
}

.fl-fluffa-mobile .fl-messages-main .fl-feed-row,
.fl-fluffa-mobile .fl-messages-main .fl-feed-row .fl-direct-messages-box {
    height: 100%;
}

.fl-fluffa-mobile .fl-direct-messages-box-content {
    flex: 1;
    display: flex;
    position: relative;
}

.fl-fluffa-mobile.fl-open-inbox .fl-direct-messages-box-content-inner {
    position: unset;
}

.fl-mobile-top-nav-main .fl-encoding-post-thumb-text {
    margin-top: 0px;
}

.fl-mobile-top-nav-main .fl-col-4 {
    width: calc(calc(100% / 12) * 4);
}

.fl-fluffa-mobile-unauthenticated .fl-profile-no-subscribe-box {
    border-radius: 0px;
}

.fl-fluffa-mobile-unauthenticated .fl-profile-main,
.fl-fluffa-mobile-unauthenticated .fl-profile-main .fl-feed-row,
.fl-fluffa-mobile-unauthenticated .fl-profile-main .fl-feed-column {
    padding: 0px;
}

.fl-fluffa-mobile-unauthenticated .fl-profile-main{
    padding-top: 0px !important;
}

.fl-fluffa-mobile.fl-open-inbox .fl-main-container,
.fl-fluffa-mobile.fl-open-inbox .fl-main-container .fl-main-container-row,
.fl-fluffa-mobile.fl-open-inbox .fl-main-container .fl-messages-main
{
    height: 100%;
}

.fl-fluffa-mobile.fl-open-inbox .fl-direct-messages-title {
    height: 10%;
    padding: 8px 13px;
}

.fl-fluffa-mobile.fl-open-inbox  .fl-direct-messages-box-input {
    height: 10%;
}

.fl-fluffa-mobile.fl-open-inbox .fl-direct-messages-box-content {
    height: 80%;
}

.fl-fluffa-mobile.fl-open-inbox .fl-direct-messages-box-content-inner {
    padding: 5px 5px 0px 5px;
}

.fl-fluffa-mobile.fl-open-inbox .fl-direct-messages-title-actions  {
    padding: 5px 0px 0px 15px;
}

.fl-fluffa-mobile.fl-open-inbox .fl-direct-messages-title-info {
    padding: 0;
}

.fl-fluffa-mobile.fl-open-inbox .fl-direct-messages-title-icon {
    margin-top: 2px;
}

.fl-fluffa-mobile.fl-open-inbox .fl-direct-messages-title-options {
    transform: translateY(-30%);
}

.fl-fluffa-mobile.fl-open-inbox .fl-direct-messages-box-input-form {
    padding: 0px;
    height: 100%;
}

.fl-fluffa-mobile .fl-drive-main  .fl-simple-card-main-mobile {
    padding: 20px;
}

.fl-fluffa-mobile.fl-open-inbox .fl-direct-messages-box-input-form-inner.fl-direct-messages-box-input-form-inner-light,
.fl-fluffa-mobile.fl-open-inbox .fl-direct-messages-box-input-form-inner.fl-direct-messages-box-input-form-inner-dark {
    border: none;
}

.fl-fluffa-mobile.fl-open-inbox .fl-direct-messages-box-input-form-inner {
    border-radius: 0px !important;
    height: 100% !important;
    padding: 0px !important;
}

.fl-fluffa-mobile.fl-open-inbox .fl-direct-messages-box-files {
    position: absolute;
    bottom: 105px;
    border-top: 1px solid #00000015;
    background: white;
}

.fl-fluffa-mobile .fl-main-container,
.fl-fluffa-mobile  .fl-main-container-row,
.fl-fluffa-mobile .fl-profile-main .fl-container,
.fl-fluffa-mobile .fl-profile-main .fl-container .fl-feed-row,
.fl-fluffa-mobile .fl-settings-main .fl-settings-inner,
.fl-fluffa-mobile .fl-settings-main .fl-settings-inner .fl-feed-row,
.fl-fluffa-mobile .fl-settings-main .fl-settings-inner .fl-feed-row .fl-settings-col,
.fl-fluffa-mobile .fl-subscriptions-inner,
.fl-fluffa-mobile .fl-support-main .fl-support-inner
{
    height: 100%;
}

.fl-fluffa-mobile .fl-feed-main,
.fl-fluffa-mobile .fl-notifications__main,
.fl-fluffa-mobile .fl-notifications__main .fl-container,
.fl-fluffa-mobile .fl-notifications__main .fl-container .fl-feed-row,
.fl-fluffa-mobile .fl-profile-main,
.fl-fluffa-mobile .fl-settings-main,
.fl-fluffa-mobile .fl-support-main {
    height: 100%;
    overflow-x: auto;
}

.fl-fluffa-mobile {
    overflow-x: auto;
}

.fl-fluffa-mobile .fl-settings-main .fl-settings-inner .fl-feed-row .fl-settings-col .fl-settings-sub-main,
.fl-fluffa-mobile .fl-subscriptions-filter-single {
    padding: 20px;
}

.fl-fluffa-mobile .fl-subscriptions-section-title,
.fl-fluffa-mobile .fl-support-main {
    padding: 10px;
    font-size: 15px;
    margin-bottom: 0px;
}

.fl-fluffa-mobile .fl-subscriptions-single {
    margin-bottom: 0px;
}

.fl-fluffa-mobile .fl-subscriber-card-main {
    border-radius: 0px;
    border: none;
}

.fl-fluffa-mobile .fl-profile-no-subscribe-box {
    border-radius: 0px;
}

.fl-fluffa-mobile .fl-user-following {
    padding: 0px 5px;
}

.fl-fluffa-mobile .fl-user-following button {
    margin-bottom: 20px;
}

.fl-fluffa-mobile .fl-user-statistics-right {
    padding: 15px 0px;
}

.fl-post-deleted {
    display: flex;
    align-items: center;
    justify-content: center;
    padding: 20px;
    border-bottom: 1px solid #0000001c;
    position: relative;
}

.fl-post-deleted .fl-post-deleted-close {
    position: absolute;
    right: 25px;
    top: 19px;
}

.fl-post-deleted div {
    margin-top: 7px;
}

.fl-mobile-upload-navigation {
    height: 55px;
    width: 55px;
    object-fit: contain;
}

.fl-mobile-create-post .fl-encoding-post {
    padding: 30px;
}

.fl-fluffa-mobile .fl-profile-empty-post {
    padding: 0px 10px 1px 10px;
}

.fl-fluffa-mobile .fl-inbox-record {
    position: absolute;
    bottom: 105px;
    width: 100%;
    background: white;
    border-top: 1px solid #00000015;
    border-bottom: 1px solid #00000015;
}

.fl-fluffa-mobile.fl-open-inbox .fl-message-bubble-main,
.fl-fluffa-mobile.fl-open-inbox .fl-inbox-text,
.fl-fluffa-mobile.fl-open-inbox .fl-inbox-audio-preview,
.fl-fluffa-mobile.fl-open-inbox .fl-inbox-files {
    width: 100%;
}

.fl-fluffa-mobile.fl-open-inbox .fl-message-bubble-message-text {
    max-width: calc(100% - 5px);
}

.fl-fluffa-mobile.fl-open-inbox .fl-message-bubble-avatar.fl-message-bubble-avatar-right {
    right: 0px;
}

.fl-fluffa-mobile.fl-open-inbox .fl-message-bubble-avatar.fl-message-bubble-avatar-left {
    left: 0px;
}

.fl-fluffa-mobile.fl-open-inbox .fl-message-bubble-main-right .fl-message-bubble-message,
.fl-fluffa-mobile.fl-open-inbox .fl-message-bubble-main-right .fl-message-bubble-message-info {
    width: 100%;
    padding-right: 50px;
}

.fl-fluffa-mobile.fl-open-inbox .fl-message-bubble-main-left .fl-message-bubble-message,
.fl-fluffa-mobile.fl-open-inbox .fl-message-bubble-main-left .fl-message-bubble-message-info {
    width: 100%;
    padding-left: 50px;
}

.fl-fluffa-mobile.fl-open-inbox .fl-inbox-text,
.fl-fluffa-mobile.fl-open-inbox .fl-inbox-audio-preview,
.fl-fluffa-mobile.fl-open-inbox .fl-inbox-files {
    display: block;
}

.fl-fluffa-mobile.fl-open-inbox .fl-message-bubble-main-right .fl-messageaudio-main {
    max-width: 100%;
    float: right;
}

.fl-fluffa-mobile.fl-open-inbox .fl-message-bubble-message-typing {
    float: left;
}

.fl-fluffa-mobile.fl-open-inbox .fl-message-bubble-typing-text {
    padding-left: 50px;
}

.fl-fluffa-mobile.fl-open-inbox .fl-message-bubble-main {
   margin: 0px auto 15px;
}

.fl-fluffa-mobile .fl-subscriptions-tablet {
    padding-bottom: 60px;
}

.fl-fluffa-mobile .fl-explore-verified .fl-explore-verified-tick {
    top: 2px;
}

.fl-fluffa-mobile .fl-profile-actions-main  {
    padding: 10px 30px;
}

.fl-fluffa-mobile .fl-preview-main .fl-gotopost-comments {
    padding-bottom: 60px;
}

.fl-fluffa-mobile .fl-bulk__title,
.fl-fluffa-mobile .fl-bulk__top,
.fl-fluffa-mobile .fl-bulk__users__list,
.fl-fluffa-mobile .fl-bulk__actions {
    width: 100%;
    margin: auto;
}

.fl-fluffa-mobile .fl-bulk__users__custom--row {
    padding: 0.3rem;
}

.fl-fluffa-mobile .fl-bulk__top {
    padding: 0px;
}

.fl-fluffa-mobile .fl-bulk-select-users {
    padding-bottom: 60px;
}

.fl-fluffa-mobile .fl-bulk__actions--cancel,
.fl-fluffa-mobile .fl-bulk__actions--next {
    padding: 10px;
}