.vjs-fluid:not(.vjs-audio-only-mode) {
    padding-top: 0px !important;
}

.fl-smooth-video {
    width: 100%;
    position: relative;
}

.fl-smooth-video .video-js{
    width: 100%;
    height: 100%;
}

.fl-smooth-video .fl-smooth-video-duration {
    text-align: center;
    min-width: 60px;
    position: absolute;
    right: 15px;
    padding: 6px 0px;
    bottom: 16px;
    color: white;
    background: linear-gradient(to right, #ff037c, #ff4443);
    font-size: 15px;
    border-radius: 5px;
}

.fl-smooth-video .vjs-menu-content {
    display: flex !important;
    flex-direction: column;
}

.fl-smooth-video .vjs-menu-content li:nth-child(4) {
    order: 2;
}

.fl-smooth-video .vjs-menu-content li:nth-child(3) {
    order: 3;
}

.fl-smooth-video .vjs-menu-content li:nth-child(2) {
    order: 4;
}

.fl-smooth-video .vjs-menu-content li:first-child {
    order: 1;
}

.fl-smooth-video .vjs-menu-content li:nth-child(5) {
    order: 5;
}