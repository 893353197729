.fl-loader-animation{
    display:inline-block;
    margin-left: 10px;
}

.fl-loader-animation-resend{
    display:inline-block;
}

.fl-contact-form textarea {
    overflow: hidden;
}

/* Large devices (desktops, less than 1200px) */
@media (max-width: 1199.98px) {

}

/* Medium devices (tablets, less than 992px) */
@media (max-width: 991.98px) { 

}

/* Small devices (landscape phones, less than 768px) */
@media (max-width: 767.98px) { 
    
 }

/* Extra small devices (portrait phones, less than 576px) */
@media (max-width: 575.98px) {

}