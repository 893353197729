.fl-subscriber-card-main {
    width: 100%;
    border-radius: 5px;
    overflow: hidden;
}

.fl-subscriber-card-main.fl-subscriber-card-main-dark {
    border: 1px solid #ffffff20;
}

.fl-subscriber-card-main.fl-subscriber-card-main-light {
    border: 1px solid #00000020;
}

.fl-subscriber-card-main.fl-subscriber-card-main {
    height: 100%;
}

.fl-subscriber-card-cover {
    width: 100%;
    height: 100px;
    background-color: #00000020;
    position: relative;
    cursor: pointer;
}

.fl-subscriber-card-cover img {
    width: 100%;
    height: 100%;
    object-fit: cover;
    object-position: center;
}

.fl-subscriber-card-content {
    padding: 10px;
    width: 100%;
}

.fl-subscriber-card-profile {
    position: absolute;
    left: 10px;
    bottom: 0px;
    transform: translate(0%, 50%);
    width: 80px;
    height: 80px;
    background-color: #999999;
    border-radius: 50%;
    overflow: hidden;
    cursor: pointer;
}

.fl-subscriber-card-content-name {
    width: 100%;
    padding-left: 86px;
}

.fl-subscriber-card-content-name-full {
    font-size: 14px;
    text-overflow: ellipsis;
    white-space: nowrap;
    overflow: hidden;
    text-decoration: none;
}

.fl-subscriber-card-content-name-full.fl-subscriber-card-content-name-full-dark {
    color: #fff;
}
.fl-subscriber-card-content-name-full.fl-subscriber-card-content-name-full-light {
    color: #000;
}

.fl-subscriber-card-content-name-username {
    color: #FF0D72;
    font-size: 12px;
    text-overflow: ellipsis;
    white-space: nowrap;
    overflow: hidden;
    text-decoration: none;
}

.fl-subscriber-card-content-created-date {
    width: 100%;
    margin-top: 10px;
    font-size: 12px;
    color: #888;
}

.fl-subscriber-card-content-earnings {
    width: 100%;
    font-size: 12px;
    color: #888;
    margin-top: 5px;
}

.fl-subscriber-card-content-actions {
    padding-top: 15px;
    margin-top: 10px;
}

.fl-subscriber-card-content-actions.fl-subscriber-card-content-actions-dark {
    border-top: 1px solid #ffffff20;
}
.fl-subscriber-card-content-actions.fl-subscriber-card-content-actions-light {
    border-top: 1px solid #00000020;
}

.fl-subscriber-card-content-actions button {
    margin-top: 5px;
}

.fl-subscriber-copy-profile {
    position: absolute;
    cursor: pointer;
    color: white;
    left: 10px;
    top: 10px;
}

.fl-subscriber-copy-profile:hover {
    opacity: 0.7;
}

/* Large devices (desktops, less than 1200px) */
@media (max-width: 1199.98px) {
    
}

/* Medium devices (tablets, less than 992px) */
@media (max-width: 991.98px) { 

}

/* Small devices (landscape phones, less than 768px) */
@media (max-width: 767.98px) { 

}

/* Extra small devices (portrait phones, less than 576px) */
@media (max-width: 575.98px) {

}