
.fl-settings-sub-payment-filter-single {
  width: 100%;
  padding: 5px;
  position: relative;
  overflow: hidden;
}

.fl-settings-sub-payment-filter-date {
    box-shadow: 0px 0px 8px 0px #00000020;
    padding: 15px;
}

.fl-referral-title {
    padding: 5px 0px 5px;
    margin-bottom: 0px;
    text-align: left;
}

.fl-referral-title .fl-referral-title-note {
    color: #888888;
    font-size: 14px;
}

.fl-referral-title-note-div {
    padding-left: 33px;
}

.fl-settings-sub-earnings {
    color: #888888;
}

.fl-settings-sub-earnings-dark {
    color: white;
}

/* Large devices (desktops, less than 1200px) */
@media (max-width: 1199.98px) {

}

/* Medium devices (tablets, less than 992px) */
@media (max-width: 991.98px) { 

}

/* Small devices (landscape phones, less than 768px) */
@media (max-width: 767.98px) { 
    
 }

/* Extra small devices (portrait phones, less than 576px) */
@media (max-width: 575.98px) {

}