.fl-notifications-drive-light {
    background-color: #f4f4f4;
}

.fl-notifications-drive-dark {
    background-color: #1b1b1b;
}

.fl-drive-main {
    width: 100%;
    flex: 1 1;
}

.fl-drive-update-label .fl-minput-input-inner {
    font-size: 14px;
    border: 3px solid #646464;
    padding: 15px 25px;
    border-radius: 20px;
    background: none;
    outline: none;
    width: 100%;
    height: 100%;
}

.fl-drive-modal-thumb {
    width: 8%;
    position: absolute;
    top: 30px;
    right: 40px;
    cursor: pointer;
}

.fl-drive-modal-thumb img {
    width: 100%;
}

.fl-drive-update-label .fl-minput-label {
    left: 10px;
    top: 18.5px;
    transform: unset;
}

.fl-drive-update-label .fl-minput-label-active {
    transform: translate(-10px, calc(-50% - 25px));
    font-size: 12px;
}

.fl-drive-image-duration {
    position: absolute;
    bottom: 50px;
    font-weight: 500;
    color: #ff0d72;
    left: 10px;
}

.fl-drive-image-label {
    width: 100%;
    position: absolute;
    bottom: 0;
    padding: 2px;
}

.fa-drive-option {
    width: 30px;
    height: 30px;
    min-width: 30px;
    font-size: 18px;
    display: flex;
    justify-content: center;
    align-items: center;
    color: white;
    position: relative;
    -webkit-user-select: none;
    background: linear-gradient(to right, #ff037c, #ff4443);
    border-radius: 20px;
    top: 10px;
    cursor: pointer;
    display: none;
}

.fa-label-delete {
    width: 15px;
    height: 15px;
    font-size: 10px;
    justify-content: center;
    align-items: center;
    position: relative;
    -webkit-user-select: none;
    border-radius: 20px;
    cursor: pointer;
    display: none;
    z-index: 99;
}

.fl-drive-image-label-name .fa-label-delete,
.fl-drive-example-single-label .fa-label-delete {
    color: #ff3b4b;
    background: white;
    border: 1px solid #ff3b4b;
}

.fl-drive-image-label-name:hover .fa-label-delete,
.fl-drive-example-single-label:hover  .fa-label-delete {
    display: flex;
    position: absolute;
    top: -7px;
    right: -7px;
}

.fa-drive-option svg  {
    width: 100% !important;
}

.fa-drive-option {
    display: none;
}

.fa-drive-delete {
    position: absolute;
    left: 10px;
}

.fl-drive-image-hover:hover:not(.fl-modal-wrapper .fl-drive-image-hover:hover) .fa-drive-option
{
    display: flex;
    animation: remove-preview-popup 0.3s forwards;
    position: absolute;
    right: 10px;
}

.fl-drive-image-hover .fa-drive-option:hover {
    background: linear-gradient( 90deg ,#ea0070,#e43c3b);
    cursor: pointer;
}

.fl-drive-example-label {
    float: left;
}

.fl-drive-labels-sort {
    display: inline-block;
}

.fl-drive-labels-sort-function span {
    display: block;
}

.fl-drive-labels-sort-function span:last-child:hover {
    text-decoration: underline;
    cursor: pointer;
}

.fl-drive-labels-sort-function span:first-child {
    color: #888888;
    margin-top: 5px;
}

.fl-drive-labels-sort-function span:last-child {
    color: #ff3b4b;
    margin-top: 5px;
}

.fl-drive-example-single-label,
.fl-drive-create-label {
    position: relative;
    padding: 0.4rem 1em;
    margin: 0.25rem;
    border-radius: 4px;
    border: 1px solid #ff3b4b;
    color: #ff3b4b;
    cursor: pointer;
    max-height: 32px;
    display: flex;
    align-items: center;
    float: left;
}

.fl-drive-label-selected {
    color: white;
    background: #ff3b4b;
}

.fl-drive-example-label-form {
    width: 100%;
    float: left;
}

.fl-drive-create-label input {
    font-size: 16px;
    color: #ff3b4b;
    outline: none;
    border: none;
}

.fl-drive-example-single-label input {
    width: 125px;
    min-width: 125px;
    max-width: 400px;
}

.fl-drive-attachments-grid {
    margin: 1rem auto;
    outline: none;
}

.fl-drive-attachments-grid .fl-drive-image {
    min-height: 50px;
}

.fl-drive-attachments-grid .fl-drive-image-label {
    position: relative;
    border-radius: 0px;
    display: flex;
    justify-content: center;
    align-items: center;
    color: #888;
    border: none;
}

.fl-drive-attachments-grid .fl-drive-image img,
.fl-drive-attachments-grid .fl-drive-image video {
    width: 50px;
    border-radius: 0px;
    padding: 5px;
}

.fl-drive-attachments-grid .fl-drive-image:nth-child(odd)
{
    border-top: 1px solid #00000020;
    border-bottom: 1px solid #00000020;
}

.fl-drive-sort-option {
    display: flex;
    justify-content: flex-end;
    color: #888;
    position: relative;
    outline: none;
}

.fl-active {
    color: white;
    background-color: #ff0d72 !important;
}

.fl-drive-sort-single-option-select-count {
    height: 35px;
    display: flex;
    align-items: center;
    justify-content: center;
    margin-left: 8px;
}

.fl-drive-sort-single-option-select {
    height: 35px;
    display: flex;
    align-items: center;
    justify-content: center;
    margin-left: 8px;
    color: #15c;
    cursor: pointer;
}

.fl-drive-sort-single-option-select:hover span {
    text-decoration: underline;
}

.fl-drive-sort-single-option {
    cursor: pointer;
    background: #00000010;
    width: 35px;
    height: 35px;
    display: flex;
    align-items: center;
    justify-content: center;
    border-radius: 50%;
    float: right;
    margin-left: 8px;
    outline: none;
    font-size: 20px;
}

.fl-drive-grid-view-option {
    margin-top: -8px;
}

.fl-drive-sort-option .fl-filter-head-actions-dropdown {
    right: 30px;
    top: 40px;
}

.fl-drive-sort-info {
    display: flex;
    align-items: center;
    justify-content: center;
}

.fl-drive-sort-info .fl-drive-example-label {
    padding: 0px 5px;
}

.fl-drive-example-label-function {
    cursor: pointer;
}

.fl-drive-attachments .fl-drive-image-one-file{
    width: 211px;
    height: 200px;
}

.fl-drive-sort-type span {
    padding: 0px 10px;
    cursor: pointer;
}

.fl-drive-sort-type .active {
    font-weight: 500;
    color: #ff0d72;
}

.fl-drive-example-label-create {
    cursor: pointer;
}

.fl-drive-example-label-search {
    padding-bottom: 10px;
    cursor: pointer;
}

.fl-drive-example-label-create span{
    color: #ff3b4b;
}

.fl-drive-example-label-create input {
    padding: 0.4em 4em 0.4em 1em;
    margin: 0.25rem;
    border-radius: 4px;
    border: 1px solid #ff3b4b;
    color: #ff3b4b;
    max-height: 32px;
    outline: none;
    animation: remove-preview-popup 0.3s forwards;
}
.fl-drive-tag-close-button,
.fl-drive-tag-create-button {
    cursor: pointer;
    color: white;
    width: 25px;
    height: 25px;
    display: flex;
    align-items: center;
    justify-content: center;
    border-radius: 50%;
    margin-left: 10px;
    animation: remove-preview-popup 0.3s forwards;
}

.fl-drive-tag-create-button {
    background: #38a169;
}

.fl-drive-tag-close-button {
    background: #ff3b4b;
}

.fl-drive-tag-close-button:hover,
.fl-drive-tag-create-button:hover {
    opacity: 0.9;
}

.fl-drive-image-label-name {
    position: relative;
    padding: 3px;
    margin: 0.1rem;
    border-radius: 4px;
    background:  #ff3b4b;
    color: white;
    cursor: pointer;
    max-height: 35px;
    display: flex;
    float: left;
}

.fl-label-modal-note {
    padding-top: 10px;
}

.fl-drive-delete-select {
    border-top: 1px solid rgba(0,0,0,.12549019607843137);
}
fl-drive-image-svg
.fl-drive-image-label-add-tag {
    cursor: default;
}

.fl-drive-image-label-add-tag {
    color: #15c;
    cursor: pointer;
    text-decoration: underline;
}

.fl-drive-image-label-add-tag:hover {
    text-decoration: none;
}

.fl-drive-image-label-grid:first-child {
    width: 8%;
}

.fl-drive-image-label-grid-files {
    cursor: pointer !important;
}

.fl-drive-image-label-grid  {
    width: 23%;
    display: flex;
    align-items: center;
    justify-content: center;
    cursor: default;
}

.fl-drive-image-tag-grid {
    padding: 5px;
    display: block;
}

.fl-welcome-message-undo {
    font-weight: bold;
}

.fl-welcome-message-undo:hover {
    text-decoration: underline;
    cursor: pointer;
}


.fl-drive-update-label .fl-drive-example-label {
    padding: 20px 0px;
}

.sc-htpNat {
    z-index: 99;
}

.sc-bdVaJa div:first-child {
    border: 1px solid #666666 !important;
    color: #666666;
    max-height: 32px;
    border-radius: 4px;
}

.sc-bdVaJa input {
    max-height: 30px;
    color: #666666;
}


.fl-drive-update-label {
    position: relative;
}

.fl-drive-image .fl-filter-head-actions-dropdown {
    width: 200px;
    right: 1px;
    top: 44px;
}

.sc-bwzfXH {
    background-image:url("data:image/svg+xml;utf8,<svg xmlns='http://www.w3.org/2000/svg'  version='1.1' height='50px' width='120px'><text x='0' y='19' fill='gray' font-size='16' font-family='Objectivity, -apple-system, BlinkMacSystemFont, Segoe UI, Roboto, Oxygen, Ubuntu, Cantarell, Fira Sans, Droid Sans, Helvetica Neue, sans-serif'>Type to search...</text></svg>");
    background-repeat: no-repeat;
}

.sc-bwzfXH:focus {
    background-image: none;
}

.fl-drive-search-hide-background .sc-bwzfXH {
    background-image: none;
}

.fl-drive-search-result {
    display: flex;
    align-items: center;
}

.fl-drive-search-result ul {
    width: 200px;
    max-height: 140px;
    border: 1px solid #dedede;
    border-radius: 0px 0px 5px 5px;
    box-sizing: border-box;
    overflow-y: auto;
    position: absolute;
    padding: 0;
    margin: 0;
    z-index: 99;
    background: white;
    top: 75px;
}

.fl-drive-search-result ul li {
    padding: 8px 10px;
    cursor: pointer;
    list-style-type: none;
}

.fl-drive-search-result-single {
    color: #000;
}

.fl-drive-search-result-hover,
.fl-drive-search-result-active {
    background-color: #ff0d72;
    color: #fff;
}

.fl-drive-search-result ul li:not(.search ul li:last-child) {
    border-bottom: 1px solid #00000020;
}

.fl-drive-create-form {
    cursor: pointer;
    display: flex;
    align-items: center;
}

.fl-drive-search-result input {
    padding: 0.4rem 1em;
    margin: 0.25rem;
    border-radius: 4px;
    border: 1px solid #666666;
    max-height: 32px;
    outline: none;
    animation: remove-preview-popup 0.3s forwards;
}

.fl-drive-search-result-no-match input {
    color: #666666;
}

.fl-drive-search-result-match input,
.fl-drive-search-result-match ul li {
    color: #ff3b4b;
}

.fl-drive-search-no-result {
    color: #888888 !important;
    text-align: center;
    pointer-events: none;
}

.fl-drive-search-no-result:hover {
    background: none;
}

.fl-drive-more-labels {
    padding: 3px;
    margin: 0.1rem;
    border-radius: 4px;
    color: #ff3b4b;
    cursor: pointer;
    max-height: 32px;
    display: -webkit-flex;
}

.fl-drive-attachments .fl-dropdown-filter-option {
    cursor: pointer;
}

.fl-dropdown-filter-option-share {
    cursor: pointer !important;
}

.fl-drive-count-character {
    ext-align: right;
    font-size: 13px;
    position: absolute;
    left: 205px;
}

.fl-drive-menu-option-light {
    cursor: pointer;
    font-weight: 500;
}

.fl-drive-menu-option {
    font-weight: 500;
}

.fl-drive-menu-option span {
    cursor: default;
}

.fl-drive-menu-option-dark {
    cursor: pointer;
    font-weight: 500;
    color: white;
}

.fl-drive-menu-active {
   background: #FF0D72 !important;
    color: white !important;
}

.fl-drive-menu-option:hover {
    box-shadow: 0px 5px 8px 2px #00000020;
}

.fl-settings-main-dark .fl-drive-menu-option:hover  {
    background: #0a0a0a;
}

.fl-modal-description-row{
    font-size: 15px;
    line-height: 1.5;
}

.fl-drive-restore-post {
    color: #fff;
    font-size: 15px;
    background-color: #FF0D72;
    margin: 0;
    padding: 10px;
    border-radius: 15px;
    font-weight: 600;
    cursor: pointer;
    text-align: center;
    display: flex;
    align-items: center;
    justify-content: center;
}

.fl-drive-restore-post:hover {
    background: linear-gradient(to right, #ea0070, #e43c3b);
    box-shadow: 0px 5px 8px 2px #00000020;
}

.fl-drive-restore-post span:first-child {
    margin-right: 5px;
    max-height: 15px;
}

.fl-drive-restore-post-option .fl-drive-restore-post:last-child,
.fl-drive-restore-post-option .fl-drive-restore-post:nth-child(2) {
    margin-top: 5px;
}

.fl-drive-restore-post span:first-child img {
    margin-top: -3px;
    margin-left: 2px;
}

.fl-drive-sub-section-title {
    width: 100%;
    margin-bottom: 30px;
}

.fl-drive-sub-section-title span:not(.fl-drive-grid-view-option-select) {
    color: #888;
}

.fl-drive-sub-section-title span:first-child {
    font-weight: 500;
}

.fl-drive-grid-view-option-select {
    color: #15c;
    cursor: pointer;
    font-weight: normal !important;
    font-size: 16px;
}

.fl-drive-grid-view-option-select-count {
    font-weight: normal;
    font-size: 16px;
    padding-left: 5px;
}

.fl-drive-grid-view-option-select:hover {
    text-decoration: underline;
}

.fl-drive-grid-select-posts {
    display: inline-block;
    float: right;
}

.fl-drive-post-checked {
    width: 30px;
    height: 30px;
    border-radius: 50%;
    color: white;
    display: flex;
    align-items: center;
    justify-content: center;
    transition: background-color .3s ease-in-out;
}

.fl-drive-post-checked-enable {
    background-color: #ff0d72;
}

.fl-drive-post-checked-disable {
    background: #8888;
    border: 1px solid #ff3b4b;
}

.fl-drive-grid-view-option-delete {
    margin-right: 8px;
}

.fl-removed-post-head-actions {
    position: absolute;
    right: 20px;
    top: 10px;
}

.fl-removed-post-head {
    position: relative;
    border-bottom: 1px solid #00000020;
    justify-content: space-between;
}

.fl-removed-post-head-actions-dropdown {
    top: 20px;
}

.fl-post-display-grid .fl-post-images {
    position: relative;
}

.fl-post-images .fa-drive-option {
    z-index: 99;
}

.fl-drive-removed-post-hover:hover .fa-drive-option
{
    display: flex;
    right: 10px;
    position: absolute;
}

.fl-post-images .fl-drive-post-checked  {
    position: absolute;
    right: 10px;
    top: 10px;
    z-index: 99;
}

.fl-post-images .fa-drive-option:hover {
    background: linear-gradient(90deg,#ea0070,#e43c3b);
}

.smooth-image-wrapper img {
    object-fit: cover !important;
}

.fl-dropdown-filter-option-restore img:first-child {
    display: none;
}

.fl-dropdown-filter-option-restore:hover img:last-child {
    display: none;
}

.fl-dropdown-filter-option-restore:hover img:first-child {
    display: block;
}

.fl-drive-archive-option .fl-modal-wrapper-show {
    z-index: 9999;
}

.fl-drive-modal-image,
.fl-drive-modal-image img {
    width: 100%;
    height: 100%;
}

.fl-list-tags-link {
    text-align: right;
    padding: 0px 30px 10px 30px
}

.fl-list-tags-link span {
    cursor: pointer;
}

.fl-modal-drive-files {
    overflow-y: auto;
}

/* Large devices (desktops, less than 1200px) */
@media (max-width: 1199.98px) {
    .fl-drive-attachments .fl-drive-image-one-file {
        width: 100%;
        height: 100%;
    }
}

/* Medium devices (tablets, less than 992px) */
@media (max-width: 991.98px) {

}

/* Small devices (landscape phones, less than 768px) */
@media (max-width: 767.98px) {
    .fl-drive-main .fl-d-flex:not(.fl-drive-main .fl-profile-card-name) {
        display: block;
    }
    .fl-drive-sub-single {
        line-height: 22px;
    }
    .fl-drive-sub-single-img {
        width: 100%;
        display: flex;
        justify-content: center;
    }
    .fl-drive-sub-single-img div,
    .fl-drive-sub-single-img div img {
        width: 200px !important;
        height: 200px !important;
    }
    .fl-drive-sub-single-date,
    .fl-drive-sub-single-labels,
    .fl-drive-sub-single-size,
    .fl-drive-sub-single-duration {
        text-align: center;
    }
    .fl-drive-delete-option {
        float: none;
        padding-bottom: 20px;
        justify-content: center;
    }
}

/* Extra small devices (portrait phones, less than 576px) */
@media (max-width: 575.98px) {
    .fl-drive-restore-post-option {
        margin-top: 10px;
    }
    .fl-drive-sort-option {
        display: block;
    }
    .fl-drive-option-sort {
        display: flex;
        justify-content: center;
        padding-top: 20px;
        padding-bottom: 20px;
    }
    .fl-drive-back-icon {
        display: none;
    }
}
@media (max-width: 470px) {
    .fl-xsm-font {
        font-size: 17px;
    }
}