.fl-profile-actions-main {
  margin: 0 auto;
}

.fl-profile-actions-main button,
.fl-profile-actions-plans button {
  width: 100%;
  margin: 15px auto;
  border-radius: 0;
  height: 55px;
}

.fl-profile-actions-plans button {
  margin: 5px auto;
}

.fl-modal-description-single-button {
  padding-bottom: 8px;
}

.fl-profile-actions-main-default {
  display: block;
  width: 100%;
}

.fl-user-following {
  display: flex;
  justify-content: center;
}

.fl-profile-actions-main-small {
  justify-content: center;
}

.fl-profile-restrict-text {
  margin-left: 5px;
}

.fl-profile-restrict-text-icon {
  margin-top: -1px;
}

.fl-profile-actions-button {
  width: 100%;
  margin-bottom: 10px;
  position: relative;
}

.fl-profile-trial-gift {
  padding-right: 5px;
}

.fl-profile-sub-actions button {
  margin: 8px auto;
}

.fl-profile-sub-actions span,
.fl-profile-actions-plans span {
  font-size: 13px;
}

.fl-profile-actions-additional-text {
  font-size: 14px;
  color: #888;
  margin-bottom: 10px;
}

.fl-profile-actions-button-free {
  width: 100%;
  position: relative;
}

.fl-profile-actions-main-small .fl-profile-actions-button {
  margin-right: 10px;
}

.fl-profile-actions-main-small .fl-profile-actions-button:nth-last-of-type(1) {
  margin-right: 0px;
}

.fl-modal-subscription-plans-all {
  display: flex;
  justify-content: center;
}

.fl-modal-subscription-plan {
  width: 100%;
  display: flex;
  justify-content: center;
  flex-direction: column;
  display: flex;
  margin-right: 20px;
  padding: 20px;
  border-radius: 20px;
  border: 2px solid #00000010;
  cursor: pointer;
}

.fl-modal-subscription-plan-period {
  text-align: center;
  font-size: 20px;
  text-transform: uppercase;
  font-weight: 600;
  padding-bottom: 10px;
  border-bottom: 1px solid #00000010;
  margin-bottom: 10px;
}

.fl-modal-subscription-plan-period span {
  font-size: 32px;
  display: block;
}

.fl-modal-subscription-plan-price {
  text-align: center;
  color: green;
  font-weight: 500;
  display: flex;
  justify-content: center;
  align-items: center;
}

.fl-modal-subscription-plan-price-old {
  display: flex;
  align-items: center;
  color: #888;
}

.fl-modal-subscription-plan-price-old span {
  text-decoration: line-through;
  color: #ff0d72;
}

.fl-modal-subscription-plan-price-icon {
  margin: -3px 5px 0px;
}

.fl-modal-subscription-plan-active,
.fl-modal-subscription-plan-active *,
.fl-modal-subscription-plan-active .fl-modal-subscription-plan-price-old span {
  color: #fff;
  background-color: #ff0d72;
}

.fl-modal-description-subscription-cancel-anytime {
  text-align: center;
  color: #000;
  margin-top: 50px;
  display: block;
  font-weight: 600;
}

.fl-profile-actions-subscribe-modal-cover {
  width: 100%;
  height: 100px;
  background-color: #00000020;
  border-radius: 20px 20px 0px 0px;
  position: relative;
}

.fl-profile-actions-subscribe-modal-cover img {
  width: 100%;
  height: 100%;
  object-fit: cover;
  object-position: center;
  border-radius: 20px 20px 0px 0px;
}

.fl-profile-actions-subscribe-modal-profile {
  width: 100px;
  height: 100px;
  left: 50%;
  bottom: 0%;
  transform: translate(-50%, 30%);
  border-radius: 50%;
  overflow: hidden;
  position: absolute;
  background-color: #888;
  border: 4px solid #fff;
}

.fl-profile-actions-subscribe-modal-description {
  padding: 40px 20px 20px;
}

.fl-profile-actions-subscribe-modal-description-name {
  font-size: 16px;
  text-align: center;
  font-weight: 600;
}

.fl-profile-actions-subscribe-modal-description-name a {
  font-weight: 600;
  color: #666;
  text-decoration: none;
}

.fl-profile-actions-subscribe-modal-description-username {
  font-size: 14px;
  text-align: center;
  padding: 5px;
}

.fl-profile-actions-subscribe-modal-description-username a,
.fl-profile-actions-subscribe-modal-description-list-item span {
  font-weight: 500;
  color: #ff0d72;
  text-decoration: none;
  padding: 5px;
}

.fl-profile-actions-subscribe-modal-description-username a:hover {
  text-decoration: underline;
}

.fl-profile-actions-subscribe-modal-description-list-title {
  font-weight: 400;
  text-align: center;
  padding-bottom: 5px;
}

.fl-profile-actions-subscribe-modal-description-list {
  width: 100%;
  padding: 0px 20px 0px 20px;
  margin: 0px auto;
  max-width: 500px;
}

.fl-profile-actions-subscribe-modal-description-list-item {
  width: 100%;
  font-size: 16px;
  color: #666;
  margin-bottom: 20px;
  position: relative;
  padding-left: 25px;
}

.fl-profile-actions-subscribe-modal-description-list p {
  color: #666;
  line-height: 1.2;
  font-size: 13px;
}

.fl-profile-actions-subscribe-small-info {
  font-size: 12px;
  margin-top: 15px;
}

.fl-profile-actions-subscribe-small-info svg,
.fl-padlock-green {
  color: green;
}

.fl-profile-actions-subscribe-modal-description-list-item::before {
  content: "\2713";
  color: #ff0d72;
  font-size: 20px;
  left: 0px;
  top: -5px;
  position: absolute;
  font-weight: 600;
}
.fl-profile-actions-subscribe-modal-description-list-item:nth-last-of-type(1) {
  margin-bottom: 0px;
}

.fl-profile-actions-button-message {
  width: 49%;
  float: left;
  margin-right: 10px;
}

.fl-profile-actions-button-send-tip {
  margin-bottom: 30px;
  width: 49%;
}

.fl-blocked-user button {
  width: 49%;
  border-radius: 0px;
}

.fl-profile-actions-button-message svg,
.fl-profile-actions-button-send-tip svg {
  margin-right: 10px;
}

.fl-profile-actions-list-more-open {
  /*transition: transform 0.5s, max-height 0.1s;*/
  /*transform-origin: top;*/
  max-height: 100%;
  position: relative;
  /*transform: scaleY(1);*/
}

.fl-profile-actions-discount,
.fl-profile-actions-limited {
  background: #a020f0;
  color: white;
  width: 70px;
  line-height: 1.4;
  font-size: 14px;
}

.fl-profile-actions-limited {
  background: linear-gradient(to bottom, #ffb659, #ff840f);
}

.fl-profile-actions-limited-shadow-light {
  background: linear-gradient(to right bottom, #d16f13 50%, #ffff 55%) !important;
}

.fl-profile-actions-limited-shadow-dark {
  background: linear-gradient(to right bottom, #d16f13 50%, #000000 55%) !important;
}

.fl-profile-actions-discount-shadow-light {
  background: linear-gradient(to right bottom, #57008e 50%, #ffff 55%) !important;
}

.fl-profile-actions-discount-shadow-dark {
  background: linear-gradient(to right bottom, #57008e 50%, #000000 55%) !important;
}

.fl-profile-actions-discount-shadow,
.fl-profile-actions-limited-shadow {
  position: absolute;
  right: -15px;
  width: 12px;
  height: 9px;
  top: 43px;
}

.fl-profile-actions-discount-shadow {
  background: linear-gradient(to right bottom, #64099d 50%, #ffff 55%);
}

.fl-profile-actions-main .fl-profile-actions-discount,
.fl-profile-actions-discount{
  position: absolute;
  right: -15px;
  top: 6.4px;
}

.fl-profile-actions-main .fl-profile-actions-limited {
  position: absolute;
  right: -15px;
  top: 6.4px;
}

.fl-modal-description-single-button .fl-profile-actions-limited ,
.fl-modal-description-single-button .fl-profile-actions-discount {
  top: 6.8px;
}

.fl-profile-actions-limited {
  position: absolute;
  right: -15px;
  top: 3.8px;
}

.fl-profile-actions-discount span,
.fl-profile-actions-limited span {
  display: block;
}

.fl-profile-actions-list-more-closed {
  max-height: 0px;
  transform: scaleY(0);
  overflow: visible;
  transform-origin: top;
  /*transition: transform 0.3s, max-height 0.1s;*/
}

.fl-profile-actions-more {
  text-align: center;
  color: #666;
  font-weight: 500;
  cursor: pointer;
  border-top: 1px solid #00000032;
}

.fl-profile-actions-more span {
  padding-top: 15px;
  user-select: none;
}

.fl-profile-actions-more-open {
  padding-bottom: 8px;
}

.fl-profile-actions-more svg {
  color: #15c;
}

.fl-profile-actions-more span:hover {
  text-decoration: underline;
}

.fl-profile-actions-modal-more > div {
  border-bottom: 2px solid #e4e3e3;
  flex-grow: 3;
  height: 50%;
  align-self: center;
}

.fl-profile-actions-modal-more {
  display: flex;
}

.fl-profile-actions-modal-more span {
  padding: 0px 15px 0px 15px;
  color: #666;
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;
}

.fl-profile-actions-modal-more span svg {
  color: #666;
}

.fl-profile-actions-more span {
  display: flex;
  align-items: center;
  justify-content: center;
}

.fl-profile-actions-more span svg,
.fl-profile-actions-modal-more span svg {
  margin-top: -5px;
  margin-left: 5px;
}

.fl-post-head-actions-dropdown-option .fl-info-text {
  color: #888888;
}

.fl-profile-actions-plans-selected {
  position: absolute;
  top: 5px;
  left: 10px;
  width: 35px;
  height: 35px;
  border-radius: 50%;
  border: 1px solid white;
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: transparent;
  transition: background-color 0.3s ease-in-out;
}

.fl-profile-actions-list-more-open .fl-profile-actions-plans-selected {
  border: 1px solid #ff0d72;
}

.fl-profile-actions-list-more-open
  button:hover
  .fl-profile-actions-plans-selected {
  border: 1px solid white;
}

.fl-profile-actions-plans-confirmation {
  display: flex;
  align-items: center;
  justify-content: center;
  padding-top: 10px;
}

.fl-profile-actions-subscribe-modal-description
  .fl-profile-actions-subscribe-modal-description-list-item {
  font-size: 14px;
}

.fl-profile-actions-description {
  background: #8888883d;
  display: flex;
  align-items: center;
  border-bottom-left-radius: 25px;
  border-top-left-radius: 25px;
}

.fl-profile-actions-description span {
  margin-left: 10px;
}
.fl-profile-actions-description-dark {
  color: white;
}
.fl-profile-actions-description .smooth-image-wrapper,
.fl-profile-actions-description .smooth-image-wrapper img {
  border-radius: 50%;
  width: 50px;
  height: 50px;
}

.fl-profile-actions-description .smooth-image-wrapper img {
  border: 2px solid white;
}

/* Large devices (desktops, less than 1200px) */
@media (min-width: 991.99px) {
  .fl-profile-actions-main-large {
    display: block;
  }
}

/* Medium devices (tablets, less than 992px) */
@media (max-width: 991.98px) {
  .fl-profile-actions-main-small {
    display: flex;
  }
}

@media (max-width: 575.98px) {
  .fl-profile-actions-main-small {
    display: block;
  }
  .fl-profile-actions-button {
    width: 100%;
  }
}
