.fl-privacy-main {
    flex: 1;
    display: flex;
    flex-direction: column;
}

.fl-fluffa-desktop .fl-privacy-main {
    margin-top: 80px;
}

.fl-privacy-main.fl-privacy-main-dark {
    background-color: #1b1b1b;
}

.fl-privacy-main.fl-privacy-main-light {
    background-color: #f4f4f4;
}

.fl-privacy-inner {
    padding: 20px;
}

.fl-privacy-title {
    font-size: 32px;
    text-align: center;
    font-weight: 500;
    padding: 15px 0px;
    border-bottom: 2px solid;
    margin-bottom: 15px;
    text-transform: uppercase;
}

.fl-privacy-title.fl-privacy-title-dark {
    border-color: #ffffff;
    color: #ff0D72;
}

.fl-privacy-title.fl-privacy-title-light {
    border-color: #000000;
    color: #ff0D72;
}

.fl-privacy-row {
    margin-bottom: 15px;
    font-size: 14px;
}

.fl-privacy-row.fl-privacy-row-dark {
    color: #f4f4f4;
}

.fl-privacy-row.fl-privacy-row-light {
    color: #1b1b1b;
}

.fl-privacy-section-title {
    font-size: 22px;
    padding: 20px 0px;
    color: #ff0D72;
    text-transform: uppercase;
    font-weight: 500;
}

.fl-privacy-row b {
    font-weight: 600;
}

.fl-privacy-sub-row {
    padding-left: 50px;
    margin: 5px 0px;
    font-size: 14px;
    position: relative;
}

.fl-privacy-sub-row::before {
    position: absolute;
    left: 25px;
    top: 7px;
    width: 8px;
    height: 8px;
    border-radius: 50%;
    content: '';
    transform: translate(-50%,-50%);
}

.fl-privacy-sub-row.fl-privacy-sub-row-dark::before {
    background-color: #fff;
}
.fl-privacy-sub-row.fl-privacy-sub-row-light::before {
    background-color: #000;
}

.fl-privacy-sub-row.fl-privacy-sub-row-dark {
    color: #f4f4f4;
}

.fl-privacy-sub-row.fl-privacy-sub-row-light {
    color: #1b1b1b;
}

.fl-privacy-row i {
    font-size: 18px;
    font-style: italic;
    font-weight: 600;
}

.fl-privacy-main a {
    color: #ff0D72;
}

.fl-privacy-row > .fl-privacy-sub-row:nth-of-type(1) {
    margin-top: 15px;
}

/* Large devices (desktops, less than 1200px) */
@media (max-width: 1199.98px) {

}

/* Medium devices (tablets, less than 992px) */
@media (max-width: 991.98px) { 

}

/* Small devices (landscape phones, less than 768px) */
@media (max-width: 767.98px) { 
    
 }

/* Extra small devices (portrait phones, less than 576px) */
@media (max-width: 575.98px) {

}