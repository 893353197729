.fl-image-section {
    width: 100%;
    display: flex;
    align-items: center;
}

.fl-image-section.fl-image-section-invert .fl-image-section-text{
    order: 2;
}

.fl-image-section-text a {
    display: inline-flex;
}

.fl-image-section.fl-image-section-invert .fl-image-section-image{
    order: 1;
}

.fl-image-section-title {
    font-size: 36px;
    font-weight: 600;
    line-height: 48px;
    max-width: 350px;
}

.fl-image-section-title.fl-image-section-title-light {
    color: #000;
}

.fl-image-section-title.fl-image-section-title-dark {
    color: #fff;
}

.fl-image-section-separator {
    margin: 25px 0px;
    width: 60px;
    height: 5px;
    border-radius: 3px;
    background-color: #000;
}

.fl-image-section-separator.fl-image-section-separator-light {
    background-color: #000;
}
.fl-image-section-separator.fl-image-section-separator-dark {
    background-color: #fff;
}

.fl-image-section-description {
    font-size: 18px;
    line-height: 30px;
    margin-bottom: 25px;
}

.fl-image-section-description.fl-image-section-description-light {
    color: #7D7987;
}

.fl-image-section-description.fl-image-section-description-dark {
    color: #888888;
}

.fl-image-section-image-shapes {
    width: 100%;
    position: relative;
    /*padding-top: 100%;*/
}

.fl-image-section-image-shape {
    position: absolute;
    width: 60%;
    transform: translate(-50%,-50%);
}

.fl-image-section-image-shape-1 {
    left: 67%;
    top: 60%;
}

.fl-image-section-image-shape-2 {
    left: 80%;
    top: 45%;
}

.fl-image-section-image-shape-3 {
    left: 40%;
    top: 59%;
    width: 50%;
}

.fl-image-section-image-shape-4 {
    left: 20%;
    top: 50%;
}

.fl-image-section-main-image {
    position: absolute;
    width: 75%;
    transform: translate(-50%,-50%);
    top: 45%;
    left: 45%;
}

.fl-image-section-invert .fl-image-section-main-image {
    top: 45%;
    left: 55%;
}

/* Large devices (desktops, less than 1200px) */
@media (max-width: 1199.98px) { 
    .fl-image-section-title {
        font-size: 36px;
        line-height: 42px;
    }
    .fl-image-section-separator {
        margin: 15px 0px;
    }
    .fl-image-section-description {
        font-size: 16px;
        line-height: 22px;
    }
 }

/* Medium devices (tablets, less than 992px) */
@media (max-width: 991.98px) { 
    .fl-image-section-title {
        font-size: 28px;
        line-height: 32px;
    }
    .fl-image-section-description {
        font-size: 14px;
        line-height: 18px;
    }
 }

/* Small devices (landscape phones, less than 768px) */
@media (max-width: 767.98px) {  }

/* Extra small devices (portrait phones, less than 576px) */
@media (max-width: 575.98px) { 
    .fl-image-section  {
        flex-wrap: wrap;
    }
    .fl-image-section-text {
        order: 2 !important;
    }
    .fl-image-section-image {
        order: 1 !important;
    }
    .fl-image-section-text {
        margin-top: 145px;
    }
 }

@media (max-width: 375.98px) {
    .fl-image-section-text {
        margin-top: 110px;
    }
}