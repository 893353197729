.fl-textarea {
  font-size: 18px;
  border: 1px solid #646464;
  padding: 15px 25px;
  border-radius: 20px;
  margin: 0px;
  resize: none;
  outline: none;
  width: 100%;
  height: 100%;
  background: none;
  overflow-y: hidden;
}
.fl-textarea-dark:not(.fl-write-post-all .fl-textarea-dark) {
  border: 1px solid #646464 !important;
}

.fl-textarea-dark {
  color: #646464;
}

.fl-textarea-outter {
  position: relative;
  width: 100%;
  margin: 10px 0px;
}

/* Large devices (desktops, less than 1200px) */
@media (max-width: 1199.98px) {
  /*.fl-textarea-outter {*/
  /*  min-height: 80px;*/
  /*}*/
  .fl-textarea {
    border-width: 2px;
    border-radius: 15px;
    font-size: 14px;
  }
}

/* Medium devices (tablets, less than 992px) */
@media (max-width: 991.98px) {
}

/* Small devices (landscape phones, less than 768px) */
@media (max-width: 767.98px) {
  .fl-textarea {
    font-size: 16px;
  }
}

/* Extra small devices (portrait phones, less than 576px) */
@media (max-width: 575.98px) {
}
