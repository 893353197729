.fl-videobanner-main {
    width: 100%;
    height: 100vh;
    position: relative;
    margin-top: -120px;
}

.fl-videobanner-video-watermark {
    width: 70px;
    right: 20px;
    bottom: 20px;
    filter: grayscale(1);
    position: absolute;
}

.fl-videobanner-video {
    position: absolute;
    width: 100%;
    height: 100%;
    object-fit: cover;
    object-position: center;
    pointer-events: none;
    left: 0;
    top: 0;
}

.fl-videobanner-inner {
    width: 100%;
    height: 100%;
    background-color: #00000090;
    position: absolute;
    left: 0;
    top: 0;
}

.fl-videobanner-contenct {
    display: flex;
    align-items: center;
    justify-content: center;
    height: 100%;
    flex-direction: column;
}

.fl-videobanner-title {
    font-size: 86px;
    color: #fff;
    font-weight: 700;
    border-bottom: 3px solid #fff;
}

.fl-videobanner-description {
    font-size: 22px;
    color: #fff;
    font-weight: 400;
    padding: 20px 0px;
    max-width: 600px;
    text-align: center;
}

/* Large devices (desktops, less than 1200px) */
@media (max-width: 1199.98px) {
    
}

/* Medium devices (tablets, less than 992px) */
@media (max-width: 991.98px) {  }

/* Small devices (landscape phones, less than 768px) */
@media (max-width: 767.98px) {  }

/* Extra small devices (portrait phones, less than 576px) */
@media (max-width: 575.98px) {
    .fl-videobanner-title {
        font-size: 64px;
    }
    .fl-videobanner-description {
        font-size: 18px;
        padding: 20px;
    }
}