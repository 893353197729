.fl-cookie-box-main {
    width: calc(100%- 40px);
    max-width: 340px;
    position: fixed;
    right: 20px;
    bottom: 20px;
    padding: 20px;
    z-index: 999;
}

.fl-cookie-box-main.fl-cookie-box-main-dark {
    background-color: #000;
    box-shadow: 0px 0px 15px 5px #ffffff60;
    color: #fff;
}

.fl-cookie-box-main.fl-cookie-box-main-light {
    background-color: #fff;
    box-shadow: 0px 0px 15px 5px #00000060;
    color: #000;
}

.fl-cookie-box-title {
    font-size: 32px;
    margin-bottom: 20px;
    font-weight: 500;
}

.fl-cookie-box-description {
    font-size: 14px;
    margin-bottom: 20px;
}

.fl-cooke-box-buttons {
    display: flex;
    justify-content: flex-end;
}

.fl-cooke-box-buttons button {
    border-radius: 0px;
    padding: 8px 15px;
    height: auto;
    margin-right: 10px;
}

.fl-cooke-box-buttons a {
    text-decoration: none;
    color: #000;
}

.fl-cookie-box-main-dark .fl-cooke-box-buttons a {
    color: #fff;
}

.fl-cooke-box-buttons button:nth-last-of-type(1) {
    margin-right: 0px;
}

/* Large devices (desktops, less than 1200px) */
@media (max-width: 1199.98px) {

}

/* Medium devices (tablets, less than 992px) */
@media (max-width: 991.98px) { 

}

/* Small devices (landscape phones, less than 768px) */
@media (max-width: 767.98px) { 
    
 }

/* Extra small devices (portrait phones, less than 576px) */
@media (max-width: 575.98px) {
    .fl-cookie-box-main {
        max-width: 100%;
        width: 100%;
        bottom: 0px;
        right: 0px;
        padding: 15px;
    }
    .fl-cookie-box-description {
        margin-bottom: 7px;
    }
    .fl-cooke-box-buttons a {
        margin: 0 10px;
    }
    .fl-cooke-box-buttons span {
        color: #ff037c;
    }
}