.fl-back-to-top {
    width: 60px;
    height: 60px;
    border-radius: 50%;
    overflow: hidden;
    z-index: 10;
}

.fl-back-to-top-icon {
    display: flex;
    align-items: center;
    justify-content: center;
    width: 100%;
    height: 100%;
}

.fl-back-to-top-icon:hover {
    animation: rotate360 500ms forwards ease-in-out;
}

.fl-back-to-top-button {
    padding: 0px;
    font-size: 20px;
    width: 100%;
    height: 100%;
    color: #fff;
    background: linear-gradient(to bottom, #0066ff, #1010b3);
    border: none;
}

.fl-back-to-top-button:hover {
    background: linear-gradient(to bottom, #0056d8, #0f0f94) !important;
}

@keyframes rotate360 {
    0% { transform: rotate(0deg);}
    100% { transform: rotate(360deg);}
}

/* Large devices (desktops, less than 1200px) */
@media (max-width: 1199.98px) {
    
}

/* Medium devices (tablets, less than 992px) */
@media (max-width: 991.98px) {  }

/* Small devices (landscape phones, less than 768px) */
@media (max-width: 767.98px) { 
    .fl-back-to-top {
        width: 40px;
        height: 40px;
        font-size: 20px;
    }
 }

/* Extra small devices (portrait phones, less than 576px) */
@media (max-width: 575.98px) {

}