.fl-tipmodal__close {
  position: absolute;
  top: 1rem;
  right: 2rem;
  cursor: pointer;
}

.fl-tipmodal__custom {
  max-width: 100px;
  user-select: none;
  margin-left: 1rem;
}