.fl-settings-sub-payment-cards {
  width: 100%;
}

.fl-settings-sub-payment-cards.fl-settings-sub-payment-cards-dark {
  color: #fff;
}

.fl-settings-sub-payment-cards.fl-settings-sub-payment-cards-light {
  color: #000;
}

.fl-settings-sub-payment-add-bank:hover,
.fl-settings-sub-payment-add-card:hover {
 opacity: 0.8;
}

.fl-settings-sub-payment-card {
  width: 100%;
  padding: 10px 10px;
  display: flex;
  align-items: center;
  justify-content: center;
}

.fl-settings-sub-payment-card.fl-settings-sub-payment-card-light {
  background-color: #00000005;
  border-bottom: 1px solid #00000020;
}

.fl-settings-sub-payment-card.fl-settings-sub-payment-card-dark {
  background-color: #ffffff05;
  border-bottom: 1px solid #ffffff20;
}

.fl-settings-sub-payment-card.fl-settings-sub-payment-card-light:nth-of-type(odd) {
  background-color: #00000010;
}

.fl-settings-sub-payment-card.fl-settings-sub-payment-card-dark:nth-of-type(odd) {
  background-color: #ffffff10;
}

.fl-settings-sub-payment-card:nth-last-of-type(1) {
  border-bottom: 0px;
}

.fl-settings-sub-payment-card-image {
  width: 50px;
  height: 30px;
  margin-right: 10px;
  border: 1px solid #00000020;
  background-color: #fff;
  cursor: pointer;
}

.fl-settings-sub-payment-card-image-img {
  width: 50px;
  height: 100%;
  object-fit: cover;
  object-position: center;
}

.fl-settings-sub-payment-card-number {
  width: 150px;
}

.fl-settings-sub-payment-iban-title {
  width: 10%;
}

.fl-settings-sub-payment-iban-number {
  width: 30%;
}

.fl-settings-sub-payment-card-brand {
  font-weight: 600;
  width: calc(100% - 460px);
}

.fl-settings-sub-payment-card-exp {
  width: 100px;
}

.fl-settings-sub-payment-card-cell {
  padding: 0px 5px;
  white-space: wrap;
  font-size: 14px;
}

.fl-settings-sub-payment-card-primary {
  width: 170px;
}

.fl-settings-sub-payment-card-newdefault {
  opacity: 0.2;
  transition: 0.2s all;
  cursor: pointer;
  overflow-wrap: break-word;
}
.fl-settings-sub-payment-card-newdefault:hover {
  opacity: 1;
}

.fl-settings-sub-payment-card-remove {
  width: 50px;
  height: 24px;
  display: flex;
  justify-content: flex-end;
}

.fl-settings-sub-payment-card-remove-icon {
  display: block;
  width: 24px;
  height: 24px;
  background-color: #888;
  color: rgb(193, 192, 192) !important;
  display: flex;
  justify-content: center;
  align-items: center;
  font-size: 16px;
  border-radius: 50%;
  cursor: pointer;
}

.fl-settings-sub-payment-card-remove-icon:hover {
  background-color: #424242;
  color: #fff !important;
}

.fl-settings-sub-payment-card-add {
  width: 100%;
  padding: 5px 10px;
}

.fl-settings-sub-payment-balance {
  display: flex;
}

.fl-settings-sub-payment-balance-single {
  width: 100%;
  margin-right: 20px;
  margin-bottom: 20px;
  border-radius: 8px;
  padding: 20px;
  position: relative;
  overflow: hidden;
}

.fl-settings-sub-payment-balance-single.fl-settings-sub-payment-balance-single-dark {
  background-color: #000;
  box-shadow: 0px 0px 8px 0px #ffffff20;
}
.fl-settings-sub-payment-balance-single.fl-settings-sub-payment-balance-single-light {
  background-color: #fff;
  box-shadow: 0px 0px 8px 0px #00000020;
}

.fl-settings-sub-payment-balance-single-label {
  width: 100%;
  font-size: 14px;
  font-weight: 500;
  color: #888;
  margin-bottom: 10px;
  cursor: default;
}

.fl-settings-sub-payment-balance-single-value {
  color: #ff0d72;
  font-size: 26px;
}

.fl-settings-sub-payment-balance-single:nth-last-of-type(1) {
  margin-right: 0px;
}

.fl-settings-payment-total-earnings {
  width: 48.8%;
}

.fl-settings-sub-payment-card-buttons {
  display: flex;
  justify-content: flex-end;
}

.fl-settings-sub-payment-card-append {
  display: flex;
  justify-content: center;
  color: #888;
  padding: 5px 10px;
  text-transform: uppercase;
  font-size: 12px;
  font-weight: 600;
  cursor: pointer;
}

.fl-settings-sub-payment-card-append-icon {
  margin-top: -1px;
  margin-right: 3px;
}

.fl-settings-sub-payment-card-add-inner {
  border-radius: 20px;
  padding: 5px 20px 20px;
  margin-top: 20px;
}
.fl-settings-sub-payment-card-add-inner.fl-settings-sub-payment-card-add-inner-dark {
  border: 1px solid #ffffff20;
}
.fl-settings-sub-payment-card-add-inner.fl-settings-sub-payment-card-add-inner-light {
  border: 1px solid #00000020;
}

.fl-settings-sub-payment-balance-single-bg {
  position: absolute;
  font-size: 85px;
  right: 20px;
  top: 0px;
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
}

.fl-settings-sub-payment-balance-single-bg img {
  width: 75px;
}

.fl-settings-sub-payment-balance-single-bg.fl-settings-sub-payment-balance-single-bg-dark {
  color: #88888829;
}

.fl-settings-sub-payment-balance-single-bg.fl-settings-sub-payment-balance-single-bg-light {
  color: #00000010;
}

.fl-settings-sub-payment-balance-single-content {
  width: 100%;
  position: relative;
}

.fl-settings-sub-payment-balance-single-value {
  cursor: default;
}

.fl-settings-sub-payment-balance-single-value-link:hover {
  text-decoration: underline;
}

.fl-settings-sub-payment-allowed-cards {
  display: flex;
  align-items: center;
  justify-content: center;
  border-top: 1px solid #00000020;
  padding-top: 20px;
  border-radius: 5px;
}

.fl-settings-sub-payment-allowed-cards-dark {
  border-top: 1px solid #ffffff20;
}

.fl-settings-sub-payment-allowed-card {
  width: 60px;
  height: 35px;
  margin-right: 10px;
  border: 1px solid #00000020;
  background-color: #fff;
}

.fl-settings-sub-payment-allowed-cards:nth-last-of-type(1) {
  margin-right: 0px;
}

.fl-settings-sub-payment-allowed-cards img {
  width: 100%;
  height: 100%;
  object-fit: contain;
  object-position: center;
}

.fl-settings-sub-payment-company {
  width: 100%;
  text-align: center;
  color: #888;
  font-size: 10px;
  padding: 10px;
  margin-top: 20px;
}

.fl-settings-sub-payment-terms {
  text-align: center;
  margin-top: 20px;
}

.fl-settings-sub-payment-transactions {
  width: 100%;
}

.fl-settings-sub-payment-display {
  display: flex;
  flex-wrap: wrap;
}

.fl-settings-sub-payment-display .fl-settings-sub-payment-display{
  width: 50%;
}

.fl-settings-sub-payment-transactions-dark {
  color: #fff;
}

.fl-settings-sub-payment-transactions-light {
  color: #000;
}

.fl-settings-sub-payment-transactions-single {
  width: 100%;
  display: flex;
  height: 40px;
  align-items: center;
}

.fl-settings-sub-payment-transactions-single.fl-settings-sub-payment-transactions-single-dark {
  background-color: #ffffff05;
}
.fl-settings-sub-payment-transactions-single.fl-settings-sub-payment-transactions-single-light {
  background-color: #00000005;
}

.fl-settings-sub-payment-secured {
  color: green;
  font-size: 14px;
  display: flex;
  align-items: center;
}

.fl-settings-sub-payment-secured span {
  margin-top: 5px;
}

.fl-settings-sub-payment-secured img {
  width: 15px;
  height: 15px;
  margin-right: 5px;
}

.fl-settings-sub-payment-accept-text {
  font-size: 14px;
  color: #888;
  padding: 30px 0px 20px 0px;
}

.fl-settings-sub-payment-accept-text-link {
  text-decoration: none;
  color: #ff0d72;
}

.fl-settings-sub-payment-accept-text-link:hover {
  text-decoration: underline;
}

.fl-settings-sub-payment-customcard-text {
  font-size: 14px;
  color: #888;
  cursor: pointer;
}

.fl-settings-sub-payment-customcard-text:hover {
  text-decoration: underline;
}

.fl-settings-sub-payment-transactions-single-date {
  width: 80%;
  padding: 0px 10px;
}

.fl-settings-payout-status {
  display: flex;
  align-content: center;
}

.fl-settings-payout-status span {
  margin-top: 2px;
  margin-left: 5px;
}

.fl-settings-sub-payment-transactions-single-date-short {
  display: block;
}

.fl-settings-sub-payment-transactions-single-date-long {
  display: none;
}

.fl-settings-sub-payment-transactions-single-date-long span {
  color: #888;
}

.fl-settings-sub-payment-transactions-single-date:hover
  .fl-settings-sub-payment-transactions-single-date-short {
  display: none;
}

.fl-settings-sub-payment-transactions-single-date:hover
  .fl-settings-sub-payment-transactions-single-date-long {
  display: block;
}

.fl-settings-sub-payment-transactions-single-action {
  height: initial;
}

.fl-settings-sub-payment-transactions-single-amount {
  padding: 0px 10px;
  width:20%;
  text-align: center;
}

.fl-settings-sub-payout-transactions-single-amount {
  padding: 0px 10px;
  width:70%;
  text-align: center;
}

.fl-settings-sub-payment-transactions-single-date a {
  color: #277eff;
  text-decoration: none;
}

.fl-settings-sub-payment-transactions-single-date a:hover {
  text-decoration: underline;
}

.fl-settings-sub-payment-transactions-single-currency {
  width: 200px;
  padding: 0px 10px;
}

.fl-settings-sub-payment-transactions-single.fl-settings-sub-payment-transactions-single-dark:nth-of-type(odd) {
  background-color: #ffffff10;
}

.fl-settings-sub-payment-transactions-single.fl-settings-sub-payment-transactions-single-light:nth-of-type(odd) {
  background-color: #00000017;
}

.fl-settings-sub-payment-transactions-single-head {
  background-color: #424242 !important;
  color: #fff;
  font-weight: 500;
  text-transform: uppercase;
}

.fl-settings-sub-payment-terms-link {
  color: #ff0d72;
  text-decoration: none;
}

.fl-settings-sub-payment-terms-link:hover {
  text-decoration: underline;
}

.fl-settings-sub-payment-card-add-form-row
  .fl-minput-main
  .fl-minput-input-outer
  .fl-minput-validator {
  position: absolute;
}

.fl-settings-sub-payment-card-add-form-row {
  display: flex;
  align-items: center;
}

.fl-settings-sub-payment-card-add-form-row-item {
  width: calc(calc(100% - 55px) / 3);
  margin-right: 5px;
  max-width: 100px;
}

.fl-settings-sub-payment-balance-funds-add-all {
  display: flex;
  text-decoration: none;
  cursor: pointer;
}
.fl-settings-sub-payment-balance-funds-add-all:hover {
  text-decoration: none;
}

.fl-settings-sub-payment-balance-funds-add {
  width: 100%;
  margin-right: 10px;
  border-radius: 50px;
  border: 1px solid #424242;
  color: #ff0d72;
  display: flex;
  align-items: center;
  justify-content: center;
  font-size: 12px;
  font-weight: 600;
  padding: 5px 5px 2px;
  text-decoration: none;
}
.fl-settings-sub-payment-balance-funds-add:hover {
  text-decoration: underline;
}

.fl-settings-sub-payment-balance-funds-add:nth-last-of-type(1) {
  margin-right: 0px;
}

.fl-settings-sub-payment-card-add-form-row-img {
  height: 40px;
  width: 50px;
  margin-top: 20px;
  cursor: pointer;
  pointer-events: all;
}

.fl-settings-sub-payment-card-add-form-row-img-popup {
  transition: 0.4s all;
  font-size: 0.8rem;
  opacity: 0;
  user-select: none;
  pointer-events: none;
  position: absolute;
  width: 280px;
  background-color: #fff;
  padding: 4px;
  border-radius: 4px;
  transition-delay: 0.4s;
  box-shadow: 1px 1px 5px #a9a9a9;
  z-index: 101;
  color: #000;
}

.fl-settings-sub-payment-card-add-form-row-img-popup div {
  height: 170px;
  background-size: contain;
  background-repeat: no-repeat;
}

.fl-settings-sub-payment-card-add-form-row-img:hover
  + .fl-settings-sub-payment-card-add-form-row-img-popup {
  opacity: 1;
}

.fl-settings-sub-payment-transactions-more {
  color: #ff0d72;
  padding: 10px 0px;
  margin-top: 10px;
  cursor: pointer;
}

.fl-settings-sub-payment-transactions-more:hover {
  text-decoration: underline;
}

.fl-settings-sub-payment-card-popup {
  height: 350px;
  background-size: contain;
  background-repeat: no-repeat;
  background-position: center;
  border-radius: 30px;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
}
.fl-settings-sub-payment-card-popup-brand {
  text-align: right;
}
.fl-settings-sub-payment-card-popup-digits {
  font-size: 2.5rem;
  margin: 0;
  text-shadow: 1px 1px #fff;
}
.fl-settings-sub-payment-card-popup-exp {
  padding: 0 1.5rem;
  font-size: 1.25rem;
}

.fl-text-with-bold > span {
  font-weight: 700;
}

.fl-settings-sub-payment-default-card {
  display: flex;
  align-items: center;
  justify-content: center;
}

.fl-settings-sub-payment-default-card span:last-child svg {
  color: green;
  margin-left: 5px;
}

.fl-settings-card-single-image {
  width: calc(100% - 750px);
  padding: 0px;
  cursor: pointer;
  text-align: center;
}

.fl-settings-sub-payment-card-single-image {
  width: calc(100% - 750px);
}

.fl-settings-sub-payment-card-single-number {
  width: calc(100% - 650px);
  text-align: center;
}

.fl-settings-sub-payment-card-single-brand {
  width: calc(100% - 700px);
  text-align: center;
}

.fl-settings-sub-payment-card-single-name {
  width: calc(100% - 680px);
  text-align: center;
}

.fl-settings-sub-payment-card-single-expire {
  width: calc(100% - 700px);
  text-align: center;
}

.fl-settings-sub-payment-card-single-default {
  width: calc(100% - 675px);
  text-align: center;
}

.fl-settings-sub-payment-card-single-remove {
  width: calc(100% - 695px);
  text-align: center;
}

.fl-settings-sub-card-head {
  font-size: 14px;
}

.fl-settings-card-single-number {
  width: calc(100% - 650px);
  padding: 0px 10px;
  text-align: center;
  font-size: 14px;
}

.fl-settings-card-single-name {
  width: calc(100% - 680px);
  padding: 0px 10px;
  text-align: center;
  font-size: 14px;
}

.fl-settings-card-single-default {
  font-size: 14px;
  width: calc(100% - 675px);
  text-align: center;
}

.fl-settings-sub-payment-card-single {
  padding-top: 5px;
  padding-bottom: 5px;
  height: 45px;
}

.fl-settings-card-single-remove {
  width: calc(100% - 795px);
}

.fl-settings-card-single-brand {
  width: calc(100% - 700px);
  padding: 0px 10px;
  text-align: center;
  font-size: 14px;
  font-weight: 600;
}

.fl-settings-card-single-expire {
  width: calc(100% - 700px);
  padding: 0px 10px;
  text-align: center;
  font-size: 14px;
}

.fl-transaction-link {
  color: #ff0d72;
  text-decoration: none;
}

.fl-transaction-link:hover {
  text-decoration: underline;
}

.fl-transaction-space-light {
  border-bottom: 1px solid #8888 ;
}

.fl-transaction-space-dark {
  border-bottom: 1px solid white ;
}

/* Large devices (desktops, less than 1200px) */
@media (max-width: 1199.98px) {
  /* new responsive */
  .fl-settings-sub-payment-iban-title {
    width: 10% !important;
  }

  .fl-settings-sub-payment-iban-number {
    width: 30% !important;
  }

  .fl-settings-sub-card-head {
    font-size: 13px;
  }
  .fl-settings-card-single-image {
    width: calc(100% - 600px);
  }
  .fl-settings-sub-payment-card-single-image {
    width: calc(100% - 600px);
  }
  .fl-settings-card-single-number {
    width: calc(100% - 530px);
    font-size: 13px;
  }
  .fl-settings-sub-payment-card-single-number {
    width: calc(100% - 530px);
  }
    .fl-settings-card-single-brand {
    width: calc(100% - 590px)
  }
  .fl-settings-sub-payment-card-single-brand {
    width: calc(100% - 590px)
  }
  .fl-settings-card-single-name {
    width: calc(100% - 560px)
  }
  .fl-settings-sub-payment-card-single-name {
    width: calc(100% - 560px)
  }
  .fl-settings-card-single-expire {
    width: calc(100% - 560px)
  }
  .fl-settings-sub-payment-card-single-expire {
    width: calc(100% - 560px)
  }
  .fl-settings-card-single-default {
    width: calc(100% - 550px)
  }
  .fl-settings-sub-payment-card-single-default {
    width: calc(100% - 550px)
  }
  .fl-settings-card-single-remove {
    width: calc(100% - 640px)
  }
  .fl-settings-sub-payment-card-single-remove {
    width: calc(100% - 640px)
  }
  /* new responsive */
  .fl-settings-sub-payment-card-cell {
    font-size: 12px;
    padding: 0px 3px;
  }
  .fl-settings-sub-payment-card-number {
    width: 120px;
  }
  .fl-settings-sub-payment-card-exp {
    width: 60px;
  }
  .fl-settings-sub-payment-card-primary {
    width: 80px;
  }
  .fl-settings-sub-payment-card-remove {
    width: 40px;
  }
  .fl-settings-sub-payment-card-brand {
    width: calc(100% - 360px);
  }
  .fl-settings-sub-payment-transactions-single-date,
  .fl-settings-sub-payment-transactions-single-amount,
  .fl-settings-sub-payment-transactions-single-currency {
    width: 100%;
  }
}

.fl-toggle-main {
  margin-left: 10px;
  /*display: inline-block;*/
  display: block;
}

.fl-auto-refill-input {
  display: inline-block;
  width: 12rem;
  margin-left: 5px;
}

.fl-sub-section-header-options {
  float: right;
}

/* Medium devices (tablets, less than 992px) */
@media (max-width: 991.98px) {
  .fl-settings-sub-payment-single-card {
    height: auto;
  }
  .fl-settings-payment-total-earnings {
    width: 100%;
  }
  .fl-settings-card-single-image,
  .fl-settings-card-single-image img,
  .fl-settings-card-single-number,
  .fl-settings-card-single-brand,
  .fl-settings-card-single-name,
  .fl-settings-card-single-expire,
  .fl-settings-card-single-default,
  .fl-settings-card-single-remove
  {
    width: 100%;
    padding: 0px 0px 5px 0px;
  }
  .fl-settings-card-single-remove {
    display: flex;
    align-items: center;
    justify-content: center;
  }
  .fl-settings-sub-card-head {
    display: none;
  }
  .fl-settings-sub-payment-balance {
    flex-wrap: wrap;
  }
  .fl-settings-sub-payment-balance-single {
    margin-right: 0px;
  }
  .fl-settings-sub-payment-card,
  .fl-settings-sub-payment-single-card {
    flex-wrap: wrap;
    justify-content: space-between;
    margin-bottom: 20px;
    padding: 20px;
    border-radius: 20px;
    border: 1px solid #00000020;
  }
  .fl-settings-sub-payment-single-card-dark {
    border: 1px solid #ffffff20;
  }
  .fl-settings-sub-payment-card-add {
    padding: 0px;
  }
  .fl-settings-sub-payment-card-image {
    order: 1;
    width: 100%;
    margin: 0px 0px 20px;
    position: relative;
    height: unset;
    padding-top: 60%;
  }
  .fl-settings-sub-payment-card-image img {
    position: absolute;
    left: 0px;
    top: 0px;
  }
  .fl-settings-sub-payment-card-brand {
    width: unset;
    order: 2;
  }
  .fl-settings-sub-payment-card-remove {
    order: 3;
  }

  .fl-settings-sub-payment-card-number {
    order: 5;
    width: 100%;
  }
  .fl-settings-sub-payment-card-exp {
    width: 100%;
    order: 6;
  }
  .fl-settings-sub-payment-card-primary {
    width: 100%;
    order: 7;
  }

  .fl-settings-sub-payment-card-popup {
    height: 240px;
  }
  .fl-settings-digits {
    font-size: 2rem;
  }

  .fl-settings-sub-payment-card-add-form-row-img-popup {
    right: 0;
  }
}

/* Small devices (landscape phones, less than 768px) */
@media (max-width: 767.98px) {
  .fl-settings-sub-payment-transactions-single {
    height: auto;
    padding: 20px 0px;
    font-size: 12px;
    text-align: center;
  }
  .fl-settings-sub-payment-transactions-single-date,
  .fl-settings-sub-payment-transactions-single-amount,
  .fl-settings-sub-payment-transactions-single-currency {
    width: 100%;
  }
}

@media (max-width: 678px) {
  .fl-settings-sub-payment-display {
    display: block;
  }
}
/* Extra small devices (portrait phones, less than 576px) */
@media (max-width: 575.98px) {
  .fl-settings-sub-payment-iban-title {
    width: 100% !important;
    text-align: center;
    padding-bottom: 10px;
  }

  .fl-settings-sub-payment-iban-number,
  .fl-settings-sub-payment-card-number {
    width: 100% !important;
    text-align: center;
    padding-bottom: 10px;
  }

}
