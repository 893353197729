.fl-my-profile-img {
  min-width: 64px;
  height: 64px;
  border-radius: 50%;
  position: relative;
  display: block;
}

.fl-my-profile-name {
  width: 100%;
  font-size: 21px;
  font-weight: 600;
  margin-bottom: 5px;
  line-height: 21px;
  display: flex;
  align-items: center;
  text-decoration: none;
}

.fl-my-profile-name-dark:hover {
  cursor: default;
  color: #fff !important;
}

.fl-my-profile-name-light:hover {
  cursor: default;
  color: #000 !important;
}

.fl-user-profile-images-main-inner .fl-profile-holder-dropdown-status {
  top: -10px !important;
}

.fl-my-profile-name.fl-my-profile-name-light {
  color: #000;
}
.fl-my-profile-name.fl-my-profile-name-dark {
  color: #fff;
}

.fl-my-profile-username {
  color: #888888;
  font-size: 18px;
  display: block;
  text-decoration: none;
  text-overflow: ellipsis;
  white-space: nowrap;
  overflow: hidden;
  display: block;
}

.fl-my-profile-info {
  padding: 0px 20px;
  width: calc(100% - 64px);
}

.fl-my-profile-key {
  font-size: 18px;
  padding: 5px 0px;
  color: #888888;
  font-weight: 500;
}
.fl-my-profile-value {
  font-size: 18px;
  padding: 5px 0px;
  font-weight: 700;
}

.fl-my-profile-value-followers {
  cursor: pointer;
}

.fl-my-profile-value.fl-my-profile-value-light {
  color: #000;
}

.fl-my-profile-value.fl-my-profile-value-dark {
  color: #fff;
}

.fl-my-profile-post {
  width: 100%;
  padding-top: 15px;
  margin-top: 15px;
}

.fl-my-profile-post.fl-my-profile-post-dark {
  border-top: 1px solid #ffffff20;
}
.fl-my-profile-post.fl-my-profile-post-light {
  border-top: 1px solid #00000020;
}

.fl-my-profile-post-button {
  width: 100%;
}

/* Large devices (desktops, less than 1200px) */
@media (max-width: 1199.98px) {
  .fl-my-profile-img {
    min-width: 50px;
    height: 50px;
  }
  .fl-my-profile-info {
    width: calc(100% - 50px);
  }
  .fl-my-profile-name {
    line-height: 18px;
    font-size: 18px;
  }
  .fl-my-profile-username {
    font-size: 16px;
  }
  .fl-my-profile-key {
    font-size: 16px;
  }
  .fl-my-profile-value {
    font-size: 16px;
  }
}

.fl-post-head-actions-profile {
  width: 5px;
}

.fl-post-head-actions-profile .fl-direct-messages-title-indicator {
  margin-left: -15px;
  bottom: 0;
  position: absolute;
}

.fl-direct-messages-title-indicator-profile {
  cursor: pointer;
}

/* Medium devices (tablets, less than 992px) */
@media (max-width: 991.98px) {
}

/* Small devices (landscape phones, less than 768px) */
@media (max-width: 767.98px) {
  .fl-user-profile-images-main-inner .fl-profile-holder-dropdown-status {
    top: -24px !important;
  }
}

/* Extra small devices (portrait phones, less than 576px) */
@media (max-width: 575.98px) {
}
