
.fl-settings-notification {
    padding-bottom: 8px;
}

.fl-settings-notification-toggle {
    display: flex;
    align-items: center;
    padding: 10px 0px;
}

.fl-settings-toggle-span {
    margin-left: 5px;
    color: #888;
}

.fl-settings-notification .fl-settings-sub-account-field-label {
    color: #000;
}

.fl-settings-sub-section span:nth-child(2) {
    color: #888;
}

.fl-settings-sub-section-dark span:nth-child(1){
    color:white;
}

.fl-settings-sub-section-dark svg{
    color:white;
}

.fl-settings-sub-title-svg-dark {
    color: #fff;
    border-bottom: 1px solid #ffffff20;
}

.fl-settings-sub-title-svg-dark svg {
    color: #ff0d72;
}

.fl-settings-sub-section-light span:nth-child(1){
    color:black;
}

.fl-settings-enabled {
    border-left: solid 2px #155eff !important;
}


.fl-settings-sub-section > .fl-settings-notification {
    margin-left: 10px;
    padding-left: 10px;
    position: relative;
    border-left: solid 2px #ff7839;
}


.fl-toogle-hidden {
    display: none;
}

.fl-settings-notification-major {
    display: flex;
    flex-direction: column;
    align-items: center;
}

.fl-settings-notification-major-dark {
    color: white;
}

.fl-settings-notification-group-switch {
    font-weight: 600;
}

.fl-group-toggle {
    margin-bottom:10px;
}

/* Large devices (desktops, less than 1200px) */
@media (max-width: 1199.98px) {
}

/* Medium devices (tablets, less than 992px) */
@media (max-width: 991.98px) {
}

/* Small devices (landscape phones, less than 768px) */
@media (max-width: 767.98px) {
}

/* Extra small devices (portrait phones, less than 576px) */
@media (max-width: 575.98px) {
}