.fl-message-bubble-gallery-content {
    border-radius: 5px;
    height: 230px;
    margin-top: 10px;
    display: flex;
    flex-wrap: wrap;
    overflow: hidden;
    border: 4px solid #efefef;
    justify-content: space-between;
    align-content: space-between;
    position: relative;
    background-color: #efefef;
}

.fl-message-bubble-gallery-content-single  {
    height: 100%;
    position: relative;
    background-color: #414141;
    color: #fff;
    font-size: 26px;
    display: flex;
    align-items: center;
    justify-content: center;
    outline: none;
}

.fl-message-bubble-gallery-content-single img {
    width: 100%;
    height: 100%;
    object-fit: cover;
    object-position: center;
}

.fl-message-bubble-gallery {
    display: grid;
    grid-template-columns: minmax(0, 1fr) minmax(0, 1fr) minmax(0, 1fr);
    grid-column-gap: 10px;
    column-gap: 10px;
    width: 100%;
    align-items: center;
    height: 100%;
    overflow: auto;
}

/* 1 Image */
.fl-message-bubble-gallery-content-1
.fl-message-bubble-gallery-content-single:nth-of-type(1) {
    width: calc(100% - 2px);
    height: calc(100% - 2px);
}

/* 2 Images */
.fl-message-bubble-gallery-content-2
.fl-message-bubble-gallery-content-single:nth-of-type(1),
.fl-message-bubble-gallery-content-2
.fl-message-bubble-gallery-content-single:nth-of-type(2) {
    width: calc(50% - 2px);
    height: calc(100% - 2px);
}

/* 3 Images */
.fl-message-bubble-gallery-content-3
.fl-message-bubble-gallery-content-single:nth-of-type(1),
.fl-message-bubble-gallery-content-3
.fl-message-bubble-gallery-content-single:nth-of-type(2) {
    width: calc(50% - 2px);
    height: calc(50% - 2px);
}
.fl-message-bubble-gallery-content-3
.fl-message-bubble-gallery-content-single:nth-of-type(3) {
    width: calc(100% - 2px);
    height: calc(50% - 2px);
}

/* 4 Images */
.fl-message-bubble-gallery-content-4
.fl-message-bubble-gallery-content-single:nth-of-type(1),
.fl-message-bubble-gallery-content-4
.fl-message-bubble-gallery-content-single:nth-of-type(2),
.fl-message-bubble-gallery-content-4
.fl-message-bubble-gallery-content-single:nth-of-type(3),
.fl-message-bubble-gallery-content-4
.fl-message-bubble-gallery-content-single:nth-of-type(4) {
    width: calc(50% - 2px);
    height: calc(50% - 2px);
}

/* More Images */
.fl-message-bubble-gallery-content-more
.fl-message-bubble-gallery-content-single:nth-of-type(1),
.fl-message-bubble-gallery-content-more
.fl-message-bubble-gallery-content-single:nth-of-type(2),
.fl-message-bubble-gallery-content-more
.fl-message-bubble-gallery-content-single:nth-of-type(3),
.fl-message-bubble-gallery-content-more
.fl-message-bubble-gallery-content-single:nth-of-type(4) {
    width: calc(50% - 2px);
    height: calc(50% - 2px);
    float: left;
}

.fl-message-gallery-empty {
    text-align: center;
    color: #888;
    font-size: 22px;
    display: block;
}

.fl-message-bubble-gallery-content  .fl-message-bubble-message-attachment-img-blur {
    cursor: pointer;
}

.fl-message-bubble-gallery-content-single-avatar {
    width: 30px;
    height: 30px;
    border-radius: 50%;
    overflow: hidden;
    z-index: 1;
    display: block;
    position: absolute;
    text-decoration: none;
    top: 10px;
    left: 10px;
    border: 1px solid white;
}

.fl-message-bubble-gallery-content-single-avatar .fl-profile-holder {
    padding: 2px;
}

.fl-message-bubble-gallery-content-single  .fl-message-bubble-message-attachment-unlock-logo img{
    width: 40px;
    float: right;
    opacity: 0.5;
}

.fl-message-bubble-gallery-content-single  .fl-message-padlock {
    width: 55px;
    height: 55px;
}

/* Large devices (desktops, less than 1200px) */
@media (max-width: 1199.98px) {
}

/* Medium devices (tablets, less than 992px) */
@media (max-width: 991.98px) {
}

/* Small devices (landscape phones, less than 768px) */
@media (max-width: 767.98px) {
}

/* Extra small devices (portrait phones, less than 576px) */
@media (max-width: 575.98px) {
     .fl-message-bubble-gallery-content {
        height: 80px;
    }
}

/* for even smaller devices */
@media (max-width: 300px) {
}
