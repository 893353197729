.fl-settings-sub-account-field {
  width: 100%;
}

.fl-settings-sub-account-field-label {
  font-size: 12px;
  text-transform: uppercase;
  color: #888;
  font-weight: 500;
  line-height: 15px;
}

.fl-settings-sub-account-field-input {
  height: auto;
  margin: 5px 0px;
}

.fl-settings-sub-account-field-input .fl-input,
.fl-settings-sub-account-field-input .fl-datepicker {
  border: none;
  padding: 8px 10px;
  border-radius: 5px;
  height: 35px;
}
.fl-settings-sub-account-field-input .fl-input-dark,
.fl-settings-sub-account-field-input .fl-datepicker-dark {
  background-color: #ffffff10;
}
.fl-settings-sub-account-field-input .fl-input-light,
.fl-settings-sub-account-field-input .fl-datepicker-light {
  background-color: #00000010;
}

.fl-settings-sub-account-field-input .fl-textarea {
  border: none;
  padding: 8px 10px;
  border-radius: 5px;
  height: 100px;
}

.fl-settings-sub-account-field-input .fl-textarea-light {
  background-color: #00000010;
}

.fl-settings-sub-account-field-input .fl-textarea-dark {
  background-color: #ffffff10;
}

.fl-settings-sub-account-field-half {
  width: calc(50% - 10px);
}

.fl-settings-sub-account-actions {
  display: flex;
  flex-direction: column;
  margin-top: 50px;
  margin-bottom: 10px;
  align-items: flex-end;
}

.fl-settings-sub-account-actions-single {
  margin-bottom: 8px;
  color: #ff0d72;
  cursor: pointer;
}

.fl-delete-account {
  color: rgb(117, 117, 117);

  /* color: #949494; */
}

.fl-settings-sub-account-actions-single:nth-last-of-type(1) {
  margin-bottom: 0px;
}

.fl-settings-account-modal-buttons {
  display: flex;
}

.fl-settings-account-modal-button {
  width: 100%;
  border-radius: 0px;
}

.fl-settings-account-modal-button:nth-of-type(1) {
  border-radius: 15px 0px 0px 15px;
}

.fl-settings-account-modal-button:nth-last-of-type(1) {
  border-radius: 0px 15px 15px 0px;
}

.fl-settings-sub-account-field-suggestion {
  width: 100%;
  font-size: 16px;
  color: #888;
}

.fl-settings-sub-account-field-suggestion span {
  color: #ff0d72;
}

.fl-settings-sub-account-field-row {
  display: flex;
  width: 100%;
}

.fl-settings-account-modal-recoverrow {
  transition: 0.2s height;
  overflow: hidden;
}

.fl-settings-account-modal-recoverpass {
  color: #ff0d72;
  cursor: pointer;
  padding: 0.5rem 0;
  text-decoration: none;
  display: inline-block;
  margin-top: 0.5rem;
}

.fl-settings-sub-account-field-row-item {
  margin: 5px 0px;
  border-radius: 5px 0px 0px 5px;
  width: 50px;
  display: flex;
  align-items: center;
  justify-content: center;
  color: #fff;
}

.fl-settings-sub-account-field-row .fl-settings-sub-account-field-input input {
  border-top-left-radius: 0px;
  border-bottom-left-radius: 0px;
}

.fl-settings-sub-account-new-email img {
  width: 20px;
  height: 20px;
  vertical-align: middle;
}

.fl-settings-sub-account-new-email p {
  line-height: 2;
}

/*.fl-settings-update-mail:hover {*/
/*  text-decoration: underline;*/
/*}*/

.fl-settings-verify-mail {
  margin-left: 15px;
}

.fl-settings-account-modal-recoverpass:hover {
  text-decoration: underline;
}

.fl-settings-sub-account-update-span {
  color: #888;
  font-size: 16px;
}

.fl-settings-sub-account-update-span a {
  color: #ff0d72;
  text-decoration: none;
}

.fl-settings-sub-account-update-span a:hover {
  color: #ff0d72;
  text-decoration: underline;
}

.fl-settings-sub-account-update-span a:hover {
  text-decoration: underline;
}

.fl-settings-sub-account-label .fl-minput-label-active {
  transform: translate(-10px, calc(-50% - 25px));
  font-size: 12px;
}

.fl-settings-update-email-button {
  color: #15c;
  cursor: pointer;
  text-decoration: none;
  font-weight: 500;
}

.fl-settings-update-email-button:hover {
  text-decoration: underline;
}

.fl-social-wrapper {
  display: flex;
  flex-wrap: wrap;
  align-items: center;
  justify-content: space-between;
}

.fl-social-column {
  display: flex;
  flex: 1 0;
  flex-direction: column;
}

.fl-social-column-dark {
  color: white;
}

.fl-social-toggle {
  margin-top: 10px;
}

.fl-social-icons img {
  width: 50px;
  height: 50px;
}

.fl-social-icons div:hover {
  cursor: pointer;
}

.fl-social-icons > div:first-of-type {
  display: flex;
  flex-wrap: wrap;
  align-self: start;
  align-items: center;
  flex-direction: column;
}

.fl-social-item > span {
  margin: 5px;
}

.fl-toggle-row {
  padding-top: 10px;
  padding-bottom: 10px;
  display: flex;
}

.fl-toggle-row span {
  display: flex;
  align-items: center;
  margin-left: 5px;
}

.fl-toggle-main {
  margin-right: 2.5px;
}

.fl-destroy-action {
  margin: 5px;
}

.fl-destroy-action:hover {
  color: red;
}

.fl-settings-mt-40 {
  margin-top: 40px;
}

.fl-settings-mt-30 {
  margin-top: 30px;
}

.fl-settings-mt-20 {
  margin-top: 20px;
}

.fl-settings-mt-15 {
  margin-top: 15px;
}

.fl-settings-mt-10 {
  margin-top: 10px;
}

.fl-settings-mt-5 {
  margin-top: 5px;
}

.fl-settings-pb-10 {
  padding-bottom: 10px !important;
}

.fl-settings-pb-5 {
  padding-bottom: 5px;
}

.fl-settings-account-textarea textarea {
  overflow: hidden;
}


.fl-settings-account-textarea textarea {
  width: 100%;
  border: 1px solid #00000020;
  padding: 15px;
}

.fl-settings-ml-15 {
  margin-left: 15px;
}

.fl-settings-overflow-hidden {
  overflow: hidden;
}

.fl-responsive-ml-10 {
  margin-left: 10px;
}

/* Large devices (desktops, less than 1200px) */
@media (max-width: 1199.98px) {
}

/* Medium devices (tablets, less than 992px) */
@media (max-width: 991.98px) {
}

/* Small devices (landscape phones, less than 768px) */
@media (max-width: 767.98px) {
}

/* Extra small devices (portrait phones, less than 576px) */
@media (max-width: 575.98px) {
}

@media (max-width: 480px) {
  .fl-flex-direction-column {
    flex-direction: column;
  }
  .fl-responsive-mt-20 {
    margin-top: 20px;
  }
  .fl-settings-sub-account-field-half {
    width: 100%;
  }
  .fl-responsive-ml-10 {
    margin: 0;
  }
}
