.fl-register-relative {
  position: relative;
}

.fl-register-eye {
  position: absolute;
  right: -40px;
  top: 45px;
  width: 30px;
  height: 30px;
  display: flex;
  align-items: center;
  justify-content: center;
  transform: translate(50%, -50%);
  cursor: pointer;
}

.fl-forgot-mb {
  margin-bottom: 10px;
  color: #424242;
  font-size: 14px;
}

.fl-expire-mb {
  display: flex;
  padding-bottom: 20px;
  margin-bottom: 10px;
  color: #ff0000;
  font-size: 14px;
}

.fl-expire-mb span {
  padding-right: 5px;
}

.fl-forgot-mb span {
  color: #ff0d72;
}

.fl-text-underline:hover {
  text-decoration: underline #ff0d72 1px;
}

.fl-fullscreen-with-footer-bg {
  z-index: -1;
}

/* Large devices (desktops, less than 1200px) */
@media (max-width: 1199.98px) {
}

/* Medium devices (tablets, less than 992px) */
@media (max-width: 991.98px) {
}

/* Small devices (landscape phones, less than 768px) */
@media (max-width: 767.98px) {
}

/* Extra small devices (portrait phones, less than 576px) */
@media (max-width: 575.98px) {
}
