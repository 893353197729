.fl-card-main {
    width: 100%;
    border-radius: 20px;
    padding: 30px 20px;
}

.fl-card-main.fl-card-main-light {
    background-color: #f4f4f4;
}

.fl-card-main.fl-card-main-dark {
    background-color: #1B1B1B;
}

.fl-card-icon {
    display: block;
    width: 28%;
    padding-top: 28%;
    margin-bottom: 20px;
    position: relative;
}

.fl-card-icon-img {
    width: 100%;
    height: 100%;
    object-fit: contain;
    object-position: center;
    position: absolute;
    left: 0;
    top: 0;
}

.fl-card-icon-svg {
    width: 100% !important;
    height: 100%;
    position: absolute;
    top:0 ;
}

.fl-card-icon-svg>path {
    fill: #ff1a67;
}

.fl-card-title {
    font-size: 36px;
    font-weight: 600;
    line-height: 48px;
    margin-bottom: 20px;
    width: 90%;
}

.fl-support-icon-home { /*TODO check if needed anymore*/
    font-size:5.4rem !important;
    color: #ff0f71 !important;
    position: absolute !important;
    top:0 !important;
    left:0 !important;
}

.fl-card-title.fl-card-title-light {
    color: #000;
}

.fl-card-title.fl-card-title-dark {
    color: #fff;
}

.fl-card-description {
    font-size: 18px;
    line-height: 30px;
}

.fl-card-description.fl-card-description-light {
    color: #7D7987;
}

.fl-card-description.fl-card-description-dark {
    color: #888888;
}


/* Large devices (desktops, less than 1200px) */
@media (max-width: 1199.98px) { 
    .fl-card-title {
        font-size: 28px;
        line-height: 36px;
    }
    .fl-card-description {
        font-size: 16px;
        line-height: 22px;
    }
    .fl-card-icon {
        width: 60px;
        height: 60px;
        padding-top: 0px;
    }
 }

/* Medium devices (tablets, less than 992px) */
@media (max-width: 991.98px) { 
    .fl-card-icon {
        width: 40px;
        height: 40px;
    }
    .fl-card-title {
        font-size: 20px;
        line-height: 26px;
        margin-bottom: 10px;
    }
    .fl-card-description {
        font-size: 14px;
        line-height: 18px;
    }
}

/* Small devices (landscape phones, less than 768px) */
@media (max-width: 767.98px) {  }

/* Extra small devices (portrait phones, less than 576px) */
@media (max-width: 575.98px) { 
    .fl-card-icon {
        margin: 0px auto;
        width: 80px;
        height: 80px;
        max-width: 100%;
    }
    .fl-card-title {
        text-align: center;
        width: 60%;
        margin-left: auto;
        margin-right: auto;
        margin-top: 20px;
    }
    .fl-card-description {
        display: none;
    }
 }