.fl-inbox-filter-dropdown {
    top: 60px;
}

.fl-inbox-title-dropdown {
    top: 45px;
    right: 0px;
}

.fl-inbox-text {
    display: flex;
    align-items: center;
    position: relative;
}

.fl-inbox-single-message-dropdown {
    position: relative;
}

.fl-d-right {
   float: right;
}

.fl-d-left {
    float: left;
}

.fl-message-bubble-main-right .fl-message-bubble-message .fl-inbox-single-message-dropdown {
    order: 1;
}

.fl-message-bubble-main-right .fl-message-bubble-message .fl-message-bubble-message-text {
    order: 2;
}

.fl-message-bubble-main-right .fl-message-bubble-message .fl-direct-message-option-dropdown {
    margin-right: 20px;
}

.fl-inbox-type-left-text,
.fl-inbox-type-left-all,
.fl-inbox-type-left-file {
    width: 160px;
    position: absolute;
    top: 0px;
    margin-left: 15px;
    z-index: 99;
}

.fl-inbox-type-right-text {
    position: absolute;
    left: -80px;
    z-index: 99;
    min-width: 140px;
    outline: none;
}

.fl-inbox-type-right-all,
.fl-inbox-type-right-file {
    position: absolute;
    margin-left: -80px;
}

.fl-inbox-files {
    display: flex;
    align-items: center;
}

.fl-inbox-files-right .fl-message-bubble-message-attachment {
    order: 2;
}

.fl-message-bubble-main .fl-inbox-single-message-dropdown {
    display: none;
}

.fl-message-bubble-main:hover .fl-inbox-single-message-dropdown,
.fl-message-bubble-main:hover > .fl-inbox-single-message-dropdown {
    display: flex;
}

.fl-inbox-reported {
    opacity: 0.2;
}

.text {
    position: absolute;
    top: -10px;
    z-index: 99;
    min-width: 150px;
    margin-left: 5px;
}

.fl-direct-messages-box-content-inner-animation,
.fl-inbox-gallery-empty {
    display: flex !important;
    align-items: center !important;
    justify-content: center !important;
}

.fl-message-bubble-main-left .fl-message-bubble-message-attachment:not(.fl-settings-sub-stretch .fl-message-bubble-main-left .fl-message-bubble-message-attachment) {
    overflow: unset !important;
}

.fl-inbox-gallery {
    padding: 25px;
}

.fl-messages-single-text-msg {
    display: inline-block;
    text-overflow: revert-layer;
    text-overflow: ellipsis;
    margin-top: 3px;
}

.fl-messages-single-text-msg img {
    width: 13px;
    margin-top: -3px;
    margin-left: 3px;
}

.fl-inbox-preview-audio {
    padding: 20px;
    width: 100%;
}

.fl-inbox-preview-audio .fl-direct-messages-record-wrapper {
    position: initial;
}

.fl-inbox-audio-recording {
    width: 100%;
    height: 50px;
    border-radius: 25px;
    background: #007eff;
    padding: 5px;
    overflow: hidden;
}

.fl-inbox-record {
    padding: 20px;
    display: flex;
    align-items: center;
}

.fl-inbox-record .react-audio-player {
    width: 100%;
}

.fl-inbox-record .fl-messageaudio-main {
    width: 100%;
    height: 50px;
    border-radius: 25px;
}

.fl-inbox-audio-preview {
    height: 40px;
    position: relative;
}

.fl-inbox-record-none,
.fl-inbox-record-stop {
    display: none;
}

.fl-inbox-record-start {
    display: block;
}

.fl-message-bubble-message-text-italic {
    font-style: italic;
}

.fl-message-bubble-message-text-italic img {
    width: 16px;
    margin-top: -4px;
}

.fl-create-form-link {
    text-decoration: none;
    color: #277eff;
}

.fl-create-form-link:hover {
    text-decoration: underline;
}