.fl-postlock__main {
  position: absolute;
  left: 50%;
  top: 50%;
  transform: translate(-50%, -50%);
  display: flex;
  justify-content: center;
  flex-direction: column;
  align-items: center;
  width: 100%;
  max-width: 300px;
  padding: 20px;
  background-color: #888888ad;
  border-radius: 20px;
  z-index: 1;
}

.fl-postlock__text {
  text-align: center;
  color: #fff;
  font-size: 16px;
  padding: 20px;
}

.fl-card-icon-padlock-ppv-unlock {
  display: none;
}

.fl-postlock__main:hover .fl-card-icon-padlock-ppv-unlock,
.fl-message-bubble-message-attachment:hover  .fl-card-icon-padlock-ppv-unlock {
  display: flex;
  width: 100%;
  height: 80px;
}

.fl-postlock__main:hover .fl-card-icon-padlock-ppv,
.fl-message-bubble-message-attachment:hover .fl-card-icon-padlock-ppv {
  display: none;
}

.fl-postlock__text-bold {
  font-weight: 600;
}

.fl-postlock-lock {
  font-size: 60px;
  filter: drop-shadow(0px 0px 4px #00000090);
  width: 130px;
  height: 100px;
  display: flex;
  align-items: center;
  justify-content: center;
  border-bottom: 2px solid #fff;
}

.fl-postlock-lock-logout {
  border-bottom: none;
  padding-bottom: 15px;
}

.fl-postlock-icon * {
  fill: url(#rg);
}

.fl-postlock__main .fl-message-padlock {
  width: 60px;
}

.fl-card-icon-padlock-ppv {
  width: 100% !important;
  height: 80px !important;
}

/* Large devices (desktops, less than 1200px) */
@media (max-width: 1199.98px) {
}

/* Medium devices (tablets, less than 992px) */
@media (max-width: 991.98px) {
}

/* Small devices (landscape phones, less than 768px) */
@media (max-width: 767.98px) {
}

/* Extra small devices (portrait phones, less than 576px) */
@media (max-width: 575.98px) {
}
