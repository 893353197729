.fl-notifications__main {
  width: 100%;
  flex: 1;
  min-height: 100%;
}
.fl-notifications__main--light {
  background-color: #f4f4f4;
}
.fl-notifications__main--dark {
  background-color: #1b1b1b;
}

.fl-notifications__mark {
  font-weight: bold;
}
.fl-notifications__mark:hover {
  text-decoration: underline;
  cursor: pointer;
}

.fl-notifications__title {
  width: 100%;
  font-size: 21px;
  font-weight: 600;
  padding: 10px 0px;
  line-height: 21px;
  display: flex;
  justify-content: space-between;
  align-items: center;
}
.fl-notifications__title--light {
  color: #000;
}
.fl-notifications__title--dark {
  color: #fff;
}
.fl-notifications__title span {
  color: #ff0d72;
  font-size: 14px;
  font-weight: 400;
  cursor: pointer;
}

.fl-notifications__title__dropdown {
  flex: 1;
  justify-content: flex-end;
  display: flex;
  flex-direction: column;
  align-items: flex-end;
  text-align: right;
}

.fl-notifications__title__dropdown svg {
  position: absolute;
  top: 25px;
  color: #888888;
  font-size: 1rem;
  cursor: pointer;
}
.fl-notifications__title__dropdown__items {
  color: #888888;
  font-size: 1rem;
  cursor: pointer;
  font-weight: 400;
  animation: headReadPopup 0.1s forwards;
}
.fl-notifications__title__dropdown__items span:hover {
  text-decoration: underline;
}
@keyframes headReadPopup {
  0% {
    transform: scale(0);
  }
  100% {
    transform: scale(1);
  }
}

.fl-notifications__all {
  margin: 10px 0px;
}
.fl-notifications__all--dark {
  border-top: 1px solid #ffffff20;
}
.fl-notifications__all--light {
  border-top: 1px solid #00000020;
}

.fl-notifications__show-more__link {
  font-size: 18px;
  line-height: 30px;
  text-decoration: none;
  color: #ff0d72;
  cursor: pointer;
  display: flex;
}

.fl-notifications__show-more-loading {
  opacity: 0.5;
  pointer-events: none;
}

.fl-notifications__empty {
  font-size: 22px;
  text-align: center;
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 20px;
  min-height: 200px;
}
.fl-notifications__empty--dark {
  color: #fff;
}

#fl-notifications__container {
  position: fixed;
  bottom: 0;
  right: 0;
  color: #888888;
  z-index: 100;
}
#fl-notifications__container div {
  border: 1px solid #888888;
  padding: 20px;
  margin-bottom: 8px;
  border-radius: 10px;
  animation: notification-slide-in 0.25s forwards;
}
#fl-notifications__container div strong {
  color: #ff0d72;
  cursor: pointer;
}

.fl-notifications__container--light div {
  background-color: #fff;
}
.fl-notifications__container--dark div {
  background-color: #000;
}

@keyframes notification-slide-in {
  0% {
    transform: translateX(100%);
  }
  100% {
    transform: translateX(0);
  }
}

/* Medium devices (tablets, less than 992px) */
@media (max-width: 991.98px) {
  .fl-notifications__tablet--hide {
    display: none;
  }
  .fl-notifications__tablet--show {
    width: 100%;
    display: block;
  }
}

/* Small devices (landscape phones, less than 768px) */
@media (max-width: 767.98px) {
  .fl-notifications__tablet--show {
    padding-bottom: 100px;
  }
}

@media (max-width: 470px) {
  .fl-notifications__title {
    flex-wrap: wrap;
  }
}
