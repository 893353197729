.fl-direct-messages-box {
  display: flex;
  flex-direction: column;
  min-height: inherit;
  padding: 0px;
  height: 100%;
}

.fl-direct-message-drop-files {
  display: none;
  position: absolute;
  width: 100%;
  height: 100%;
  background: #ffffffd9;
  border: 3px solid #888;
  border-style: dashed;
  border-radius: 20px;
  align-items: center;
  text-align: center;
}

.fl-direct-message-drop .fl-direct-message-drop-files {
  display: flex;
}

.fl-direct-message-drop .fl-direct-messages-box-content,
.fl-direct-message-drop .fl-direct-messages-title,
.fl-direct-message-drop .fl-direct-messages-box-input {
  opacity: 0.1;
}

.fl-direct-message-drop-files span {
  width: 100%;
  font-size: 35px;
  color: #888;
  font-weight: 500;
}

/* .fl-direct-messages-box-input {

} */

.fl-direct-messages-box-files {
  width: 100%;
  background-color: #00000005;
  border-bottom: 1px solid #00000015;
  display: flex;
  align-items: center;
  justify-content: space-between;
}

.fl-direct-messages-box-input-form-disabled {
  background-color: #88888820;
}

.fl-direct-messages-title-indicator {
  width: 12px;
  height: 12px;
  border-radius: 50%;
  display: inline-block;
  margin-left: 10px;
}

.fl-direct-messages-box-content-typing {
  margin-left: 55px;
  margin-top: 20px;
  font-size: 12px;
  color: #ff0d72;
  font-weight: 500;
}

.fl-direct-messages-box-content-loader {
  display: flex;
  align-items: center;
  justify-content: center;
}

.fl-direct-messages-title-indicator.fl-direct-messages-title-indicator-online {
  background: #4dd965;
}

.fl-direct-messages-title-indicator.fl-direct-messages-title-indicator-offline {
  background: #8b8d91;
}

.fl-direct-messages-box-files-inner {
  display: flex;
  padding: 8px 0px 8px 0px;
  width: calc(100% - 170px);
  margin: 0px 10px;
  overflow-x: auto;
  overflow-y: hidden;
}
.fl-direct-messages-box-files-inner::-webkit-scrollbar,
.fl-direct-message-blur-preview::-webkit-scrollbar {
  width: 6px;
  height: 6px;
  border-radius: 20px;
}

.fl-direct-messages-box-files-inner::-webkit-scrollbar-track,
.fl-direct-message-blur-preview::-webkit-scrollbar-track {
  background: transparent;
}

.fl-direct-messages-box-files-inner::-webkit-scrollbar-thumb,
.fl-direct-message-blur-preview::-webkit-scrollbar-thumb {
  background: #ff4444;
}

.fl-direct-messages-box-files-inner::-webkit-scrollbar-thumb:hover,
.fl-direct-message-blur-preview::-webkit-scrollbar-thumb:hover {
  background: black;
}

.fl-direct-messages-box-files-price {
  width: 150px;
  padding-left: 20px;
  height: 100%;
  border-left: 1px solid #00000020;
  background-color: #ffffff;
  padding: 10px;
  display: flex;
  flex-direction: column;
  justify-content: center;
}

.fl-direct-messages-box-files-price span {
  font-size: 12px;
  font-weight: 500;
  color: #888;
  margin-bottom: 3px;
}

.fl-direct-messages-box-files-price input {
  width: 100%;
  border: 1px solid #00000020;
  border-radius: 5px;
  height: 40px;
  padding: 0px 10px;
}

.fl-direct-messages-box-files-single {
  width: 80px;
  background-color: #15c;
  height: 60px;
  min-width: 80px;
  margin-right: 10px;
  position: relative;
  border-radius: 3px;
  outline: none;
}

.fl-direct-messages-box-files-single-add-new-files {
  width: 50px;
  height: 60px;
  min-width: 40px;
  background: #cececed9;
  color: #fff;
  display: flex;
  justify-content: center;
  align-items: center;
  cursor: pointer;
  border-radius: 3px;
}
.fl-direct-messages-box-files-single-add-new-files:hover {
  background: #c7c7c7;
}

.fl-direct-messages-box-files-remove {
  width: 15px;
  height: 15px;
  cursor: pointer;
  font-size: 14px;
  color: #ff4444;
  filter: drop-shadow(0px 0px 1px #000000);
  display: flex;
  align-items: center;
  justify-content: center;
  position: absolute;
  right: 0px;
  top: 0px;
}

.fl-direct-messages-box-files-single img,
.fl-direct-messages-box-files-single video {
  width: 100%;
  height: 100%;
  position: absolute;
  object-fit: cover;
  object-position: center;
  border-radius: 3px;
}

.fl-direct-messages-box-files-single:nth-last-of-type(1) {
  /* margin-right: 0px; */
}

.fl-direct-messages-box-input-actions {
  padding: 20px 20px 0px;
  width: 100%;
}

.fl-direct-messages-box-input-form {
  width: 100%;
  padding: 20px;
  position: relative;
}

.fl-direct-messages-box-input-form .fl-post-comment-emojis-picker{
  top: 75px !important;
}

.fl-direct-messages-box-input-form-disabled {
  background-color: #f7f7f7;
  width: 100%;
  padding: 20px;
}

.fl-input-form-blocked-text {
  display: inline-block;
}

.fl-direct-messages-box-input-form-inner {
  width: 100%;
  border-radius: 25px;
  height: 50px;
  align-items: center;
  display: flex;
  padding: 5px;
  position: relative;
}

.fl-post-head-actions-dropdown-option-icon {
  display: inline-block;
}

.fl-direct-messages-box-input-form-inner.fl-direct-messages-box-input-form-inner-dark {
  border: 1px solid #ffffff20;
  color: #fff;
}
.fl-direct-messages-box-input-form-inner.fl-direct-messages-box-input-form-inner-light {
  border: 1px solid #00000020;
  color: #000;
}

.fl-direct-messages-box-input-form-camera,
.fl-report-bug-attach {
  position: relative;
  outline: none;
  width: 40px;
  height: 40px;
  min-width: 40px;
  background: linear-gradient(to bottom, #679dff, #007eff);
  border-radius: 50%;
  color: #fff;
  display: flex;
  justify-content: center;
  align-items: center;
  cursor: pointer;
}
.fl-direct-messages-box-input-form-camera .fl-upload-file-select-bar--option,
.fl-direct-messages-box-input-action-single .fl-upload-file-select-bar--option {
  top: -90px;
}

.fl-direct-messages-box-input-form-camera:hover {
  background: linear-gradient(to bottom, #5886dd, #0067cd);
}

.fl-direct-messages-record-audio-chunks {
  width: 100%;
  padding: 0px 5px;
  height: 100%;
}

.fl-direct-messages-record-audio-chunks canvas {
  height: 40px;
  width: 100%;
}

.fl-direct-messages-box-input-form-input {
  height: 100%;
  /*width: calc(100% - 160px);*/
  width: 100%;
  border: none;
  outline: none;
  background: none;
  padding: 7px 0px 0 10px;
  font-size: 13px;
}

.fl-direct-messages-box-restricted {
  border: 1px solid red !important;
}

.fl-direct-messages-box-input-form-input.fl-direct-messages-box-input-form-input-wide {
  width: calc(100% - 80px);
}

.fl-direct-messages-box-input-form-input.fl-direct-messages-box-input-form-input-dark {
  color: #fff;
}
.fl-direct-messages-box-input-form-input.fl-direct-messages-box-input-form-input-light {
  color: #000;
}

.fl-secure-message-light {
  color: gray;
}

.fl-message-load-more {
  display: flex;
  align-items: center;
  justify-content: center;
  margin-bottom: 1%;
}

.fl-secure-message-dark {
  color: white;
}

.fl-secure-message-header {
  display: flex;
  margin-right: -20px;
  margin-left: -20px;
}

.fl-message-header-back {
  width: 100% !important;
}

.fl-secure-message-header > div {
  width: 35%;
  height: 50%;
  align-self: center;
  border-bottom: solid gray 1px;
}

.fl-secure-message-header > h4 {
  text-align: center;
  overflow: hidden;
  white-space: nowrap;
  width: 30%;
  display: flex;
  margin-top: 21px;
  text-align: center;
  justify-content: center;
}

.fl-secure-message-header + p {
  display: flex;
  font-weight: 500;
  justify-content: center;
  margin-top: -2%;
  font-size: 80%;
  text-align: center;
  margin-bottom: 3%;
}

@keyframes showScale {
  0% {
    transform: scaleY(0);
  }
  100% {
    transform: scaleY(1);
  }
}
.fl-direct-messages-record-audio-chunks-single {
  width: 3px;
  border-radius: 2px;
  min-height: 3px;
  background-color: #fff;
  margin-right: 2px;
  transform: scaleY(0);
  animation: showScale 300ms forwards ease-in-out;
}

.fl-direct-messages-box-input-form-actions {
  display: flex;
  align-items: center;
  padding-left: 50px;
}

.fl-direct-messages-box-input-form-actions-icons {
  position: absolute;
  margin-top: -15px;
  z-index: 99;
}

.fl-direct-messages-box-input-form-actions-icons
  .fl-direct-messages-box-input-action-single {
  background: #f2f1f1ff;
  border-radius: 20px;
}

.fl-direct-messages-box-input-action-single:hover {
  background: #f6f6f6;
  box-shadow: 0px 5px 8px 2px #00000020;
}

.fl-direct-messages-box-input.fl-direct-messages-box-input-dark {
  border-top: 1px solid #ffffff20;
}

.fl-direct-messages-box-input.fl-direct-messages-box-input-light {
  border-top: 1px solid #00000020;
}

.fl-direct-messages-box-content {
  width: 100%;
  flex: 1;
  position: relative;
}
.fl-direct-messages-box-content-inner {
  width: 100%;
  height: 100%;
  position: absolute;
  left: 0px;
  top: 0px;
  padding: 20px 20px 0px 20px;
  display: block;
  overflow-y: scroll;
  overflow-x: hidden;
}
.fl-direct-messages-box-input-text {
  height: 50px;
  margin: 0px;
}
.fl-direct-messages-box-input-text input {
  border-radius: 20px 0px 0px 20px;
  border-right: none;
  border-width: 1px;
}
.fl-direct-messages-box-input-button {
  border-radius: 0px 20px 20px 0px;
  width: 80px;
  height: 50px;
  font-size: 20px;
}

.fl-direct-messages-box-input-text.fl-direct-messages-box-input-text-dark
  input {
  border-color: #ffffff20;
}

.fl-direct-messages-box-input-text.fl-direct-messages-box-input-text-light
  input {
  border-color: #00000020;
}

.fl-direct-messages-title-info {
  width: 100%;
  font-size: 21px;
  font-weight: 500;
  padding: 20px 20px 10px 20px;
  line-height: 21px;
  display: flex;
  justify-content: flex-start;
  align-items: center;
  position: relative;
}

.fl-direct-messages-title-actions {
  padding: 0px 0px 10px 35px;
  font-size: 15px;
  width: 100%;
  color: #888;
}

.fl-direct-messages-title-actions span {
  cursor: pointer;
  padding-right: 15px;
  padding-left: 15px;
}

.fl-direct-messages-title-actions span:first-child {
  border-right: 1px solid #888;
}

.fl-direct-messages-title-options {
  position: absolute;
  right: 10px;
  cursor: pointer;
  top: 50%;
  transform: translateY(-50%);
  width: 30px;
  height: 30px;
  display: flex;
  justify-content: center;
  align-items: center;
  font-size: 20px;
  color: #888;
  z-index: 9;
  outline: none;
}

.fl-direct-messages-title-options .fl-post-head-actions-dropdown-option {
  font-size: 14px;
  font-weight: 400;
}

.fl-sendmoney-row {
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
}

.fl-sendmoney-single {
  width: 100%;
  margin-right: 10px;
  display: flex;
  justify-content: center;
  align-items: center;
  border-radius: 5px;
  padding: 15px 10px 10px;
  cursor: pointer;
}
.fl-sendmoney-single.fl-sendmoney-single-dark {
  background-color: #ffffff20;
  border: 2px solid #ffffff40;
  color: #fff;
}
.fl-sendmoney-single.fl-sendmoney-single-light {
  background-color: #efefef;
  border: 2px solid #888;
  color: #000;
}
.fl-sendmoney-single-selected {
  color: #fff !important;
  background-color: #ff0d72 !important;
  border: 2px solid #ff4444 !important;
}
.fl-sendmoney-single.fl-sendmoney-single-dark:hover {
  background-color: #ffffff30;
  border: 2px solid #ffffff40;
  color: #fff;
}
.fl-sendmoney-single.fl-sendmoney-single-light:hover {
  background-color: #dfdfdf;
  border: 2px solid #888;
  color: #000;
}
.fl-sendmoney-single:last-child {
  margin-right: 0px;
}

.fl-direct-messages-title.fl-direct-messages-title-light {
  color: #000;
  border-bottom: 2px solid #00000020;
}
.fl-direct-messages-title.fl-direct-messages-title-dark {
  color: #fff;
  border-bottom: 2px solid #ffffff20;
}

.fl-direct-messages-box-empty {
  display: flex;
  flex: 1;
  justify-content: center;
  align-items: center;
  font-size: 26px;
  flex-direction: column;
}

.fl-direct-messages-box-empty-dark {
  color: white;
}

.fl-direct-messages-box-empty-button {
  padding-top: 40px;
}

.fl-direct-messages-box-empty-button a {
  height: 60px;
  border-radius: 40px;
}

.fl-direct-messages-box-empty-icon {
  font-size: 64px;
  margin-bottom: 15px;
  color: #ff0d72;
}

.fl-direct-messages-box-empty-sub {
  font-size: 10px;
  text-transform: uppercase;
  color: #888;
  font-weight: 500;
  margin-top: 5px;
}

.fl-direct-messages-title-icon {
  margin-right: 10px;
  color: #ff0d72 !important;
  cursor: pointer;
}

.fl-direct-messages-box-input-actions-inner {
  display: flex;
  width: 100%;
  height: 30px;
}

.fl-direct-messages-box-input-action-single {
  width: 30px;
  height: 30px;
  min-width: 30px;
  font-size: 20px;
  display: flex;
  justify-content: center;
  align-items: center;
  margin-right: 10px;
  color: #a8a7a7;
  position: relative;
  user-select: none;
}

.fl-voice-record-cursor-enable {
  cursor: pointer;
}

.fl-direct-messages-box-input-action-single-send {
  color: #ff0d72;
  cursor: pointer;
  border: none;
  background: none;
}

.fl-modal-range {
  padding: 10px 40px 20px 40px;
}

.fl-modal-description-range,
.fl-post-images-swipe-button {
  outline: none;
}

.fl-direct-messages-box-input-action-single-voice {
  position: relative;
  z-index: 1;
  opacity: 1;
  transition: opacity 300ms ease-in-out;
  touch-action: none;
}
/* .fl-direct-messages-box-input-action-single-voice:drop */

.fl-direct-messages-box-input-action-single-voice-hide {
  opacity: 0;
}

.fl-range-modal-align-center {
  text-align: center;
}

.fl-direct-messages-record-wrapper {
  width: 100%;
  height: 50px;
  top: 0px;
  left: 0px;
  border-radius: 25px;
  background: #007eff;
  opacity: 0;
  pointer-events: none;
  transition: opacity 300ms ease-in-out;
  padding: 5px;
  position: absolute;
  overflow: hidden;
}

.fl-mobile .fl-direct-messages-record-wrapper {
  left: 0px;
  top: 0px;
  width: 100%;
  height: 100%;
  border-radius: 50px;
}

.fl-direct-messages-record-wrapper-inner {
  position: relative;
  width: 100%;
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: space-between;
}

.fl-direct-messages-record-wrapper::before {
  position: absolute;
  left: 0px;
  top: 0px;
  width: 100%;
  height: 100%;
  background: linear-gradient(to bottom, rgb(255, 94, 94), rgb(255, 42, 42));
  opacity: 0;
  content: "";
  transition: 300ms opacity ease-in-out;
}

.fl-direct-messages-record-wrapper-deleting::before {
  opacity: 1;
}

.fl-direct-messages-record-delete {
  background-color: #fff;
  color: #00000080;
  display: flex;
  justify-content: center;
  align-items: center;
  font-size: 12px;
  width: 20px;
  height: 20px;
  border-radius: 2px;
  cursor: pointer;
}

.fl-mobile .fl-direct-messages-record-delete {
  width: 40px;
  height: 40px;
  font-size: 16px;
  border-radius: 50%;
}

@keyframes shake {
  0% {
    transform: rotate(0deg);
  }
  33% {
    transform: rotate(-30deg);
  }
  66% {
    transform: rotate(30deg);
  }
  100% {
    transform: rotate(0deg);
  }
}
.fl-direct-messages-record-wrapper-deleting
  .fl-direct-messages-record-delete
  > * {
  animation: shake 1000ms infinite linear;
}

.fl-direct-messages-record-timer {
  width: 50px;
  height: 40px;
  display: flex;
  align-items: center;
  justify-content: center;
  color: #fff;
  font-size: 10px;
  font-weight: bold;
}

.fl-direct-messages-record-wrapper-active {
  opacity: 1;
  pointer-events: all;
}

@keyframes showCamera {
  0% {
    transform: scale(0);
    opacity: 0;
  }
  100% {
    transform: scale(1);
    opacity: 1;
  }
}

.fl-messages-camera-main {
  width: 100%;
  height: 100%;
  left: 0px;
  top: 0px;
  position: fixed;
  z-index: 1000000;
  background-color: #000;
  transform: scale(0);
  opacity: 0;
  animation: showCamera 300ms forwards ease-in-out;
}

.fl-messages-camera-main video {
  left: 0px;
  top: 0px;
  width: 100%;
  height: 100%;
  position: absolute;
  object-fit: cover;
  object-position: center;
  transform: scaleX(-1);
}

.fl-messages-camera-controls {
  width: 100%;
  height: 100%;
  position: relative;
  padding: 20px;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
}

.fl-messages-camera-controls-bottom {
  display: flex;
  width: 100%;
  justify-content: space-between;
  align-items: center;
  padding: 0px 20px;
}

.fl-messages-camera-controls-top {
  display: flex;
  width: 100%;
  justify-content: space-between;
  align-items: center;
}

.fl-messages-camera-controls-single {
  color: #ffffffa0;
  cursor: pointer;
  font-size: 26px;
  width: 30px;
  height: 30px;
  display: flex;
  justify-content: center;
  align-items: center;
}

.fl-messages-camera-controls-snap {
  width: 80px;
  height: 80px;
  border-radius: 50%;
  border: 3px solid #fff;
}

.fl-direct-messages-box-input-action-single {
  background: #f2f1f1ff;
  border-radius: 20px;
}

.fl-direct-messages-box-input-action-single-send:hover {
  color: #ca0a5a;
}

.fl-direct-messages-box-input-actions-single:nth-last-of-type(1) {
  margin-right: 0px;
}

.fl-direct-messages-sender-profile {
  text-decoration: none;
}

.fl-direct-messages-title-dark .fl-direct-messages-sender-profile {
  color: #ffffff;
}

.fl-direct-messages-title-light .fl-direct-messages-sender-profile {
  color: #000;
}

.fl-direct-messages-sender-profile-handle-light {
  color: #888;
  text-decoration: none;
  margin-left: 5px;
}

.fl-direct-messages-sender-profile-handle-dark {
  color: #fff;
  text-decoration: none;
  margin-left: 5px;
}

.fl-direct-messages-box-files-single-remove {
  width: 18px;
  height: 18px;
  border-radius: 50%;
  margin-left: 5px;
  border: 1px solid #ff0d72;
  display: flex;
  justify-content: center;
  align-items: center;
  font-size: 10px;
  background-color: #ff0d72;
  cursor: pointer;
  position: absolute;
  top: 3px;
  right: 3px;
  animation: remove-preview-popup 0.25s forwards;
}

.fl-direct-messages-box-files-single-remove svg {
  color: white;
}

/*.fl-direct-messages-box-files-single:hover*/
/*  > .fl-direct-messages-box-files-single-remove {*/
/*  display: flex;*/
/*}*/

.fl-message-bubble-message-attachment-unlock:hover
  > .fl-direct-messages-box-files-single-remove {
  display: flex;
}

.fl-direct-messages-box-files-single-remove:hover {
  background: white;
  border: none;
}

.fl-direct-messages-box-files-single-remove:hover > svg {
  color: #ff0d72;
}

.fl-direct-messages-box-input-true {
  pointer-events: none;
  opacity: 0.5;
}

.fl-direct-messages-box-input-action-single-send-disabled {
  pointer-events: none;
  opacity: 0.5;
}

.fl-direct-message-disable-send {
  pointer-events: none;
  color: #888;
}

.fl-bulk__price__toggle-blurred {
  padding-bottom: 5px;
}

.fl-direct-message-subscribe,
.fl-direct-message-resubscribe {
  color: #ff0d72;
  text-decoration: underline;
  cursor: pointer;
}

.fl-direct-message-resubscribe {
  display: none;
}

.fl-direct-message-subscribe:hover .fl-direct-message-resubscribe {
  display: -webkit-inline-flex;
  text-decoration: none;
}

.fl-direct-message-subscribe:hover {
  text-decoration: none;
}

.fl-direct-messages-box-input-form-enabled {
  pointer-events: auto;
}

.fl-bulk-blur {
  color: #15c !important;
  cursor: pointer;
  text-decoration: underline;
  outline: none;
}

.fl-bulk-blur:hover {
  text-decoration: none;
}

.fl-direct-message-range-minimum {
  float: left;
  color: #ff0d72;
  font-weight: 500;
}

.fl-direct-message-range-maximum {
  float: right;
  color: #ff0d72;
  font-weight: 500;
}

.fl-direct-message-blur-preview {
  display: flex;
  padding: 8px 0px 2px 0px;
  width: 100%;
  margin: 0px 10px;
  overflow-x: auto;
  overflow-y: hidden;
  padding-top: 40px;
  justify-content: center;
  align-items: center;
}

.fl-direct-message-blur-preview img {
  width: 180px;
  height: 200px;
  object-fit: cover;
  border-radius: 5px;
}

.fl-direct-message-blur-preview-file {
  cursor: pointer;
  padding-right: 10px;
  padding-left: 10px;
}

.fl-direct-messages-box-input-action-single-voice-record svg {
  color: red;
  -webkit-animation: flashIcon linear 1s infinite;
  animation: flashIcon linear 1s infinite;
}

.fl-recording-voice svg {
  -webkit-animation: flashIcon linear 1s infinite;
  animation: flashIcon linear 1s infinite;
}

.fl-direct-message-send-recording:hover {
  color: #ff0d72;
}

.fl-direct-message-send-recording {
  color: #15c;
}

.fl-direct-messages-box-files-single {
  cursor: pointer;
}

.fl-post-images-swipe-button-preview-next,
.fl-post-images-swipe-button-preview-prev {
  color: #ff0d72;
}

.fl-post-images-swipe-button-preview-next:hover,
.fl-post-images-swipe-button-preview-prev:hover {
  border-radius: 9999px;
  background-color: #888;
  color: white;
}

.fl-bulk__media__icons .fl-direct-messages-box-input-action-single,
.fl-direct-messages-box-input-form-actions
  .fl-direct-messages-box-input-action-single {
  cursor: pointer;
  outline: none;
}

@-webkit-keyframes flashIcon {
  0% {
    opacity: 1;
  }
  50% {
    opacity: 0.1;
  }
  100% {
    opacity: 1;
  }
}
@keyframes flashIcon {
  0% {
    opacity: 1;
  }
  50% {
    opacity: 0.1;
  }
  100% {
    opacity: 1;
  }
}

.fl-direct-messages-record-audio-chunks wave {
  display: none !important;
}

.fl-direct-messages-box-input-form-inner-preview {
  padding: 0px;
}

.fl-direct-messages-box-input-form-inner-preview .fl-messageaudio-main {
  width: 100%;
  height: 50px;
  border-radius: 25px;
}

.fl-direct-messages-box-input-action-single-voice-stop {
  font-size: 25px;
  color: white;
  padding-left: 5px;
}

.fl-messages-one-message {
  position: relative;
}

.fl-messages-single-message-delete {
  display: none;
  position: absolute;
  right: 12px;
  top: 35%;
  width: 24px;
  height: 24px;
  justify-content: center;
  align-items: center;
  background: #888;
  border-radius: 20px;
  color: white;
  cursor: pointer;
}

.fl-messages-single-message-ban-delete {
  display: none;
  position: absolute;
  right: 12px;
  top: 55%;
  width: 24px;
  height: 24px;
  justify-content: center;
  align-items: center;
  background: #888;
  border-radius: 20px;
  color: white;
  cursor: pointer;
}

.fl-messages-single-message-delete-ban {
  display: none;
  position: absolute;
  right: 12px;
  top: 18%;
  width: 24px;
  height: 24px;
  justify-content: center;
  align-items: center;
  background: #888;
  border-radius: 20px;
  color: white;
  cursor: pointer;
}

.fl-list-messages-mute,
.fl-list-messages-ban {
  color: #888;
}

.fl-messages-single-active .fl-list-messages-mute,
.fl-messages-single-active .fl-list-messages-ban {
  color: white;
}

.fl-message-option-delete {
  top: 60px;
  right: 5px;
  position: absolute;
  z-index: 99;
}

.fl-messages-one-message-active .fl-messages-single-message-delete {
  background: white;
  color: #ff0d72;
}

.fl-single-message {
  position: relative;
  cursor: pointer;
}

.fl-single-message .fl-messages-single-date-msg {
  position: absolute;
  right: 0;
  top: 15px;
  right: 10px;
}

.fl-direct-messages-box-input-form-recording {
  border: none !important;
}

.fl-direct-messages-box-delete-record,
.fl-direct-messages-box-send-record {
  min-width: 28px;
  min-height: 28px;
  max-height: 28px;
  max-width: 28px;
  font-size: 16px;
  display: flex;
  justify-content: center;
  align-items: center;
  cursor: pointer;
  border-radius: 20px;
}

.fl-direct-messages-box-delete-record {
  margin-right: 5px;
  background: #888;
  color: white;
}

.fl-direct-messages-box-send-record {
  margin-left: 5px;
}

.fl-direct-messages-box-send-record {
  color: #277eff;
}

.fl-direct-messages-box-send-record svg {
  width: 100% !important;
  height: 100%;
}

.fl-direct-messages-title-actions-gallery {
  color: #ff0d72;
}

.fl-direct-messages-title-actions-search {
  background-color: #00000010;
  border: none;
  color: #000;
  padding: 5px 150px 0px 55px;
  border-radius: 0px;
  height: 35px;
  width: 100%;
  outline: none;
}

.fl-direct-messages-title-actions-show-search {
  padding: 0px;
  position: relative;
}

.fl-direct-messages-title-actions-search-clear,
.fl-direct-messages-title-actions-search-button {
  position: absolute;
  top: 16.5px;
  width: 45px;
  height: 30px;
  display: flex;
  align-items: center;
  justify-content: center;
  font-size: 16px;
  transform: translateY(-50%);
  cursor: pointer;
}

.fl-direct-messages-title-actions-search-count {
  right: 105px;
  position: absolute;
  top: 18.5px;
  display: flex;
  align-items: center;
  justify-content: center;
  font-size: 16px;
  transform: translateY(-50%);
  cursor: pointer;
}

.fl-direct-messages-title-actions-search-clear {
  left: 5px;
}

.fl-direct-messages-title-actions-search-button {
  right: 15px;
  top: 18.5px;
  font-size: 12px;
  height: 20px;
}

.fl-direct-messages-filter-media span:nth-child(1),
.fl-direct-messages-filter-media span:nth-child(2) {
  border-right: 1px solid #888;
}

.fl-direct-messages-filter-media-active {
  font-weight: 500;
  color: #ff0d72;
}

/* Large devices (desktops, less than 1200px) */
@media (max-width: 1199.98px) {
}

/* Medium devices (tablets, less than 992px) */
@media (max-width: 991.98px) {
}

/* Small devices (landscape phones, less than 768px) */
@media (max-width: 767.98px) {
  /* .fl-direct-messages-box-input-form {
    padding: 10px;
  } */
  .fl-direct-messages-box-input-form-input {
    font-size: 16px;
  }
}

/* Extra small devices (portrait phones, less than 576px) */
@media (max-width: 575.98px) {
}

@media (max-width: 455px) {
  .fl-direct-messages-title-indicator {
    margin-left: 4px;
    margin-bottom: 2px;
  }
  .fl-direct-messages-title-info {
    font-size: 17px;
  }
  .fl-direct-messages-title-options {
    top: 33%;
  }
}

/* for even smaller devices */
@media (max-width: 300px) {
  .fl-secure-message-header > h4 {
    overflow: visible;
    white-space: normal;
  }
}
