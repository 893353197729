.fl-banner-main {
    width: 100%;
    height: calc(100vh - 50px);
    min-height: 800px;
    position: relative;
    margin-top: -90px;
    /*overflow: hidden;*/
    /*max-height: 900px;*/
}

.fl-banner-inner {
    display: flex;
    position: relative;
    padding: 20px;
    align-items: center;
    height: 100%;
    max-height: 1000px;
}

.fl-banner-background {
    position: absolute;
    right: 0px;
    top: 0px;
    width: 100%;
    overflow: hidden;
    height: 100%;
}

.fl-banner-background-circle-orange {
    display: block;
    width: 57%;
    padding-top: 53%;
    background: linear-gradient(to bottom, #FFB659, #FFB659);
    border-radius: 50% 50% 50% 50% / 60% 60% 40% 40%;
    transform: rotate(37deg) translate(-15%, -40%);
    position: absolute;
    right: 0px;
    top: 0px;
}

.fl-banner-background-circle-pink {
    display: block;
    width: 44%;
    padding-top: 60%;
    background: linear-gradient(to bottom, #FF027D, #FF4443);
    border-radius: 50% 50% 50% 50% / 60% 60% 40% 40%;
    transform: rotate(45deg) translate(-40%, -30%);
    position: absolute;
    right: 0px;
    top: 0px;
}

.fl-banner-heading {
    font-size: 40px;
    font-weight: 600;
    line-height: 60px;
}

.fl-banner-heading-dark {
    color: #fff;
}

.fl-banner-heading-light {
    color: #000;
}

.fl-banner-description {
    font-size: 21px;
    line-height: 35px;
    font-weight: 400;
    padding: 20px 0px;
    width: 100%;
}

.fl-banner-description.fl-banner-description-light {
    color: #8C8895;
}

.fl-banner-description.fl-banner-description-dark {
    color: #8C8895;
}

.fl-banner-images {
    position: relative;
    width: 100%;
}

.fl-banner-image-first {
    width: 70%;
    position: absolute;
    transform: translate(50%,-70%);
}
.fl-banner-image-second {
    width: 70%;   
    position: absolute;
    transform: translate(10%,-0%); 
}

.fl-banner-col-content {
    position: relative;
    top:5%;
}

/* Large devices (desktops, less than 1200px) */
@media (max-width: 1199.98px) { 
    .fl-banner-heading {
        font-size: 36px;
        line-height: 42px;
    }
    .fl-banner-description {
        font-size: 16px;
        line-height: 22px;
    }
 }

/* Medium devices (tablets, less than 992px) */
@media (max-width: 991.98px) {
    /*.fl-banner-inner {*/
    /*   margin: 80px auto !important;*/
    /*}*/
    .fl-banner-main {
        height: auto;
        margin-top: -100px;
        padding-top: 80px;

    }
    .fl-banner-col-content {
        margin: 0px auto;
        align-items: center;
        flex-direction: column;
        display: flex;
        order: 2;
        width: 100% !important;
        max-width: 400px;
        top:0px;
    }
    .fl-banner-col-img {
        order: 1;
        width: 100% !important;
        max-width: 400px;
    }
    .fl-banner-heading {
        text-align: center;
    }
    .fl-banner-description {
        text-align: center;
    }
    .fl-banner-inner {
        flex-direction: column;
        justify-content: center;
    }
    .fl-banner-image-first {
        display: none;
    }
    .fl-banner-image-second {
        position: static;
        width: 400px;
        transform: none;
    }
    .fl-banner-images {
        /* display: flex; */
        justify-content: center;
    }

 }

/* Small devices (landscape phones, less than 768px) */
@media (max-width: 767.98px) { 

}

/* Extra small devices (portrait phones, less than 576px) */
@media (max-width: 575.98px) { 
    .fl-banner-image-second {
        width: 100%;
    }
    .fl-banner-main {
        margin-top: 0px;
    }
 }