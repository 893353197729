.fl-settings-sub-appearance-dark-mode {
  display: flex;
  align-items: center;
  padding: 10px 0px;
}

.fl-settings-sub-appearance-dark-mode span {
  margin-left: 5px;
}

.fl-settings-sub-appearance-dark-mode.fl-settings-sub-appearance-dark-mode-dark
  span {
  color: #fff;
}

.fl-settings-sub-appearance-dark-mode.fl-settings-sub-appearance-dark-mode-light
  span {
  color: #000;
}

.fl-settings-sub-security-button-true {
  pointer-events: none;
  opacity: 0.5;
}

/* Large devices (desktops, less than 1200px) */
@media (max-width: 1199.98px) {
}

/* Medium devices (tablets, less than 992px) */
@media (max-width: 991.98px) {
}

/* Small devices (landscape phones, less than 768px) */
@media (max-width: 767.98px) {
}

/* Extra small devices (portrait phones, less than 576px) */
@media (max-width: 575.98px) {
}
