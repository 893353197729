.fl-preregister-main {
    width: 100%;
    flex: 1;
}

.fl-preregister-content {
    width: 100%;
    overflow: hidden;
}


.fl-preregister-img {
    max-width: 70%;
    max-height: unset !important;
    margin-right: 15%;
    margin-right: 15%;
    width: 100%;
    height: 100%;
}

.fl-preregister-form {
    padding-bottom: 50px;
}


.fl-preregister-icons {
    width: 100%;
    display: flex;
    justify-content: space-between;
    padding: 20px 10px 30px;
    border-radius: 10px;
    flex-wrap: wrap;
}

.fl-preregister-icon {
    width: calc(100% / 3);
    display: flex;
    align-items: center;
    flex-direction: column;
    align-items: center;
    text-align: center;
    position: relative;
    padding: 30px 0px;
}

.fl-preregister-icon::after {
    position: absolute;
    width: 30%;
    bottom: 20px;
    left: 50%;
    transform: translate(-50%,0);
    height: 3px;
    border-radius: 3px;
    background:#ff2b58;
    content: '';
}

.fl-preregister-icon-fa {
    font-size: 32px;
    margin-bottom: 15px;
    color: #ff2b58;
}

.fl-preregister-icon-img {
    height: 32px;
    margin-bottom: 15px;
}

.fl-preregister-icon span {
    font-size: 16px;
    font-weight: 500;
}

.fl-preregister-icon span.fl-preregister-icon-light {
    color: #000;
}

.fl-preregister-icon span.fl-preregister-icon-dark {
    color: #fff;
}

.fl-preregister-icons-main {
    width: 100%;
    padding: 50px 0px;
}

.fl-preregister-icons-main.fl-preregister-icons-main-light {
    background-color: #efefef;
}

.fl-preregister-icons-main.fl-preregister-icons-main-dark {
    background-color: #1B1B1B;
}

/* Large devices (desktops, less than 1200px) */
@media (max-width: 1199.98px) {
    
}

/* Medium devices (tablets, less than 992px) */
@media (max-width: 991.98px) {
    .fl-preregister-img {
        max-width: 40vw;
    }
}

/* Small devices (landscape phones, less than 768px) */
@media (max-width: 767.98px) {  }

/* Extra small devices (portrait phones, less than 576px) */
@media (max-width: 575.98px) {
    .fl-preregister-icon {
        width: 50%;
    }
    /* .fl-preregister-icons {
        display: block;
    }
    .fl-preregister-icon {
        flex-direction: row;
        width: 100%;
        padding: 5px 0px;
    }
    .fl-preregister-icon-fa {
        width: 26px !important;
        font-size: 20px;
        display: block;
        margin-right: 8px;
        margin-bottom: 0px;
    }
    .fl-preregister-icon-img {
        width: 26px !important;
        height: 20px;
        object-fit: contain;
        margin-right: 8px;
        margin-bottom: 0px;
        object-position: center;
    }
    .fl-preregister-icon::after {
        display: none;
    } */
    .fl-preregister-form {
        padding: 20px 0px;
    }
    .fl-preregister-icon span {
        width: 80%;
    }
}