.fl-settings-verification-file-with-image {
    width: 100%;
    display: flex;
    align-items: center;
}

.fl-settings-verification-file {
    width: calc(100% - 330px);
}
.fl-settings-verification-forms-file {
    width: calc(100% - 50px);
}
.fl-settings-verification-forms-remove {
    width: 30px;
    font-size: 24px;
    color: #FF0D72;
    display: flex;
    align-items: center;
    justify-content: center;
}

.fl-settings-verification-title,
.fl-settings-verification-top-text {
    color: #888 !important;
}

.fl-settings-verification-forms-list{
    text-align: left;
    list-style-position: inside;
    list-style: none;
    color: #888;
}

.fl-settings-verification-forms-list span {
    margin-left: 3px;
    font-weight: 400 !important;
}
.fl-settings-verification-forms-list-gray {
    color: gray;
}

.fl-settings-verification-forms-header {
    font-weight: 600;
    color: black;
}

.fl-settings-verification-image {
    width: 100%;
    display: flex;
    align-items: center;
    padding: 10px;
    justify-content: space-between;
}

.fl-settings-verification-image-src {
    width: 130px;
}

.fl-settings-verification-image-description {
    width: calc(100% - 140px);
}

.fl-settings-verification-image-title {
    color: #25de25;
    font-size: 16px;
    font-weight: 600;
}

.fl-settings-verification-image-text {
    color: #888;
    font-size: 15px;
    padding-top: 8px;
}

.fl-settings-verification-image-text ul li {
    margin: 2px 0;
}

.fl-settings-verification-image-text span {
    font-weight: 500;
}

.fl-settings-verification-info {
    font-size: 16px;
    margin-bottom: 30px;
    color: #FF0D72;
}

.fl-settings-sub-verification-checkbox {
    padding-top: 10px;
    display: flex;
    align-items: center;
    margin-bottom: 20px;
}

.fl-settings-sub-verification-checkbox-input {
    width: 18px;
    height: 18px;
    border-radius: 50%;
    border: 1px solid #888;
    display: inline-block;
    position: relative;
    cursor: pointer;
    margin-right: 3px;
}
.fl-settings-sub-verification-checkbox-input input ~ span::before {
    width: 100%;
    height: 100%;
    content: '';
    position: absolute;
    background-color: #FF0D72;
    transform: scale(0);
    border-radius: 50%;
    transition: 150ms transform ease-in-out;
}

.fl-settings-sub-verification-checkbox-input input:checked ~ span::before {
    transform: scale(1.2);
}

.fl-settings-note {
    color: #FF0D72;
    margin-top: 10px;
    margin-bottom: 10px;
    font-size: 14px;
}

.fl-settings-sub-verification-checkbox-input input ~ span::after {
    width: 8px;
    position: absolute;
    height: 4px;
    top: 50%;
    left: 50%;
    border-bottom: 2px solid #fff;
    border-left: 2px solid #fff;
    transform: translate(-50%,-70%) rotate(-45deg) scale(0);
    content: '';
    transition: 150ms transform ease-in-out;
}

.fl-settings-sub-verification-checkbox-input input:checked ~ span::after {
    transform: translate(-50%,-70%) rotate(-45deg) scale(1);
}

.fl-settings-sub-verification-checkbox-text {
    margin-top: 4px;
    color: #888;
}

.fl-settings-sub-verification-checkbox-input input {
    display: none;
}

.fl-settings-verification-add-files-button {
    margin-top: 20px;
}

.fl-settings-sub-verification-country-img {
    width: 16px;
    height: 16px;
    border-radius: 50%;
    overflow: hidden;
    margin-right: 8px;
}

.fl-settings-sub-verification-country-img img {
    width: 100%;
    height: 100%;
    object-fit: cover;
    object-position: center;
}

.fl-settings-verification-image-event {
    cursor: pointer;
    display: block;
}

.fl-settings-sub-verification-age.fl-settings-sub-verification-age-dark  {
    color: #fff;
}
.fl-settings-sub-verification-age.fl-settings-sub-verification-age-light  {
    color: #000;
}

.fl-settings-verification-top-text {
    display: none;
    padding: 10px 0px;
    font-size: 12px;
    font-weight: 500;
}

.fl-settings-sub-verification-other {
    position: relative;
}

.fl-settings-sub-verification-add-other {
    font-size: 14px;
    color: #FF0D72;
    cursor: pointer;
    margin-top: 10px;
}

.fl-settings-sub-verification-other-remove {
    width: 20px;
    height: 20px;
    right: 10px;
    top: 50%;
    transform: translateY(-50%);
    display: flex;
    align-items: center;
    color: #FF0D72;
    justify-content: center;
    position: absolute;
    cursor: pointer;
}

.fl-settings-sub-verification-other-input .fl-minput-input-inner {
    padding-right: 40px;
}

.fl-settings-verification-top-text.fl-settings-verification-top-text-dark {
    color: #fff;
}
.fl-settings-verification-top-text.fl-settings-verification-top-text-white {
    color: #000;
}

.fl-settings-reject {
    padding-bottom: 20px;
}

.fl-settings-reject b {
    color: red;
    font-weight: 600;
}

/* Large devices (desktops, less than 1200px) */
@media (max-width: 1199.98px) {

}

/* Medium devices (tablets, less than 992px) */
@media (max-width: 991.98px) { 
    .fl-settings-verification-file-with-image {
        display: flex;
        flex-wrap: wrap;
    }
    .fl-settings-verification-file {
        width: 100%;
    }
    .fl-settings-verification-image {
        width: 100%;
    }
    .fl-settings-verification-top-text {
        display: block;
        order: 1;
    }
    .fl-settings-verification-file {
        order: 3;
    }
    .fl-settings-verification-image {
        order: 2;
    }

}

/* Small devices (landscape phones, less than 768px) */
@media (max-width: 767.98px) { 
    
 }

/* Extra small devices (portrait phones, less than 576px) */
@media (max-width: 575.98px) {
    .fl-settings-verification-image {
        display: block;
    }
    .fl-settings-verification-image-src {
        width: 100%;
    }
    .fl-settings-verification-image-description {
        width: 100%;
    }
    .fl-settings-verification-image {
        padding: 10px 0;
    }
    .fl-settings-verification-image-text ul,
    .fl-settings-verification-forms-list-gray {
        padding-inline-start: 15px;
    }
    .fl-settings-verification-forms-list {
        padding-inline-start: 0px;
    }
}
