.fl-simple-card-outter {
  /*perspective: 2000px;*/
  width: 100%;
  min-height: inherit;
}

.fl-simple-card-main {
  border-radius: 20px;
  display: block;
  width: 100%;
  padding: 20px;
  /*animation: remove-preview-popup 0s forwards;*/
  position: relative;
}

.fl-simple-card-main:not(.fl-post-head-actions-dropdown .fl-simple-card-main ) {
  margin-bottom: 30px;
}

.fl-simple-card-main.fl-simple-card-main-light {
  background-color: #fff;
  box-shadow: 0px 0px 8px 2px #00000008;
}

.fl-simple-card-main.fl-simple-card-main-dark {
  background-color: #000;
  box-shadow: 0px 0px 8px 2px #00000008;
}

.fl-simple-card-main-dark.fl-post-0,
.fl-simple-card-main-dark .fl-post-images-row,
.fl-simple-card-main-dark  .fl-post-images-row .vjs-poster,
.fl-simple-card-main-dark  .fl-post-images-row .video-js{
  background-color: #535353 !important;
}

.fl-simple-card-main-light .fl-post-images-row,
.fl-simple-card-main-light  .fl-post-images-row .vjs-poster,
.fl-simple-card-main-light  .fl-post-images-row .video-js{
  background-color: #e6e5e5 !important;
}

.fl-simple-card-main-remove {
  animation: none;
}

/* Large devices (desktops, less than 1200px) */
@media (max-width: 1199.98px) {
}

/* Medium devices (tablets, less than 992px) */
@media (max-width: 991.98px) {
}

/* Small devices (landscape phones, less than 768px) */
@media (max-width: 767.98px) {
}

/* Extra small devices (portrait phones, less than 576px) */
@media (max-width: 575.98px) {
}
