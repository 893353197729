@font-face {
    font-family: "Objectivity";
    src: url("./Objectivity-Thin.otf");
    font-weight: 100;
    font-style: normal;
}
@font-face {
    font-family: "Objectivity";
    src: url("./Objectivity-ThinSlanted.otf");
    font-weight: 100;
    font-style: italic;
}
@font-face {
    font-family: "Objectivity";
    src: url("./Objectivity-Light.otf");
    font-weight: 300;
    font-style: normal;
}
@font-face {
    font-family: "Objectivity";
    src: url("./Objectivity-LightSlanted.otf");
    font-weight: 300;
    font-style: italic;
}
@font-face {
    font-family: "Objectivity";
    src: url("./Objectivity-Regular.otf");
    font-weight: 400;
    font-style: normal;
}
@font-face {
    font-family: "Objectivity";
    src: url("./Objectivity-RegularSlanted.otf");
    font-weight: 400;
    font-style: italic;
}
@font-face {
    font-family: "Objectivity";
    src: url("./Objectivity-Medium.otf");
    font-weight: 500;
    font-style: normal;
}
@font-face {
    font-family: "Objectivity";
    src: url("./Objectivity-MediumSlanted.otf");
    font-weight: 500;
    font-style: italic;
}
@font-face {
    font-family: "Objectivity";
    src: url("./Objectivity-Bold.otf");
    font-weight: 600;
    font-style: normal;
}
@font-face {
    font-family: "Objectivity";
    src: url("./Objectivity-BoldSlanted.otf");
    font-weight: 600;
    font-style: italic;
}
@font-face {
    font-family: "Objectivity";
    src: url("./Objectivity-ExtraBold.otf");
    font-weight: 700;
    font-style: normal;
}
@font-face {
    font-family: "Objectivity";
    src: url("./Objectivity-ExtraBoldSlanted.otf");
    font-weight: 700;
    font-style: italic;
}
@font-face {
    font-family: "Objectivity";
    src: url("./Objectivity-Black.otf");
    font-weight: 800;
    font-style: normal;
}
@font-face {
    font-family: "Objectivity";
    src: url("./Objectivity-BlackSlanted.otf");
    font-weight: 800;
    font-style: italic;
}
@font-face {
    font-family: "Objectivity";
    src: url("./Objectivity-Super.otf");
    font-weight: 900;
    font-style: normal;
}
@font-face {
    font-family: "Objectivity";
    src: url("./Objectivity-SuperSlanted.otf");
    font-weight: 900;
    font-style: italic;
}