.fl-purchased-files {
    display: flex;
    flex-wrap: wrap;
}

.fl-purchased-file-single {
    grid-template-columns: repeat(auto-fit, minmax(200px, 1fr));
}

.fl-purchased-file-mass {
    grid-template-columns: repeat(auto-fit, minmax(250px, 1fr));
}

.fl-purchased-content {
    height: 100%;
    display: flex;
    flex-wrap: wrap;
    /*overflow: hidden;*/
    justify-content: space-between;
    align-content: space-between;
    position: relative;
    cursor: pointer;
}

.fl-purchased-file {
    outline: none;
    width: 29.3%;
    margin: 2% 2% 5%;
    height: 330px;
}

.fl-purchased-content-single  {
    height: 100%;
    position: relative;
    background-color: #414141;
    color: #fff;
    font-size: 26px;
    display: flex;
    align-items: center;
    justify-content: center;
    outline: none;
}

.fl-purchased-content-single .fl-smooth-video {
    height: 330px;
}

.fl-purchased-content-single img {
    width: 100%;
    height: 100%;
    object-fit: cover;
    object-position: center;
}

.fl-purchased-content-single-avatar {
    width: 30px;
    height: 30px;
    border-radius: 50%;
    overflow: hidden;
    z-index: 1;
    display: block;
    position: absolute;
    text-decoration: none;
    top: 10px;
    left: 10px;
    border: 1px solid white;
}

.fl-purchased-content-single-avatar .fl-profile-holder {
    padding: 2px;
}

/* 1 Image */
.fl-purchased-content-1
.fl-purchased-content-single:nth-of-type(1) {
    width: calc(100% - 2px);
    height: calc(100% - 2px);
}

/* 2 Images */
.fl-purchased-content-2
.fl-purchased-content-single:nth-of-type(1),
.fl-purchased-content-2
.fl-purchased-content-single:nth-of-type(2) {
    width: calc(50% - 2px);
    height: calc(100% - 2px);
}

/* 3 Images */
.fl-purchased-content-3
.fl-purchased-content-single:nth-of-type(1),
.fl-purchased-content-3
.fl-purchased-content-single:nth-of-type(2) {
    width: calc(50% - 2px);
    height: calc(50% - 2px);
}
.fl-purchased-content-3
.fl-purchased-content-single:nth-of-type(3) {
    width: calc(100% - 2px);
    height: calc(50% - 2px);
}

/* 4 Images */
.fl-purchased-content-4
.fl-purchased-content-single:nth-of-type(1),
.fl-purchased-content-4
.fl-purchased-content-single:nth-of-type(2),
.fl-purchased-content-4
.fl-purchased-content-single:nth-of-type(3),
.fl-purchased-content-4
.fl-purchased-content-single:nth-of-type(4) {
    width: calc(50% - 2px);
    height: calc(50% - 2px);
}

/* More Images */
.fl-purchased-content-more
.fl-purchased-content-single:nth-of-type(1),
.fl-purchased-content-more
.fl-purchased-content-single:nth-of-type(2),
.fl-purchased-content-more
.fl-purchased-content-single:nth-of-type(3),
.fl-purchased-content-more
.fl-purchased-content-single:nth-of-type(4) {
    width: calc(50% - 2px);
    height: calc(50% - 2px);
    float: left;
}

.fl-purchased-price {
    position: absolute;
    right: 10px;
    bottom: 10px;
    font-weight: 600;
    font-size: 20px;
    color: white;
    -webkit-text-stroke-width: 0.5px;
    -webkit-text-stroke-color: #ff0d72;
}

.fl-purchased-empty,
.fl-subscription-empty {
    text-align: center;
    padding: 40px 20px;
}

.fl-purchased-empty span,
.fl-subscription-empty span {
    color: #15c;
}

.fl-purchased-empty span:hover {
    text-decoration: underline;
    cursor: default;
}

/* Large devices (desktops, less than 1200px) */
@media (max-width: 1199.98px) {
    .fl-purchased-file {
        width: 46%;
    }
}

/* Medium devices (tablets, less than 992px) */
@media (max-width: 991.98px) {

}

/* Small devices (landscape phones, less than 768px) */
@media (max-width: 767.98px) {
    .fl-purchased-file-mass {
        grid-template-columns: repeat(auto-fit, minmax(200px, 1fr));
    }
    .fl-purchased-tablet-show {
        padding-bottom: 100px;
    }
}

/* Extra small devices (portrait phones, less than 576px) */
@media (max-width: 575.98px) {
    .fl-purchased-file-single,
    .fl-purchased-file-mass {
        grid-template-columns: repeat(auto-fit, minmax(200px, 1fr));
    }
    .fl-purchased-file {
        width: 100%;
    }
}