.fl-write-post-main {
  width: 100%;
  padding: 0px;
  position: relative;
}

.fl-write-post-controls {
  width: 100%;
  padding: 0px 0px 0px;
  display: flex;
  align-items: center;
  justify-content: space-between;
  height: 4rem;
  position: relative;
}

.fl-write-controls-end {
  justify-content: end!important;
}

.fl-write-post-actions {
  display: flex;
}

.fl-write-post-action-buttons-container {
  display: flex;
}

.fl-write-post-actions-button {
  padding: 6px 15px;
  height: 36px;
  border-radius: 0;
  border-bottom-left-radius: 8px;
  border-top-left-radius: 8px;
  margin-right: 2px;
}

.fl-button-radius {
  border-bottom-right-radius: 8px;
  border-top-right-radius: 8px;
}

.fl-write-post-actions-button-drop-arrow {
  padding: 6px 5px 6px 0;
  text-align: center;
  width: 30px;
  height: 36px;
  border-radius: 0;
  border-bottom-right-radius: 8px;
  border-top-right-radius: 8px;
  margin-right: 2px;
  font-size: 18px;
}

.fl-write-post-actions-icon-right-text {
  display: inline-block;
  margin-left: 10px;
}
.fl-write-post-actions-button:nth-last-of-type(1) {
  margin-right: 0px;
}

.fl-write-post-add {
  display: flex;
  align-items: center;
  outline: none;
}

.fl-write-post-add-item {
  display: flex;
  align-items: center;
  justify-content: center;
  margin-right: 15px;
  font-size: 22px;
  color: #888888;
  cursor: pointer;
  display: block;
  position: relative;
}

.fl-write-post-add-item svg {
  outline: 0;
}

.fl-write-post-add-item-input {
  display: none;
}

.fl-write-post-add-item:hover {
  color: #666666;
}

.fl-write-post-privacy {
  font-size: 12px;
  padding: 10px 30px;
  display: flex;
  position: relative;
}

.fl-write-post-privacy.fl-write-post-privacy-dark {
  color: #fff;
}

.fl-write-post-privacy.fl-write-post-privacy-light {
  color: #000;
}

.fl-write-post-limit {
  color: #fff;
  font-size: 12px;
  text-transform: uppercase;
  background-color: #ffab22;
  margin: 0px;
  padding: 5px 10px 5px 5px;
  border-radius: 15px;
  font-weight: 600;
  display: flex;
  align-items: center;
}

.fl-write-post-limit-icon {
  font-size: 20px;
  margin-right: 8px;
}

.fl-write-post-limit b {
  padding-top: 3px;
}

.fl-write-post-preview {
  display: flex;
  max-width: 100%;
  margin: 0 auto;
  position: relative;
  overflow-y: hidden;
  overflow-x: auto;
  -webkit-overflow-scrolling: touch;
}

.fl-write-post-preview-img {
  position: relative;
}

.fl-write-post-preview-img-focus img {
  border: 4px solid #1155ccc4;
}

.fl-write-post-progress {
  height: 20px;
  position: relative;
  border-radius: 0 0 10px 10px;
  overflow: hidden;
  background-color: #888;
}

.fl-write-post-progress-bar-left {
  margin-left: 10px;
}

.fl-write-post-progress-bar-right {
  margin-right: 10px;
}

.fl-write-post-progress-bar .CircularProgressbar {
  width: auto;
  padding-bottom: 5px;
  height: 85px;
}

.fl-write-post-preview {
  padding: 0 30px 0 30px;
}

.fl-write-post-controls {
  padding: 0 30px 0 30px;
}

.fl-write-post-preview .fl-write-post-progress-bar {
  width: 110px;
  text-align: center;
  min-height: 100px;
}

.fl-write-post-progress-bar .CircularProgressbar .CircularProgressbar-text {
  font-size: 25px;
}
.fl-write-post-progress-bar span,
.fl-write-post-mobile-progress-bar {
  color: #3e98c7;
  font-size: 13px;
  display: flex;
  align-items: center;
  padding-left: 5px;
  justify-content: center;
}

.fl-write-post-mobile-progress-bar {
  width: 50px;
}

.fl-write-post-progress-perc {
  position: absolute;
  left: 50%;
  top: 50%;
  transform: translate(-50%, -50%);
  color: #fff;
  font-size: 12px;
  font-weight: 500;
}

.fl-write-post-textarea {
  margin: 0px;
  height: 87px;
}

.fl-write-post-textarea textarea {
  border: none;
  width: 100%;
  padding: 35px 0px;
  margin: 0;
  font-size: 12pt;
}

.fl-write-post-actions-button-mobile-cancel {
  display: none;
}

.fl-write-post-all {
  width: 100%;
  position: relative;
  padding: 0 30px 0 30px;
}

.fl-write-post-open {
  border-radius: 20px 20px 0 0;
}

.fl-write-post-close {
  border-radius: 20px;
}

.fl-write-post-all-light:not(.fl-write-post-main-mobile .fl-write-post-all-light) {
  border: 1px solid #00000032;
  border-radius: 20px;
}

.fl-write-post-all-dark {
  border: 1px solid #ffffff32;
  border-radius: 20px;
}

.fl-write-post-controls-light {
  border-top: 1px solid #00000032;
}

.fl-write-post-controls-dark {
  border-top: 1px solid #ffffff32;
}

.fl-write-post-all-hover:hover {
  opacity: 0.7;
}

.fl-write-post-main .fl-restricted-box {
  border-radius: 20px;
}

.fl-write-post-main-mobile .fl-write-post-cancel {
  top: 20px;
}

.fl-write-post-cancel {
  position: absolute;
  right: 15px;
  top: 7px;
  width: 30px;
  height: 30px;
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;
  z-index: 98;
}

.fl-write-post-mobile-add {
  position: absolute;
  bottom: 0;
  width: 100%;
  border-radius: 20px 20px 0px 0px;
  background: #f4f4f4;
  padding: 20px 0px;
}

.fl-write-post-mobile-add .fl-write-post-add-item{
  margin-right: 0px;
  padding: 15px;
}

.fl-write-post-mobile-add .fl-write-post-add-item span {
  margin-left: 15px;
}

.fl-write-post-cancel.fl-write-post-cancel-light {
  color: #666;
}
.fl-write-post-cancel.fl-write-post-cancel-light:hover {
  color: #000;
}
.fl-write-post-cancel.fl-write-post-cancel-dark {
  color: #aaa;
}
.fl-write-post-cancel.fl-write-post-cancel-dark:hover {
  color: #fff;
}

.fl-write-post-add > label:hover:nth-child(-n + 3) {
  color: #ff0d72;
}

.fl-write-post-add > label:hover:nth-child(-n + 3) {
  border-bottom: 1px solid #ff0d72;
}

.fl-write-post-add > label span {
  font-size: 20px;
  margin-left: 5px;
  vertical-align: 0.12em;
}

.fl-write-post-privacy .fl-select-main {
  float: left;
  width: auto;
  margin: 3px 0px 0px 10px;
  font-size: 15px;
  height: 30px;
  padding: 5px 10px;
}

.fl-write-post-select-bar {
  /* display: flex;
  flex-direction: row;
  margin-left: 5px;
  justify-content: center;
  align-items: center; */
}

.fa-caret-down {
  margin-left: 2px !important;
}

.fl-write-post-select-bar {
  position: relative;
  display: inline-block;
  margin-inline-start: 5px;
  cursor: pointer;
  font-size: 0.75rem;
  border-bottom: 1px solid;
  border-color: transparent;
  user-select: none;
  outline: none;
  padding: 5px;
  border-radius: 5px;
  margin-top: 2px;
}

.fl-write-post-select-bar-light {
  background-color: hsl(0, 0%, 90%);
}

.fl-write-post-select-bar-light:hover {
  background-color: hsl(0, 0%, 84%);
}

.fl-write-post-select-bar-dark {
  background-color: #161616;
}

.fl-write-post-select-bar-dark:hover {
  background-color: #2e2e2e;
}

.fl-write-post-select-bar--option {
  position: absolute;
  width: 140px;
  z-index: 200;
  /* left: 8rem; */
  height: 100%;
}

.fl-write-post-cancel-actions {
  display: flex;
  align-items: center;
  margin-right: 15px;
}

.fl-write-post-cancel-actions a {
  cursor: pointer;
}

.fl-write-post-cancel-actions a svg {
  margin-left: 5px;
  color: red;
}

.fl-write-post-preview-img-remove svg {
  width: 100%;
}

.fl-write-post-preview-img-remove,
.fl-bug-preview-img-remove {
  width: 18px;
  height: 18px;
  border-radius: 50%;
  margin-left: 5px;
  border: 1px solid #ff0d72;
  display: none;
  justify-content: center;
  align-items: center;
  font-size: 10px;
  background-color: #ff0d72;
  cursor: pointer;
  position: absolute;
  top: 4px;
  right: 3px;
  animation: remove-preview-popup 0.25s forwards;
}

.fl-write-post-preview-img-remove svg,
.fl-bug-preview-img-remove svg {
  color: white;
}

.fl-write-post-preview-img:hover > .fl-write-post-preview-img-remove,
.fl-file-picker-image:hover > .fl-bug-preview-img-remove {
  display: flex;
}

.fl-write-post-preview-img-remove:hover,
.fl-bug-preview-img-remove:hover {
  background: white;
  border: none;
}

.fl-write-post-preview-img-remove:hover > svg,
.fl-bug-preview-img-remove:hover > svg {
  color: #ff0d72;
}

.fl-write-post-actions-retry-svg {
  margin-left: 5px;
}

.schedule-btn-style {
  background: #fff;
  color: #ff0d72;
  border: 1px solid #ff0d72;
  margin-right: 1px;
}
.schedule-btn-style:not(:disabled):hover {
  background: #fff;
  color: #ff0d72;
}

.fl-write-post-preview-drag {
  display: flex;
}

.fl-write-post-preview-drag-mobile {
  order: 2;
  margin-left: 10px;
}

.fl-write-post-preview-drag img,
.fl-write-post-preview-drag video {
  width: 100%;
  object-fit: cover;
}

.fl-write-post-audience-ppv {
  border-right: 1px solid #00000015;
}

.fl-write-post-audience {
  justify-content: center;
  padding-right: 15px;
  display: flex;
  align-items: center;
}

.fl-write-post-blur {
  padding-left: 15px;
}

.fl-write-post-blur-price,
.fl-write-post-blur-range {
  display: flex;
  padding-bottom: 5px;
}

.fl-write-post-price .fl-toggle-main {
  margin-left: 0px;
}

.fl-write-post-price-error {
  margin-left: 0.5rem;
  color: rgb(255, 63, 71);
}

.fl-write-post-price {
  display: block;
}

.fl-write-post-blur-range .fl-bulk__price__label span:hover {
  text-decoration: none;
}

.fl-write-post-price-label {
  color: #888;
}

.fl-write-post-price-label span {
  margin-left: 5px;
}

.fl-write-post-unlock-anyone {
  color: #888888;
}

.fl-write-post-thumbnail {
  outline: none;
}

.fl-bulk__price__label__disable {
  cursor: default;
  color: #888888;
  text-decoration: none;
}

.fl-bulk__price__label__enable {
  cursor: pointer;
  color: #15c;
  text-decoration: underline;
}

.fl-bulk__price__label__enable:hover {
  text-decoration: none;
}

.fl-write-post-blur-range {
  margin-block-start: 0.3em;
}

.fl-write-post-unlock-anyone label {
  display: inline-block;
}

.fl-write-post-unlock-anyone input,
.fl-write-post-unlock-anyone svg {
  vertical-align: middle;
}

.fl-write-post-unlock-anyone span {
  vertical-align: bottom;
}

.fl-write-post-drop-files {
  display: none;
  position: absolute;
  width: 100%;
  height: 100%;
  background: #ffffffd9;
  border: 3px solid #888;
  border-style: dashed;
  border-radius: 20px;
  align-items: center;
  text-align: center;
}

.fl-write-post-drop .fl-write-post-drop-files {
  display: flex;
}

.fl-write-post-drop .fl-write-post-all,
.fl-write-post-drop .fl-write-post-controls,
.fl-write-post-drop .fl-write-post-preview,
.fl-write-post-drop .fl-write-post-privacy {
  opacity: 0.1;
  position: relative;
}

.fl-write-post-drop-files span {
  width: 100%;
  font-size: 35px;
  color: #888;
  font-weight: 500;
}

.fl-write-post-restricted {
  padding-left: 30px;
}

.fl-write-post-preview-img  {
  width: 100px;
  height: 100px;
}

.fl-write-post-preview-img img,
.fl-write-post-preview-img video {
  width: 100px;
  height: 100px;
  object-fit: cover;
}

.fl-write-post-preview-drag .fl-write-post-preview-img:not(.fl-write-post-preview-drag .fl-write-post-preview-img:first-child) {
  margin-left: 10px;
}

.fl-write-post-preview   .fl-bulk__input__files--line--add {
  height: 100px;
  margin-left: 10px;
}

.fl-write-post-preview .fl-bulk__input__files--line--add-mobile {
  margin-left: 0px;
}

.fl-encoding-post {
  padding: 35px 0px 30px 0px;
  text-align: center;
  color: #888888;
  position: relative;
}

.fl-encoding-post-thumb {
  position: absolute;
  width: 50px;
  height: 50px;
  top: 0;
}

.fl-encoding-post-thumb .fl-encoding-progress-bar {
  position: absolute;
  left: 0;
  top: 0;
}

/*.fl-encoding-post-thumb .CircularProgressbar .CircularProgressbar-path {*/
/*  stroke: #ff0d72 !important;*/
/*}*/

/*.fl-encoding-post-thumb .CircularProgressbar .CircularProgressbar-text {*/
/*  fill: #ff0d72;*/
/*}*/

.fl-encoding-post-thumb-text {
  display: flex;
  align-items: center;
  justify-content: center;
}

.fl-encoding-post-thumb-text .fl-post-spinner span {
  width: 5px;
  height: 5px;
  margin-right: 3px;
}

.fl-encoding-post-thumb-text .fl-post-spinner {
  margin-left: 2px;
}

.fl-encoding-post-thumb .fl-encoding-progress-bar text {
  font-size: 40px;
}

.fl-encoding-post-thumb img,
.fl-encoding-post-thumb video {
  height: 50px;
  width: 50px;
  object-fit: cover;
}
.fl-encoding-quick-post {
  padding: 30px 0px 30px 0px;
}

.fl-encoding-quick-post-modal {
  margin-bottom: 0px !important;
}

.fl-write-post-main-mobile {
  margin-bottom: 0px !important;
}

.fl-write-mobile-post {
  background: #f4f4f4;
  text-align: center;
  padding: 25px;
  font-size: 20px;
  color: #888888;
  font-weight: 500;
}

.fl-main-mobile-create-post .fl-write-post-action-buttons-container,
.fl-main-mobile-create-post .fl-write-post-action-buttons-container .fl-write-post-actions-button  {
  width: calc(100% - 10%);
}

.fl-main-mobile-create-post .fl-write-post-actions {
  width: 100%;
}

.fl-main-mobile-create-post .fl-write-post-action-buttons-container {
  margin: auto;
}

.fl-main-mobile-create-post .fl-write-post-add  .fl-write-post-add-item  {
  width: 40px;
  text-align: center;
  margin-right: 0px;
}

.fl-create-post-option-active {
  background-color: #ff0d72;
  color: #fff !important;
}

.fl-create-post-option-active .fl-post-head-actions-dropdown-option-icon {
  color: #fff !important;
}

/* Large devices (desktops, less than 1200px) */
@media (max-width: 1199.98px) {
}

/* Medium devices (tablets, less than 992px) */
@media (max-width: 991.98px) {
}

/* Small devices (landscape phones, less than 768px) */
@media (max-width: 767.98px) {
  .fl-write-post-main {
    display: none;
  }
  .fl-write-post-main-mobile {
    display: block;
    margin-bottom: 0px !important;
  }
}

/* Extra small devices (portrait phones, less than 576px) */
@media (max-width: 575.98px) {
  .fl-write-post-cancel-actions {
    font-size: 12px;
  }
  .fl-write-post-actions-button-mobile-cancel {
    display: block;
  }

  .fl-write-post-actions-button-cancel {
    width: 40px;
    padding: 0px;
  }
  .fl-write-post-actions-button-cancel span {
    display: none;
  }
  .fl-write-post-limit {
    display: none;
  }
  .fl-write-post-controls {
    padding: 20px 20px;
  }
  .fl-write-post-privacy {
    padding: 10px 20px
  }
  .fl-write-post-preview,
  .fl-write-post-all {
    padding: 0 20px 0 20px;
  }
  .fl-write-post-textarea textarea {
    padding: 20px;
  }
  .fl-encoding-post {
    padding: 0;
  }
  .fl-encoding-post-thumb {
    position: unset;
    margin: auto;
  }
  .fl-encoding-post-thumb-text {
    margin-top: 10px;
  }
}

@media (max-width: 410px) {
  .fl-write-post-audience-ppv {
    flex-direction: column;
  }
  .fl-write-post-audience-ppv .fl-write-post-select-bar {
    margin-inline-start: auto;
  }
}

