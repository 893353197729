.fl-route-holder-main {
    position: fixed;
    width: 100%;
    height: 100%;
    left: 0;
    top: 0;
    z-index: 1000;
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    font-size: 32px;
    flex: 1;
    background-color: #efefef;
}

.fl-route-holder-bg {
    width: 100%;
    position: absolute;
    left: 0px;
    top: 0px;
    height: 100%;
}

.fl-route-holder-content {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    position: relative;
}

.fl-route-holder-content-img {
    width: 200px;
    height: 200px;
    object-fit: contain;
    object-position: center;
}
.fl-route-holder-content-tick {
    display: flex;
    align-items: center;
    justify-content: center;
}

@keyframes tickAnimation {
    0% { background-color: #afafaf; }
    50% { background-color: #ffc235; }
    100% { background-color: #afafaf; }
}

.fl-route-holder-content-tick span {
    display: block;
    width: 15px;
    height: 15px;
    margin-right: 15px;
    background-color: #afafaf;
    border-radius: 50%;
}

.fl-route-holder-content-tick span:nth-of-type(1) {
    animation: tickAnimation 1000ms ease-in-out 0ms infinite;
}
.fl-route-holder-content-tick span:nth-of-type(2) {
    animation: tickAnimation 1000ms ease-in-out 100ms infinite;
}
.fl-route-holder-content-tick span:nth-of-type(3) {
    animation: tickAnimation 1000ms ease-in-out 200ms infinite;
}

.fl-route-holder-content-tick span:last-child {
    margin-right: 0px;
}

@keyframes slideBg {
    0% { transform: translateX(0%);}
    50% { transform: translateX(calc(-100% + 100vw));}
    100% { transform: translateX(0%);}
}

.fl-route-holder-bg svg {
    width: 500%;
    height: 50%;
    position: absolute;
    bottom: 0px;
    left: 0px;
    animation: slideBg 30s infinite linear;
}


/* Large devices (desktops, less than 1200px) */
@media (max-width: 1199.98px) {

}

/* Medium devices (tablets, less than 992px) */
@media (max-width: 991.98px) { 

}

/* Small devices (landscape phones, less than 768px) */
@media (max-width: 767.98px) { 
    
 }

/* Extra small devices (portrait phones, less than 576px) */
@media (max-width: 575.98px) {

}