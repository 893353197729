.fl-profile-holder {
  width: 100%;
  height: 100%;
  object-fit: cover;
  position: absolute;
  left: 0px;
  top: 0px;
  border-radius: 50%;
}

.fl-profile-holder .smooth-image-wrapper img,
.fl-profile-holder .smooth-image-wrapper {
  border-radius: 50%;
}

.fl-featured-profiles .smooth-image-wrapper img,
.fl-featured-profiles .smooth-image-wrapper {
  border-radius: unset !important;
}

.fl-post-head-image .smooth-image {
  object-fit: contain;
  width: 100%;
  height: 100%;
  object-fit: cover;
  position: absolute;
  left: 0px;
  top: 0px;
  border-radius: 50%;
}

.fl-user-profile-images-main-inner a {
  cursor: default;
}

.fl-user-profile-images-main-inner .fl-profile-holder-status-icon {
  width: 15px;
  height: 15px;
  border-radius: 50%;
  position: absolute;
  right: -1px;
  bottom: 2px;
}

.fl-profile-holder-status-icon {
  width: 12px;
  height: 12px;
  border-radius: 50%;
  position: absolute;
  right: 0px;
  bottom: 5px;
}

.fl-user-profile-images-main .fl-profile-holder-status-icon {
  right: 19px;
  top: -25px;
  display: flex;
  align-items: center;
  justify-content: center;
}

.fl-gotopost-post-header-image .fl-profile-holder-status-icon {
  right: -2px;
  bottom: 5px;
}

.fl-simple-comment-image .fl-profile-holder-status-icon {
  right: 4px;
  bottom: 2px;
}

.fl-profile-holder-status-icon-true {
  background: #4dd965;
}

.fl-profile-holder-status-icon-false {
  background: #8b8d91;
}

.fl-profile-holder-dropdown-status {
  position: absolute;
  right: -100px;
  top: 0px;
  z-index: 10;
  font-size: 14px;
}

.fl-profile-holder-status {
  position: absolute;
  right: 0;
  cursor: default;
  z-index: 9;
}

.fl-profile-holder-status-icon-cursor-true {
  cursor: pointer;
}

.fl-profile-holder-status-user
  .fl-profile-holder-status-icon-cursor-true:hover
  svg {
  display: block;
  font-size: 12px;
}

.fl-profile-holder-status-user .fl-profile-holder-status-icon svg {
  display: none;
}

.fl-profile-holder-status-user .fl-profile-holder-status-icon:hover {
  background: white;
  border: 1px solid #00000020;
}

/* Large devices (desktops, less than 1200px) */
@media (max-width: 1199.98px) {
}

/* Medium devices (tablets, less than 992px) */
@media (max-width: 991.98px) {
}

/* Small devices (landscape phones, less than 768px) */
@media (max-width: 767.98px) {
  .fl-profile-holder-dropdown-status {
    right: -85px;
  }
}

/* Extra small devices (portrait phones, less than 576px) */
@media (max-width: 575.98px) {
}
