.fl-feed-main {
  width: 100%;
  flex: 1;
  min-height: 100%;
}

.fl-feed-container {
  height: 100%;
}

.fl-fluffa-desktop .fl-feed-main.fl-feed-main-light {
  background-color: #f4f4f4;
}

.fl-feed-main.fl-feed-main-dark {
  background-color: #1b1b1b;
}

.fl-feed-row {
  padding: 0px 5px;
}
.fl-feed-column {
  padding: 0px 15px;
}

.fl-feed-write-post-card {
  font-size: 18px;
  color: #888;
  height: 100px;
}

.fl-feed-column-sticky {
  /* position: sticky; */
  top: 100px;
  height: 100%;
}

.fl-feed-no-posts {
  width: 100%;
  padding: 30px 0px;
}

.fl-feed-no-posts-text {
  width: 100%;
  text-align: center;
  font-size: 32px;
  font-weight: 500;
}

.fl-feed-no-posts-text.fl-feed-no-posts-text-light {
  color: #000;
}
.fl-feed-no-posts-text.fl-feed-no-posts-text-dark {
  color: #fff;
}

.fl-feed-no-posts-icon {
  font-size: 64px;
  width: 100px;
  height: 100px;
  display: flex;
  align-items: center;
  justify-content: center;
  color: #ff0d72;
  border: 10px solid #ff0d72;
  border-radius: 50%;
  margin: 0px auto 30px;
}

.fl-post-display-grid,
.fl-favorites-display-grid {
  display: grid;
  grid-template-columns: minmax(0, 1fr) minmax(0, 1fr) minmax(0, 1fr);
  column-gap: 10px;
  width: 100%;
  align-items: center;
}

.fl-post-display-grid > div,
.fl-favorites-display-grid > div {
  align-self: stretch;
}

.toast-link {
  cursor: pointer;
  font-weight: 600;
}

.toast-lin-user {
  color: white;
}

.toast-link:hover {
  text-decoration: underline;
}
.fl-feed-options-view {
  display: none;
}

.fl-display-options-span {
  margin-left: 10px;
}

/* Large devices (desktops, less than 1200px) */
@media (max-width: 1199.98px) {
  .fl-feed-column-sticky {
    height: 760px;
  }
}

/* Medium devices (tablets, less than 992px) */
@media (max-width: 991.98px) {
  .fl-feed-tablet-hide {
    display: none;
  }
  .fl-feed-tablet-prio {
    width: 100% !important;
  }
  .fl-feed-options-view {
    display: flex;
    z-index: 1;
    position: fixed;
    top: 150px;
    padding: 7px 7px;
    background: #ececec;
    border-radius: 5px;
    cursor: pointer;
  }
}

/* Small devices (landscape phones, less than 768px) */
@media (max-width: 767.98px) {
  .fl-feed-tablet-prio {
    padding-bottom: 100px;
  }
}
@media (max-width: 760px) {
  .fl-feed-column {
    padding-top: 15px;
  }
}

/* Extra small devices (portrait phones, less than 576px) */
@media (max-width: 575.98px) {
  .fl-post-display-grid,
  .fl-favorites-display-grid {
    grid-template-columns: minmax(0, 1fr) minmax(0, 1fr);
  }
  .fl-display-options-span {
    display: none;
  }
}
