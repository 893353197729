.fl-navbar-main {
  width: 100%;
  height: 80px;
  left: 0;
  top: 0;
  z-index: 10;
  position: fixed;
  background: white;
}

.fl-navbar-main-terms {
  background: #f4f4f4;
}

/*.fl-body-lock-scroll .fl-navbar-main,*/
.fl-body-fixed .fl-navbar-main {
  padding-right: 15px;
}

.fl-navbar-main-not-home.fl-navbar-main-dark {
  background-color: #000;
  border-bottom: 1px solid #ffffff20;
}

.fl-navbar-main-not-home.fl-navbar-main-light {
  background-color: #fff;
  border-bottom: 1px solid #00000020;
}

.fl-navbar-main-dark {
  background-color: #000;
}

.fl-navbar-inner {
  /*padding: 20px;*/
  padding: 0 20px 0 20px;
  display: flex;
  justify-content: space-between;
  align-items: center;
  height: 80px;
}

.fl-navbar-logo {
  display: block;
  height: 80px;
}

.fl-navbar-logo-img {
  width: 80px;
  height: 80px;
  object-fit: contain;
  object-position: center;
}

.fl-navbar-main-sticky {
  position: fixed;
  animation: navbar 300ms forwards ease-in-out;
  height: 80px;
  z-index: 99;
}

.fl-navbar-main-sticky.fl-navbar-main-light {
  background-color: #fff !important;
  border-bottom: 1px solid #00000020 !important;
}

.fl-navbar-main-sticky.fl-navbar-main-dark {
  background-color: #000 !important;
  border-bottom: 1px solid #ffffff20 !important;
}

.fl-navbar-main-sticky .fl-navbar-inner {
  padding: 10px 20px;
}

.fl-navbar-main-sticky .fl-navbar-logo-img {
  height: 80px;
}

.fl-navbar-main-home {
  background: none;
  border-bottom: none;
}

.fl-navbar-logo-force-min {
  height: 80px;
}

@keyframes navbar {
  0% {
    transform: translateY(-100%);
  }
  100% {
    transform: translateY(0%);
  }
}

.fl-navbar-items {
  display: flex;
  justify-content: flex-end;
}

.fl-navbar-button-icon-show-always {
  display: block !important;
}

/*.fl-navbar-button-icon {*/
/*  display: none;*/
/*}*/

.fl-navbar-button-icon {
  width: 50px;
  padding: 0px !important;
}

.fl-navbar-button-mobile-hide span {
  display: block;
}
.fl-navbar-button-mobile-hide .fl-navbar-button-icon {
  display: none;
}

/* Large devices (desktops, less than 1200px) */
@media (max-width: 1199.98px) {
  .fl-navbar-main {
    height: 80px;
  }
  .fl-navbar-main.fl-navbar-main-sticky {
    height: 80px;
  }
  .fl-navbar-logo-img,
  .fl-navbar-logo {
    height: 80px;
  }
  .fl-navbar-button-icon {
    width: 40px;
  }
}

/* Medium devices (tablets, less than 992px) */
@media (max-width: 991.98px) {
  .fl-body-lock .fl-navbar-main,
  .fl-body-fixed .fl-navbar-main,
  .fl-navbar-main .fl-navbar-main {
  padding-right: 0px;
  }
}

/* Small devices (landscape phones, less than 768px) */
@media (max-width: 767.98px) {
  .fl-body-fixed .fl-navbar-main {
    padding-right: 0px;
  }
}

/* Extra small devices (portrait phones, less than 576px) */
@media (max-width: 575.98px) {
  .fl-navbar-button-mobile-hide span {
    display: none;
  }
  .fl-navbar-button-mobile-hide .fl-navbar-button-icon {
    display: block;
  }
  .fl-navbar-button-mobile-hide {
    padding: 0px !important;
    width: 40px;
  }
}
