@keyframes spin {
    0% { transform: rotate(0deg);}
    100% { transform:  rotate(360deg);}
}

.fl-verify-spin {
    animation: spin 2s infinite linear;
}

.fl-verify-text {
    max-width: 400px;
    width: 100%;
    margin: 0px auto;
    padding: 20px 0px;
}

.fl-verify-text span {
    color: #FF0D72;
}

.fl-green-icon {
    color: #28a745;
}


/* Large devices (desktops, less than 1200px) */
@media (max-width: 1199.98px) {

}

/* Medium devices (tablets, less than 992px) */
@media (max-width: 991.98px) { 

}

/* Small devices (landscape phones, less than 768px) */
@media (max-width: 767.98px) { 
    
 }

/* Extra small devices (portrait phones, less than 576px) */
@media (max-width: 575.98px) {

}