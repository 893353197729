
.fl-login-recapthca {
 padding: 15px 0px 0px 0px;
}

.fl-twitter-button {
    background: linear-gradient(90deg, rgba(188,242,249,1) 0%, rgba(86,187,208,1) 84%);
    border: 0px;
}

.fl-twitter-button:hover {
    background: linear-gradient(90deg, rgba(188,242,249,1) 0%, rgba(6,148,177,1) 100%) !important;
}

.fl-login-button-break{
    border: none;
    border-color: rgba(226, 226, 226, 0.562);
    border-top: 3px double ;
    color: rgb(145, 145, 145);
    overflow: visible;
    text-align: center;
    height: 5px;
    margin-top:15px;
    margin-bottom:40px;
}

.fl-login-button-break:after {
    content: 'OR';
    padding: 0 4px;
    position: relative;
    top:10px
}


/* Large devices (desktops, less than 1200px) */
@media (max-width: 1199.98px) {

}

/* Medium devices (tablets, less than 992px) */
@media (max-width: 991.98px) { 

}

/* Small devices (landscape phones, less than 768px) */
@media (max-width: 767.98px) { 
    
 }

/* Extra small devices (portrait phones, less than 576px) */
@media (max-width: 575.98px) {

}