.fl-subscriptions-main {
    width: 100%;
    flex: 1;
    height: 100%;
    padding-top: 15px;
}

.fl-subscriptions-main.fl-subscriptions-main-light {
    background-color: #f4f4f4;
}

.fl-subscriptions-main.fl-subscriptions-main-dark {
    background-color: #1b1b1b;
}

.fl-subscriptions-inner {
    display: flex;
    padding: 0px 5px;
}

.fl-subscriptions-col {
    padding: 0px 15px;
}

.fl-subscriptions-filter-single {
    color: #424242;
    font-weight: 600;
    display: flex;
    align-items: center;
}

.fl-subscriptions-filter-count {
    margin-left: 5px;
}

.fl-subscriptions-filter-single-link {
    text-decoration: none;
}

.fl-subscriptions-filter-single-icon {
    margin-right: 10px;
    font-size: 20px;
    margin-top: -4px;
}


.fl-subscriptions-section-title {
    font-size: 22px;
    font-weight: 500;
    padding: 5px 0px 15px;
    margin-bottom: 15px;
    text-transform: capitalize;
}

.fl-subscriptions-section-title.fl-subscriptions-section-title-dark {
    color: #fff;
    border-bottom: 1px solid #ffffff20;
}

.fl-subscriptions-section-title.fl-subscriptions-section-title-light {
    color: #000;
    border-bottom: 1px solid #00000020;
}

.fl-subscriptions-filter-single-active .fl-subscriptions-filter-single {
    color: #fff;
    background-color: #FF0D72 !important;
}

.fl-subscriptions-list {
    width: 100%;
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
}

.fl-subscriptions-list-empty {
    justify-content: center;
}

.fl-subscriptions-single {
    width: calc(calc(100% / 3) - calc(40px / 3));
    margin-right: 20px;
    margin-bottom: 20px;
    position: relative;
}

.fl-subscriptions-section-card-button {
    width: calc(100% - 80px);
    height: 35px;
    border-radius: 5px;
    margin: 0px auto;
    font-size: 12px;
    text-transform: uppercase;
}

.fl-subscriptions-section-card-button-active {
    background: #28a745 !important;
}

.fl-subscriptions-section-card-button-cancelled {
    background: #ff4444 !important;
}

.fl-subscriptions-section-card-button-former {
    background: #ffc107 !important;
}

.fl-subscriptions-single-dates {
    width: 100%;
    text-align: center;
    font-size: 12px;
    color: #888;
    padding: 10px 0px 0px;
}

.fl-subscriptions-section-card-renew {
    text-decoration: none;
    color: #FF0D72;
    text-decoration: none;
}

.fl-subscriptions-section-card-renew:hover {
    text-decoration: underline;
}

.fl-subscriptions-button-trial {
    background: #ff840f;
}

.fl-subscriptions-button-trial:hover {
    background: #ef7b0c !important;
}

.fl-subscriptions-button-free {
    background: #a020f0;
}

.fl-subscriptions-button-free:hover {
    background: #820ecb !important;
}

.fl-subscriptions-free-trial {
    position: absolute;
    top: 10px;
    right: 10px;
    background: white;
    width: 35px;
    height: 35px;
    display: flex;
    align-items: center;
    border-radius: 50%;
    justify-content: center;
}

.fl-subscriptions-free-trial svg {
    font-size: 20px;
}

/* Large devices (desktops, less than 1200px) */
@media (max-width: 1199.98px) {
    .fl-subscriptions-single {
        width: calc(calc(100% / 2) - 10px);
        margin-right: 20px;
        margin-bottom: 20px;
    }
    .fl-subscriptions-single:nth-of-type(2n) {
        margin-right: 0px;
    }
}

@media (min-width: 1200px) {
    .fl-subscriptions-single:nth-of-type(3n) {
        margin-right: 0px;
    }
}

/* Medium devices (tablets, less than 992px) */
@media (max-width: 991.98px) { 
    .fl-subscriptions-single {
        margin-right: 0px;
        width: 100%;
    }
    .fl-subscriptions-inner {
        display: block;
    }

    .fl-subscriptions-col {
        width: 100%;
    }

    .fl-subscriptions-filter {
        display: flex;
    }

    .fl-subscriptions-filter-single-link {
        width: 100%;
        display: block;
        margin-right: 20px;
    }
    .fl-subscriptions-filter-single-link:nth-last-of-type(1) {
        margin-right: 0px;
    }
}

/* Small devices (landscape phones, less than 768px) */
@media (max-width: 767.98px) { 
    .fl-subscriptions-filter-single span {
        display: none;
    }
    .fl-subscriptions-filter-single {
        display: flex;
        justify-content: center;
        align-items: center;
    }
    .fl-subscriptions-filter-single-icon {
        margin-right: 0px;
    }
    .fl-subscriptions-tablet {
        padding-bottom: 100px;
    }
 }

/* Extra small devices (portrait phones, less than 576px) */
@media (max-width: 575.98px) {
}