.fl-toggle-main {
    width: 56px;
    height: 26px;
    border-radius: 13px;
    background: linear-gradient(to right, #ffb727, #ff3b4a);
    position: relative;
    cursor: pointer;
}

.fl-toggle-main.fl-toggle-main-active {
    background: linear-gradient(to right, #277eff, #0037ff);
}

.fl-toggle-dot {
    width: 20px;
    height: 20px;
    background-color: #fff;
    border-radius: 50%;
    position: absolute;
    left: 3px;
    top: 3px;
    transition: 300ms left ease-in-out;
}

.fl-toggle-main-active .fl-toggle-dot {
    left: calc(100% - 23px);
}

.fl-toggle-icons {
    width: 100%;
    height: 100%;
    display: flex;
    justify-content: space-between;
    padding: 3px;
}

.fl-toggle-icon {
    width: 20px;
    height: 20px;
    font-size: 14px;
    display: flex;
    align-items: center;
    justify-content: center;
    color: #fff;
    transition: transform 300ms ease-in-out;
    transform: scale(1);
}

.fl-toggle-icon-hide {
    transform: scale(0);
}


/* Large devices (desktops, less than 1200px) */
@media (max-width: 1199.98px) {

}

/* Medium devices (tablets, less than 992px) */
@media (max-width: 991.98px) { 

}

/* Small devices (landscape phones, less than 768px) */
@media (max-width: 767.98px) { 
    
 }

/* Extra small devices (portrait phones, less than 576px) */
@media (max-width: 575.98px) {

}