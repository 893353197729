.fl-drive-attachments {
    margin: 1rem auto;
    display: grid;
    grid-gap: 1em;
    grid-auto-rows: 200px;
    grid-template-columns: repeat(auto-fit, minmax(200px, 1fr));
    outline: none;
}

.fl-drive-image {
    display: flex;
    position: relative;
    outline: none;
    cursor: pointer;
}

.fl-drive-image .lazy-load-image-background img {
    height: 100%;
    width: 100%;
    object-fit: cover;
    border-radius: 5px;
}

.fl-drive-image img {
    width: 100%;
    object-fit: cover;
    border-radius: 5px;
}

.fl-drive-image video {
    width: 100%;
}

.fl-drive-image-added {
    transition: background-color .3s ease-in-out;
}

.fl-drive-image-svg {
    position: absolute;
    border-radius: 50%;
    color: white;
    display: flex;
    align-items: center;
    justify-content: center;
    transition: background-color .3s ease-in-out;
}

.fl-drive-attachments .fl-drive-image-svg {
    top: 10px;
    right: 10px;
    width: 30px;
    height: 30px;
    font-size: 20px;
}

.fl-drive-attachments-grid .fl-drive-image-svg {
    top: 25%;
}

.fl-drive-delete-enable {
    background-color: #ff0d72 !important;
}

.fl-drive-delete-disable {
    background: #8888;
    border: 1px solid #ff3b4b;
}

.fl-drive-attachments-grid .fl-drive-image-svg {
    width: 25px;
    height: 25px;
    right: 0;
    z-index: 999;
    font-size: 12px;
}

.fl-drive-image-svg svg {
    width: 100%;
}

.fl-modal-window-drive  .fl-modal-window-close {
    z-index: 99;
}

.fl-drive-delete-option {
    display: flex;
    justify-content: flex-start;
    float: left;
    color: #888;
    align-items: center;
    min-height: 35px;
}

.fl-image-close {
    right: -40px;
    top: -20px;
}

.fl-modal-window-drive {
    width: 100%;
}