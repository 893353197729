.fl-mobile-nav-main {
  position: fixed;
  left: 0px;
  bottom: 0px;
  width: 100%;
  height: 60px;
  /*display: none;*/
  display: flex;
  justify-content: space-between;
  z-index: 100;
}

.fl-mobile-nav-main.fl-mobile-nav-main-dark {
  background-color: #000;
  box-shadow: 0px 0px 4px 2px #ffffff20;
}

.fl-mobile-nav-main.fl-mobile-nav-main-light {
  background-color: #fff;
  box-shadow: 0px 0px 4px 2px #afafaf20;
}

.fl-mobile-nav-item {
  width: 20%;
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  text-decoration: none;
  color: #888;
  font-size: 26px;
  position: relative;
}

.fl-mobile-nav-photo {
  max-width: 100%;
  width: 50px;
  height: 50px;
  position: absolute;
  border-radius: 50%;
  background-color: #ff0d72;
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;
}

.fl-mobile-nav-photo-plus {
  position: relative;
  color: #fff;
  right: -3px;
  top: 0px;
  font-size: 28px;
}

.fl-mobile-nav-item-active {
  color: #ff0d72;
}

.fl-mobile-nav-wrapper {
  width: 100%;
  height: 100%;
  position: fixed;
  left: 0px;
  top: 0px;
  background-color: #00000090;
  z-index: 200;
  overflow: scroll;
  display: flex;
  align-items: center;
}

.fl-mobile-nav-posting-main {
  width: 100%;
  padding: 0px 10px;
}

/* Large devices (desktops, less than 1200px) */
@media (max-width: 1199.98px) {
}

/* Medium devices (tablets, less than 992px) */
@media (max-width: 991.98px) {
}

/* Small devices (landscape phones, less than 768px) */
@media (max-width: 767.98px) {
  /*.fl-mobile-nav-main {*/
  /*  display: flex;*/
  /*}*/
}

/* Extra small devices (portrait phones, less than 576px) */
@media (max-width: 575.98px) {
}

/* Extra small devices (portrait phones, less than 576px) */
@media (max-width: 350px) {
  .fl-mobile-nav-photo {
    height: 60px;
    width: 60px;
  }
}