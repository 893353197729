.fl-footer-main {
    width: 100%;
    /* border-radius: 15px 15px 0px 0px; */
}

.fl-footer-main.fl-footer-main-light {
    background-color: #f4f4f4;
}
.fl-footer-main.fl-footer-main-dark {
    background-color: #1B1B1B;
}

.fl-footer-inner {
    padding: 50px 0px;
    display: flex;
}

.fl-footer-logo {
    width: 80px;
}

.fl-footer-logo-link {
    display: block;
    text-decoration: none;
    width: 100%;
    margin-bottom: 10px;
}

.fl-footer-logo-link-img {
    width: 100%;
}

.fl-footer-social-link {
    font-size: 16px;
    text-decoration: none;
    color: #888888;
    margin-right: 10px;
    border-radius: 50%;
    display: inline-flex;
    justify-content: center;
    align-items: center;
    padding: 4px;
}

.fl-footer-social-link.fl-footer-social-link-light:hover {
    background-color: #00000020;
}
.fl-footer-social-link.fl-footer-social-link-dark:hover {
    background-color: #ffffff20;
}

.fl-footer-text {
    width: calc(100% - 80px);
    padding: 0px 20px;
    font-size: 14px;
}

.fl-footer-text-inner {
    width: 90%;
    margin: 0px auto;
}

.fl-footer-text-inner.fl-footer-text-inner-light {
    color: #888888;
}
.fl-footer-text-inner.fl-footer-text-inner-dark {
    color: #fff;
}

.fl-footer-nav {
    width: 100%;
    display: flex;
    margin-bottom: 15px;
    justify-content: space-between;
}

.fl-footer-nav-link {
    text-decoration: none;
    font-weight: 500;
}

.fl-footer-nav-link.fl-footer-nav-link-light {
    color: #000;
}
.fl-footer-nav-link.fl-footer-nav-link-dark {
    color: #fff;
}

.fl-footer-nav-link:hover {
    opacity: 0.6;
}

.fl-footer-settings {
    width: 100%;
    display: flex;
    justify-content: flex-end;
}

.fl-footer-settings-link {
    font-size: 20px;
    color: #888888;
    cursor: pointer;
}

.fl-footer-logo-link-img.fl-footer-logo-link-img-dark {
    filter: invert(1);
}

.fl-footer-copyright {
    width: 100%;
    padding: 10px 20px;
    text-align: center;
    background: linear-gradient(to right, #FF037C, #FF4443);
    color: #fff;
    font-weight: 500;
    font-size: 14px;
}

.fl-footer-settings-link-img {
    width: 22px;
    height: 22px;
}
.fl-footer-part {
    display: flex;
}

.fl-footer-pad {
    padding: 0px 20px;
}

/* Large devices (desktops, less than 1200px) */
@media (max-width: 1199.98px) {
    .fl-footer-text {
        font-size: 13px;
    }
}

/* Medium devices (tablets, less than 992px) */
@media (max-width: 991.98px) { 
    .fl-footer-part {
        margin-bottom: 20px;
    }
    .fl-footer-text {
        padding-right: 0px;
        display: none;
    }
}

/* Small devices (landscape phones, less than 768px) */
@media (max-width: 767.98px) {  }

/* Extra small devices (portrait phones, less than 576px) */
@media (max-width: 575.98px) {
    .fl-footer-inner {
        display: block;
    }
    .fl-footer-logo {
        width: 100%;
        display: flex;
        justify-content: space-between;
    }
    .fl-footer-text {
        width: 100%;
    }
    .fl-footer-part {
        display: block;
    }
    .fl-footer-logo-link {
        width: 80px;
    }
    .fl-footer-social-link {
        font-size: 25px;
        margin-right: 20px;
    }
    .fl-footer-social-link:nth-last-of-type(1) {
        margin-right: 0px;
    }
    .fl-footer-text {
        padding: 30px 0px;
    }
    .fl-footer-text-inner {
        width: 100%;
    }
    .fl-footer-nav {
        flex-wrap: wrap;
    }
    .fl-footer-nav-link {
        width: 50%;
        margin-bottom: 20px;
    }
    .fl-footer-settings {
        justify-content: flex-start;
    }
}