.fl-quickpost {
  width: 60px;
  height: 60px;
  background: red;
  border-radius: 50%;
  overflow: hidden;
  z-index: 10;
  margin-bottom: 15px;
}

.fl-quickpost:hover .fl-quickpost-icon {
  transform: scale(1.1);
}

.fl-quickpost-icon {
  display: flex;
  align-items: center;
  justify-content: center;
  width: 100%;
  height: 100%;
  font-size: 33px;
  position: relative;
  top: -2px;
  right: -3px;
  transform: scale(0.9);
  transition: transform 500ms ease-in-out;
}

.fl-quickpost-icon img {
  width: 30px;
  height: 30px;
}

.fl-quickpost-fa {
  transition: transform 300ms ease-in-out;
  transform: scale(1);
}

.fl-quickpost-button {
  padding: 0px;
  font-size: 20px;
  width: 100%;
  height: 100%;
  color: #fff;
}

.fl-quickpost-button:hover .fl-quickpost-fa {
  transform: scale(1.5);
}

.fl-quickpost-fade {
  opacity: 0 !important;
  pointer-events: none !important;
}

/* Large devices (desktops, less than 1200px) */
@media (max-width: 1199.98px) {
}

/* Medium devices (tablets, less than 992px) */
@media (max-width: 991.98px) {
}

/* Small devices (landscape phones, less than 768px) */
@media (max-width: 767.98px) {
  .fl-quickpost {
    display: none;
  }
}

/* Extra small devices (portrait phones, less than 576px) */
@media (max-width: 575.98px) {
  .fl-quickpost {
    right: 20px;
    bottom: 20px;
  }
}
