.fl-settings-main {
    width: 100%;
    flex: 1;
    min-height: 100%;
}

.fl-settings-main.fl-settings-main-light {
    background-color: #f4f4f4;
}

.fl-settings-main.fl-settings-main-dark {
    background-color: #1b1b1b;
}

.fl-settings-col {
    padding: 0px 15px;
}

.fl-settings-link-box {
    display: flex;
    justify-content: space-between;
}

.fl-settings-link {
    text-decoration: none;
    font-weight: 500;
    flex-shrink: 20px;
    display: block;
}

.fl-settings-link.fl-settings-link-dark,
.fl-settings-main-dark .fl-settings-link-box {
    color: #888888;
}
.fl-settings-link.fl-settings-link-light {
    color: #000;
}

.fl-settings-link-active .fl-settings-link-box {
    color: #fff !important;
    background-color: #FF0D72 !important;
}

.fl-settings-sub-main {
    /*min-height: 400px;*/
    width: 100%;
    display: flex;
    flex-direction: column;
}

.fl-settings-sub-title {
    font-size: 22px;
    font-weight: 500;
    padding: 5px 0px 15px;
    margin-bottom: 15px;
}

.fl-settings-sub-section-p-dark {
    color: #fff;
}

.fl-settings-sub-title.fl-settings-sub-title-dark {
    color: #fff;
    border-bottom: 1px solid #ffffff20;
}

.fl-settings-sub-title.fl-settings-sub-title-light {
    color: #000;
    border-bottom: 1px solid #00000020;
}

.fl-chart {
    position: relative;
}

.fl-statistics-cart-title {
    position: absolute;
    left: -30px;
    top: 50%;
    transform: rotate(-90deg);
    font-size: 12px;
}

.fl-statistics-cart-title-light {
    color: #888888;
}

.fl-statistics-cart-title-dark {
    color: #fff;
}

.fl-settings-sub-stretch {
    flex: 1;
}

.fl-settings-sub-section {
    width: 100%;
    padding-bottom: 30px;
}

.fl-settings-sub-section-dark {
    color: white;
}

.fl-settings-svg-not-verified {
    color: red;
}

.fl-settings-svg-verified {
    color: green;
}

.fl-settings-svg-pending {
    color: orange;
}

.fl-settings-link-active .fl-settings-svg-not-verified,
.fl-settings-link-active .fl-settings-svg-verified,
.fl-settings-link-active .fl-settings-svg-pending,
.fl-settings-link-active .fl-settings-svg-send-verification {
    color: white;
}

.fl-mb-0 {
    margin-bottom: 0px !important;
}

.fl-settings-sub-stretch-blur {
    filter:blur(3px);
}

.fl-spinner {
    display: inline-block;
    width: 50px;
    height: 50px;
    position: absolute;
    right: 0;
    top: 5px;
}
.fl-spinner div {
    box-sizing: border-box;
    display: block;
    position: absolute;
    width: 30px;
    height: 30px;
    margin: 8px;
    border: 4px solid #FF0D72;
    border-radius: 50%;
    animation: lds-ring 1.2s cubic-bezier(0.5, 0, 0.5, 1) infinite;
    border-color: #FF0D72 transparent transparent transparent;
}
.fl-spinner div:nth-child(1) {
    animation-delay: -0.45s;
}
.fl-spinner div:nth-child(2) {
    animation-delay: -0.3s;
}
.fl-spinner div:nth-child(3) {
    animation-delay: -0.15s;
}

.fl-settings-grey {
    color: #888888;
}

.fl-settings-link .fl-simple-card-main:hover {
    box-shadow: 0px 5px 8px 2px #00000020;
}

.fl-settings-link-dark .fl-simple-card-main:hover {
    background: #0a0a0a;
    box-shadow: 0px 5px 8px 2px #00000020;
}

.fl-settings-link .fl-simple-card-main-dark:hover {
    color: #888888;
}

@keyframes lds-ring {
    0% {
        transform: rotate(0deg);
    }
    100% {
        transform: rotate(360deg);
    }
}

/* Large devices (desktops, less than 1200px) */
@media (max-width: 1199.98px) {

}

/* Medium devices (tablets, less than 992px) */
@media (max-width: 991.98px) { 
    .fl-settings-tablet-hide {
        display: none;
    }
    .fl-settings-tablet-show-small {
        width: calc((100% / 12) * 4);
    }
    .fl-settings-tablet-show-large {
        width: calc((100% / 12) * 8);
    }
}

/* Small devices (landscape phones, less than 768px) */
@media (max-width: 767.98px) { 

 }

/* Extra small devices (portrait phones, less than 576px) */
@media (max-width: 575.98px) {
    .fl-settings-tablet-show-small {
        display: none;
    }
    .fl-settings-tablet-show-large {
        width: 100%;
    }
    .fl-settings-mobile-no-subroute-full {
        width: 100%;
        display: block;
    }
    .fl-settings-mobile-no-subroute-hide {
        display: none;
    }
}
