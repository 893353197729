.fl-settings-messages-upload-file {
    padding: 10px 25px;
    border-radius: 15px;
    font-weight: 600;
    cursor: pointer;
    height: 50px;
    text-decoration: none;
    display: flex;
    justify-content: center;
    align-items: center;
    color: #ff037c;
    border: 3px solid #ff037c;
}
.fl-settings-messages-upload-file:hover {
    background: linear-gradient(90deg,#ff037c,#ff4443);
    color: #fff;
    border: 3px solid #ff037c;
}

.fl-settings-message-p-dark {
    color: white;
}

.fl-settings-verification-file-description span {
    color: #888;
    font-weight: 500;
    font-size: 12px;
    padding-bottom: 5px;
    margin-left: 5px;
}

.fl-settings-verification-file-description {
    padding-bottom: 5px;
    padding-top: 10px;
}

.fl-settings-verification-file {
    position: relative;
    outline: none;
}

.fl-upload-file-select-bar--option {
    font-size: 16px;
    z-index: 99;
    width: 190px;
    position: absolute;
    margin-left: auto;
    margin-right: auto;
    top: -75px;
    left: 0;
    right: 0;
    text-align: center;
}

.fl-upload-file-head-actions-dropdown-inner {
    padding: 0px 0px;
    overflow: hidden;
}

.fl-upload-file-head-actions-dropdown-light .fl-upload-file-head-actions-dropdown-inner {
    border: 1px solid #00000020;
}

.fl-upload-file-head-actions-dropdown-light .fl-upload-file-head-actions-dropdown-option {
    color: #000;
    border-bottom: 1px solid #00000020;
}

.fl-upload-file-head-actions-dropdown-option {
    width: 100%;
    padding: 10px 15px;
    cursor: pointer;
    display: flex;
     align-items: center;
}

.fl-upload-file-head-actions-dropdown-option-icon {
    display: inline-block;
}

.fl-upload-file-head-actions-dropdown-option-icon {
    width: 20px;
    height: 20px;
    margin-right: 5px;
    display: flex;
    justify-content: center;
    align-items: center;
    font-size: 16px;
    color: #ff0d7290;
}

.fl-upload-file-head-actions-dropdown-option:hover,
.fl-upload-file-head-actions-dropdown-option:hover .fl-upload-file-head-actions-dropdown-option-icon  {
    background: #ff0d72;
    color: white;
}

.fl-settings-verification-file-preview {
    width: calc(100% - 400px);
}

.fl-settings-verification-file-preview-image {
    max-width: 200px;
    text-align: center;
    display: block;
    margin-left: auto;
    margin-right: auto;
    position: relative;
}

.fl-settings-verification-file-preview-image img {
    width: 100%;
    border-radius: 5px;
}

.fl-settings-verification-file-preview-image:hover .fl-direct-messages-box-files-single-remove {
    display: flex;
}

.fl-settings-sub-stretch .fl-direct-messages-box-files-price {
    border: none;
}

.fl-settings-sub-stretch fl-bulk__price__currency  {
    top: 35px;
}

.fl-settings-sub-stretch .fl-welcome-message-price-error span {
    margin-left: 115px;
    display: flex;
    margin-top: 6px;
}

.fl-settings-sub-stretch .fl-bulk__price__toggle-blurred {
    padding-bottom: 15px;
}

.fl-welcome-settings-store {
    float: left;
    width: 100%;
}

.fl-welcome-settings-store .fl-direct-messages-box-send-record {
    display: none;
}

.fl-welcome-settings-button button {
    float: left;
    margin-left: 10px;
}

.fl-settings-sub-stretch .fl-direct-messages-box-input  {
    border: none;
}

.fl-settings-sub-stretch .fl-direct-messages-box-input-form-inner {
    border-radius: 10px;
    height: 60px;
}

.fl-welcome-message-toggle {
    display: flex;
    align-items: center;
    padding: 20px 0px;
}

.fl-welcome-message-toggle span {
    color: #888;
    margin-left: 5px;
}

.fl-welcome-message-set,
.fl-welcome-message-change span {
    color: #15c;
    cursor: pointer;
    text-decoration: underline;
}

.fl-welcome-message-change-set:hover
.fl-welcome-message-change span:hover {
    text-decoration: none;
}

.fl-welcome-message-change,
.fl-welcome-message-info {
    margin: 0px auto 10px;
    position: relative;
    flex-direction: column;
    display: flex;
}

.fl-welcome-message-change {
    padding-top: 10px;
}

.fl-welcome-message-info {
    color: #000;
    text-decoration: underline;
}

.fl-settings-sub-section-welcome-message {
    margin-bottom: 0px;
}

.fl-welcome-message-info-tittle {
    padding-top: 20px;
}



.fl-welcome-message-drop .fl-direct-message-drop-files {
    display: flex;
}

.fl-welcome-message-drop .fl-settings-sub-section,
.fl-welcome-message-drop .fl-welcome-settings-store,
.fl-welcome-message-drop  .fl-welcome-settings-button,
.fl-welcome-message-drop .fl-message-bubble-main
{
    opacity: 0.1;
    position: relative;
}