.fl-sidebar-wrapper {
    width: 100%;
    height: 100%;
    position: fixed;
    z-index: 10000;
    backdrop-filter: blur(0px);
    left: 0;
    top: 0px;
    transition: backdrop-filter 100ms ease-in-out, background-color 100ms ease-in-out;
    pointer-events: none;
    overflow: hidden;
    background-color: #00000000;
}

.fl-sidebar-free-account {
    margin-left: 10px;
    color: #15c;
    text-decoration: none;
}

.fl-sidebar-free-account:hover {
    text-decoration: underline;
}

.fl-sidebar-wrapper-show {
    /* backdrop-filter: blur(5px);
    background-color: #00000090; */
    background-color: rgba(0, 0, 0, 0.5);
    -webkit-tap-highlight-color: transparent;
    pointer-events: all;
}

.fl-sidebar-main {
    width: 100%;
    right: 0px;
    top: 0px;
    height: 100%;
    width: 100%;
    max-width: 320px;
    position: absolute;
    transform: translateX(100%);
    transition: transform .4s ease-in-out;
}

.fl-sidebar-wrapper-show .fl-sidebar-main {
    transform: translateX(0%);
}

.fl-sidebar-main.fl-sidebar-main-dark {
    background-color: #000;
    box-shadow: 0px 0px 10px #7b7f84;
}
.fl-sidebar-main.fl-sidebar-main-light {
    background-color: #fff;
}

.fl-sidebar-title {
    width: 100%;
    height: 50px;
    display: flex;
    align-items: center;
}

.fl-sidebar-title.fl-sidebar-title-dark {
    border-bottom: 1px solid #ffffff20;
}
.fl-sidebar-title.fl-sidebar-title-light {
    border-bottom: 1px solid #00000020;
}

.fl-sidebar-back {
    width: 50px;
    height: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
    color: #888;
    cursor: pointer;
}

.fl-sidebar-back.fl-sidebar-back-dark {
    border-right: 1px solid #ffffff20;
}
.fl-sidebar-back.fl-sidebar-back-light {
    border-right: 1px solid #00000020;
}
.fl-sidebar-back.fl-sidebar-back-dark:hover {
    color: #ddd;
}
.fl-sidebar-back.fl-sidebar-back-light:hover {
    color: #333;
}

.fl-sidebar-title-label {
    width: calc(100% - 50px);
    height: 100%;
    padding: 0px 15px;
    display: flex;
    align-items: center;
    font-size: 20px;
    color: #888;
}

.fl-sidebar-profile {
    width: 100%;
    padding: 15px;
    display: flex;
    align-items: center;
    justify-content: space-between;
    cursor: pointer;
    text-decoration: none;
    background: linear-gradient(to right, #FF037C, #FF4443);
}

.fl-sidebar-profile:hover {
    background: linear-gradient(to right, #ea0070, #e43c3b);
}

.fl-sidebar-profile-image {
    width: 60px;
    height: 60px;
    position: relative;
    border-radius: 50%;
}

.fl-sidebar-profile-text {
    width: calc(100% - 110px);
}

.fl-sidebar-profile-image .fl-profile-holder {
    background-color: white;
}

.fl-sidebar-profile-fullname {
    font-size: 16px;
    font-weight: 600;
    margin-bottom: 5px;
    width: 100%;
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
    color: #fff;
    display: block;
    text-decoration: none;
}

.fl-sidebar-profile-username {
    font-size: 16px;
    color: #efefef;
    width: 100%;
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
    display: block;
    text-decoration: none;
}

.fl-sidebar-profile-follow {
    display: flex;
    width: 100%;
}

.fl-sidebar-profile-follow-value {
    width: 50%;
    height: 30px;
    display: flex;
    align-items: center;
    justify-content: center;
    text-decoration: none;
    font-size: 14px;
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
}

.fl-sidebar-profile-follow-value.fl-sidebar-profile-follow-value-light:nth-of-type(1) , .fl-sidebar-profile-follow-value.fl-sidebar-profile-follow-value-dark:nth-of-type(2) {
    background-color: #000;
    color: #fff;
}

.fl-sidebar-profile-follow-value.fl-sidebar-profile-follow-value-light:nth-of-type(1):hover , .fl-sidebar-profile-follow-value.fl-sidebar-profile-follow-value-dark:nth-of-type(2):hover {
    background-color: #323232;
    color: #fff;
}

.fl-sidebar-profile-follow-value.fl-sidebar-profile-follow-value-light:nth-of-type(2) {
    background-color: #fff;
    color: #000;
}

.fl-sidebar-profile-follow-value.fl-sidebar-profile-follow-value-dark:nth-of-type(1) {
    background-color: #888888;
    color: white;
}

.fl-sidebar-profile-follow-value.fl-sidebar-profile-follow-value-light:nth-of-type(2):hover , .fl-sidebar-profile-follow-value.fl-sidebar-profile-follow-value-dark:nth-of-type(1):hover {
    background-color: #efefef;
    color: #000;
}

.fl-sidebar-navitem-icon-rotate {
    transform: rotateY(180deg);
}

.fl-sidebar-profile-follow-value:hover {
    background-color: #ea0070;
}

.fl-sidebar-profile-follow-value:nth-last-of-type(1) {
    border-right: none;
}

.fl-sidebar-profile-icon {
    width: 30px;
    font-size: 16px;
    color: #fff;
    display: flex;
    justify-content: center;
}

.fl-sidebar-scrollable {
    overflow-x: hidden;
    overflow-y: scroll;
    width: 100%;
    height: calc(100% - 50px);
}

.fl-sidebar-navitem {
    height: 40px;
    width: 100%;
    display: flex;
    align-items: center;
    padding: 20px;
    text-decoration: none;
    color: #888;
    cursor: pointer;
    font-size: 14px;
}

.fl-sidebar-navitem.fl-sidebar-navitem-dark {
    border-bottom: 1px solid #ffffff20;
}
.fl-sidebar-navitem.fl-sidebar-navitem-light {
    border-bottom: 1px solid #00000020;
}

.fl-sidebar-navitem.fl-sidebar-navitem-dark:hover {
    background-color: #ffffff10;
}
.fl-sidebar-navitem.fl-sidebar-navitem-light:hover {
    background-color: #00000010;
}

.fl-sidebar-navitem-icon {
    font-size: 18px;
    margin-right: 15px;
    width: 20px;
    text-align: center;
    display: block;
}

.fl-sidebar-navitem-icon img {
    width: 100%;
}

.fl-sidebar-category {
    width: 100%;
}

.fl-sidebar-category-title {
    height: 40px;
    width: 100%;
    display: flex;
    align-items: center;
    padding: 20px;
    font-size: 16px;
    font-weight: 500;
}

.fl-sidebar-category-title.fl-sidebar-category-title-dark {
    color: #fff;
    background-color: #ffffff20;
}

.fl-sidebar-category-title.fl-sidebar-category-title-light {
    color: #000;
    background-color: #00000020;
}

.fl-sidebar-category-items {
    width: 100%;
}

.fl-sidebar-verified {
    /* background-color: #FF0D72; */
    padding: 4px 5px 2px;
    border-radius: 5px;
    text-transform: uppercase;
    font-size: 26px;
    font-weight: 600;
    /* color: #fff; */
    width: 20px;
    height: 20px;
    border-radius: 50%;
    position: relative;
    margin-left: 5px;
}

.fl-sidebar-verified-crt {
    color: #25de25;
    font-size: 20px;
    position: absolute;
    left: 50%;
    top: 50%;
    transform: translate(-50%,-50%);
}

.fl-sidebar-verified-chc {
    color: #fff;
    font-size: 11px;
    position: absolute;
    left: 50%;
    top: 50%;
    transform: translate(-50%,-50%);
}

.fl-sidebar-verified-icon {
    width: 15px;
    height: 15px;
    margin-left: 5px;
    margin-top: -4px;
}

.fl-sidebar-verified-icon img {
    width: 100%;
}

.fl-sidebar-counter {
    min-width: 14px;
    height: 14px;
    font-size: 10px;
    line-height: 10px;
    color: #fff;
    border-radius: 5px;
    background-color: red;
    display: flex;
    align-items: center;
    justify-content: center;
    font-weight: bold;
    padding: 2px 3px 0px;
    margin-left: 5px;
}

.fl-sidebar-profile-follow-value {
    cursor: pointer;
}