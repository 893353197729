.fl-drive-sub-section {
    width: 100%;
    margin-bottom: 30px;
    margin-top: 30px;
}

.fl-drive-sub-light {
    color: #000;
}

.fl-drive-tittle-icon {
    margin-left: 5px;
    font-size: 18px;
}

.fl-drive-sub-payment-transactions {
    width: 100%;
}

.fl-drive-sub-single-head {
    background-color: #424242 !important;
    color: #fff;
    font-weight: 500;
    text-transform: uppercase;
}

.fl-drive-sub-single {
    width: 100%;
    display: flex;
    min-height: 40px;
    align-items: center;
    position: relative;
}

.fl-drive-sub-single-labels  {
    width: 40%;
    padding: 0px 10px;
}

.fl-drive-sub-single-duration,
.fl-drive-sub-single-size,
.fl-drive-sub-single-date {
    width: 18%;
    padding: 0px 10px;
}

.fl-drive-sub-single-img {
    width: 8%;
    padding: 0px 10px;
}

.fl-drive-sub-single-labels {
    padding: 5px;
}

.fl-drive-sub-single-light {
    background-color: #00000005;
}

.fl-drive-sub-single-light:nth-of-type(odd) {
    background-color: #00000010;
}

.fl-drive-sub-single-dark {
    background-color: #ffffff05;
}

.fl-drive-sub-single-dark:nth-of-type(odd) {
    background-color: #ffffff10;
}

.fl-drive-sub-single-date-short {
    display: block;
}

.fl-drive-sub-dark {
    color: #fff;
}

.fl-drive-sub-more {
    color: #ff0d72;
    padding: 10px 0px;
    margin-top: 10px;
    cursor: pointer;
}

.fl-drive-image-label-name {
    color: white;
    cursor: pointer;
}

.fl-drive-image-label-add-tag {
    color: #15c;
}

.fl-drive-sub-single-date {
    cursor: default;
}

.fl-drive-sub-single-img div {
    width: 50px;
    outline: none;
    cursor: pointer;
}

.fl-drive-sub-single-img div img,
.fl-drive-sub-single-img div video {
    width: 50px;
    height: 50px;
    object-fit: cover;
    padding: 5px;
    border-radius: 15%;
    cursor: pointer;
}

.fl-drive-sub-single .fl-drive-image-svg {
    right: 15px;
    z-index: 9;
}

/* Medium devices (tablets, less than 992px) */
@media (max-width: 991.98px) {

}

/* Small devices (landscape phones, less than 768px) */
@media (max-width: 767.98px) {
    .fl-drive-sub-single-head {
        display: none !important;
    }
    .fl-drive-sub-single-labels {
        width: 100%;
    }
    .fl-drive-sub-single {
        display: flex;
        height: auto;
        padding: 20px 10px;
        flex-direction: column;
    }
    .fl-drive-sub-single-img,
    .fl-drive-sub-single-date,
    .fl-drive-sub-single-size,
    .fl-drive-sub-single-duration {
        width: 100%;
    }
}

/* Extra small devices (portrait phones, less than 576px) */
@media (max-width: 575.98px) {
}
