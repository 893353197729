.fl-post-main {
  padding: 0;
}

.fl-post-head {
  width: 100%;
  padding: 20px;
  display: flex;
  align-items: center;
}

.fl-post-actions {
  border-top: 1px solid #0000001c;
  display: flex;
  padding: 20px 10px 20px 30px;
  justify-content: space-between;
  flex-wrap: wrap;
}

.fl-post-actions-dark {
  border-top: 1px solid #ffffff20;
}

.fl-simple-card-main-light.fl-feed-new-post {
  background-color: #cae1f3 !important;
}

.fl-simple-card-main-dark.fl-feed-new-post {
  background-color: #2e2f30 !important;
}

.transform-component-module_content__2jYgh img {
  pointer-events: all !important;
}

.fl-post-image-reported-wrapper {
  position: absolute;
  left: 0px;
  top: 0px;
  width: 100%;
  height: 100%;
  backdrop-filter: blur(5px);
  /* background-color: #00000090; */
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
}

.fl-post-image-reported {
  padding: 5px 15px;
  height: auto;
  border-radius: 5px;
  opacity: 1 !important;
  position: absolute;
}

.fl-post-image-reported-button {
  background: linear-gradient(to right, #15c, #347af7);
  border: 3px solid #15c;
}

.fl-post-image-reported-button:hover {
  background: linear-gradient(to right, #0f4fbf, #1e6cf7) !important;
  box-shadow: 0px 5px 8px 2px #00000020;
}

.fl-write-post-schedule-date-container {
  width: 100%;
  padding-top: 10px;
  text-align: left;
  padding-left: 20px;
  padding-right: 20px;
}
.fl-write-post-schedule-date-single {
  cursor: pointer;
}
.fl-post-common-actions {
  display: flex;
}

.fl-post-action-single {
  font-size: 16px;
  display: flex;
  align-items: center;
  margin-right: 25px;
  color: #888888;
  cursor: pointer;
  user-select: none;
  height: 16px;
}

.fl-post-action-single:hover {
  color: #ff0d72;
  cursor: pointer;
}

.fl-post-counts-disable {
  pointer-events: none;
}

.fl-post-action-single-no-hover {
  font-size: 16px;
  display: flex;
  align-items: center;
  margin-right: 25px;
  color: #888888;
  pointer-events: none;
  user-select: none;
}

.fl-post-action-single span {
  padding-top: 4px;
}

.fl-post-action-single span:hover {
  cursor: pointer;
}

.fl-post-action-single:nth-last-of-type(1) {
  margin-right: 0px;
}

.fl-post-action-iconholder {
  font-size: 17px;
  margin-right: 6px;
  position: relative;
  width: 20px;
  height: 16px;
}

/*.fl-post-action-single-icon {*/
/*  position: absolute;*/
/*  left: 50%;*/
/*  top: 50%;*/
/*  transform: translate(-50%, -50%);*/
/*  animation: like 300ms ease-in-out forwards;*/
/*}*/

.fl-post-action-single .fl-post-action-single-icon:hover {
  color: #ff0d72;
}

.fl-post-action-single-icon-no-hover,
.fl-post-action-single-icon {
  animation: unlikePost 0.1s;
  animation-iteration-count: 1;
}

.fl-post-action-single-icon-active {
  color: #ff0d72;
  animation: likePost 0.3s;
  animation-iteration-count: 1;
}

.fl-post-scheduled-icon {
  margin-top: -3px;
}

@keyframes likePost {
  from {
    transform: scale(0);
  }
  to {
    transform: scale(1);
  }
}

@keyframes unlikePost {
  from {
    transform: scale(1);
  }
  to {
    transform: scale(0);
  }
}

.fl-post-action-single-disabled {
  opacity: 0.6;
  cursor: default;
  pointer-events: none;
}
.fl-post-action-save-icon {
  font-size: 20px;
}

.fl-post-images {
  width: 100%;
  height: 400px;
  background-color: #888888;
  overflow: hidden;
  background-repeat: no-repeat;
  background-size: 15%;
  background-position: left 20px top 20px;
  background-color: #e6e5e5;
  position: relative;
}

.fl-post-encoding {
  width: 100%;
  height: 400px;
  background-color: #e6e5e5;
  overflow: hidden;
  background-repeat: no-repeat;
  background-size: 15%;
  background-position: left 20px top 20px;
  position: relative;
  display: flex;
  align-items: center;
  justify-content: center;
}

.fl-post-encoding .fl-encoding-post-thumb-text {
  font-size: 18px;
}

.fl-post-images-grid {
  height: 200px;
}

.fl-post-encoding span {
  color: #888888;
}

.fl-post-images-row-wrapper {
  width: 100%;
  position: relative;
  cursor: pointer;
}

/*.fl-post-images-row-wrapper .smooth-image-wrapper .smooth-preloader {*/
/*  display: none;*/
/*}*/

.fl-post-images-row-wrapper-false {
  cursor: unset;
}

.fl-post-images-row {
  /*width: 100%;*/
  height: 100%;
  position: relative;
  display: flex;
  flex-wrap: nowrap;
  transform: translateX(0%);
  outline: none;
}

.fl-post-modal-preview .smooth-image video {
  margin-top: 0px;
  width: 100%;
  height: 500px;
}

.fl-post-images-row-transition {
  transition: transform 300ms ease-in-out;
}

.fl-post-images-row-no-transition {
  transition: "none";
}

.fl-post-images-row-preview {
  z-index: 100;
  color: #ff0d72;
  position: absolute;
  bottom: 2px;
  right: 2px;
  font-size: 2rem;
  transition: 0.25s;
  cursor: pointer;
}
.fl-post-images-row-preview:hover {
  transform: scale(1.1);
}

.fl-post__video video {
  /*width: 763px !important;*/
}

.fl-post-image-swipe-count {
  user-select: none;
  position: absolute;
  left: 50%;
  bottom: 25px;
  transform: translateX(-50%);
  display: flex;
  pointer-events: all;
  background-color: #00000054;
  color: #fff;
  padding: 0.5rem;
  border-radius: 4px;
  margin-bottom: -12px;
  border:0.5px solid white;
}
.fl-post-image-swipe-count svg,
.fl-post-image-swipe-count img {
  margin: 0px 4px;
}

.fl-post-image-swipe-count img {
  width: 16px;
}

.fl-post-image-swipe-count b {
  font-weight: 600;
  margin-top: 2.3px;
  font-size: 14px;
}

.fl-post-image-swipe-count span {
  width: 10px;
  height: 10px;
  border-radius: 50%;
  background-color: #fff;
  opacity: 0.4;
  transition: opacity 300ms ease-in-out, background-color 300ms ease-in-out;
  margin: 0px 3px;
  cursor: pointer;
}

.fl-post-image-swipe-count span.fl-post-image-swipe-count-active {
  opacity: 1;
  background-color: #ff1e64;
}

.fl-post-images-row img {
  width: 100%;
  /*height: 100%;*/
  height: 400px;
  object-fit: contain;
  object-position: center;
  min-width: 100%;
  min-height: 100%;
  position: relative;
  pointer-events: none;
}

.fl-post-images-row-grid img {
  height: 200px;
}

.fl-post-images-row .smooth-image,
.fl-post-images-row .fl-smooth-video {
  width: 100% !important;
  /*height: 100%;*/
  height: 400px !important;
  object-fit: contain !important;
  object-position: center;
  min-width: 100%;
  min-height: 100%;
  position: relative;
}

.fl-post-images-row-grid .smooth-image, 
.fl-post-images-row-grid .fl-smooth-video {
  height: 200px !important;
}

.fl-post-images-row .smooth-image-wrapper {
  display: contents;
}

.fl-post-images-row img::before {
  position: absolute;
  width: 100%;
  background-color: #666;
  content: "";
  display: flex;
  align-items: center;
  justify-content: center;
  color: #fff;
  font-size: 12px;
  font-weight: bold;
  height: 100%;
  left: 0;
  top: 0;
}

.fl-post-images-swipe {
  position: absolute;
  left: 0;
  top: 0;
  width: 100%;
  height: 100%;
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 10px;
  pointer-events: none;
}

.fl-fullscreen-icon svg {
  stroke: #ff0d72;
  stroke-width: 25px;
}
.fl-post-images-swipe-button {
  width: 30px;
  height: 30px;
  font-size: 20px;
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: #00000054;
  color: #fff;
  border-radius: 50%;
  text-shadow: 0px 0px 2px #00000090;
  cursor: pointer;
  opacity: 1;
  transition: 300ms opacity ease-in-out;
  pointer-events: all;
}

.fl-post-images-swipe-button svg {
  -webkit-touch-callout: none; /* iOS Safari */
  -webkit-user-select: none; /* Safari */
  -khtml-user-select: none; /* Konqueror HTML */
  -moz-user-select: none; /* Old versions of Firefox */
  -ms-user-select: none; /* Internet Explorer/Edge */
  user-select: none; /* Non-prefixed version, currently
                                  supported by Chrome, Edge, Opera and Firefox */
}

.fl-post-images-swipe-button:hover {
  border-radius: 9999px;
  background-color: #00000078;
}

.fl-post-images-swipe-button-disabled {
  opacity: 0.4 !important;
  pointer-events: none !important;
}

.fl-post-head-image {
  width: 56px;
  height: 56px;
  border-radius: 50%;
  position: relative;
  display: block;
  z-index: 1;
}

.fl-post-head-info {
  width: calc(100% - 76px);
  padding: 0px 20px;
}

.fl-post-head-info-padding {
  padding: 0px 20px;
}

.fl-post-head-info-name {
  width: 100%;
  font-size: 21px;
  font-weight: 600;
  margin-bottom: 5px;
  line-height: 30px;
  text-decoration: none;
  cursor: pointer !important;
}

.fl-post-head-info-name.fl-post-head-info-name-light {
  color: #000;
}

.fl-post-head-info-time-link {
  text-decoration: none;
  text-align: center;
}

.fl-post-head-info-time-link-disabled {
  pointer-events: none;
}

.fl-post-head-info-name.fl-post-head-info-name-dark {
  color: #fff;
}

.fl-post-head-info-dot {
  width: 5px;
  height: 5px;
  border-radius: 50%;
  background-color: #888888;
  margin: 0px 20px 4px;
}

.fl-post-head-info-username {
  color: #888888;
  font-size: 18px;
  text-decoration: none;
  display: block;
  cursor: pointer;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
}

.fl-post-head-info-username-width {
  color: #888888;
  font-size: 18px;
  text-decoration: none;
  display: block;
  cursor: pointer;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
}

.fl-post-head-info-username:hover,
.fl-post-head-info-time .fl-post-head-info-username:hover,
.fl-post-head-actions svg:hover,
.fl-post-counts-comments span:hover,
.fl-post-head-info,
.fl-options-container div:hover,
.fl-my-profile-info a:hover,
.fl-suggestion-info a:hover {
  color: #525252;
}

.fl-post-head-info-name:hover {
  cursor: default;
}

.smooth-image-wrapper:hover:not(.fl-user-profile-images-main-inner
    .smooth-image-wrapper:hover, .fl-user-profile-images-cover
    .smooth-image-wrapper:hover, .fl-drive-image
    .smooth-image-wrapper:hover, .fl-featured-profiles-list
    .smooth-image-wrapper:hover, .fl-tag-list-image
    .smooth-image-wrapper:hover, .fl-user-profile-modal-img-holder
    .smooth-image-wrapper:hover, .fl-support-no-hover .smooth-image-wrapper:hover) {
  opacity: 0.7;
}

.fl-post-head-info-time {
  color: #888888;
  font-size: 18px;
  line-height: 18px;
  min-width: 7%;
}

.fl-post-head-info-time-width {
  color: #888888;
  font-size: 18px;
  line-height: 18px;
}

.fl-post-head-actions {
  position: relative;
  outline: none;
}

.fl-singlenotification__main .fl-post-head-actions {
  position: absolute;
  outline: none;
  right: 15px;
  outline: none;
}

.fl-post-preview {
  width: 100%;
  height: 100%;
  position: fixed;
  left: 0px;
  top: 0px;
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 99;
}

.fl-post-preview .fl-gotopost-post-comment-list {
  overflow-x: hidden;
  overflow-y: scroll;
}

.fl-post-preview.fl-post-preview-dark {
  background-color: #060606e0;
}

.fl-post-preview-dark .fl-gotopost-row {
  box-shadow: 0px 0px 10px #7b7f84;
}

.fl-post-preview.fl-post-preview-light {
  background-color: #000000cf;
}

.fl-post-head-actions-dropdown-option-icon {
  width: 20px;
  height: 20px;
  margin-right: 5px;
  display: flex;
  justify-content: center;
  align-items: center;
  font-size: 16px;
  color: #ff0d7290;
}
.fl-post-head-actions-dropdown-option:hover
  .fl-post-head-actions-dropdown-option-icon,
.fl-post-head-actions-dropdown-option-icon svg:hover {
  color: #fff;
}

.fl-post-head-actions-icon {
  color: #333333;
  width: 20px !important;
  height: 20px;
  cursor: pointer;
}

.fl-post-text {
  padding: 0px 30px;
  font-size: 18px;
  line-height: 30px;
  color: #888888;
  font-weight: 500;
  margin-bottom: 20px;
}

/*.fl-post-counts-light {*/
/*  border-bottom: 1px solid #0000001c;*/
/*}*/
.fl-post-counts-dark {
  border-bottom: 1px solid #ffffff20;
}
.fl-post-counts {
  padding: 20px 30px;
  font-size: 16px;
  font-weight: 400;
  display: flex;
  justify-content: space-between;
}

.fl-post-counts-likes.fl-post-counts-likes-dark {
  color: #fff;
}
.fl-post-counts-likes.fl-post-counts-likes-light {
  color: #000;
}

.fl-post-counts-likes span {
  color: #ff0d72;
  font-weight: 500;
}

.fl-post-counts-comments {
  color: #888888;
  font-weight: 500;
  cursor: pointer;
  user-select: none;
}

.fl-post-comments {
  padding: 25px 30px;
}

.fl-post-comments.fl-post-comments-light {
  border-top: 1px solid #00000020;
}
.fl-post-comments.fl-post-comments-dark {
  border-top: 1px solid #ffffff20;
}

.fl-post-comments-make {
  position: relative;
  width: 100%;
  padding: 30px;
}

.fl-post-comments-make.fl-post-comments-make-dark {
  border-top: 1px solid #ffffff20;
}
.fl-post-comments-make.fl-post-comments-make-light {
  border-top: 1px solid #00000020;
}

.fl-post-comments-make-input {
  margin: 0px;
}

.fl-post-comments-make-input input {
  width: 100%;
  border-radius: 8px;
}

.fl-post-comments-make-input.fl-post-comments-make-input-light input {
  border: 1px solid #00000020;
}

.fl-post-comments-make-input.fl-post-comments-make-input-dark input {
  border: 1px solid #ffffff20;
}

.fl-post-comments-make-buttons {
  display: flex;
  justify-content: flex-end;
  align-items: center;
}

.fl-post-comments-make-button {
  padding: 6px 20px;
  height: 36px;
  border-radius: 8px;
  margin-right: 10px;
}

.fl-post-comments-make-button:nth-last-of-type(1) {
  margin-right: 0px;
}

.fl-post-comments-make-label {
  color: #ff0d72;
  font-size: 16px;
}

.fl-post-comments-make-label i {
  color: #888888;
  font-style: italic;
  display: block;
}

.fl-post-head-actions-dropdown {
  position: absolute;
  right: -10px;
  top: 20px;
  width: 240px;
  z-index: 10;
  font-size: 14px;
}

.fl-post-head-actions-dropdown-overlay {
  right: 4px;
  top: 45px;
  max-width: 240px;
  width: calc(100% - 5px);
}

.fl-post-head-actions-dropdown--right {
  right: -50px;
}

.fl-post-head-actions-dropdown-dark .fl-post-head-actions-dropdown-option {
  color: #fff;
  border-bottom: 1px solid #ffffff20;
}

.fl-post-head-actions-dropdown-light .fl-post-head-actions-dropdown-option {
  color: #000;
  border-bottom: 1px solid #00000020;
}

.fl-post-head-actions-dropdown-option {
  width: 100%;
  padding: 8px 15px;
  cursor: pointer;
  display: flex;
  align-items: center;
}

.fl-post-head-actions-dropdown-option:hover {
  background-color: #ff0d72;
  color: #fff;
}

.fl-post-head-actions-dropdown-option:hover svg {
  color: white;
}

.fl-post-head-actions-dropdown-option:nth-last-of-type(1) {
  border-bottom: none;
}

.fl-post-head-actions-dropdown-inner {
  padding: 0px 0px;
  overflow: hidden;
}

.fl-post-head-actions-dropdown-dark .fl-post-head-actions-dropdown-inner {
  border: 1px solid #ffffff20;
}

.fl-post-head-actions-dropdown-light .fl-post-head-actions-dropdown-inner {
  border: 1px solid #00000020;
}

.fl-post-comments-reply {
  position: relative;
  padding-top: 15px;
  /*width: calc(100% - 60px);*/
  /*margin-left: 60px;*/
}

.fl-post-comments-reply-cancel {
  width: 0;
}

.fl-post-comments-make-all {
  width: 100%;
  position: relative;
  display: flex;
  align-items: center;
  /*margin-bottom: 10px;*/
}
.fl-post-comments-light textarea,
.fl-gotopost-post-comment-single-data-text-data-light textarea {
  border: 1px solid #00000020;
}

.fl-post-comments-dark textarea,
.fl-gotopost-post-comment-single-data-text-data-dark textarea {
  border: 1px solid #ffffff20;
}

.fl-post-comment-make-button-cancel {
  position: absolute;
  right: 5px;
  top: 0px;
  width: 30px;
  height: 30px;
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;
}
.fl-post-comment-make-button-cancel.fl-post-comment-make-button-cancel-light {
  color: #666;
}
.fl-post-comment-make-button-cancel.fl-post-comment-make-button-cancel-light:hover {
  color: #000;
}
.fl-post-comment-make-button-cancel.fl-post-comment-make-button-cancel-dark {
  color: #aaa;
}
.fl-post-comment-make-button-cancel.fl-post-comment-make-button-cancel-dark:hover {
  color: #fff;
}

.fl-post-comment-emojis {
  width: 30px;
  height: 30px;
  font-size: 24px;
  display: flex;
  align-items: center;
  justify-content: center;
  color: #88888888;
  cursor: pointer;
  position: relative;
  z-index: 1;
  min-width: 30px;
  margin-right: 10px;
}

.fl-post-comment-emojis:hover {
  color: #888888bf;
}
.fl-post-comment-emojis-picker aside.emoji-picker-react {
  width: 100%;
  border-radius: 0px 0px 15px 15px;
  box-shadow:none;
}

.fl-write-post-all-light .fl-post-comment-emojis-picker aside.emoji-picker-react {
  border-left: 1px solid #00000032;
  border-right: 1px solid #00000032;
  border-bottom: 1px solid #00000032;
}

.fl-write-post-all-dark .fl-post-comment-emojis-picker aside.emoji-picker-react {
  border-left: 1px solid #ffffff80;
  border-right: 1px solid #ffffff80;
  border-bottom: 1px solid #ffffff80;
}

.fl-post-comment-emojis-picker:not(.fl-write-post-main .fl-post-comment-emojis-picker) {
  position: absolute;
  left: 0px;
  top: 35px;
  z-index: 100;
}

.fl-write-post-main .fl-post-comment-emojis-picker {
  position: absolute;
  z-index: 10;
  top: 0px;
  left: 0rem;
}

.fl-post-preview-action {
  width: 40px;
  height: 60px;
  font-size: 32px;
  color: #fff;
  display: flex;
  justify-content: center;
  align-items: center;
  cursor: pointer;
}

.fl-post-preview-holder {
  margin: 0px !important;
}

.fl-post-preview-action-disabled {
  opacity: 0;
  pointer-events: none;
}

.fl-post-head-image .fl-direct-messages-title-indicator {
  position: absolute;
  bottom: 5px;
  right: 0px;
  z-index: 99;
}

.fl-images-overlayer {
  width: 100%;
  height: 100%;
  position: absolute;
  color: white;
  display: none;
}

.fl-post-images-row:hover
  > .fl-images-overlayer:not(.fl-post-images-drive-row:hover
    .fl-images-overlayer, .fl-post-images-row:hover .fl-post-images-option) {
  /*background-color: rgba(0, 0, 0, 0.3);*/
  display: flex;
  z-index: 10;
  justify-content: center;
  align-items: center;
}

.fl-images-overlayer > div {
  /* display: inline-block;
  position: absolute;
  top: 48%; */
  transform: scale(1.3);
  z-index: 10;
}

.fl-images-overlayer-false > div {
  /* top: 6%; */
}

.fl-images-overlayer span {
  margin-left: 3px;
}

.fl-images-overlayer > div:first-child {
  /* left: 30%; */
  /*margin-right: 25px;*/
}

.fl-images-overlayer > div:last-child {
  /* right: 30%; */
}

.fl-post-actions-disable .fl-post-action-single:hover,
.fl-post-actions-disable .fl-post-action-single-icon:hover {
  color: #c3c3c3;
  cursor: default;
  pointer-events: none;
}

.fl-post-actions-disable .fl-post-action-single,
.fl-post-counts-disable,
.fl-post-counts-disable .fl-post-counts-likes span,
.fl-post-counts-disable .fl-post-counts-comments,
.fl-post-action-single-no-hover {
  color: #c3c3c3;
  cursor: default;
  pointer-events: none;
}

.fl-post-actions-ppv-true .fl-post-action-single:hover,
.fl-post-actions-ppv-true .fl-post-action-single-icon:hover {
  color: #c3c3c3;
  cursor: default;
  pointer-events: none;
}

.fl-post-actions-ppv-true .fl-post-action-single,
.fl-post-actions-ppv-true,
.fl-post-actions-ppv-true .fl-post-counts-likes span,
.fl-post-counts-ppv-true .fl-post-counts-comments {
  color: #c3c3c3;
  cursor: default;
  pointer-events: none;
}

.fl-post-actions-subscribe-true .fl-post-action-single:hover,
.fl-post-actions-subscribe-true .fl-post-action-single-icon:hover {
  color: #c3c3c3;
  cursor: default;
  pointer-events: none;
}

.fl-post-actions-subscribe-true .fl-post-action-single,
.fl-post-actions-subscribe-true,
.fl-post-actions-subscribe-true .fl-post.fl-post-counts-counts-likes span,
.fl-post-counts-subscribe-true .fl-post-counts-comments {
  color: #c3c3c3;
  cursor: default;
  pointer-events: none;
}

.fl-post-actions-disabled {
  cursor: default;
  pointer-events: none;
}

.fl-post-actions-disabled .fl-post-action-single {
  color: #c3c3c3;
}

.fl-post-comments-make-light textarea,
.fl-simple-comment-make-light textarea {
  border: 1px solid #00000010;
  color: #000;
  background-color: #00000010;
}

.fl-post-comments-make-dark textarea {
  border: 1px solid #ffffff20;
  color: #fff;
  background-color: #00000010;
}

.fl-post-comments-make-all textarea,
.fl-simple-comment-make-all textarea {
  min-height: 34px;
  padding: 8px 10px;
  border-radius: 5px;
}

.fl-post-comments-make-all textarea{
  padding: 8px 10px;
}

.fl-simple-comment-make-all textarea {
  padding: 10px 10px;
}

.smooth-no-image {
  display: none !important;
}

.fl-post-images-option {
  width: 30px;
  height: 30px;
  min-width: 30px;
  font-size: 18px;
  display: flex;
  justify-content: center;
  align-items: center;
  color: white;
  position: relative;
  -webkit-user-select: none;
  background: linear-gradient(to right, #ff037c, #ff4443);
  border-radius: 20px;
  top: 10px;
  cursor: pointer;
  display: none;
}

.fl-post-images:hover .fl-post-images-option {
  display: flex;
  animation: remove-preview-popup 0.3s forwards;
  position: absolute;
  right: 10px;
  z-index: 10;
}

.fl-full-screen {
  position: fixed;
  z-index: 999;
  top: 0px;
  left: 0px;
  width: 100%;
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
}

.fl-full-screen .fl-fullscreen__close {
  position: absolute;
  right: 5px;
  top: 5px;
}

.fl-full-screen-image {
  width: 100%;
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
}

.fl-full-screen-size-true {
  transform: scale(1);
  transition: transform 0.5s;
}

@keyframes fullImage {
  0% {
    height: 500px;
  }
  100% {
    height: 100%;
  }
}

.fl-full-screen-size-false {
  transform: scale(0.7);
  transition: transform 0.5s;
}

@keyframes smallImage {
  0% {
    height: 100%;
  }
  100% {
    height: 500px;
  }
}

.fl-full-screen-size-null {
  transform: scale(0.7);
}

.fl-full-screen .fl-fullscreen__close {
  background: linear-gradient(90deg, #ff037c, #ff4443);
  border-radius: 50%;
  width: 40px;
  height: 40px;
  display: flex;
  align-items: center;
  justify-content: center;
}

.fl-full-screen-image .fl-full-screen-tools {
  position: absolute;
  left: 0;
  top: 0;
  display: flex;
  align-items: center;
  justify-content: center;
  z-index: 999;
  cursor: default;
}

.fl-full-screen-defualt {
  height: 100%;
}

.fl-full-screen-tools span {
  color: white;
  width: 40px;
  height: 40px;
  background: linear-gradient(90deg, #ff037c, #ff4443);
  color: white;
  border-radius: 50%;
  display: flex;
  align-items: center;
  cursor: pointer;
  justify-content: center;
  margin: 15px 0px 0px 10px;
  border: 1px solid white;
}

.fl-full-screen-tools span p {
  font-weight: 600;
}

.fl-full-screen-tools span:hover {
  opacity: 0.8;
}

.fl-full-screen-tools span svg {
  height: 20px;
  width: 20px !important;
}

.fl-full-screen-image .react-transform-wrapper,
.fl-full-screen-image .react-transform-component {
  height: 100%;
  width: 100%;
  justify-content: center;
}

.fl-full-screen-image img {
  width: 100%;
  height: 100%;
  object-fit: contain;
  pointer-events: all;
}

.fl-full-screen-cursor-zoomIn {
  cursor: -webkit-zoom-in;
  cursor: zoom-in;
}

.fl-full-screen-cursor-zoomOut {
  cursor: -webkit-zoom-ou;
  cursor: zoom-out;
}

.fl-full-screen-cursor-pointer {
  cursor: pointer;
}

.fl-full-screen-animation-fade {
  background-color: rgb(141 141 141);
  animation: fade-in 1s;
}

.fl-full-screen-animation-fullscreen {
  background-color: rgb(141 141 141);
}

.fl-full-screen-animation-close {
  animation: fade-out 1s;
  background-color: rgb(141 141 141);
}

.comment-paragraph-text a {
  text-decoration: none;
  font-weight: 400;
  color: #277eff;
}

.comment-paragraph-text a:hover {
  text-decoration: underline;
}

@keyframes fade-in {
  from {
    opacity: 0;
  }
  to {
    opacity: 1;
  }
}

@keyframes fade-out {
  from {
    opacity: 1;
  }
  to {
    opacity: 0;
  }
}

.fl-post-comments-cancel {
  margin-block-start: 1px;
  font-size: 12px;
}

/*// tagging */
.fl-post-comments-make-all .fl-tag-box,
.fl-gotopost-post-comment-form .fl-tag-box,
.fl-simple-comment-description .fl-tag-box,
.fl-post-comments-reply .fl-tag-box,
.fl-gotopost-post-comment-single .fl-tag-box {
  padding: 0px;
  min-height: 39px;
}

.fl-post-comments-make-all .mentions-light__highlighter,
.fl-post-comments-make-all .mentions-dark__highlighter,
.fl-gotopost-post-comment-form .mentions-light__highlighter,
.fl-gotopost-post-comment-form .mentions-dark__highlighter,
.fl-simple-comment-description .mentions-light__highlighter,
.fl-simple-comment-description .mentions-dark__highlighter,
.fl-post-comments-reply .mentions-light__highlighter,
.fl-post-comments-reply .mentions-dark__highlighter {
  min-height: 34px;
}

.fl-post-comments-make-all .mentions-light__highlighter strong,
.fl-post-comments-make-all .mentions-dark__highlighter strong {
  top: 7.7px;
  left: 10.4px;
}

.fl-gotopost-post-comment-form .mentions-light__highlighter strong,
.fl-gotopost-post-comment-form .mentions-dark__highlighter strong {
  top: 9px;
  left: 10.2px;
}

.fl-post-comments-reply .mentions-light__highlighter strong,
.fl-post-comments-reply .mentions-dark__highlighter strong {
  top: 9.5px;
  left: 10.2px;
}

.fl-simple-comment-description .mentions-light__highlighter strong,
.fl-simple-comment-description .mentions-dark__highlighter strong {
  top: 6.3px;
  left: 10.2px;
}

.fl-simple-comment-description p,
.fl-gotopost-post-comment-single-data-text-data p,
.fl-simple-comment-description p,
.fl-post-comments-reply p {
  margin-block-start: 0em;
  margin-block-end: 0em;
}

.fl-post-price {
  display: flex;
}

.fl-post-price img {
  width: 15px;
  margin-top: -5px;
  margin-left: 5px;
}

/* Large devices (desktops, less than 1200px) */
@media (max-width: 1199.98px) {
  .fl-post-head-image,
  .fl-post-head-image .fl-profile-holder {
    width: 45px;
    height: 45px;
  }
  .fl-post-head-info {
    width: calc(100% - 65px);
  }
  .fl-post-head-info-name {
    font-size: 16px;
    line-height: 16px;
  }
  .fl-post-head-info-username,
  .fl-post-head-info-username-width {
    font-size: 16px;
  }
  .fl-post-head-info-time,
  .fl-post-head-info-time-width {
    font-size: 16px;
  }
  .fl-post-head-info-dot {
    margin: 0px 15px;
  }
  .fl-post-head {
    padding: 20px;
  }
  .fl-post-counts {
    padding: 20px;
  }
  .fl-post-actions {
    padding: 10px 20px;
  }
  .fl-post-text {
    padding: 0px 20px;
    font-size: 16px;
    line-height: 22px;
  }

  .fl-post__video video {
    /*width: 630px !important;*/
  }
}

.fl-post-deleted {
  text-align: center;
  color: #888;
}

.fl-post-deleted-close {
  float: right;
  transform: scale(1.5);
}

.fl-post-deleted-close svg:hover {
  color: #ff0d7290;
  opacity: 0.7;
}

.fl-post-deleted-close:hover {
  cursor: pointer;
  transform: scale(1.6);
}

.fl-post-0 {
  background-color: #e6e5e5 !important;
}

.fl-post-0 .fl-post-text,
.fl-post-0 .fl-gotopost-comments-text div p {
  opacity: 0.2;
  pointer-events: none;
}

.fl-post-0 .smooth-image-wrapper img {
  opacity: 0.5;
}

.fl-reported-1 img:not(.fl-reported-1 .fl-post-image-swipe-count img) {
  filter: blur(4px);
}

.fl-write-post-pending {
  color: #fff;
  font-size: 12px;
  /* text-transform: uppercase; */
  background-color: #ffab22;
  margin: 0px;
  padding: 5px 10px 5px 10px;
  border-radius: 15px;
  font-weight: 600;
  display: flex;
  align-items: center;
}

.fl-write-post-pending svg {
  font-size: 15px;
}

.fl-write-post-schedule-date {
  background-color: initial;
  padding: 0;
}
.fl-write-post-schedule-icon {
  margin-left: 10px;
  width: 16px;
}
.fl-write-post-schedule-date-margin {
  margin-bottom: 4px;
}

.fl-write-post-pending-icon {
  font-size: 20px;
  margin-left: 8px;
}

.fl-post-head-info-pin {
  color: #ff0d72;
}

.fl-post-head-info-globe {
  color: #888888;
}

.fl-post-image-info-holder {
  display: flex;
  align-items: center;
}

.fl-post-info-right {
  display: flex;
  align-items: center;
}

@media (max-width: 1200px) {
  /* .fl-post-image-swipe-count {
    bottom: 7px;
  } */

}

.fl-post-overlayer-icons {
  display: flex;
  background: linear-gradient(to right, #ff037c, #ff4443);
  padding: 5px;
  border-radius: 5px;
}

.fl-post-overlayer-icons div {
  display: flex;
  align-items: center;
  justify-content: center;
}

.fl-post-overlayer-icons div:last-child {
  margin-left: 7px;
}

.fl-post-overlayer-icons div span {
  margin-top: 3px;
}

aside.emoji-picker-react {
  max-height: 275px;
}

/* Medium devices (tablets, less than 992px) */
@media (max-width: 991.98px) {
  .fl-post__video video {
    /*width: 710px !important;*/
  }
}

.fl-post-head-actions-dropdown-post {
  left: -75px;
  top: 55px;
}

.fl-post-indicator {
  position: absolute;
  bottom: 0;
  right: 25px;
}

.fl-indicator-post-pointer {
  cursor: pointer;
}

.fl-post-head-actions-status {
  position: absolute;
  right: 0px;
}

.fl-post-head-actions-status .fl-direct-messages-title-indicator {
  left: 0;
  margin-left: -13px;
}
.fl-write-post-schedule-icon-mobile {
  opacity: 0.8;
  width: 20px;
  height: 20px;
  cursor: pointer;
}

.fl-post-head-actions-dropdown-option:hover .fl-post-head-actions-dropdown-option-icon-schedule-hover {
  background-image: url("data:image/svg+xml,%3C%3Fxml version='1.0' standalone='no'%3F%3E%3C!DOCTYPE svg PUBLIC '-//W3C//DTD SVG 20010904//EN' 'http://www.w3.org/TR/2001/REC-SVG-20010904/DTD/svg10.dtd'%3E%3Csvg version='1.0' xmlns='http://www.w3.org/2000/svg' width='344.000000pt' height='392.000000pt' viewBox='0 0 344.000000 392.000000' preserveAspectRatio='xMidYMid meet'%3E%3Cg transform='translate(0.000000,392.000000) scale(0.100000,-0.100000)' fill='%23000000' stroke='none'%3E%3Cpath d='M926 3845 c-53 -19 -85 -44 -121 -94 -30 -43 -30 -43 -33 -207 l-3%0A-164 -183 0 c-100 0 -209 -5 -241 -11 -103 -19 -176 -65 -229 -143 -57 -84%0A-66 -133 -66 -363 l0 -203 1680 0 1680 0 0 173 c0 205 -10 271 -50 354 -36 73%0A-81 115 -170 157 l-65 31 -216 3 -217 4 -4 151 c-4 169 -14 202 -79 265 -89%0A87 -263 76 -344 -20 -49 -58 -55 -85 -55 -248 l0 -150 -479 0 -479 0 -4 153%0Ac-3 131 -7 158 -25 193 -58 110 -182 160 -297 119z' fill='%23FFFFFF'/%3E%3Cpath d='M50 1377 c0 -1156 -3 -1101 66 -1203 54 -80 129 -126 235 -144 45 -8%0A471 -10 1419 -8 l1355 3 65 31 c36 17 83 46 103 63 46 41 94 138 107 216 6 36%0A10 458 10 1073 l0 1012 -1680 0 -1680 0 0 -1043z m2250 527 c64 -35 114 -98%0A136 -173 33 -110 0 -201 -117 -317 l-69 -69 -43 45 c-24 25 -112 116 -197 203%0Al-154 157 64 66 c38 37 90 78 125 95 54 28 67 30 131 26 53 -3 83 -11 124 -33z%0Am-307 -641 l87 -88 -282 -282 c-169 -167 -299 -289 -323 -301 -54 -27 -343%0A-92 -377 -85 -37 7 -88 66 -88 101 0 15 19 106 41 202 l42 175 290 292 290%0A292 116 -109 c64 -60 155 -148 204 -197z' fill='%23FFFFFF'/%3E%3C/g%3E%3C/svg%3E%0A");  background-repeat: no-repeat;
  background-size: 16px;
  background-repeat: no-repeat;
}

.fl-post-head-actions-dropdown-option:hover .fl-post-head-actions-dropdown-option-icon-delete-hover {
  background-image: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 448 512'%3E%3C!--! Font Awesome Pro 6.1.1 by @fontawesome - https://fontawesome.com License - https://fontawesome.com/license (Commercial License) Copyright 2022 Fonticons, Inc. --%3E%3Cpath d='M135.2 17.69C140.6 6.848 151.7 0 163.8 0H284.2C296.3 0 307.4 6.848 312.8 17.69L320 32H416C433.7 32 448 46.33 448 64C448 81.67 433.7 96 416 96H32C14.33 96 0 81.67 0 64C0 46.33 14.33 32 32 32H128L135.2 17.69zM31.1 128H416V448C416 483.3 387.3 512 352 512H95.1C60.65 512 31.1 483.3 31.1 448V128zM111.1 208V432C111.1 440.8 119.2 448 127.1 448C136.8 448 143.1 440.8 143.1 432V208C143.1 199.2 136.8 192 127.1 192C119.2 192 111.1 199.2 111.1 208zM207.1 208V432C207.1 440.8 215.2 448 223.1 448C232.8 448 240 440.8 240 432V208C240 199.2 232.8 192 223.1 192C215.2 192 207.1 199.2 207.1 208zM304 208V432C304 440.8 311.2 448 320 448C328.8 448 336 440.8 336 432V208C336 199.2 328.8 192 320 192C311.2 192 304 199.2 304 208z' fill='%23FFFFFF'/%3E%3C/svg%3E");
  background-size: 15px;
  background-repeat: no-repeat;
}

.fl-post-head-actions-dropdown-option:hover .fl-post-head-actions-dropdown-option-icon-edit-hover {
  background-image: url("data:image/svg+xml;charset=UTF-8,%3csvg version='1.0' xmlns='http://www.w3.org/2000/svg' width='396.000000pt' height='346.000000pt' viewBox='0 0 396.000000 346.000000' preserveAspectRatio='xMidYMid meet'%3e%3cg transform='translate(0.000000,346.000000) scale(0.100000,-0.100000)' fill='%23FFFFFF' stroke='none'%3e%3cpath d='M1860 3399 c-452 -27 -872 -180 -1186 -433 -366 -294 -574 -696 -574 -1110 0 -335 121 -649 354 -916 36 -41 66 -78 66 -82 0 -18 -67 -192 -97 -253 -65 -127 -258 -413 -308 -455 -21 -18 -19 -64 5 -85 18 -16 32 -17 123 -11 142 9 263 30 385 66 150 45 392 169 527 271 l52 39 38 -16 c59 -24 297 -82 420 -101 153 -25 524 -24 685 1 584 89 1049 361 1337 783 218 318 286 684 198 1051 -64 265 -196 484 -425 705 -134 130 -253 214 -440 310 -346 180 -746 261 -1160 236z m738 -736 c21 -9 76 -56 124 -103 105 -104 133 -158 125 -232 -8 -66 -28 -96 -147 -213 l-100 -100 -210 210 -210 210 103 103 c56 56 120 111 142 122 49 25 124 26 173 3z m-548 -1196 l-385 -383 -223 -44 c-252 -49 -272 -48 -272 13 0 19 15 110 34 203 18 93 37 187 40 209 7 36 44 76 389 423 l382 382 210 -210 210 -210 -385 -383z'/%3e%3c/g%3e%3c/svg%3e ");
  background-size: 16px;
  background-repeat: no-repeat;
}

.fl-post-head-actions-dropdown-option:hover .fl-post-head-actions-dropdown-option-icon-schedule-hover svg,
.fl-post-head-actions-dropdown-option:hover .fl-post-head-actions-dropdown-option-icon-delete-hover svg,
.fl-post-head-actions-dropdown-option:hover .fl-post-head-actions-dropdown-option-icon-edit-hover svg {
  display: none;
}

.fl-post-head-actions-dropdown-option-icon-schedule-hover svg,
.fl-post-head-actions-dropdown-option-icon-delete-hover svg,
.fl-post-head-actions-dropdown-option-icon-edit-hover svg {
  width: 16px;
  height: 18px;
}

.fl-post-disabled {
  cursor: default;
  pointer-events: none;
  opacity: 0.2;
}

/* Small devices (landscape phones, less than 768px) */
@media (max-width: 767.98px) {
  .fl-write-post-schedule-date {
    margin-top: 5px;
  }
  .fl-images-overlayer > div:first-child {
    margin-right: 15px;
  }
  .fl-images-overlayer > div {
    transform: scale(1);
  }
  .fl-dot-none .fl-post-head-info-dot {
    display: none;
  }
}

/* Extra small devices (portrait phones, less than 576px) */
@media (max-width: 575.98px) {
  .fl-images-overlayer > div:first-child {
    margin-right: 25px;
  }
  .fl-images-overlayer > div {
    transform: scale(1.3);
  }
  .fl-post-head-info-username {
    font-size: 12px;
  }
  .fl-post-head-info-time-link {
    text-align: left;
  }
  .fl-post-head-info-dot {
    margin: 0px 8px;
    width: 3px;
    height: 3px;
  }
  .fl-post-head-info-time,
  .fl-post-head-info-time-width {
    font-size: 12px;
  }
  .fl-post-text {
    font-size: 14px;
  }
  .fl-post-counts {
    font-size: 14px;
  }
  .fl-post-action-single-no-hover span:not(.fl-post-action-single-no-hover .fl-write-post-pending) {
    display: none;
  }
  .fl-post-comments-make {
    padding: 20px;
  }
  .fl-post-comments {
    padding: 20px;
  }
  .fl-post-comments-reply {
    width: 100%;
    margin-left: 0px;
  }

  .fl-post__video video {
    /*width: 90vw !important;*/
  }
  .emoji-picker-react .emoji-group:before,
  .emoji-picker-react input.emoji-search {
    display: none;
  }
}

@media (max-width: 575.98px) {
  .fl-post-display-block-sm {
    display: block;
  }
  .fl-post-info-user-time {
    display: block !important;
  }
  .fl-post-info-right .fl-post-head-info-dot:first-child {
    display: none;
  }
  .fl-post-info-right .fl-post-head-info-globe {
    font-size: 12px;
    margin-top: 2px
  }
  .fl-post-price {
    font-size: 12px;
  }
  .fl-post-price img {
    width: 13px;
    margin-top: -3px;
  }
}

@media (max-width: 470px) {
  .fl-images-overlayer > div:first-child {
    margin-right: 15px;
  }
  .fl-images-overlayer > div {
    transform: scale(1);
  }
  .fl-singlenotification__main .fl-post-head-actions {
    top: 10px;
  }
}

@media (min-width: 575.98px) {
  .fl-write-post-schedule-icon-mobile-container {
    display: none;
  }
  .fl-write-post-schedule-date-single {
    display: inline-block;
  }
}

@media (min-width: 768px) {
  .fl-write-post-schedule-date-container {
    text-align: right;
    padding-right: 40px;
  }
}
@media (min-width: 1200px) {
  .fl-write-post-schedule-date-container {
    text-align: right;
    padding-right: 85px;
  }
}

@media (max-width: 380px) {
  .fl-images-overlayer {
    flex-direction: column;
  }
  /* .fl-images-overlayer > div {
    transform: scale(0.9);
  } */
  .fl-images-overlayer > div:first-child {
    margin: 0px;
  }
  .fl-post-action-single-no-hover {
    font-size: 14px;
    margin-right: 15px;
  }
}
  
@media (max-width: 320px) {
  .fl-post-action-single-no-hover {
    font-size: 11px;
    margin-right: 10px;
  }
}

@media (max-width: 787.98px) {
  .fl-post-head-actions-dropdown-option {
    padding: 8px 10px;
  }
}

@media (max-width: 575.98px) {
  .fl-write-post-main .fl-post-comment-emojis-picker {
    left: 0rem;
  }
  .fl-post-comment-emojis-picker aside.emoji-picker-react {
    max-height: 175px;
  }
}
.fl-post-head-info-pinned {
  position: absolute;
  top: 3px;
  left: 3px;
  color: #ff0d72;
  z-index: 1;
}
