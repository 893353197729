@keyframes showToast {
  0% {
    transform: scaleY(0) translateY(50%);
    opacity: 0;
  }
  50% {
    transform: scaleY(1) translateY(50%);
    opacity: 0;
  }
  100% {
    transform: scaleY(1) translateY(0%);
    opacity: 1;
  }
}

@keyframes hideToast {
  0% {
    transform: scaleY(1) translateY(0%);
    opacity: 1;
  }
  50% {
    transform: scaleY(1) translateY(50%);
    opacity: 0;
  }
  100% {
    transform: scaleY(0) translateY(50%);
    opacity: 0;
  }
}

.fl-toast-main {
  pointer-events: all;
  opacity: 0;
  max-width: 280px;
  border-radius: 10px;
  display: flex;
  padding: 10px;
  color: #fff;
  margin-bottom: 10px;
  width: fit-content;
}

.fl-toast-main:nth-last-of-type(1) {
  margin-bottom: 0px;
}

.fl-toast-main-show {
  animation: showToast 500ms ease-in forwards;
}

.fl-toast-main-hide {
  animation: hideToast 500ms ease-in forwards;
}

.fl-toast-main-success,
.fl-toast-main-money_out,
.fl-toast-main-money_in{
  background-color: #38a169;
}

.fl-toast-main-reactivated {
  background-color: #38a169;
}

.fl-toast-main-error {
  background-color: #e53e3e;
}

.fl-toast-main-warning {
  background-color: #dd6b20;
}

.fl-toast-main-info {
  background-color: #3182ce;
}

.fl-toast-main-deleted {
  background-color: #000;
}

.fl-toast-main-resend {
  background-color: #000;
  z-index: 1000;
}

.fl-toast-main-disabled {
  background-color: #ebaf4c;
}
.fl-toast-main-deactivated {
  background-color: #ebaf4c;
}

.fl-toast-icon-svg,
.fl-toast-icon img {
  width: 70% !important;
  height: 70%;
  margin-left: 15%;
  margin-top: 15%;
}

.fl-toast-icon-holder {
  min-width: 36px;
  width: 36px;
  height: 36px;
  border: 2px solid #fff;
  border-radius: 50%;
  position: relative;
}

.fl-toast-icon-error::after,
.fl-toast-icon-error::before {
  content: "";
  width: 20px;
  height: 2px;
  left: 50%;
  top: 50%;
  background-color: #fff;
  position: absolute;
}

.fl-toast-icon-error::after {
  transform: translate(-50%, -50%) rotate(45deg);
}

.fl-toast-icon-error::before {
  transform: translate(-50%, -50%) rotate(-45deg);
}

/* .fl-toast-icon-deleted::after,
.fl-toast-icon-deleted::before {
  content: "";
  width: 20px;
  height: 2px;
  left: 50%;
  top: 50%;
  background-color: #fff;
  position: absolute;
}

.fl-toast-icon-deleted::after {
  transform: translate(-50%, -50%) rotate(45deg);
}

.fl-toast-icon-deleted::before {
  transform: translate(-50%, -50%) rotate(-45deg);
} */

.fl-toast-icon-warning {
  width: 100%;
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
}

.fl-toast-icon-warning::after {
  width: 3px;
  height: 3px;
  background-color: #fff;
  display: block;
  content: "";
}

.fl-toast-icon-warning::before {
  width: 3px;
  height: 12px;
  background-color: #fff;
  display: block;
  content: "";
  margin-bottom: 3px;
}

.fl-toast-icon-success::before {
  width: 14px;
  height: 7px;
  border-bottom: 2px solid #fff;
  border-left: 2px solid #fff;
  position: absolute;
  left: 50%;
  top: 50%;
  content: "";
  transform: translate(-50%, -70%) rotate(-45deg);
}

.fl-toast-icon-reactivated::before {
  width: 14px;
  height: 7px;
  border-bottom: 2px solid #fff;
  border-left: 2px solid #fff;
  position: absolute;
  left: 50%;
  top: 50%;
  content: "";
  transform: translate(-50%, -70%) rotate(-45deg);
}

/* .fl-toast-icon-info {
    width: 15px;
    height: 15px;
    border-radius: 50%;
    background-color: #fff;
    position: absolute;
    left: 50%;
    top: 50%;
    transform: translate(-50%, -50%);
} */

.fl-toast-icon-info {
  width: 100%;
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
}

.fl-toast-icon-info::before {
  content: "i";
  display: block;
  font-size: 28px;
  font-style: italic;
  font-family: serif;
  font-weight: 600;
}

.fl-toast-text {
  padding-left: 10px;
}

.fl-toast-text-title {
  display: flex;
  align-items: center;
  justify-content: space-between;
  border-bottom: 1px solid #fff;
  font-size: 14px;
  padding-bottom: 2px;
  min-width: 100px;
}

.fl-toast-text-title-type {
  font-weight: 500;
  text-transform: uppercase;
}

.fl-toast-text-title-action {
  margin-top: -5px;
  cursor: pointer;
}

.fl-toast-text-description {
  padding-top: 5px;
  font-size: 14px;
}
