.fl-notification-content {
    display: flex;
    justify-content: space-around;
    align-items: center;
    flex-wrap: wrap;
    height: 100%;
}

.fl-notification-content-light {
    color: black;
}

.fl-notification-content-dark {
    color: white;
}

.fl-notification-content > div {
    padding: 0px 20px 20px 20px;
    text-align: center;
}

.fl-notification-content svg {
    color: #ff3352;
    width: 10% !important;
    height: 10%;
    margin: 15px;
    margin-bottom: 0px;
}

.fl-notification-content p:first-child {
    font-weight: 800;
}

.fl-dismiss-button {
    width: 100%;
    background: linear-gradient(to right, #ff037c, #ff4443);
    text-align: center;
    color: white;
}

.fl-underline-none-hover:hover {
    text-decoration: none;
}