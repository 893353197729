.fl-input {
    font-size: 18px;
    border: 1px solid #646464;
    padding: 15px 25px;
    border-radius: 20px;
    background: none;
    outline: none;
    width: 100%;
    height: 100%;
}

.fl-input-password {
    padding-right: 55px;
}

.fl-input-light {
    color: #000;
}

.fl-input-dark {
    color: #fff;
}


.fl-input-outter {
    position: relative;
    width: 100%;
    height: 56px;
    margin: 10px 0px;
}

.fl-input-show-password {
    position: absolute;
    right: 20px;
    top: 50%;
    transform: translateY(-50%);
    color: #888;
    cursor: pointer;
    user-select: none;
}

.fl-input-show-password.fl-input-show-password-dark:hover {
    color: #aaa;
}

.fl-input-show-password.fl-input-show-password-light:hover {
    color: #666;
}

.fl-input-invalid {
    transition: 300ms color ease-in-out, 300ms border-color ease-in-out;
    border-color: #ff4444 !important;
    color: #ff4444 !important;
}

/* Large devices (desktops, less than 1200px) */
@media (max-width: 1199.98px) {
    .fl-input-outter {
        height: 40px;
    }
    .fl-input {
        border-width: 2px;
        border-radius: 15px;
        font-size: 14px;
    }
}

/* Medium devices (tablets, less than 992px) */
@media (max-width: 991.98px) { 

}

/* Small devices (landscape phones, less than 768px) */
@media (max-width: 767.98px) {  }

/* Extra small devices (portrait phones, less than 576px) */
@media (max-width: 575.98px) {

}