.fl-singlenotification__main {
  width: 100%;
  padding: 10px;
  position: relative;
  margin-bottom: 4px;
}
.fl-singlenotification__main--read .fl-singlenotification__icon,
.fl-singlenotification__main--read .fl-singlenotification__content__withImg,
.fl-singlenotification__main--read .fl-notification-date,
.fl-singlenotification__main--read .fl-singlenotification__content {
  opacity: 0.25;
  pointer-events: none;
}
.fl-singlenotification__main--dark {
  background-color: #1b1b1b !important;
  border-bottom: 1px solid #00000010;
}
.fl-notifications__all-dark .fl-singlenotification__main--notread {
  background-color: #1b1b1b !important;
  cursor: pointer;
}

.fl-singlenotification__main--light {
  background-color: #00000000;
  border-bottom: 1px solid #00000010;
}
.fl-notifications__all-light .fl-singlenotification__main--notread {
  background-color: #f4f4f4;
  cursor: pointer;
}

.fl-singlenotification__main--notread::before {
  position: absolute;
  left: 0px;
  top: 0px;
  width: 3px;
  height: 100%;
  content: "";
}

.fl-singlenotification__inner {
  display: flex;
  align-items: center;
}
.fl-singlenotification__icon img {
  width: 35px;
}
.fl-singlenotification__icon {
  min-height: 60px;
  width: 80px;
  display: flex;
  justify-content: center;
  font-size: 28px;
  border-right: 1px solid #00000020;
}
.fl-singlenotification__icon-center {
  align-items: center;
}
.fl-singlenotification__icon-unset {
  align-items: unset;
}
.fl-singlenotification__icon--tip {
  color: #00aa00;
}
.fl-singlenotification__icon--like {
  color: #0781ff;
}
.fl-singlenotification__icon--comment {
  color: #ff0d72;
}

.fl-singlenotification__unreadsign {
  font-size: 12px;
  color: #ff0d72;
  position: absolute;
  top: 4px;
  right: 1rem;
  box-shadow: 0 0 4px;
  border-radius: 50%;
}

.fl-singlenotification__image {
  width: 50px;
  display: block;
}
.fl-singlenotification__image img {
  width: 100%;
  height: 50px;
  object-fit: contain;
  object-position: center;
}

.fl-singlenotification__content {
  width: calc(100% - 100px);
  padding-left: 10px;
  line-height: 1.5;
}
.fl-singlenotification__content__withImg {
  width: calc(100% - 100px);
  padding: 0px 10px;
}
.fl-singlenotification__content__inner {
  width: calc(100% - 100px);
}

.fl-singlenotification__content__title {
  font-size: 16px;
  font-weight: 600;
}
.fl-singlenotification__content__title--dark {
  color: #fff !important;
}
.fl-singlenotification__content__title--light {
  color: #000 !important;
}

.fl-singlenotification__content__description {
  color: #888;
  font-size: 14px;
}

.fl-singlenotification__content__description p {
  margin: auto;
}

.fl-singlenotification__content__description a,
.fl-singlenotification__content__description span {
  color: #ff0d72;
  text-decoration: none;
}
.fl-singlenotification__content__description a:hover {
  text-decoration: underline;
}

.fl-singlenotification__ellipsish--dark {
  color: #fff;
}
.fl-singlenotification__ellipsish--light {
  color: #333333;
}

.single-notification-link {
  text-decoration: none;
  color: #ff0d72;
  cursor: pointer !important;
}

.single-notification-link:hover {
  text-decoration: underline;
}

.fl-singlenotification-unlocked-amount {
  color: #ff0d72;
}

.fl-notification-date {
  position: absolute;
  width: 77px;
  text-align: center;
  bottom: 5px;
  font-size: 11px;
  color: #277eff;
}

.fl-single-notifications-unread {
  color: #ff0d72;
}