.fl-segpay-modal-close {
    width: 40px;
    height: 40px;
    font-size: 30px;
    color: #888888;
    position: absolute;
    right: 5px;
    display: flex;
    align-items: center;
    justify-content: center;
    cursor: pointer;
    top: 5px;
}
.fl-segpay-modal-close:hover {
    background: #f6f6f6;
    border-radius: 50%;
}