.fl-blue-link {
    color: #277eff;
    cursor: default;
}

.fl-blue-link:hover {
    text-decoration: none;
}

.fl-blue-link:visited {
    color:#277eff;
}

.fl-grey {
    color: #888888;
}

.fl-hover-underline:hover {
    text-decoration: underline;
    cursor: pointer;
}

.fl-settings-sub-privacy-label {
    display: flex;
    align-items: center;
    font-size: 14px;
    padding: 3px 0px;
}
.fl-settings-sub-privacy-label input {
    margin: 0px;
    margin-right: 4px;
    margin-top: -3px;
}

.fl-settings-sub-privacy-label > div {
    margin-left: 10px;
}

.fl-settings-sub-privacy-label > span {
    color: lightgray;
    margin-left:5px;
}

.fl-info-text {
    transform: scale(1.2);
}

.fl-settings-sub-privacy-label.fl-settings-sub-privacy-label-dark {
    color: #fff;
}

.fl-settings-sub-privacy-label.fl-settings-sub-privacy-label-light {
    color: #000;
}

.fl-settings-sub-privacy-options {
    padding: 8px 5px;
}

.fl-settings-sub-privacy-watermark-color {
    width: 100%;
}

.fl-settings-sub-privacy-watermark-color-single {
    display: flex;
    align-items: center;
    font-size: 14px;
    text-transform: uppercase;
    padding: 5px 5px;
    border-radius: 5px;
    cursor: pointer;
    margin-bottom: 5px;
    width: 200px;
}

.fl-settings-sub-privacy-watermark-color.fl-settings-sub-privacy-watermark-color-dark .chrome-picker {
    background-color: #000 !important;
}

.fl-settings-sub-privacy-watermark-color-picker {
    width: auto;
    box-shadow: none !important;
    margin-top: 10px;
}

.fl-settings-sub-privacy-watermark-color-single-active {
    background-color: #888;
    color: #fff;
}

.fl-settings-sub-privacy-watermark-color-single span {
    width: 15px;
    height: 15px;
    margin-right: 8px;
    border-radius: 50%;
    display: block;
    margin-top: -3px;
    box-shadow: 0px 0px 1px 1px #00000040;
}

.fl-close-button {
    margin-left: auto;
    display: flex;
    align-items: center;
    justify-content: center;
    width: 20px;
    height: 20px;
}

.fl-close-button:hover {
    background-color: rgba(245, 57, 57, 0.377);
    border-radius: 99999px;
}

.fl-close-button:hover > svg {
    transform: scale(0.9);
}

.fl-blocked-countries-wrapper {
    display: flex;
    padding: 10px;
}

.fl-settings-sub-privacy-blocked-cs-form {
    display: flex;
}

.fl-settings-sub-privacy-blocked-cs-form-part {
    width: calc(50% - 50px);
}

.fl-settings-sub-privacy-blocked-cs-form-part input {
    border-top-right-radius: 0px;
    border-bottom-right-radius: 0px;
}

.fl-settings-sub-privacy-blocked-cs-form-part:nth-of-type(1) input {
    border-right: 1px solid #00000020;
}

.fl-settings-sub-privacy-blocked-cs-form-part:nth-of-type(2) input {
    border-top-left-radius: 0px;
    border-bottom-left-radius: 0px;
}

.fl-settings-sub-privacy-blocked-cs-form-button {
    width: 100px;
    padding-top: 20px;
}

.fl-settings-sub-privacy-blocked-cs-form-button button {
    width: 100%;
    border-radius: 0px 5px 5px 0px;
    padding: 0px;
    height: 35px;
}

.fl-settings-sub-privacy-blocked-cs-list {
    width: 100%;
    overflow-y: scroll;
    border-radius: 5px;
}

.fl-settings-sub-privacy-blocked-cs-list.fl-settings-sub-privacy-blocked-cs-list-dark {
    border: 1px solid #ffffff20;
    color: white;
}

.fl-settings-sub-privacy-blocked-cs-list.fl-settings-sub-privacy-blocked-cs-list-light {
    border: 1px solid #00000020;
}

.fl-blocked-users-list {
    padding: 15px;
}

.fl-blocked-users-list span:not(.fl-blocked-users-list span:first-child) {
    color: #277eff;
    padding-left: 15px;
}

.fl-blocked-users-list span:hover {
    text-decoration: underline;
}

.fl-blocked-users-list span {
    color: #277eff;
    text-decoration: none;
    cursor: pointer;
}

.fl-segpay-form {
    width: 100%;
    height: 100%;
    border: none;
}

.fl-modal-segpay {
    height: 500px;
    overflow-y: hidden;
}

/* Large devices (desktops, less than 1200px) */
@media (max-width: 1199.98px) {

}

/* Medium devices (tablets, less than 992px) */
@media (max-width: 991.98px) { 

}

/* Small devices (landscape phones, less than 768px) */
@media (max-width: 767.98px) { 
    
 }

/* Extra small devices (portrait phones, less than 576px) */
@media (max-width: 575.98px) {

}