.fl-usercard {
  margin: 10% 2% 5%;
  border-radius: 15px;
  display: flex;
  position: relative;
}

.fl-usercard-full {
  min-height: 245px;
  width: 29.3%;
  background: linear-gradient(180deg,#ff3b4a,#ff0f71);
}

.fl-usercard-full .fl-profile-card-buttons {
  padding: 5px 15px 0px 15px;
}

.fl-usercard-full .fl-profile-holder-status-icon {
  cursor: default;
}

.fl-usercard-grid {
  width: 16%;
  background: linear-gradient(180deg,#ff3b4a,#ff0f71);
}

.fl-usercard__subscribed {
  background: linear-gradient(to bottom, #ffb659, #ff840f);
}

.fl-usercard__name {
  font-size: 12px;
  white-space: normal;
  line-height: normal;
}
.fl-usercard__username {
  font-size: 12px;
  white-space: normal;
  line-height: 17px;
}

.fl-usercard__button {
  height: 20px;
  border-radius: 5px;
  font-size: 12px;
  margin-bottom: 4px;
  width: 100%;
}

.fl-usercard__info {
  display: flex;
  flex-direction: column;
  width: 100%;
  transform: rotate3d(0, 0, 0, 90deg);
  transition: 0.4s all;
}

.fl-usercard__info-full {
  padding: 70px 0px 0 0px;
}

.fl-usercard__info-grid {
  padding: 50px 5px 0 5px;
}

/*.fl-profile-card-info-section div:first-child svg {*/
/*  border: 1px solid #ff3546;*/
/*  padding: 2px 4px;*/
/*  width: 20px!important;*/
/*  border-radius: 3px;*/
/*}*/

.fl-usercard__info  > .fl-profile-card-info-row:nth-child(3){
  flex-grow: 0.5;
}

.fl-usercard__info  > *{
  flex-grow: 0;
}

.fl-usercard__info--rotated {
  transform: rotate3d(1, 0, 0, 90deg);
}

.fl-usercard__infocol {
  font-size: 12px;
  line-height: 50px;
}
.fl-usercard__infocol img {
  margin-right: 4px;
  height: 20px;
}

.fl-usercard__sub {
  height: 100%;
  position: absolute;
  width: 50%;
  right: 0;
  background: linear-gradient(to bottom, #ff3b4a, #ff0f71);
  transition: 0.4s all;
  transform: rotate3d(0, 0, 0, 90deg);
  display: flex;
  justify-content: center;
  align-items: center;
}
/*.fl-usercard__sub--rotated {*/
/*  transform: rotate3d(1, 0, 0, 90deg);*/
/*}*/

.fl-usercard__sub__title {
  color: #fff;
  /*position: absolute;*/
  /*top: 0;*/
  /*padding: 1rem;*/
  font-size: 14px;
  font-weight: 600;
}

.fl-usercard__sub__close {
  position: absolute;
  top: 0;
  right: 0;
  padding: 0.75rem;
  font-weight: 500;
  border-radius: 0 20px;
  color: #fff;
  cursor: pointer;
  transition: 0.2s all;
}
.fl-usercard__sub__close:hover {
  transform: scale(1.1);
}

.fl-usercard__sub__button {
  width: 100%;
  margin-bottom: 0.25rem;
  font-size: 12px;
  white-space: nowrap;
  height: 20px;
  border-radius: 8px;
}

.fl-usercard__sub__buttons {
  width: 100%;
  max-height: 70%;
  overflow-y: auto;
  margin-top: 15%;
  padding-right: 0.25rem;
}
.fl-usercard__sub__buttons::-webkit-scrollbar {
  width: 6px;
  border-radius: 20px;
}

.fl-usercard__sub__buttons::-webkit-scrollbar-track {
  background: transparent;
}

.fl-usercard__sub__buttons::-webkit-scrollbar-thumb {
  background: #fff;
}

.fl-usercard__sub__buttons::-webkit-scrollbar-thumb:hover {
  background: #ececec;
}

.fl-usercard__image {
  background-size: cover;
  background-position: center;
  background-repeat: no-repeat;
  padding: 0;
  border-top-left-radius: 20px;
  border-bottom-left-radius: 20px;
}

.fl-just-joined-label {
  background: #a020f0;
  padding: 3px 5px 3px 5px;
  border-radius: 20px;
  margin: 0 5px;
  cursor: default;
}

.fl-profile-card-info-section {
  bottom: 0;
  width: 100%;
  text-align: center;
  height: 55px;
  display: flex;
  align-items: center;
  padding: 0px 15px 0px 15px;
  margin-top: 5px;
}

.fl-profile-card-info-section div {
  width: 33.33%;
  background: white;
  height: 100%;
  border-radius: 5px;
  height: 80%;
}

.fl-profile-card-info-section div:nth-child(2) {
  background: white;
  height: 100%;
}

.fl-profile-card-info-section div:first-child {
  margin: 0 3% 0 0%;
}

.fl-profile-card-info-section div:last-child {
  margin: 0 0% 0 3%;
}

.fl-profile-card-info-section div span {
  font-size: 12px;
  color: #ff3546;
  height: 50%;
  display: flex;
  align-items: center;
  justify-content: center;
}

.fl-profile-card-info-section div span:nth-child(1) {
  padding-top: 10px;
}

.fl-profile-card-info-section div span:first-child {
  font-weight: 500;
  font-size: 20px;
}

.fl-profile-card-buttons {
  padding: 8px;
}

.fl-profile-card-img {
  width: var(--user-icon-radius);
  height: var(--user-icon-radius);
  border-radius: 50%;
  position: absolute;
  left: 50%;
  transform: translateX(-50%);
  top: -50px;
  --user-icon-radius: 100px;
  border: 5px solid #ffffff;
  z-index: 9;
  background-color: white;
  z-index: 0;
}

.fl-profile-card-img,
.fl-profile-card-img .smooth-image-wrapper,
.fl-profile-card-img .smooth-image-wrapper img {
  border-radius: 50%;
}

.fl-profile-card-img-pointer {
  cursor: pointer;
  z-index: 9;
}

.fl-profile-card-img-default {
  cursor: default;
}

.fl-usercard .fl-profile-card-name,
.fl-usercard .fl-profile-card-username,
.fl-usercard .fl-profile-card-subscribed {
  width: 100%;
  text-align: center;
}

.fl-usercard .fl-profile-card-subscribed span {
  color: white;
  position: relative;
  margin-left: 15px;
}

.fl-usercard .fl-profile-card-subscribed span svg {
  color: #00aa00;
  background: white;
  border-radius: 50%;
}

.fl-profile-card-buttons a {
  padding-top: 12px;
}

.fl-profile-card-age,
.fl-profile-card-location-user {
  position: absolute;
  top: 10px;
  color: white !important;
}

.fl-profile-card-age span,
.fl-profile-card-location-user span {
  color: white !important;
}

.fl-profile-card-location-user {
  left: 15px;
}

.fl-profile-card-age {
  right: 15px;
}

.fl-profile-card-age img,
.fl-profile-card-age span,
.fl-profile-card-location-user img,
.fl-profile-card-location-user span {
  display: block;
  text-align: center;
}

.fl-profile-card-age span,
.fl-profile-card-location-user span {
 padding-top: 5px;
  color: white;
  font-size: 12px;
}

.fl-profile-card-location-user img {
  margin-left: 3px;
}

.fl-profile-card-new {
  padding-bottom: 18px;
  color: white;
  text-align: center;
  padding-top: 5px;
}

@media (max-width: 1199.98px) {
  .fl-usercard-full {
    width: 46%;
    height: 200px;
  }

  .fl-usercard-grid .fl-usercard__info {
    padding: 54px 5px 0 5px;
  }

  .fl-usercard-grid {
    width: 21%;
    height: 80px;
  }

  .fl-usercard__sub__button {
    height: 32px;
  }
}

@media (max-width: 991.98px) {
  .fl-usercard__image {
    padding-top: 0 !important;
  }
  .fl-usercard-grid {
    width: 21%;
    height: 80px;
    margin: 15% 2% 0;
  }
  .fl-usercard-full {
    margin: 20% 2% 0;
  }
}

@media (max-width: 767.98px) {
  .fl-usercard__sub {
    bottom: 0;
    width: 100%;
  }
  .fl-usercard-grid {
    width: 29%;
  }
}

@media (max-width: 450px) {
  .fl-usercard-grid {
    width: 46%;
    height: 80px;
    margin: 20% 2% 0;
  }
  .fl-usercard-full {
    width: 100%;
  }
}
