.fl-bulk__main {
  width: 100%;
  flex: 1;
  height: 100%;
}
.fl-bulk__main--light {
  background-color: #f4f4f4;
}
.fl-bulk__main--dark {
  background-color: #1b1b1b;
}

.fl-bulk__col {
  min-height: 550px;
}

.fl-bulk__title {
  width: calc(100% + 40px);
  font-size: 24px;
  padding: 20px 15px 15px;
  border-bottom: 1px solid #00000020;
  margin: -20px -20px 0px;
}
.fl-bulk__title--light {
  color: #000;
}
.fl-bulk__title--dark {
  color: #fff;
}

.fl-bulk__top {
  width: calc(100% + 40px);
  padding: 1rem 0;
  margin: 0px -20px;
}

.fl-bulk__top__info {
  margin: 0.2rem 0 0.5rem 0.75rem;
  font-size: 0.75rem;
  user-select: none;
  color: #808080;
}

.fl-bulk__top__recipients {
  margin: 1rem 0 0 1rem;
  font-size: 1.125rem;
}
.fl-bulk__top__recipients--light {
  color: #000;
}
.fl-bulk__top__recipients--dark {
  color: #fff;
}

.fl-bulk__users__search {
  width: 100%;
  border: 1px solid #eee;
  padding: 15px;
  background-color: #00000010;
}

.fl-bulk__users__search:focus {
  outline: none;
}
.fl-bulk__users__search--dark {
  background: #ffffff10;
  color: #eee;
}
.fl-bulk__users__list {
  height: 360px;
  margin: 0px -20px;
  width: calc(100% + 40px);
  overflow-x: hidden;
  overflow-y: scroll;
}
.fl-bulk__user__list--user {
  display: flex;
  padding: 10px;
  align-items: center;
  justify-content: space-between;
  box-shadow: inset 0px 0px 2px 1px #00000010;
  cursor: pointer;
}
.fl-bulk__user__list--user--added {
  background-color: #ff0d7220;
}
.fl-bulk__user__list--user--image {
  width: 40px;
  height: 40px;
  position: relative;
  border-radius: 50%;
  overflow: hidden;
  background-size: cover;
  background-position: center;
}
.fl-bulk__user__list--user--info {
  display: flex;
  flex-direction: column;
  margin-left: 4px;
}
.fl-bulk__user__list--user--text {
  width: calc(100% - 85px);
}
.fl-bulk__user__list--user--text--name {
  width: 100%;
  text-overflow: ellipsis;
  overflow: hidden;
  white-space: nowrap;
  color: #000;
  font-size: 16px;
  margin-bottom: 5px;
  font-weight: 500;
}
.fl-bulk__user__list--user--text--name--light {
  color: #888888;
}
.fl-bulk__user__list--user--text--name--dark {
  color: #fff;
}
.fl-bulk__user__list--user--text--handle {
  color: #ff0d72;
  font-size: 14px;
}
.fl-bulk__user__list--user--check {
  width: 25px;
  height: 25px;
  border-radius: 50%;
  border: 1px solid #00000020;
  display: flex;
  align-items: center;
  justify-content: center;
  font-size: 14px;
  color: #fff;
  background-color: #00000000;
  transition: background-color 300ms ease-in-out;
}
.fl-bulk__user__list--user--check--light {
  color: #fff;
}
.fl-bulk__user__list--user--check--dark {
  color: #000;
}
.fl-bulk__user--list--user--check--added {
  background-color: #ff0d72;
}
.fl-bulk__user--list--user--check--added--icon {
  display: none;
}
.fl-bulk__user--list--user--check--added
  .fl-bulk__user--list--user--check--added--icon {
  display: block;
  animation: scaleUp 300ms ease-in-out;
}

.fl-bulk__actions {
  display: flex;
  margin: 0px -20px -20px;
  width: calc(100% + 40px);
  border-top: 1px solid #00000020;
}
.fl-bulk__actions--cancel {
  color: #888;
  padding: 20px;
  text-align: center;
  width: 50%;
  border-right: 1px solid #00000020;
  cursor: pointer;
  user-select: none;
}
.fl-bulk__actions--next {
  color: #ff0d72;
  padding: 20px;
  text-align: center;
  width: 50%;
  cursor: pointer;
  user-select: none;
}
.fl-bulk__actions--next--disabled {
  opacity: 0.5;
  pointer-events: none;
}

.fl-bulk__users__custom--i {
  color: #808080;
  margin-left: 0.3rem;
}
.fl-bulk__users__custom--row {
  padding: 0 0 0.5rem 0.5rem;
  display: flex;
  align-items: center;
}
.fl-bulk__users__custom--row--light {
  color: #000;
}
.fl-bulk__users__custom--row--dark {
  color: #fff;
}
.fl-bulk__users__custom--row label {
  cursor: pointer;
  margin-top: 0.2rem;
  font-size: 0.75rem;
  user-select: none;
}

.fl-bulk__tags {
  display: flex;
  flex-wrap: wrap;
  padding: 10px;
}
.fl-bulk__tags--empty {
  font-size: 14px;
  color: #888;
  padding: 8px 10px 5px;
  margin: 5px;
  border-radius: 15px;
}
.fl-bulk__tags--empty--light {
  background-color: #00000020;
}
.fl-bulk__tags--empty--dark {
  background: #eee;
}
.fl-bulk__tags--empty span {
  color: #ff0d72;
  cursor: pointer;
}
.fl-bulk__tags__tag {
  padding: 5px 10px;
  background-color: #ff0d72;
  color: #fff;
  border-radius: 15px;
  font-size: 12px;
  margin: 5px;
  display: flex;
  align-items: center;
  position: relative;
}
.fl-bulk__tags__tag span {
  padding-top: 3px;
}
.fl-bulk__tags__tag--remove {
  width: 18px;
  height: 18px;
  border-radius: 50%;
  margin-left: 5px;
  border: 1px solid #ffffff90;
  display: flex;
  justify-content: center;
  align-items: center;
  font-size: 10px;
  background-color: #ffffff20;
  cursor: pointer;
  position: absolute;
  top: 6px;
  right: 6px;
  transition: 0.2s all;
}
.fl-bulk__tags__tag--remove:hover {
  background-color: #fff;
  color: #ff0d72;
}

.fl-bulk__input {
  height: 300px;
}
.fl-bulk__input__text--dark > div > textarea {
  border: 1px solid #fff !important;
}
.fl-bulk__media {
  display: flex;
  position: relative;
  justify-content: space-between;
  padding-bottom: 5px;
}
.fl-bulk__media__icons {
  display: flex;
}
.fl-bulk__media__emoji {
  position: absolute;
  bottom: 30px;
  right: 0;
}

.fl-bulk__input__files {
  height: 100px;
  display: flex;
  justify-content: space-between;
}
.fl-bulk__input__files--dark {
  background-color: #101010;
}

.fl-bulk__input__files--line {
  height: 100%;
  display: flex;
  overflow-x: auto;
  overflow-y: hidden;
}
.fl-bulk__input__files--line--item {
  position: relative;
  height: 100%;
  margin-right: 4px;
  cursor: pointer;
}
.fl-bulk__input__files--line .fl-bulk__input__files--line--item img,
.fl-bulk__input__files--line .fl-bulk__input__files--line--item video {
  height: 100%;
}

.fl-bulk__input__files--line .fl-bulk__input__files--line--item  div:first-child,
.fl-bulk__input__files--line .fl-bulk__input__files--line--item div:first-child {
  height: 100%;
  width: 100%;
}

.fl-bulk__input__files--line--remove svg {
  color: #ececec;
  box-shadow: 0px 0px 3px #ff0d72;
  background: #ff0d72;
  border-radius: 50%;
  top: 2px;
  right: 2px;
  position: absolute;
  cursor: pointer;
  transition: 0.2s all;
  transform: scale(1);
}
.fl-bulk__input__files--line--remove svg:hover {
  background: #ececec;
  color: #ff0d72;
  transform: scale(1.2);
}
.fl-bulk__input__files--line--add {
  background: #efefef;
  border-radius: 5px;
  display: flex;
  justify-content: center;
  align-items: center;
  min-width: 3rem;
  color: #fff;
  font-size: 2rem;
  cursor: pointer;
  height: 124px;
}

.fl-bulk__recording__line {
  position: absolute;
  background-color: #efefef;
  width: 100%;
  top: -10px;
  display: flex;
}
.fl-bulk__recording__duration {
  background-color: #ff0d72;
  transition: 1s all;
  color: #fff;
}

.fl-messages-bulk-people-list-form__loader {
  position: relative;
  padding-bottom: 1rem;
}
.fl-messages-bulk-people-list-form__loading {
  position: absolute;
  background: #ff027d;
  transition: 1s all;
  color: #fff;
  min-width: 5%;
}

.fl-messages-bulk-people-list-form  .fl-direct-messages-record-wrapper {
  height: 50px;
}

.fl-bulk__price {
  width: 260px !important;
  padding: none !important;
  position: relative;
}
.fl-bulk__price input {
  padding: 0 10px 0 20px;
  height: 25px;
  width: 60%;
}
.fl-bulk__price__currency {
  position: absolute;
  font-size: 0.9rem !important;
  bottom: 11px;
  left: 135px;
}

.fl-bulk__price--dark {
  background-color: #000;
  border-left: 1px solid #fff !important;
}
.fl-bulk__price__toggle {
  display: flex;
  flex-direction: row-reverse;
  align-items: center;
}
.fl-bulk__price__label {
  white-space: nowrap;
  margin-bottom: 0 !important;
  margin-right: 5px;
  padding-top: 5px;
  width: 146.36px;
}

.fl-bulk__price__input--dark {
  background: #000;
  border: 1px solid #fff !important;
  color: #fff;
}

.fl-bulk__progress {
  height: 80px;
  display: flex;
  justify-content: flex-end;
  width: 100%;
  padding-right: 1rem;
}
.fl-bulk__progress__loader {
  height: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
}
.fl-bulk__progress__loader > svg {
  height: 100%;
  width: 100px;
}

.fl-bulk__sending {
  position: relative;
}
.fl-bulk__sending__progress {
  width: auto;
  height: 100px;
}

.fl-bulk__sending-progress-bar {
  text-align: center;
  padding-left: 5px;
}

.fl-bulk__sending-progress-bar span {
  color: #3e98c7;
  font-size: 13px;
  cursor: pointer;
}

.fl-bulk__input__files--line--item-remove {
  width: 18px;
  height: 18px;
  border-radius: 50%;
  margin-left: 5px;
  border: 1px solid #ff0d72;
  display: none;
  justify-content: center;
  align-items: center;
  font-size: 10px;
  background-color: #ff0d72;
  cursor: pointer;
  position: absolute;
  top: 3px;
  right: 3px;
  animation: remove-preview-popup 0.25s forwards;
}

.fl-bulk__input__files--line--item-remove svg {
  color: white;
}

.fl-bulk__input__files--line--item:hover > .fl-bulk__input__files--line--item-remove {
  display: flex;
}

.fl-bulk__input__files--line--item-remove:hover {
  background: white;
  border:none;
}

.fl-bulk__input__files--line--item-remove:hover > svg {
  color: #ff0d72;
}

@keyframes remove-preview-popup {
  0% {
    transform: scale(0);
  }
  100% {
    transform: scale(1);
  }
}

.fl-bulk__sending-title {
  display: inline-flex;
}

.fl-bulk__sending-title > div {
  margin-top: -7px;
  margin-left: 20px;
}

.fl-bulk__sending-progress-bar {
  width: 90px;
  text-align: center;
}

.fl-bulk__sending-progress-bar .CircularProgressbar {
  width: auto;
  height: 80px;
}
.fl-bulk__sending-progress-bar span {
  width: 100%;
  float: left;
  color: #3e98c7;
  font-size: 15px;
  padding-top: 3px;
}

.fl-bulk__sending-progress-bar-cancel {
  width: 150px;
  text-align: center;
}

.fl-bulk__sending-progress-bar-cancel .CircularProgressbar {
  width: auto;
  height: 80px;
}
.fl-bulk__sending-progress-bar-cancel span {
  width: 100%;
  float: left;
  color: #3e98c7;
  font-size: 15px;
  padding-top: 3px;
}

.fl-mass-dm-add-recipients {
  position: relative;
  padding: 3px 5px;
  outline: none;
}

.fl-mass-dm-add-recipients .fl-post-head-actions-dropdown{
  left: 0;
}

.fl-mass-dm-add-recipients  .fl-bulk__input__files--line--add {
  height: 53px;
}

.fl-mass-dm-dropdown .fl-bulk__users__list {
  height: auto;
  margin: 0;
  width: 100%;
  overflow-x: hidden;
  overflow-y: hidden;
}
.fl-mass-dm-dropdown {
  top: 60px;
}

.fl-bulk__price-set-price {
  text-align: center;
}

.fl-bulk__price-free-price {
  display: flex;
  justify-content: center;
  align-items: center;
}

.fl-bulk__price-set-price input,
.false .fl-toggle-main {
  margin-top: 2px;
}


.fl-bulk__media__icons .fl-messageaudio-main {
  width: 100%;
}

.fl-bulk__media__icons-recording {
  width: 100%;
}

.fl-messages-bulk-people-list-form .fl-messageaudio-main {
  height: 50px;
}

.fl-voice-bulk-recording,
.fl-messages-bulk-people-list-form-pb .fl-direct-messages-box-input-action-single-voice-record {
  position: absolute;
  z-index: 99;
  margin-left: 50px;
  margin-top: -15px;
}

.fl-bulk-main {
  min-height: 260px;
}

.fl-bulk__top input[type="checkbox"] {
  display: none;
}
.fl-bulk__top label {
  cursor: pointer;
  display: flex;
  align-items: center;
}
.fl-bulk__top input[type="checkbox"] + label:before {
  border: 1px solid #333;
  content: "\00a0";
  display: inline-block;
  font: 16px/1em sans-serif;
  height: 16px;
  margin: 0 .25em 0 0;
  padding: 0;
  vertical-align: top;
  width: 16px;
}
.fl-bulk__top input[type="checkbox"]:checked + label:before {
  background: #fff;
  color: #ff0d72;
  content: "\2713";
  text-align: center;
}
.fl-bulk__top input[type="checkbox"]:checked + label:after {
  font-weight: bold;
}

.fl-bulk__top input[type="checkbox"]:focus + label::before {
  outline: rgb(59, 153, 252) auto 5px;
}