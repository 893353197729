/* .iiz {
  height: 100%;
  position: relative;
}
.iiz__zoom-img--visible {
  position: absolute;
  top: 0;
  left: 0;
  height: 600px !important;
  width: 600px !important;
} */

.fullscreen {
  background: #ff0d72;
}

.fullscreen-enabled {
  display: flex !important;
  flex-direction: column !important;
}

.fullscreen-enabled {
  animation-name: postZoom;
  animation-duration: 0.5s;
}

.fl-gotopost-empty-row {
  background-color: #fff;
  box-shadow: 0px 0px 8px 2px #00000008;
  height: 100px;
  text-align: center;
  display: flex;
  flex-direction: column;
  justify-content: center;
}

.fl-gotopost-empty-link {
  color: #15c;
  text-decoration: none;
  cursor: default;
}

.fl-gotopost-empty-link:hover {
  text-decoration: underline;
}

.fl-gotopost-empty-row span {
  display: block;
}

@keyframes postZoom {
  from {
    opacity: 0;
  }

  to {
    opacity: 1;
  }
}

.fl-fullscreen-out {
  animation-name: postZoomOut;
  animation-duration: 0.4s;
  animation-fill-mode: forwards;
}

@keyframes postZoomOut {
  from {
    opacity: 1;
  }

  to {
    opacity: 0;
  }
}

.fl-fullscreen-icon {
  color: #fff;
  position: absolute;
  z-index: 1;
  bottom: 0;
  right: 0;
  cursor: pointer;
  font-size: 2rem;
  margin-right: 0.7rem;
  margin-bottom: 0.7rem;
}

.fl-fullscreen-icon:hover > svg {
  transform: scale(1.1);
}

.fl-fullscreen__close {
  position: relative;
  right: 0;
  padding: 0.5rem;
  margin: 0.5rem;
  background: transparent;
  color: #fff;
  border: none;
  cursor: pointer;
  font-size: 1.75rem;
  z-index: 1;
  color: white;
}

.fl-fullscreen__close:hover {
  border-radius: 9999px;
}

.fl-fullscreen__close:hover > svg {
  transform: scale(1.2);
}

.fl-post-preview__images {
  height: 100%;
  outline: none;
}
.fl-post-preview__images img {
  object-fit: contain !important;
}
/*.fl-post-preview__images video {*/
/*  margin-top: 120px;*/
/*}*/

.fl-gotopost-main {
  width: 100%;
  flex: 1;
}

.fl-gotopost-main.fl-gotopost-main-light {
  background-color: #f4f4f4;
}

.fl-gotopost-main.fl-gotopost-main-dark {
  background-color: #1b1b1b;
}

.fl-not-found-post-link {
  color:#15c;
  text-decoration: none;
}

.fl-not-found-post-link:hover {
  text-decoration: underline;
}

.fl-gotopost-post-header {
  width: 100%;
  height: 40px;
  display: flex;
  padding: 0px 10px;
  align-items: center;
  font-weight: 600;
}

.fl-gotopost-post-header-back-icon {
  font-size: 20px;
  width: 30px;
  height: 30px;
  margin-right: 5px;
  color: #666;
  justify-content: center;
  align-items: center;
  cursor: pointer;
  display: flex;
}

.fl-gotopost-post-header-back-icon:hover {
  background-color: #00000010;
  border-radius: 50%;
}

.fl-gotopost-post-footer {
  width: 100%;
  height: 40px;
}
.fl-gotopost-comments-header {
  width: 100%;
  display: flex;
  position: relative;
  align-items: center;
  padding: 15px 20px 15px 20px;
}

.fl-gotopost-card .fl-modal-close-button {
  right: -20px;
  top: 0px;
}

.fl-gotopost-card .fl-gotopost-post-comment-list {
  overflow-x: hidden;
  overflow-y: scroll;
}

.fl-gotopost-card .fl-write-post-schedule-date-single {
  margin-top: 5px;
}

.fl-gotopost-post-media {
  width: 100%;
  cursor: pointer;
  position: relative;
}
.fl-gotopost-post-media .fl-post-images {
  height: 500px;
}

.fl-gotopost-post-header-image {
  width: 50px;
  height: 50px;
  position: relative;
  border-radius: 50%;
  display: block;
  text-decoration: none;
}

.fl-gotopost-post-header-text {
  flex-grow: 1;
  padding-left: 10px;
  width: 85%;
}

.fl-gotopost-pd {
  padding: 0 20px;
}

.fl-gotopost-container {
  margin: 0px !important;
  position: relative;
}

.fl-gotopost-post-header-name {
  font-size: 16px;
  font-weight: 500;
  text-decoration: none;
  margin-bottom: 3px;
}

.fl-gotopost-post-header-username {
  text-overflow: ellipsis;
  white-space: nowrap;
  overflow: hidden;
  display: block;
  color: #888888;
  text-decoration: none;
}

.fl-gotopost-pending-attachments {
  display: block;
}

.fl-gotopost-pending-text,
.fl-gotopost-time {
  display: flex;
  align-items: center;
}

.fl-gotopost-post-header-name.fl-gotopost-post-header-name-light {
  color: #000;
}

.fl-gotopost-pending-attachments .fl-gotopost-pending {
  margin-left: 0px;
  width: 155px;
  margin-top: 5px;
}

.fl-gotopost-post-header-name.fl-gotopost-post-header-name-dark {
  color: #fff;
}

.fl-gotopost-post-header-time {
  font-size: 14px;
  color: #888;
}

.fl-gotopost-post-actions {
  width: 100%;
  display: flex;
}

.fl-gotopost-post-action:hover {
  color: #ff0d72;
}

.fl-gotopost-post-actions.fl-gotopost-post-actions-light {
  border-top: 1px solid #00000020;
  border-bottom: 1px solid #00000020;
}

.fl-gotopost-post-actions.fl-gotopost-post-actions-dark {
  border-top: 1px solid #ffffff20;
  border-bottom: 1px solid #ffffff20;
}

.fl-gotopost-post-action {
  width: 100%;
  padding: 10px 10px;
  display: flex;
  justify-content: center;
  align-items: center;
  color: #888;
  cursor: pointer;
  font-size: 16px;
}

.fl-gotopost-post-action span {
  margin-top: 3px;
}

.fl-gotopost-post-action.fl-gotopost-post-action-dark {
  border-right: 1px solid #ffffff20;
}
.fl-gotopost-post-action.fl-gotopost-post-action-light {
  border-right: 1px solid #00000020;
}

.fl-gotopost-post-action-icon {
  margin-right: 5px;
  font-size: 20px;
  display: flex;
  align-items: center;
}

.fl-gotopost-post-action:last-child {
  border-right: none;
}

.fl-gotopost-card {
  padding: 0px 15px;
}
.fl-gotopost-row {
  display: flex;
  padding: 0px !important;
}

.fl-gotopost-comments {
  display: flex;
  flex-direction: column;
  flex: 1 auto;
  position: relative;
  border-radius: 0 20px 20px 0px;
}

.fl-gotopost-comments-media {
}

.fl-gotopost-comments.fl-gotopost-comments-false-light {
  border-left: 1px solid #00000020;
}

.fl-gotopost-comments.fl-gotopost-comments-false-dark {
  border-left: 1px solid #ffffff20;
}

.fl-gotopost-post-comment-form {
  width: 100%;
  padding: 10px;
  color: #fff;
}

.fl-gotopost-post-comment-form-dark {
  border-bottom: 1px solid #ffffff20;
}

.fl-gotopost-post-comment-form-light {
  border-bottom: 1px solid #00000020;
}

.fl-gotopost-post-comment-form-dark textarea {
  border: 1px solid #00000010;
  color: #fff;
  padding: 10px 10px;
  border-radius: 5px;
  background-color: #00000010;
  margin-top: -1px;
}

.fl-gotopost-post-comment-form-light textarea {
  border: 1px solid #00000010;
  color: #000;
  padding: 10px 10px;
  border-radius: 5px;
  background-color: #00000010;
  margin-top: -1px;
}

.fl-gotopost-post-comment-form .fl-post-comments-make-input {
  min-height: 40px;
}

.fl-gotopost-post-comment-form-row {
  display: flex;
  justify-content: space-between;
  align-items: center;
  position: relative;
}

.fl-gotopost-post-comment-form-row-replay {
  padding-top: 5px;
}

.fl-gotopost-post-comment-form-picker {
  width: 30px;
  height: 50px;
  padding-top: 0px;
  font-size: 24px;
  color: #888;
  display: flex;
  justify-content: center;
  align-items: center;
  position: relative;
  cursor: pointer;
}

.fl-gotopost-post-comment-form-input {
  width: calc(100% - 100px);
  height: 34px;
}

.fl-post-comments-make-input:hover,
.fl-gotopost-post-comment-form-post:hover {
  opacity: 0.7;
}

.fl-post-comment-restricted {
  padding-left: 40px;
}

.fl-gotopost-post-comment-form-post {
  color: #ff0d72;
  height: 50px;
  padding-top: 0px;
  display: flex;
  justify-content: center;
  align-items: center;
  font-size: 16px;
  width: 40px;
  cursor: pointer;
  user-select: none;
}

.fl-gotopost-post-comment-form-reply-to {
  font-size: 10px;
  text-transform: uppercase;
  color: #888;
  font-weight: 500;
  margin-bottom: 10px;
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.fl-forward-post-image {
  animation: forward infinite linear;
  transform: translate3d(0, 0, 0);
}

.fl-gotopost-pending {
  margin-left: 15px;
}

.fl-gotopost-pending b {
  margin-top: 2px !important;
}

@keyframes forward {
  100% {
    -webkit-transform: translateX(-200%);
  }
}
.fl-gotopost-post-comment-form-reply-to span {
  font-size: 16px;
  cursor: pointer;
}

.fl-gotopost-post-comment-form-reply-to span:hover {
  color: #666;
}

.fl-gotopost-post-comment-form-reply-comment {
  display: flex;
  justify-content: space-between;
}

.fl-gotopost-post-comment-form-reply {
  /* width: calc(100% + 20px); */
  background-color: #ff0d7220;
  /* margin: -10px -10px 0px; */
  padding: 10px 20px;
}

.fl-gotopost-post-comment-form-post-disabled {
  opacity: 0.4;
  pointer-events: none;
}

.fl-gotopost-post-comment-form-submit {
  display: flex;
  justify-content: flex-end;
}

.fl-gotopost-post-comment-form-submit button {
  padding: 5px 25px;
  height: auto;
  font-size: 14px;
}

.fl-gotopost-post-picker {
  position: absolute;
  left: 0px;
  top: 45px;
  z-index: 1;
  width: 100%;
}

.fl-gotopost-post-picker .emoji-picker-react{
  width: 100%;
}


.fl-gotopost-post-comment-list-outer {
  flex: 1 auto;
  width: 100%;
  position: relative;
}

.fl-gotopost-post-comment-list {
  padding: 5px;
  /*overflow-x: hidden;*/
  /*overflow-y: scroll;*/
  position: absolute;
  left: 0px;
  top: 0px;
  width: 100%;
  height: 100%;
}

.fl-gotopost-post-comment-list-text {
  position: unset;
}

.fl-gotopost-comments-text {
  padding: 10px;
  font-size: 14px;
}

.fl-gotopost-comments-text.fl-gotopost-comments-text-dark {
  color: #fff;
}

.fl-gotopost-comments-text.fl-gotopost-comments-text-light {
  color: #000;
}

.fl-gotopost-post-comment-list::-webkit-scrollbar {
  display: none;
}

.fl-gotopost-post-media-gallery,
.fl-gotopost-post-media-gallery .image-gallery-content,
.fl-gotopost-post-media-gallery .image-gallery-slide-wrapper,
.fl-gotopost-post-media-gallery .image-gallery-swipe,
.fl-gotopost-post-media-gallery .image-gallery-slides,
.fl-gotopost-post-media-gallery .image-gallery-slide,
.fl-gotopost-post-media-gallery .image-gallery-slide > *,
.fl-gotopost-post-media-gallery .image-gallery-slide img {
  width: 100%;
  height: 100%;
}
.fl-gotopost-post-media-gallery .image-gallery-slide img {
  object-fit: cover;
  object-position: center;
}

.fl-gotopost-post-comment-single {
  display: flex;
  justify-content: space-between;
  padding: 10px;
  position: relative;
  outline: none;
}

.fl-gotopost-post-comment-single .fl-simple-comment-dropdown {
  top: 7px;
  position: absolute;
  right: 5px;
}

.fl-gotopost-post-comment-single
  .fl-simple-comment-dropdown
  .fl-post-head-actions-dropdown {
  width: 190px;
}

.fl-gotopost-post-comment-single-image {
  width: 30px;
  height: 30px;
  position: relative;
  border-radius: 50%;
  cursor: pointer;
}

.fl-gotopost-post-comment-single-image .fl-profile-holder-status {
  bottom: -5px;
  right: -4px;
}

.fl-gotopost-post-comment-single-data {
  width: calc(100% - 50px);
}

.fl-gotopost-post-comment-single-data-name {
  font-size: 14px;
  margin-bottom: 5px;
  font-weight: 500;
  text-decoration: none;
  display: block;
}

.fl-gotopost-post-comment-single-data-name.fl-gotopost-post-comment-single-data-name-light {
  color: #000;
}

.fl-gotopost-post-comment-single-data-name.fl-gotopost-post-comment-single-data-name-dark {
  color: #fff;
}

.fl-gotopost-post-comment-single-data-name span {
  color: #ff0d72;
}

.fl-gotopost-post-comment-single-data-text {
  width: 100%;
  color: #000;
  font-size: 14px;
  display: flex;
  align-items: center;
  justify-content: space-between;
}

.fl-gotopost-post-comment-single-data-text-data {
  width: calc(100% - 30px);
}

.fl-gotopost-post-comment-single-data-text-data.fl-gotopost-post-comment-single-data-text-data-dark {
  color: #fff;
}

.fl-gotopost-post-comment-single-data-text-data.fl-gotopost-post-comment-single-data-text-data-light {
  color: #000;
}

.fl-gotopost-post-comment-single-data-text-action {
  width: 20px;
  height: 20px;
  display: flex;
  align-items: center;
  font-size: 16px;
  color: #888;
  cursor: pointer;
  user-select: none;
}

.fl-gotopost-post-comment-single-data-text-action-icon {
  animation: scaleUp 300ms forwards ease-in-out;
}

.fl-gotopost-post-comment-single-data-text-action-icon-active {
  color: #ff0d72;
}

.fl-gotopost-post-comment-single-data-name-time {
  font-size: 14px;
  margin-top: 5px;
  color: #888;
}

.fl-gotopost-post-comment-single-data-name-time span {
  margin-right: 15px;
  cursor: pointer;
}

.fl-gotopost-post-comment-single-data-name-time span:hover {
  color: #ff0d72;
  cursor: pointer;
}

.fl-gotopost-post-comment-single-data-name-time span:last-child {
  margin-right: 0px;
}

#fl-gotopost-likes-number:hover {
  cursor: default;
  color: #888;
}

#fl-gotopost-time:hover {
  cursor: default;
  color: #888;
}

.fl-gotopost-post-comment-single-data-comments {
  color: #888;
  font-size: 14px;
  margin-top: 15px;
  display: flex;
  align-items: center;
}

.fl-gotopost-post-comment-single-data-comments::before {
  width: 40px;
  height: 1px;
  background-color: #888;
  content: "";
  margin-right: 5px;
  margin-top: -3px;
}

.fl-gotopost-post-comment-single-data-comments span {
  cursor: pointer;
}

.fl-gotopost-post-comment-single-data-comments-replies {
  width: 100%;
}

.fl-gotopost-post-head-actions {
  position: absolute;
  right: 15px;
  top: 10px;
  outline: none;
  z-index: 10;
}

.fl-gotopost-pin {
  color: #ff0d72;
  font-size: 15px;
}

.fl-gotopost-post-header-name:hover,
.fl-gotopost-post-head-actions svg:hover,
.fl-gotopost-post-comment-single-data-name:hover {
  color: #525252;
}

.fl-gotopost-post-header-time {
  cursor: default;
}

.fl-gotopost-post-header-name:hover,
.fl-gotopost-post-header-username:hover,
.fl-gotopost-post-comment-form-picker svg:hover,
.fl-simple-comment-dropdown svg:hover {
  color: #525252;
}

.fl-gotopost-post-comment-single-data-name span:hover {
  opacity: 0.7;
}

.fl-gotopost-post-comment-single-data-name-time
  span:hover:not(.fl-gotopost-post-comment-single-data-name-time
    span:first-child, span:nth-child(2)) {
  color: #ff0d72;
}

.fl-gotopost-row-files {
  position: relative;
}

.fl-gotopost-gallery-preview {
  border-radius: 20px 0px 0px 20px;
}

.fl-gotopost-post-comment-single-data-comments-replies .fl-gotopost-post-comment-single {
  padding: 13px 10px 10px 0px;
}

/* Large devices (desktops, less than 1200px) */
@media (max-width: 1199.98px) {
  .fl-gotopost-post-footer {
    display: none;
  }
  .fl-gotopost-row {
    width: 100%;
    margin: 0px;
    border-radius: 0px;
    flex-direction: column;
    overflow-x: hidden;
    overflow-y: scroll;
  }

  .fl-gotopost-gallery-preview {
    width: 100% !important;
  }
  .fl-gotopost-comments {
    width: 100% !important;
    /* //padding: 0px 0px 0px; */
    flex: 1 auto;
    border-left: none !important;
  }
  .fl-gotopost-post-media {
    height: 100%;
  }
  .fl-gotopost-post-comment-list {
    position: static;
  }
  .fl-gotopost-post-header {
    height: 50px;
  }
  .fl-gotopost-post-header-back-icon {
    display: flex;
  }

  .fl-gotopost-card .fl-modal-close-button {
    right: 0px;
  }
}

/* Medium devices (tablets, less than 992px) */
@media (max-width: 991.98px) {
  .fl-gotopost-post-head-actions {
    top: 30px;
    right: 20px;
  }
  .fl-gotopost-comments-header {
    padding: 5;
  }
}

/* Small devices (landscape phones, less than 768px) */
@media (max-width: 767.98px) {
  .fl-gotopost-time .fl-post-head-info-dot,
  .fl-gotopost-time .fl-gotopost-post-header-time {
    display: none;
  }
}

/* Extra small devices (portrait phones, less than 576px) */
@media (max-width: 575.98px) {
}
