.fl-file-picker-main {
  width: 100%;
}

.fl-file-picker-label span,
.fl-file-picker-label .fl-file-picker-label-icon {
  font-size: 12px;
  color: #888;
  font-weight: 600;
}

.fl-file-picker-label .fl-file-picker-label-icon {
  margin-left: 5px;
}

.fl-file-picker-button {
  width: 100%;
  margin: 8px 0px;
}

.fl-file-picker-description {
  font-size: 12px;
  width: 100%;
  padding: 0px 0px;
  color: #888;
  font-weight: 500;
}

.fl-file-picker-names {
  display: flex;
  max-width: 100%;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}

.fl-file-picker-preview {
  width: 25px;
  height: 20px;
  min-width: 25px;
  min-width: 20px;
  background-color: #000;
  position: relative;
  margin-right: 5px;
}

.fl-file-picker-preview img {
  width: 100%;
  height: 100%;
  object-fit: contain;
}

.fl-file-picker-images {
  width: 100%;
  display: flex;
}

.fl-file-picker-image {
  width: 100%;
  max-width: 140px;
  margin-right: 10px;
  padding-top: 70px;
  background-color: #000;
  position: relative;
}

.fl-file-picker-image img,
.fl-file-picker-image video {
  width: 100%;
  height: 100%;
  position: absolute;
  left: 0px;
  top: 0px;
  object-fit: contain;
  object-position: center;
}

.fl-file-picker-image-remove {
  position: absolute;
  right: 5px;
  top: 5px;
  width: 15px;
  height: 15px;
  font-size: 15px;
  color: red;
  cursor: pointer;
}

/* Large devices (desktops, less than 1200px) */
@media (max-width: 1199.98px) {
}

/* Medium devices (tablets, less than 992px) */
@media (max-width: 991.98px) {

}

/* Small devices (landscape phones, less than 768px) */
@media (max-width: 767.98px) {
}

/* Extra small devices (portrait phones, less than 576px) */
@media (max-width: 575.98px) {
  .fl-file-picker-label span {
    font-size: 13px;
  }
}
