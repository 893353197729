.fl-settings-sub-security-field {
    width: 100%;
}

.fl-settings-sub-security-field-label {
    font-size: 12px;
    text-transform: uppercase;
    color: #888;
    font-weight: 500;
    line-height: 15px;
}

.fl-settings-sub-security-field-input {
    height: auto;
    margin: 5px 0px;
}

.fl-settings-sub-security-field-input input {
    border: none;
    background-color: #00000010;
    padding: 8px 10px;
    border-radius: 5px;
}

.fl-settings-sub-security-button {
    border-radius: 5px;
    padding: 5px 15px;
    height: auto;
    margin-top: 20px;
}

.fl-settings-img-watermark img {
    width: 100%;
}

.fl-settings-img-watermark {
    position: relative;
}

.fl-settings-span-position-top-left {
    position: absolute;
    top: 5px;
    left: 5px;
}

.fl-settings-span-position-top-right {
    position: absolute;
    top: 5px;
    right: 5px;
}

.fl-settings-span-position-middle {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
}

.fl-settings-span-position-bottom-right {
    position: absolute;
    bottom: 5px;
    right: 5px;
}

.fl-settings-span-position-bottom-left {
    position: absolute;
    bottom: 5px;
    left: 5px;
}

.fl-settings-span-color-white {
    color: white;
}

.fl-settings-span-color-black {
    color: black;
}

.fl-settings-span-color-white-black {
    color: white;
    -webkit-text-stroke-width: .5px;
    -webkit-text-stroke-color: black;
    font-weight: 500;
}

.fl-settings-span-color-black-white {
    color: black;
    -webkit-text-stroke-width: .5px;
    -webkit-text-stroke-color: white;
    font-weight: 500;
}

.fl-settings-span-size-small {
    font-size: 14px;
}

.fl-settings-span-size-medium {
    font-size: 16px;
}

.fl-settings-span-size-large {
    font-size: 18px;
}

.fl-settings-watermark-specification .fl-select-main {
    max-width: 100%;
}

.fl-settins-security-watermark-specification {
    float: left;
    width: 50%;
}

.fl-settins-security-watermark-preview {
    float: right;
    width: 50%;
}

.fl-settins-security-watermark-specification .fl-select-main {
    padding: 0px 10px;
}

.fl-simple-card-authentification .fl-simple-card-main:last-child {
    margin-left: 10px;
}

.fl-simple-card-authentification .fl-simple-card-main {
    cursor: pointer;
}

.fl-simple-card-authentification .fl-simple-card-main:hover {
    cursor: pointer;
    background-color: #f4f4f436;
}

.fl-simple-card-authentification .fl-simple-card-main p:nth-child(1){
    color: #000;
    font-weight: 500;
    font-size: 15px;
}

.fl-simple-card-authentification .fl-simple-card-main p:last-child{
    color: #888;
    font-size: 15px;
}

.fl-simple-card-authentification .fl-simple-card-main div img {
    width: 100px;
    height: 100px;
}

.fl-simple-card-authentification .fl-simple-card-main div,
.fl-security-sms {
    text-align: center;
}

.fl-security-sms {
    border-bottom: 1px solid #00000020;
}

.fl-security-sms img {
    width: 70px;
    height: 70px;
}

.PhoneInputInput {
    background-color: #00000010;
    border: 1px solid #00000010;
    color: #000;
    padding: 8px 10px;
    border-radius: 5px;
    height: 35px;
    width: 100%;
    outline: none;
}

/* Steps */
.fl-step {
    position: relative;
    min-height: 1em;
    color: gray;
}
.fl-step + .fl-step {
    margin-top: 10px;
}
.fl-step > div:first-child {
    position: static;
    height: 0;
}
.fl-step > div:not(:first-child) {
    margin-left: 1.5em;
    padding-left: 1em;
}

.fl-step-circle {
    background: gray;
    position: relative;
    width: 1.5em;
    height: 1.3em;
    line-height: 1.5em;
    border-radius: 100%;
    color: #fff;
    text-align: center;
    box-shadow: 0 0 0 3px #fff;
}

/* Vertical Line */
.fl-step-circle:after {
    content: ' ';
    position: absolute;
    display: block;
    top: 1px;
    right: 50%;
    bottom: 1px;
    left: 50%;
    height: 100%;
    width: 1px;
    transform: scale(1, 2);
    transform-origin: 50% -100%;
    background-color: rgba(0, 0, 0, 0.25);
    z-index: -1;
}
.fl-step:last-child .fl-step-circle:after {
    display: none
}

/* Stepper Titles */
.fl-step-title {
    line-height: 1.5em;
    font-weight: bold;
}
.fl-step-caption {
    font-size: 0.8em;
}

.fl-step-caption .fl-settings-sub-account-field-half img {
    width: 100%;
    height: 60px;
    cursor: pointer;
}

.fl-step-caption .fl-settings-sub-account-field-half {
    cursor: pointer;
}

.fl-step .fl-secret-key input{
    cursor: pointer;
}

.fl-secret-key .fl-minput-main,
.fl-caption-finish .fl-minput-main {
    margin-top: 0px;
}

.fl-step .fl-secret-key span {
    color: green;
}

.fl-qr-code {
    /*width: 150px;*/
}

.fl-google-authenticate-modal {
    padding: 15px 30px;
}

.fl-google-authenticate-modal-image {
    text-align: center;
}

 /*new*/
.fl-security-status img {
    width: 20px;
    height: 20px;
    vertical-align: middle;
}

.fl-security-edit-authentication {
    color: #15c;
    cursor: pointer;
    text-decoration: none;
    font-weight: 500;
}

.fl-security-edit-authentication:hover {
    text-decoration: underline;
}

.fl-modal-close {
    text-align: right;
    padding-right: 15px;
    position: absolute;
    right: 0;
}

.fl-modal-close svg:hover {
    color: #333;
    cursor: pointer;
}

.fl-modal-close div {
    float: right;
    padding-top: 5px;
    padding-left: 8px;
    padding-right: 8px;
}

.fl-modal-close div:hover {
    background: rgba(204, 204, 204, 0.5);
    border-radius: 15px;
    color: white;
}

.fl-modal-close div svg:hover {
    color: white;
}

.fl-simple-card-authentification {
    padding: 15px 30px;
}

.fl-authentication-tittle {
    color: #000;
    font-size: 22px;
    font-weight: 500;
    margin-block-end: 0em;
}

.fl-authentication-option {
    color: #000;
    font-weight: 500;
    display: flex;
    align-items: center;
}

.fl-authentication-option img {
    width: 30px;
}

.fl-authentication-option-title {
    margin-left: 10px;
}

.fl-security-footer .fl-loader-animation {
    margin-left: 5px;
}

.fl-security-resend,
.fl-security-change-number,
.fl-security-secret-code {
    text-decoration: underline;
    cursor: pointer;
    color: #ff0d72;
}

.fl-security-resend:hover,
.fl-security-change-number:hover,
.fl-security-secret-code:hover {
    text-decoration: none;
}

.fl-secret-code-copy {
    cursor: pointer;
}

.fl-secret-code-copy-message {
    color: green;
}

.fl-settings-p-dark {
    color: white;
}

.react-tel-input input {
    width: 100% !important;
}


/* The container */
.fl-authentication-option-checkbox {
    position: relative;
    padding-left: 35px;
    margin-bottom: 25px;
    cursor: pointer;
    font-size: 22px;
    -webkit-user-select: none;
    -moz-user-select: none;
    -ms-user-select: none;
    user-select: none;
    margin-left: 10px;
}

/* Hide the browser's default checkbox */
.fl-authentication-option-checkbox input {
    position: absolute;
    opacity: 0;
    cursor: pointer;
    height: 0;
    width: 0;
}

/* Create a custom checkbox */
.fl-authentication-option-checkmark {
    position: absolute;
    top: 0;
    left: 0;
    height: 25px;
    width: 25px;
    border: 1px solid #666666;
    border-radius: 5px;background-color: #eee;
}

.fl-authentication-option-checkbox:hover input ~ .fl-authentication-option-checkmark {
    background-color: #ccc;
}

.fl-authentication-option-checkbox input:checked ~ .fl-authentication-option-checkmark {
    background-color: #ff0d72;
}

.fl-authentication-option-checkmark:after {
    content: "";
    position: absolute;
    display: none;
}

.fl-authentication-option-checkbox input:checked ~ .fl-authentication-option-checkmark:after {
    display: block;
}

.fl-authentication-option-checkbox .fl-authentication-option-checkmark:after {
    left: 8px;
    top: 4px;
    width: 5px;
    height: 10px;
    border: solid white;
    border-width: 0 3px 3px 0;
    -webkit-transform: rotate(45deg);
    -ms-transform: rotate(45deg);
    transform: rotate(45deg);
}

.fl-a-reverse-underline {
    text-decoration: underline !important;
}

.fl-a-reverse-underline:hover {
    text-decoration: none !important;
}

/* Large devices (desktops, less than 1200px) */
@media (max-width: 1199.98px) {

}

/* Medium devices (tablets, less than 992px) */
@media (max-width: 991.98px) {

}

/* Small devices (landscape phones, less than 768px) */
@media (max-width: 767.98px) {
    .fl-settins-security-watermark-specification {
        width: 100%;
    }

    .fl-settins-security-watermark-preview {
        float: right;
        width: 100%;
    }
}

/* Extra small devices (portrait phones, less than 576px) */
@media (max-width: 575.98px) {
    .fl-settins-security-watermark-specification {
        width: 100%;
    }

    .fl-settins-security-watermark-preview {
        float: right;
        width: 100%;
    }
}
