.fl-filter__accordion {
  position: relative;
  overflow: hidden;
  border-radius: 1rem;
  margin-bottom: 1rem;
  border: 1px solid rgba(191, 191, 191, 0.5);
  font-size: 16px;
  font-weight: 600;
  transition: 0.4s height;
  line-height: 20px;
}

.fl-filter__accordion-toggle {
  cursor: pointer;
  border-radius: 5px;
  padding: 0.5rem 0.5rem 0.5rem 1rem;
  transition: 0.2s background-color;
  color: #808080;
  user-select: none;
  margin-top: 1px;
}

.fl-bold-body-search-users .fl-filter__accordion-toggle {
  cursor: text;
}

.fl-bold-body-search-users .fl-filter__accordion-toggle {
  padding: 1rem 0.5rem 0.5rem 1rem;
}

.fl-filter__accordion-body {
  font-weight: 400;
  color: #888888;
  padding: 0 0.5rem;
  margin-bottom: 1rem;
}

.fl-filter__accordion-hover-light:hover {
  /*border:solid 1px #ff35466c;*/
  background: #f6f6f6;
  box-shadow: 0px 5px 8px 2px #00000020;
}
.fl-filter__accordion-hover-dark:hover {
  /*border:solid 1px #ff35466c;*/
  background: #1b1b1b;
  box-shadow: 0px 5px 8px 2px #00000020;
}

.fl-bold-body-fetish .fl-minput-select-dropdown {
  position: unset;
}

.fl-filter__accordion .fl-minput-input-clear {
  color: #808080;
  margin-top: 1px;
}

.fl-explore-fetishes .fl-filter__accordion-toggle {
  padding: 0px;
  cursor: pointer;
}

.fl-filter__accordion-toggle-selected {
  color: #ff3546;
  font-weight:500;
}

.fl-explore-fetishes .fl-minput-label  {
  padding-left: 7px;
}

.fl-explore-fetishes .fl-minput-input-inner-select {
  padding: 0px;
}

.fl-explore-fetishes  input {
  cursor: pointer;
}

.fl-explore-fetishes .fl-minput-main-expand {
  padding-top: 20px;
}

.fl-explore-fetishes .fl-minput-input-outer {
  margin: 0px;
}
