.fl-profile-main {
  width: 100%;
  flex: 1;
  min-height: 100%;
}

.fl-profile-main.fl-profile-main-light {
  background-color: #f4f4f4;
}

.fl-profile-main.fl-profile-main-dark {
  background-color: #1b1b1b;
}

.fl-profile-sort {
  width: 100%;
  margin-bottom: 20px;
  display: flex;
  align-items: flex-start;
  justify-content: center;
  padding: 0 10px;
}

.fl-profile-sort-disabled .fl-profile-sort-single {
  cursor: default;
  opacity: 0.7;
  pointer-events: none;
}

.fl-profile-sort-enabled .fl-profile-sort-single span:hover {
  cursor: pointer;
  color: #525252;
}

.fl-profile-sort-single-bold {
  font-weight: 500;
}

.fl-profile-sort-single {
  padding: 20px 15px;
  font-size: 14px;
  color: #888;
  cursor: pointer;
  text-decoration: none;
  display: flex;
  text-align: center;
}

.fl-profile-sort-single-with-image {
}
.fl-profile-sort-single-active {
  color: #ff0d72;
}

.fl-profile-sort-single-icon {
  /* margin-right: 8px; */
  /*margin-bottom: 5px;*/
}

.fl-profile-sort-single-icon-rect {
  border: 1px solid #888;
  padding: 2px 4px;
  width: 20px !important;
  border-radius: 3px;
}

.fl-profile-sort-single-active .fl-profile-sort-single-icon-rect {
  border-color: #ff0d72;
  
}

.fl-profile-sort-single-counter {
  display: inline-block;
}

.fl-posts-view-grid {
  display: grid;
  grid-template-columns: minmax(0, 1fr) minmax(0, 1fr) minmax(0, 1fr);
  column-gap: 10px;
  width: 100%;
  align-items: center;
}

.fl-posts-view-grid > div {
  align-self: stretch;
}

.fl-profile-schedule-post-message-nopost {
  width: 100%;
  text-align: center;
  padding: 20px 10px 40px 10px;
}
.fl-profile-schedule-post-message-nopost p {
  text-align: center;
}

.fl-profile-schedule-post-message-nopost img {
  width: 20px;
  height: 20px;
  display: inline-block;
  margin-right: 7px;
  opacity: 0.6;
}

.fl-user-profile-data-share {
  cursor: pointer;
  right: 10px;
  bottom: 10px;
  color: #ff0d72;
  width: 1.75rem;
  height: 1.75rem;
  padding: 0.25rem;
  border-radius: 50%;
  transition: 0.25s background-color;
  border: 1px solid #ff0d72;
  position: absolute;
  right: 10px;
  top: 10px;
}

.fl-user-profile-data-share:hover {
  background: #ff0d72;
  color: white;
}

.fl-user-profile-data-value-clickable {
  cursor: pointer;
}

.fl-profile-sort-single span {
  margin-left: 5px;
}

/* Large devices (desktops, less than 1200px) */
@media (max-width: 1199.98px) {
  .fl-profile-sort-single {
    font-size: 14px;
  }
  .fl-user-profile-data-name-full {
    font-size: 16px;
  }
  .fl-user-profile-data-name-full-location {
    width: 24px;
    height: 24px;
  }
  .fl-user-profile-data-name-username {
    font-size: 16px;
  }
  .fl-user-profile-data-key {
    font-size: 16px;
  }
  .fl-user-profile-data-value {
    font-size: 16px;
  }
  .fl-user-profile-data-right-bio {
    font-size: 16px;
    line-height: 22px;
  }
  .fl-user-profile-data-row {
    margin-bottom: 8px;
    font-size: 16px;
  }

  .fl-user-profile-data-icon {
    width: 20px;
    height: 20px;
  }
}

.fl-profile-no-subscribe-box {
  width: 100%;
  height: 400px;
  background-color: #e6e5e5 !important;
  overflow: hidden;
  position: relative;
  border-radius: 20px;
  margin-bottom: 30px;
}

.fl-profile-no-subscribe-box-logo {
  position: absolute;
  margin: 30px 20px 20px 20px;
  transform: rotate(336deg);
}

.fl-profile-no-subscribe-box .fl-postlock__main {
  background-color: #888888ad;
}

.fl-profile-sort-single .fl-profile-sort-single-icon-count-mobile {
  display: none;
}

.fl-profile-icon-grid {
  margin-top: 5px;
}

.fl-profile-empty-post {
  text-align: center;
  padding: 0px 20px;
}

.fl-profile-count-icons {
  display: flex;
  align-items: center;
  justify-content: center;
  height: 26px;
  padding-bottom: 8px;
}

.fl-profile-count-icons img:not(.fl-profile-count-icons-scheduled img) {
  width: 25px;
}

.fl-profile-count-icons-grid svg {
  font-size: 25px;
}

.fl-profile-count-icons-posts img {
  width: 28px;
}

.fl-profile-count-icons-scheduled img {
  width: 29px;
}

/* Medium devices (tablets, less than 992px) */
@media (max-width: 991.98px) {
  .fl-profile-card-main {
    display: block;
  }
  .fl-profile-card-image {
    width: 100%;
  }
  .fl-profile-card-info {
    width: 100%;
  }
  .fl-profile-sort-single {
    padding: 15px 20px;
    font-size: 14px;
    text-align: center;
  }
  .fl-profile-tablet-show {
    width: 100%;
    display: block;
  }
  .fl-profile-tablet-hide {
    display: none;
  }
  .fl-profile-sort-single-icon {
    /* display: block; */
  }
}

/* Small devices (landscape phones, less than 768px) */
@media (max-width: 767.98px) {
  .fl-profile-sort-single span {
    display: block;
  }
  /*.fl-user-profile-data-name-username {*/
  /*  margin-left: 1rem;*/
  /*}*/
  .fl-profile-sort-single span {
    margin-left: 0px;
  }
}

@media (min-width: 575.99px) {
  .fl-profile-schedule-post-message-nopost {
    display: flex;
    align-items: center;
    justify-content: center;
    align-content: center;
    margin-right: 7px;
  }
  .fl-profile-schedule-post-message-nopost img {
    margin-bottom: 9px !important;
  }
}

/* Extra small devices (portrait phones, less than 576px) */
@media (max-width: 575.98px) {
  .fl-posts-view-grid {
    grid-template-columns: minmax(0, 1fr) minmax(0, 1fr);
  }
  .fl-profile-sort-single .fl-profile-sort-single-icon-count {
    display: none !important;
  }
  .fl-profile-sort-single .fl-profile-sort-single-icon-count-mobile {
    display: block;
    font-size: 15px;
  }
  .fl-profile-sort-single {
    font-size: 20px;
    padding: 15px;
    display: flex;
    align-items: center;
    flex-direction: column;
    justify-content: center;
  }
  .fl-profile-sort-single-counter {
    display: block;
    font-size: 14px;
    margin-top: 5px;
  }
  .fl-profile-sort-single-icon {
    margin-right: 0px;
  }
}
@media (min-width: 575.98px) {
}

@media (min-width: 767.98px) {
  .fl-profile-sort {
    /* width: 100%;
        margin-bottom: 20px;
        display: flex;
        align-items: flex-start;
        padding: 0 10px; */
    justify-content: space-between;
  }
  .fl-profile-sort-single {
    padding: 15px 10px;
    font-size: 14px;
  }
  .fl-profile-sort-single span {
    display: inline-block;
  }
  .fl-profile-sort-single-icon {
    margin-bottom: 0;
  }

  @media (min-width: 1200px) {
  }
}

@media (max-width: 767.98px) {

}
