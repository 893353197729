.fl-minput-main {
  width: 100%;
}

.fl-minput-input-outer {
  margin: 5px 0px;
  position: relative;
  width: 100%;
}

.fl-minput-input-inner,
.fl-minput-input-inner-select {
  border: none;
  padding: 8px 10px;
  border-radius: 5px;
  height: 35px;
  width: 100%;
  outline: none;
}

.fl-minput-textarea-inner {
  border-radius: 5px;
  resize: none;
  height: 100px;
  outline: none;
  width: 100%;
  padding: 15px;
  border: 1px solid #00000010;
  background: #00000010;
}

.fl-minput-input-inner.fl-minput-input-inner-dark,
.fl-minput-input-inner-select.fl-minput-input-inner-select-dark,
.fl-minput-input-inner-dark {
  background-color: #ffffff10;
  border: 1px solid #ffffff10;
  color: #fff;
}

.fl-minput-input-inner.fl-minput-input-inner-light,
.fl-minput-input-inner-select.fl-minput-input-inner-select-light {
  background-color: #00000010;
  border: 1px solid #00000010;
  color: #000 !important;
}

.fl-minput-input-inner:focus,
.fl-minput-input-inner-select:focus,
.fl-minput-textarea-inner:focus {
  border-color: orange !important;
}

.fl-minput-textarea-inner:focus ~ .fl-minput-label {
  transform: translate(-10px, calc(-50% - 25px));
  font-size: 12px;
}

.fl-minput-input-inner:focus ~ .fl-minput-label,
.fl-minput-input-inner-select:focus ~ .fl-minput-label,
.fl-minput-textarea-inner:focus ~ .fl-minput-label,
.fl-minput-input-inner:focus ~ .fl-minput-label-length {
  color: orange !important;
}

.fl-minput-label {
  pointer-events: none;
  position: absolute;
  left: 10px;
  top: 17.5px;
  transform: translate(0%, -50%);
  color: #888;
  font-size: 16px;
  transition: 250ms transform ease-in-out, 250ms font-size ease-in-out;
  /* pointer-events: none; */
  text-overflow: ellipsis;
  white-space: nowrap;
  overflow: hidden;
  width: calc(100% - 15px);
}

.fl-minput-label-length {
  right: 5px;
  position: absolute;
  top: -15px;
  font-size: 12px;
  color: #888;
}

.fl-minput-input-inner-select-search {
  width: 100%;
  height: 100%;
  background: none;
  outline: none;
  border: none;
}

.fl-minput-input-inner:focus ~ .fl-minput-label,
.fl-minput-label-active {
  transform: translate(-10px, calc(-50% - 25px));
  font-size: 12px;
}

input:-webkit-autofill ~ .fl-minput-label {
  transform: translate(-10px, calc(-50% - 25px));
  font-size: 12px;
}

.fl-minput-select-dropdown {
  position: absolute;
  width: 100%;
  top: 35px;
  border-radius: 0px 0px 5px 5px;
  z-index: 1;
  max-height: 300px;
  display: none;
  overflow-x: hidden;
  overflow-y: auto;
}

.fl-minput-select-dropdown.fl-minput-select-dropdown-dark {
  border: 1px solid #ffffff20;
  background-color: #000;
}

.fl-minput-select-dropdown.fl-minput-select-dropdown-light {
  border: 1px solid #00000020;
  background-color: #fff;
}

.fl-minput-main-expand .fl-minput-select-dropdown {
  display: block !important;
}

.fl-minput-main-expand .fl-minput-input-inner {
  border-bottom-left-radius: 0px;
  border-bottom-right-radius: 0px;
}

.fl-minput-select-option {
  width: 100%;
  padding: 8px 10px;
  cursor: pointer;
  display: flex;
  align-items: center;
}

.fl-minput-select-option .fl-profile-holder {
  width: 30px;
  height: 30px;
  position: unset;
  margin-left: 5px;
}

.fl-minput-main-expand .fl-minput-select-option span {
  margin-left: 5px;
}

.fl-minput-select-option.fl-minput-select-option-light {
  color: #000;
  border-bottom: 1px solid #00000020;
}

.fl-minput-select-option.fl-minput-select-option-dark {
  color: #fff;
  border-bottom: 1px solid #ffffff20;
}

.fl-minput-select-option:nth-last-of-type(1) {
  border-bottom: none;
}

.fl-minput-select-option:hover {
  background-color: #ff0d72;
  color: #fff;
}

.fl-minput-select-options-title {
  width: 100%;
  padding: 3px 5px;
  background-color: #888;
  color: #fff;
  text-transform: uppercase;
  font-weight: 500;
  font-size: 12px;
}

.fl-minput-select-option-active {
  background-color: #00000020;
}

.fl-minput-input-clear {
  position: absolute;
  right: 0px;
  top: 17.5px;
  width: 30px;
  height: 30px;
  display: flex;
  align-items: center;
  justify-content: center;
  font-size: 16px;
  transform: translateY(-50%);
  cursor: pointer;
}

/*.fl-minput-not-valid {*/
/*  border-color: #ff0000;*/
/*}*/

.fl-minput-input-clear.fl-minput-input-clear-dark {
  color: #fff;
}
.fl-minput-input-clear.fl-minput-input-clear-light {
  color: #000;
}

.fl-minput-input-inner-select {
  padding-right: 35px;
}

.fl-minput-validator {
  color: #ff0000;
  font-size: 12px;
  padding: 5px 0px;
}

.fl-space-validator {
  padding: 0;
}

.fl-minput-no-result {
  padding: 5px;
}

.fl-minput-no-result:hover {
  background: #f6f6f6;
}

.fl-minput-no-result span {
  display: block;
  text-align: center;
}

.fl-minput-no-result span {
  font-weight: normal;
}

.fl-minput-no-result span:last-child {
  font-size: 13px;
}

.fl-minput-main-not-valid input {
  border: 1px solid #ff0000 !important;
}

/* Large devices (desktops, less than 1200px) */
@media (max-width: 1199.98px) {
}

/* Medium devices (tablets, less than 992px) */
@media (max-width: 991.98px) {
}

/* Small devices (landscape phones, less than 768px) */
@media (max-width: 767.98px) {
}

/* Extra small devices (portrait phones, less than 576px) */
@media (max-width: 575.98px) {
  /* .fl-minput-label { 
    width: calc(100% - 65px);
  } */
}
