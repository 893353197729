.fl-datepicker {
  font-size: 18px;
  border: 3px solid #646464;
  padding: 15px 25px;
  border-radius: 20px;
  background: none;
  outline: none;
  width: 100%;
  height: 100%;
  padding-right: 35px !important;
  letter-spacing: 2px;
  font-weight: 400;
}

.fl-datepicker-light {
  color: #000;
}

.fl-datepicker-time-picker-container {
  padding-bottom: 7px;
}

.fl-datepicker-time-picker-container div {
  border-bottom: 1px solid #88888847;
  padding-top: 25px;
}
/* Chrome, Safari, Edge, Opera */
.fl-datepicker-time-picker-container input::-webkit-outer-spin-button,
.fl-datepicker-time-picker-container input::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}
.fl-datepicker-time-picker-container input {
  height: 30px;
  font-size: 18px;
  font-weight: 300;
  width: 50px;
  text-align: center;
  color: black;
}

.fl-datepicker-time-picker-container svg {
  float: right;
  color: red;
  height: 30px;
}

.fl-datepicker-error {
  color: red;
}

.fl-invalid-date-error {
  display: block;
  margin-top: 5px;
}

.fl-datepicker-time-picker-container {
  font-weight: 300;
}

/* Firefox */
.fl-datepicker-time-picker-container input[type="number"] {
  -moz-appearance: textfield;
}
.fl-datepicker-time-picker {
  border: 0;
  display: inline-block;
  font-size: 15px;
  font-weight: 500;
  color: rgba(0, 0, 0, 0.7);
}
input[type="time"]::-webkit-calendar-picker-indicator {
  background: none;
  display: none;
}
.fl-datepicker-time-picker:focus {
  outline: none;
}
.fl-datepicker-dark {
  color: #fff;
}

.fl-datepicker-outter {
  position: relative;
  width: 100%;
  /* height: 56px; */
  margin: 20px 0px 0px;
}

/* .fl-datepicker-outter::after { */
.fl-datepicker-placeholder {
  content: "DD/MM/YYYY";
  position: absolute;
  top: 0;
  left: 10px;
  height: 100%;
  width: 100%;
  background: transparent;
  display: flex;
  align-items: center;
  user-select: none;
  pointer-events: none;
  opacity: 0.4;
  letter-spacing: 1.5px;
}

.fl-input-datepicker .fl-datepicker-placeholder {
  left: 3px;
  font-size: 17px;
}

.fl-input-datepicker input {
  letter-spacing: 4px;
}

.fl-datepicker-modal-calendar-side .fl-input-datepicker input {
  letter-spacing: 2.5px;
}

.fl-datepicker-placeholder-dark {
  color: #818181;
}
.fl-datepicker-placeholder-cover {
  position: absolute;
  top: 0;
  left: 0;
  height: 100%;
}
.fl-datepicker-placeholder-cover-light {
  background-color: #efefef;
}
.fl-datepicker-placeholder-cover-dark {
  background-color: #101010;
}

.fl-datepicker-calendar-button {
  position: absolute;
  right: 8px;
  top: 17.5px;
  transform: translateY(-50%);
  width: 20px;
  height: 20px;
  font-size: 16px;
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;
}

.fl-datepicker-calendar-button-icon {
  color: #ff0d72;
}

.fl-datepicker-modal-title {
  width: 100%;
  font-size: 32px;
  font-weight: 500;
  padding: 10px 0px;
}

.fl-datepicker-modal-title.fl-datepicker-modal-title-dark {
  color: #fff;
  border-bottom: 1px solid #ffffff40;
}
.fl-datepicker-modal-title.fl-datepicker-modal-title-light {
  color: #000;
  border-bottom: 1px solid #00000040;
}

.fl-datepicker-modal-main {
  padding: 20px;
  height: 100%;
  overflow-y: auto;
}

.fl-datepicker-modal-main.fl-datepicker-modal-main-dark {
  color: #fff;
}
.fl-datepicker-modal-main.fl-datepicker-modal-main-light {
  color: #000;
}

.fl-datepicker-modal-content {
  padding: 10px 0px;
  display: flex;
}

.fl-datepicker-modal-calendar-side {
  width: 50%;
}

.fl-datepicker-modal-date-side {
  width: 50%;
  padding: 10px 10px 10px 25px;
}

.fl-datepicker-modal-month {
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: space-between;
}

.fl-datepicker-modal-month-actions {
  display: flex;
  width: 90px;
  min-width: 90px;
}

.fl-datepicker-modal-month-current {
  width: calc(100% - 90px);
  padding-right: 15px;
  text-overflow: ellipsis;
  overflow: hidden;
  white-space: nowrap;
  font-size: 18px;
  font-weight: 500;
}

.fl-datepicker-modal-month-current select {
  background: none;
  outline: none;
  border: none;
  font-size: 18px;
  font-weight: 500;
}

.fl-datepicker-modal-month-current-dark select {
  color: #fff;
}
.fl-datepicker-modal-month-current-light select {
  color: #000;
}

.fl-datepicker-modal-month-current select option {
  color: #000;
}
.fl-datepicker-modal-month-current select option:disabled {
  color: #888;
}

.fl-datepicker-modal-month-action {
  width: 40px;
  height: 40px;
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 50%;
  cursor: pointer;
  margin-right: 10px;
}

.fl-datepicker-modal-month-action:nth-last-of-type(1) {
  margin-right: 0px;
}

.fl-datepicker-modal-month-action.fl-datepicker-modal-month-action-dark {
  background-color: #ffffff20;
  border: 1px solid #ffffff60;
  color: #888;
}

.fl-datepicker-modal-month-action.fl-datepicker-modal-month-action-light {
  background-color: #00000020;
  border: 1px solid #00000060;
  color: #888;
}

.fl-datepicker-modal-main .fl-modal-window-close {
  color: #888 !important;
}

.fl-datepicker-modal-calendar {
  width: 100%;
  padding: 10px 0px;
}

.fl-datepicker-modal-calendar-row {
  width: 100%;
  display: flex;
  justify-content: center;
}

.fl-datepicker-modal-calendar-row-head {
  font-weight: 600;
}

.fl-datepicker-modal-calendar-row-cell {
  width: 40px;
  height: 40px;
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;
}

.fl-datepicker-modal-calendar-row-cell.fl-datepicker-modal-calendar-row-cell-dark {
  color: #fff;
}
.fl-datepicker-modal-calendar-row-cell.fl-datepicker-modal-calendar-row-cell-light {
  color: #000;
}

.fl-datepicker-modal-calendar-row-cell-invalid {
  color: #888 !important;
}

.fl-datepicker-modal-calendar-row-cell-invalid:hover {
  background-color: transparent !important;
  /* cursor: default !important; */
}

.fl-datepicker-modal-calendar-row-cell-value {
  position: relative;
  padding-top: 3px;
}

.fl-datepicker-modal-calendar-row-cell-active {
  position: relative;
  color: #fff !important;
}

.fl-datepicker-modal-calendar-row-cell-active {
  background-color: #ff0d72 !important;
  color: white;
  border-radius: 50%;
}

.fl-datepicker-modal-calendar-row-cell:hover:not(.fl-datepicker-modal-calendar
    .fl-datepicker-modal-calendar-row:first-child
    .fl-datepicker-modal-calendar-row-cell:hover) {
  background-color: #ff0d7252;
  color: white;
  border-radius: 50%;
}

.fl-datepicker-modal-calendar-row-cell-current-date {
  background-color: #ff0d7252;
  border-radius: 50%;
  color: white !important;
}

.fl-datepicker-modal-calendar-row-cell-disable {
  pointer-events: none;
}

.fl-datepicker-modal-date-input {
  width: 100%;
  height: 30px;
  font-size: 18px;
  background: none;
  border: none;
  border-bottom: 1px solid #88888847;
  color: black;
  text-align: left;
  outline: none;
  font-weight: 300;
}

.fl-datepicker-modal-date-input:focus {
  color: #ff0d72;
}

.fl-datepicker-modal-date-input-dark:focus {
  border-bottom: 2px solid #ffffff90;
}
.fl-datepicker-modal-date-input-light:focus {
  border-bottom: 2px solid #00000090;
}

.fl-datepicker-modal-buttons {
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: flex-end;
}

.fl-datepicker:focus ~ .fl-minput-label,
.fl-minput-label-active {
  transform: translate(-10px, calc(-50% - 25px));
  font-size: 12px;
}

.fl-datepicker-modal-date-update {
  border-bottom: 1px solid #88888847;
}

.fl-datepicker-modal-date-update span {
  display: block;
  padding-bottom: 5px;
  font-size: 18px;
  font-weight: 300;
}

.fl-datepicker-modal-date-update span:first-child {
  color: #888;
}

.fl-datepicker-modal-date-update input {
  border: none;
}

.fl-datepicker-modal-date-update-new {
  display: flex;
}

.fl-datepicker-modal-date-update-new input:first-child {
  width: 170px;
}

.fl-datepicker-modal-date-update-new
  input:not(.fl-datepicker-modal-date-update-new input:first-child) {
  width: 30px;
  text-align: center;
  font-size: 18px;
  font-weight: 300;
  color: black;
}

.fl-datepicker-modal-date-update-new b {
  display: flex;
  align-items: center;
  justify-content: center;
}

.fl-datepicker-placeholder span {
  color: #030303 !important;
}

.fl-input-datepicker {
  position: relative;
}

/* Large devices (desktops, less than 1200px) */
@media (max-width: 1199.98px) {
  .fl-datepicker-outter {
    height: 40px;
  }
  .fl-datepicker {
    border-width: 2px;
    border-radius: 15px;
    font-size: 14px;
  }
}

/* Medium devices (tablets, less than 992px) */
@media (max-width: 991.98px) {
  .fl-datepicker-modal-content {
    flex-wrap: wrap;
  }
  .fl-datepicker-modal-calendar-side {
    order: 2;
    width: 100%;
  }
  .fl-datepicker-modal-date-side {
    order: 1;
    width: 100%;
    padding: 5px;
  }
}

/* Small devices (landscape phones, less than 768px) */
@media (max-width: 767.98px) {
}

/* Extra small devices (portrait phones, less than 576px) */
@media (max-width: 575.98px) {
}
