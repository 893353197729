
.fl-pt-40 {
    padding-top: 40px;
}

.fl-pb-40 {
    padding-bottom: 40px;
}

.fl-pb-0 {
    padding-bottom: 0px !important;
}

.fl-gotopost-modal-back-icon {
    position: absolute;
    left: 10px;
    top: 7px;
    font-size: 20px;
    width: 30px;
    height: 30px;
    color: #666;
    justify-content: center;
    align-items: center;
    cursor: pointer;
    display: flex;
}

.fl-gotopost-modal-back-icon:hover {
    background-color: rgba(0,0,0,.06274509803921569);
    border-radius: 50%;
}

.fl-disable-actions {
    opacity: 0.5;
    pointer-events: none;
}